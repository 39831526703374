import dataProductsAPI from 'services/api/dataProductsApi';

const getAnalyticsDataGeneral = async () => {
  const response = dataProductsAPI
    .get(`/v2/university/analytics/`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAnalyticsDataCourses = async () => {
  const response = dataProductsAPI
    .get(`/v2/university/analytics/courses/`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAdherenceData = async () => {
  const response = dataProductsAPI
    .get(`/v2/university/analytics/adherence/`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAdherenceCourseData = async (code) => {
  const response = dataProductsAPI
    .get(
      `/v2/university/analytics/adherence/?course_code=${encodeURIComponent(
        code
      )}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getAnalyticsDataGeneral,
  getAdherenceData,
  getAdherenceCourseData,
  getAnalyticsDataCourses,
};
