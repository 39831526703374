import { Dots } from '../styled';

export const renderPrincipalTabs = (array, action) => {
  return array?.find((tab) => tab?.step === action);
};

export const renderDots = (
  activeSlide,
  handleDotClick,
  arrayCount,
  universityColor
) => {
  const dots = Array.from({ length: arrayCount }, (_, index) => (
    <Dots
      key={index}
      isActive={activeSlide === index}
      onClick={() => handleDotClick(index)}
      universityColor={universityColor}
    />
  ));
  return dots;
};
