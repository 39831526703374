import { api } from 'services/api/api';
import qs from 'querystring';
import backofficeApi from 'services/api/backofficeApi';

const getProfessions = () => {
  const response = backofficeApi
    .get(`/professions`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilterProfessions = (params) => {
  const response = backofficeApi
    .get(`/professions?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getProfessionDetail = (id) => {
  const response = backofficeApi
    .get(`/professions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveProfession = (data) => {
  const response = backofficeApi
    .post(`/professions`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const editProfession = (data, id) => {
  const response = backofficeApi
    .patch(`/professions/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteProfession = (id) => {
  const response = backofficeApi
    .delete(`/professions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getProfessionsOptions = (params, getAll) => {
  const response = backofficeApi
    .options(
      `/professions?${!!getAll ? `all=${getAll}` : ''}${qs.stringify(params)}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const existProfession = async (professionName) => {
  const param = new URLSearchParams({ name: professionName }).toString();

  const response = api
    .get(`/api/internal/companies-universities/professions/exists?${param}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getProfessions,
  getProfessionDetail,
  deleteProfession,
  getProfessionsOptions,
  editProfession,
  saveProfession,
  getFilterProfessions,
  existProfession,
};
