import React, { createContext, useContext, useEffect, useState } from 'react';
import CsmApi from '../services/api';
import Api from '../services/api';
import { defaultStudentCategories } from '../resources/defaultStudentCategories';
import BaseLayoutContext from 'contexts/base-layout';
import { studentAdapter } from '../helpers/studentAdapter';
import { filterOptionsAdapter } from '../helpers/filterOptionsAdapter';
import { queryParamsFromFilterObject } from '../../../../components/FilterModal/utils';

export const CsmContext = createContext({});

export function CsmContextProvider({
  children,
  activeCategory,
  setActiveCategory,
  setActiveTab,
  egressed,
}) {
  const { handleIsLoadingState } = useContext(BaseLayoutContext);
  const [studentsList, setStudentsList] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [studentRequestInProgress, setStudentRequestInProgress] = useState(
    false
  );
  const [filterRequestInProgress, setFilterRequestInProgress] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [requestedStudentCount, setRequestedStudentCount] = useState(null);
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [selectStudent, setSelectStudent] = useState(null);

  useEffect(() => {
    async function fetchStudentsCounts() {
      const response = await CsmApi.getStudentCounts(egressed);
      if (response) {
        setCategoriesList(
          defaultStudentCategories
            .map((category) => {
              return {
                ...category,
                studentsCount: response[category.value].toLocaleString('pt-BR'),
                studentsCountNumber: response[category.value],
              };
            })
            .sort(
              (a, b) =>
                Number(b.studentsCountNumber) - Number(a.studentsCountNumber)
            )
        );
      }
    }

    fetchStudentsCounts().then(() => {
      handleIsLoadingState(false);
    });
  }, []);

  async function fetchFilterOptions(category) {
    setFilterRequestInProgress(true);
    const response = await Api.getFilterOptions(category, egressed);
    if (response && response.status === 200) {
      setFilterOptions(filterOptionsAdapter(response.data));
      setFilterRequestInProgress(false);
    }
  }

  async function fetchStudents({
    page = 1,
    filters = queryParamsFromFilterObject(filterState, {
      commaSeparatedFields: ['periods', 'name', 'external_id', 'areas'],
    }),
    category = activeCategory,
  }) {
    setStudentRequestInProgress(true);
    const offset = (page - 1) * limitPerPage;
    let queryParams = `limit=${limitPerPage}&offset=${offset}`;
    if (filters) {
      queryParams = queryParams ? queryParams + '&' + filters : filters;
    }
    const response = await Api.getStudentsByCategory(
      category,
      queryParams,
      egressed
    );
    if (response && response.status === 200) {
      if (Array.isArray(response.data.results)) {
        setStudentsList(response.data.results.map(studentAdapter));
      }
      setStudentRequestInProgress(false);
      setRequestedStudentCount(() => response.data.count);
    }
  }

  async function changePage(page) {
    setSelectedStudents([]);
    setCurrentPage(() => page);
    await fetchStudents({ page });
  }

  async function changeCategory(category) {
    setActiveCategory(() => category);
    setSelectedStudents([]);
    setCurrentPage(() => 1);
    setFilterState(() => ({}));
    if (studentRequestInProgress) {
      Api.cancelRequests();
    }
    await Promise.all([
      fetchStudents({ category, filters: '' }),
      fetchFilterOptions(category),
    ]);
  }

  async function filterStudents(filters) {
    setCurrentPage(() => 1);
    setSelectedStudents([]);
    if (studentRequestInProgress) {
      Api.cancelRequests();
    }
    await fetchStudents({ filters });
  }

  useEffect(() => {
    async function onChangeLimit() {
      setSelectedStudents([]);
      setCurrentPage(() => 1);
      if (studentRequestInProgress) {
        Api.cancelRequests();
      }
      await fetchStudents({});
    }

    if (activeCategory) {
      onChangeLimit();
    }
  }, [limitPerPage]);

  return (
    <CsmContext.Provider
      value={{
        activeCategory,
        categoriesList,
        selectedStudents,
        setSelectedStudents,
        studentsList,
        changeCategory,
        studentRequestInProgress,
        filterOptions,
        filterStudents,
        requestedStudentCount,
        limitPerPage,
        setLimitPerPage,
        currentPage,
        setCurrentPage,
        changePage,
        filterState,
        setFilterState,
        appliedFilters,
        setAppliedFilters,
        filterRequestInProgress,
        setFilterOptions,
        setActiveTab,
        selectStudent,
        setSelectStudent,
        egressed,
      }}
    >
      {children}
    </CsmContext.Provider>
  );
}

export function useCsm() {
  return useContext(CsmContext);
}
