import { authenticatedAPI } from 'services/api/api';

export const getIesEmailConfig = async () => {
  const response = await authenticatedAPI
    .get('api/internal/university/settings')
    .then((res) => res.data)
    .catch((err) => err);

  return response;
};

export const generatePreview = async (color) => {
  const response = await authenticatedAPI
    .get(`api/internal/university/settings/email-preview?color=%23${color}`)
    .then((res) => res.data)
    .catch((err) => err);

  return response;
};

export const updateIesEmailConfig = async (data) => {
  const config = {
    headers: {
      'Content-Type':
        'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    },
  };
  const response = await authenticatedAPI
    .patch('api/internal/university/settings', data, config)
    .then((res) => res.data)
    .catch((err) => err);

  return response;
};
