import React, { useEffect, useRef, useState } from 'react';

import {
  ContainerListing,
  ContainerSteps,
  ContainerButtons,
  NextButton,
  PrevsButton,
  SaveButton,
} from '../styled';
import ListingToFinish from './Steps/Listing';
import StepIndicator from 'components/StepIndicator';
import Button from '../../../Button';
import FinishForm from './Steps/Form';
import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { CoreHandlers } from 'views/StaffPages/FairAndEvents/Handlers/CoreHandlers';
import { StudentHandlers } from 'views/StaffPages/FairAndEvents/Handlers/StudentHandlers';
import ModalInformation from 'components/informationModal';
import { useVisualizationStudentContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationStudent';

export default function FormFinalization({step, setStep}) {
  const visualizationContextInstance = useVisualizationContext();
  const visualizationStudentContextInstance = useVisualizationStudentContext();
  const [config, setConfig] = useState({});
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const { detailsObject } = visualizationContextInstance;

  const { listing } = visualizationStudentContextInstance;

  const formRef = useRef(null);
  const scrollRef = useRef(null);

  const CoreHandlersFactory = CoreHandlers(visualizationContextInstance);
  const studentHandlerFactory = StudentHandlers(
    visualizationContextInstance,
    visualizationStudentContextInstance
  );

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const toStep = (step) => {
    setStep(step);
  };

  useEffect(() => {
    scrollRef?.current?.scrollIntoView();
  }, [scrollRef, step]);

  const onConfirm = () => {
    const { feedback } = formRef?.current?.getData();
    CoreHandlersFactory.handleFinishEvent(detailsObject.id, feedback);
    setModalInformationOpen(false);
  };

  const handleSubmitForm = async () => {
    const {
      students,
      confirmed_presence,
      unconfirmed_presence
    } = await studentHandlerFactory.getRegistrationCounters(detailsObject.id);

    if (listing?.length === 0) {
      return onConfirm();
    }

    setConfig({
      modalWidth: 428,
      bubbleText: 'Atenção!',
      ParagraphText: `De ${students || 0} estudantes, ${
        confirmed_presence || 0
      } foram marcados como presentes e ${
        unconfirmed_presence || 0
      } como ausentes.`,
      twoParagraphText: `Tem certeza que deseja finalizar o evento? Essa ação não poderá ser desfeita.`,
      showButtonCancel: true,
      buttonCloseX: true,
      buttonCancelText: 'NÃO',
      buttonConfirmText: 'SIM, CONTINUAR',
    });
    setModalInformationOpen(true);
  };

  const steps = {
    1: <ListingToFinish />,
    2: <FinishForm formRef={formRef} />,
  };

  const buttons = {
    1: <NextButton onClick={handleNextStep} />,
    2: (
      <>
        <PrevsButton onClick={handlePreviousStep} />
        <Button
          style={{ position: 'absolute', right: 8, top: 7 }}
          theme="dark"
          onClick={handleSubmitForm}
        >
          Salvar
        </Button>
      </>
    ),
  };

  return (
    <>
      <ModalInformation
        buttonConfirmFunction={onConfirm}
        config={config}
        modalOpenState={modalInformationOpen}
        closeModalFunction={() => setModalInformationOpen(false)}
        buttonCancelFunction={() => setModalInformationOpen(false)}
      />
      <ContainerListing>
        <ContainerSteps>
          <div ref={scrollRef}>
            <StepIndicator
              count={2}
              currentStep={step}
              toStepFunction={toStep}
            />
          </div>

          {steps[step]}
        </ContainerSteps>
      </ContainerListing>

      <ContainerButtons>{buttons[step]}</ContainerButtons>
    </>
  );
}
