import styled from 'styled-components';

const getBackgroundColor = (props) => {
  return props.universityColor ? props.universityColor : '#009291';
};

export const ButtonReward = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 30px;
  background-color: transparent;
  border: 1px solid ${getBackgroundColor};
  color: ${getBackgroundColor};
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 769px) {
    width: 114px;
  }
`;

export const ButtonSeeReward = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 15px;
  width: 100%;
  height: 40px;
  background-color: ${getBackgroundColor};
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
`;

export const ButtonStartQuestionnaire = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  padding: 10px 6px;
  height: 40px;
  background-color: ${getBackgroundColor};
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  font-size: 13px;

  &:disabled {
    background-color: #eef2f4;
    color: #949494;
    cursor: default;
  }

  @media (max-width: 700px) {
    margin: 0 auto;
    width: 80%;
  }
`;

export const AlertQuestionnaire = styled.div`
  color: #606062;
  font-size: 11px;
  line-height: 16px;
  margin-top: 13px;
`;

export const ButtonCopyLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 15px;
  width: 100%;
  height: 40px;
  background-color: #fff;
  border: ${getBackgroundColor} 1px solid;
  color: ${getBackgroundColor};
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 16px;

  @media (max-width: 700px) {
    margin-top: 0px;
    padding: 10px 8px;
  }
`;

export const BackgroundModalReward = styled.div`
  background-color: #00000040;

  top: 0;
  right: 0;

  width: 100%;
  height: 100vh;

  position: fixed;
  z-index: 1300;

  display: flex;
  align-items: center;
  justify-content: center;

  .box {
    width: calc(100% - 240px);
    border: 1px solid;
  }
`;

export const ModalReward = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 700px;
  height: auto;

  background-color: #fff;
  border-radius: 10px;
  padding: 40px;

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    border: 1px saddlebrown;
  }
  .left-side {
    width: 70%;
    border: 1px red;
  }

  .right-side {
    width: 30%;
    border: 1px rebeccapurple;
  }

  .container-buttons {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 700px) {
    padding: 23px;

    .container-buttons {
      margin-top: 16px;
      gap: 8px;
      flex-direction: row;
    }

    .right-side {
      width: 100%;
      border: 1px rebeccapurple;
    }

    .container {
      flex-direction: column;
    }

    .left-side {
      width: 100%;
    }
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const ButtonCloseModal = styled.button`
  width: 40px;
  height: 40px;

  border-radius: 50%;
  background-color: #efeeec;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TitleModal = styled.h1`
  text-align: start;
  font-size: 24px;
  color: #606062;
  font-weight: 700;
  margin: 0;
`;

export const SectionRewardLevel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;
  width: ${({ positionSection }) =>
    positionSection === 'bottom' ? '80%' : '70%'};

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const SectionButtonLevel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  transition: 0.5s;
  margin-bottom: 10px;

  @media (max-width: 520px) {
    transition: 0.5s;
    flex-direction: column;
    align-items: start;
    margin-bottom: 20px;
    gap: 15px;
  }
`;

export const ContainerIconReward = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ContainerStatus = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  transition: 0.5s;

  @media (max-width: 450px) {
    transition: 0.5s;
    width: 100%;
  }
`;

export const IconReward = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: -4px 4px 6px #0000004a;
`;

export const TitleReward = styled.p`
  text-align: start;
  font-size: 16px;
  color: #606062;
  margin: 0;
  display: ruby;
  font-weight: ${(props) => (props.isParagraph ? '400' : '700')};
`;

export const StatusReward = styled.p`
  text-align: start;
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  font-style: italic;
  color: ${(props) => (props.returnColor ? props.returnColor : '#606062')};
`;

export const ParagraphReward = styled.p`
  text-align: start;
  font-size: 14px;
  color: #606062;
  margin: 10px 0 0 0;
  width: 100%;
  font-weight: 400;

  @media (max-width: 700px) {
    order: 2;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
  margin: 30px 0;
`;
