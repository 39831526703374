import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import moment from 'moment';
import bag from '../../../../assets/JobOffer/bag.svg';
import {
  Container,
  ContainerSelectiveProcess,
  Header,
  HeaderTitle,
  InputDescNewJobText,
  SelectiveProcessWrapper,
  Wrapper,
  WrapperJobInfo,
  WrapperLink,
  WrapperPeriod,
} from './style';
import Input from '../../Opportunities/Components/Form/input';
import DatePickerCustom from '../../Opportunities/Components/Form/datePicker';
import Radio from '../components/form/radio';
import InputDesc from '../../Opportunities/Components/Form/inputDesc';
import SearchableSelectUn from '../components/form/searchableSelect';

import { colorOrRace, disability, genderIndentity, jobType, publicationType } from './useCases/SelectOptions';
import { createJobOffer, editJobOffer, getCourses, getUnits } from '../services';

import * as Yup from 'yup';
import { payloadCreateExternalJob } from './helpers/payloadCreateExternalJob';
import { schemaValidation } from './useCases/schemaValidationJob';
import {
  IModalConfigCreateCompany,
  IModalConfigCreateCompanyError,
  IModalConfigCreateJobErrorInternal,
  IModalConfigCreateJobInternal,
  IModalConfigEditJobErrorInternal,
  IModalConfigEditJobInternal,
} from '../components/steps/useCases/iModalConfig';
import { CompletedButton, ContainerButtons, FooterForm } from '../components/steps/style';

import { modalityType } from '../components/steps/useCases/SelectOptions';
import LoadingModal from '../components/loadingModal';
import { JobOfferContext } from '../context/jobOfferContext';
import { PreviewButtonExternal } from '../components/steps/Buttons/PreviewButtonExternal';
import CepAddressInput from '../../../../components/CepAddressInput';

export default function ExternalJobOffer() {
  const {
    data: contextData,
    setData,
    ExternalJobRef,
    dataFunction,
    cacheContext,
    isMaster,
    setSelectedUniversities,
  } = useContext(JobOfferContext);

  const [selectiveProcess, setSelectiveProcess] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState([]);

  const [currentModalConfig, setCurrentModalConfig] = useState(
    IModalConfigCreateCompany,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [selectedUniversitiesLength, setSelectedUniversitiesLength] =
    useState(0);

  useEffect(() => {
    if (contextData?.application_type && contextData.application_type.length > 0) {
      setSelectedApplication(contextData.application_type);
      ExternalJobRef.current.setFieldValue(
        'application_type',
        contextData.application_type,
      );
    }
  }, [contextData]);

  const handleSelectModality = (e) => {
    const value = e.value;
    if (value === 'hybrid' || value === 'presential') {
      return setShowAddress(true);
    }
    setShowAddress(false);
  };

  const handleSelectUniversity = (e) => {
    if (e.length > 0) {
      if (e.length < selectedUniversitiesLength) {
        ExternalJobRef.current.setFieldValue('courses', []);
      }
      let universities = e.map((university) => university.value);
      setSelectedUniversitiesLength(universities.length);
      setSelectedUniversities(e);
      return getCoursesAPI(universities);
    }
    setSelectedUniversities([]);

    return getCoursesAPI();
  };

  const handleSelectPublicationType = (e) => {
    const value = e.value;
    if (value === 'link') {
      setData((prev) => ({
        ...prev,
        job_email: '',
      }));
      setShowLink(true);
      setShowEmail(false);
      ExternalJobRef.current.setFieldValue('job_email', '');
    }
    if (value === 'email') {
      setData((prev) => ({
        ...prev,
        divulgation_link: '',
      }));
      setShowLink(false);
      setShowEmail(true);
      ExternalJobRef.current.setFieldValue('divulgation_link', '');
    }
  };

  useEffect(() => {
    if (contextData && contextData?.external?.job_email?.length > 0) {
      ExternalJobRef.current.setFieldValue('publication_type', {
        value: 'email',
        label: 'E-mail de direcionamento',
      });
      ExternalJobRef.current.setFieldValue(
        'job_email',
        contextData?.external?.job_email,
      );
      setShowLink(false);
      setShowEmail(true);
    }

    if (contextData && contextData?.external?.divulgation_link?.length > 0) {
      ExternalJobRef.current.setFieldValue('publication_type', 'link');

      setShowLink(true);
      setShowEmail(false);
      ExternalJobRef.current.setFieldValue(
        'divulgation_link',
        contextData?.external?.divulgation_link,
      );
    }
  }, [
    contextData?.external?.divulgation_link,
    contextData?.external?.job_email,
    cacheContext,
  ]);

  useEffect(() => {
    if (contextData?.modality_type !== 'remote' && contextData?.modality_type?.value !== 'remote') {
      setShowAddress(true);
    }
  }, [contextData, cacheContext]);

  const radioOptions = [
    {
      id: '1',
      value: true,
      label: `Quero promover a diversidade e inclusão de pessoas
        levando em consideração raça, identidade de
        gênero e pessoa com deficiência.`,
      type: 'checkbox',
    },
  ];

  const handleSetModalState = () => {
    setModalOpen(false);
  };

  const returnCurrentFunction = () => {
    if (currentModalConfig === IModalConfigCreateJobErrorInternal) {
      return handleSetModalState();
    }
    if (currentModalConfig === IModalConfigCreateCompanyError) {
      return handleSetModalState();
    }
    if (currentModalConfig === IModalConfigEditJobErrorInternal) {
      return handleSetModalState();
    }
  };

  const handleSelectSelectionProcess = (e) => {
    setData((prev) => ({
      ...prev,
      administrative_selection_process: e.target ? e.target.checked : false,
      disability: [],
      color_or_race: [],
      gender_identity: [],
    }));
    ExternalJobRef.current.setFieldValue('disability', []);
    ExternalJobRef.current.setFieldValue('color_or_race', []);
    ExternalJobRef.current.setFieldValue('gender_identity', []);
    if (e.target.checked) {
      setSelectiveProcess(true);
      return;
    }
    setSelectiveProcess(false);
  };

  const getCoursesAPI = async (e) => {
    if (e) {
      const response = await getCourses(e);
      return setCourses(response);
    }
    const response = await getCourses();
    return setCourses(response);
  };

  useEffect(() => {
    const getUniversitiesAPI = async () => {
      const response = await getUnits();
      setUniversities(response);
    };

    getCoursesAPI();
    getUniversitiesAPI();
  }, []);

  const editJob = async (data) => {
    setModalOpen(true);
    setCurrentModalConfig(IModalConfigEditJobInternal);

    const responseEdit = await editJobOffer(
      contextData.jobId,
      payloadCreateExternalJob(data),
    );

    if (responseEdit.message) {
      return setCurrentModalConfig(IModalConfigEditJobErrorInternal);
    }

    if (contextData.isInternalIes) {
      setModalOpen(false);
      dataFunction.handlePageChange('limit=0');
      setData({});
      dataFunction.setOpenModalPublishJob(false);
    }
  };

  const createJob = async (data) => {
    setModalOpen(true);
    setCurrentModalConfig(IModalConfigCreateJobInternal);

    const responseJOB = await createJobOffer(payloadCreateExternalJob(data));

    if (responseJOB.message) {
      return setCurrentModalConfig(IModalConfigCreateJobErrorInternal);
    }

    setModalOpen(false);
    dataFunction.handlePageChange('limit=0');
    setData({});
    dataFunction.setOpenModalPublishJob(false);
  };

  const handleSubmit = async (data) => {
    const formattedData = {
      ...data,
      start_offer_at: moment(data.start_offer_at).format('YYYY-MM-DD'),
      end_offer_at: moment(data.end_offer_at).format('YYYY-MM-DD'),
    };
    ExternalJobRef.current.setErrors({});

    try {
      await schemaValidation.validate(formattedData, {
        abortEarly: false,
      });

      if (contextData.isEditJob) {
        return editJob(formattedData);
      }

      return createJob(formattedData);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        ExternalJobRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (contextData.isEditJob) {
      const citys = contextData.locations
        .map((item) =>
          cacheContext.filter((itemF) => String(itemF.id) === String(item)),
        )
        .flat();

      ExternalJobRef.current.setFieldValue('locations', citys);

      ExternalJobRef.current.setFieldValue(
        'application_type',
        contextData.application_type,
      );
      ExternalJobRef.current.setFieldValue('courses', contextData.courses);

      ExternalJobRef.current.setFieldValue(
        'modality_type',
        contextData.modality_type.value,
      );

      if (contextData.enabled_just_for_universities) {
        ExternalJobRef.current.setFieldValue(
          'enabled_just_for_universities',
          contextData.enabled_just_for_universities,
        );
      }

      if (contextData.administrative_selection_process) {
        setSelectiveProcess(true);
      }
    }
  }, [contextData, cacheContext]);

  useEffect(() => {
    if (selectedApplication.length > 1) {
      const lastOption = selectedApplication[selectedApplication.length - 1];
      setSelectedApplication([lastOption]);
      ExternalJobRef.current.setFieldValue('application_type', [lastOption]);
    }
  }, [selectedApplication]);

  const handleApplicationTypeSelect = (options) => {
    setSelectedApplication(options);
  };

  return (
    <Fragment>
      <LoadingModal
        modalOpenState={modalOpen}
        config={currentModalConfig}
        buttonFunction={returnCurrentFunction}
      />

      <Wrapper>
        <Form
          ref={ExternalJobRef}
          onSubmit={handleSubmit}
          initialData={contextData}
        >
          <Header>
            <img src={bag} alt="Ícone de mala representando processo seletivo"/>
            <HeaderTitle>Divulgar processo seletivo</HeaderTitle>
          </Header>
          <p>
            Agora só falta completar as <strong>informações específicas</strong>{' '}
            de sua vaga.
          </p>

          <WrapperPeriod>
            <h5>Período de Divulgação*</h5>
            <Container>
              <DatePickerCustom
                minDate={new Date()}
                name="start_offer_at"
                placeholder="De"
              />
              <DatePickerCustom
                minDate={new Date()}
                name="end_offer_at"
                placeholder="Até"
              />
            </Container>
          </WrapperPeriod>

          <WrapperLink>
            <h5>Como você deseja divulgar sua vaga:</h5>
            <SearchableSelectUn
              name="publication_type"
              label="Tipo de publicação*"
              options={publicationType}
              onChange={handleSelectPublicationType}
            />

            {showLink && (
              <Input
                name="divulgation_link"
                placeholder="Insira o link que o estudante irá acessar ao se candidatar"
              />
            )}
            {showEmail && (
              <Input
                name="job_email"
                placeholder="Insira o e-mail que o estudante irá acessar ao se candidatar"
              />
            )}
          </WrapperLink>

          <hr />

          <WrapperJobInfo>
            <InputDescNewJobText>
              <Input
                name="name"
                placeholder="Digite aqui..."
                label="Nome da vaga*"
              />
            </InputDescNewJobText>
            <InputDescNewJobText>
              <InputDesc
                name="description"
                placeholder="Digite aqui"
                label="Descrição da vaga"
              />
            </InputDescNewJobText>
            {isMaster && (
              <>
                <SearchableSelectUn
                  name="enabled_just_for_universities"
                  label="Instituições que deseja publicar"
                  placeholder="Todas"
                  isMulti
                  isClearable
                  options={universities}
                  onChange={handleSelectUniversity}
                />

                <SearchableSelectUn
                  name="courses"
                  label="Cursos"
                  placeholder="Todos"
                  options={courses}
                  isMulti
                  isClearable
                />
              </>
            )}

            <SearchableSelectUn
              name="application_type"
              label="Tipo de vaga*"
              isClearable
              isMulti
              value={selectedApplication}
              onChange={handleApplicationTypeSelect}
              options={jobType}
            />

            <SearchableSelectUn
              name="modality_type"
              label="Modalidade de trabalho*"
              options={modalityType}
              onChange={handleSelectModality}
            />
            {showAddress && (
              <CepAddressInput
                formRef={ExternalJobRef}
                defaultValues={{
                  zip_code: contextData.zip_code,
                  logradouro: contextData.address,
                  address_number: contextData.address_number,
                  address_complement: contextData.address_complement,
                  bairro: contextData.district,
                  uf: contextData.state,
                  localidade: contextData.city?.label,
                }}
              />
            )}
            {!isMaster && (
              <SearchableSelectUn
                name="courses"
                label="Cursos"
                isClearable
                isMulti
                options={courses}
              />
            )}
          </WrapperJobInfo>

          <SelectiveProcessWrapper>
            <h5>Processo Seletivo Afirmativo </h5>
            <Radio
              name="administrative_selection_process"
              options={radioOptions}
              onChange={handleSelectSelectionProcess}
              checked={selectiveProcess}
            />
          </SelectiveProcessWrapper>

          {selectiveProcess && (
            <ContainerSelectiveProcess>
              <SearchableSelectUn
                name="disability"
                isClearable
                isMulti
                label="PCD - Selecione possíveis deficiências"
                options={disability}
              />
              <SearchableSelectUn
                name="color_or_race"
                isClearable
                label="Raça/Cor"
                isMulti
                options={colorOrRace}
              />
              <SearchableSelectUn
                name="gender_identity"
                isClearable
                isMulti
                label="Identidade de Gênero"
                options={genderIndentity}
              />
            </ContainerSelectiveProcess>
          )}

          <FooterForm>
            <ContainerButtons>
              <PreviewButtonExternal
                courses={courses}
                handleSubmit={handleSubmit}
              >
                Pré-Visualizar Vaga
              </PreviewButtonExternal>
              <CompletedButton> Publicar </CompletedButton>
            </ContainerButtons>
          </FooterForm>
        </Form>
      </Wrapper>
    </Fragment>
  );
}
