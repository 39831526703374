import backofficeApi from 'services/api/backofficeApi';

class AcademicInnovationApi {
  getCountTags = async () => {
    return await backofficeApi
      .get('/academic-modeling/count-tags')
      .then((response) => response.data)
      .catch((error) => error);
  };

  getPendingTagsList = async (term = '') => {
    return await backofficeApi
      .get(`/academic-modeling/pending-tags?${term}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getAssociatedTagsList = async (term = '') => {
    return await backofficeApi
      .get(`/academic-modeling/associated-tags?${term}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getMainTagsList = async (term = '') => {
    return await backofficeApi
      .get(`/academic-modeling/principal-tags?${term}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getOptionsRequirement = async () => {
    return await backofficeApi
      .get('/academic-modeling/market/options')
      .then((response) => response.data)
      .catch((error) => error);
  };

  postRegisterTagMain = async (data) => {
    try {
      const response = await backofficeApi.post(
        '/academic-modeling/principal-tags',
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getOptionsCourses = async () => {
    return await backofficeApi
      .get('/academic-modeling/courses/options')
      .then((response) => response.data)
      .catch((error) => error);
  };

  getOptionsDisciplines = async () => {
    return await backofficeApi
      .get('/academic-modeling/disciplines/options')
      .then((response) => response.data)
      .catch((error) => error);
  };

  getOptionsPendingTags = async () => {
    return await backofficeApi
      .get('/academic-modeling/pending-tags/options')
      .then((response) => response.data)
      .catch((error) => error);
  };

  getOptionsFilterMainTags = async () => {
    return await backofficeApi
      .get(`/academic-modeling/principal-tags/filter`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getOptionsFilterPending = async () => {
    return await backofficeApi
      .get(`/academic-modeling/pending-tags/filter`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getOptionsFilterAssociated = async () => {
    return await backofficeApi
      .get(`/academic-modeling/associated-tags/filter`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getCoursesOptionsAssociateForm = () => {
    return backofficeApi
      .get(
        `/academic-modeling/courses/options
      `
      )
      .then((response) => response.data)
      .catch((error) => error);
  };

  getDisciplinesOptionsAssociateForm = (courses) => {
    return backofficeApi
      .get(
        `/academic-modeling/disciplines/options?${
          courses && `courses=${courses}`
        }`
      )
      .then((response) => response.data)
      .catch((error) => error);
  };

  getTagsOptionsAssociateForm = (query) => {
    return backofficeApi
      .get(`/academic-modeling/pending-tags/options?${query}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  putAssociteTagForm = (id, data) => {
    return backofficeApi
      .put(`/academic-modeling/principal-tags/associate/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => error);
  };

  getSelectUniversityAssociateForm = () => {
    return backofficeApi
      .get(`/academic-modeling/principal-tags/options`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getDetailsPrincipalTag = (id) => {
    return backofficeApi
      .get(`/academic-modeling/principal-tags/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  putEditTag = (id, data) => {
    return backofficeApi
      .put(`/academic-modeling/principal-tags/${id}`, data)
      .then((response) => response.data)
      .catch((error) => error);
  };

  putAssociateTag = (id, data) => {
    return backofficeApi
      .put(`/academic-modeling/pending-tags/associate/${id}`, data)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getAssociatedTagsModal = (id, query) => {
    return backofficeApi
      .get(`/academic-modeling/principal-tags/${id}/content-tags?${query}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getAssociatedTags = (id) => {
    return backofficeApi
      .get(`/academic-modeling/principal-associated-tags/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  };
}

export default new AcademicInnovationApi();
