import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { ContainerLabelInput, SelectValidate, UnInputLabel } from './styled';

export default function SearchableSelectChallenge({
  label,
  name,
  options,
  placeholder,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
 
  
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (!ref.state.value) {
          return '';
        } else if (ref.state.value.value) {
          return ref.state.value.value;
        }
        return ref.state.value;
      },

      setValue: (ref, value) => {
        const valor = ref.props.options.find(option => option.value === value.value);
        ref.onChange(valor);

      }
    });
  }, [fieldName, registerField]);


  return (
    <ContainerLabelInput>
      {label && <UnInputLabel>{label}</UnInputLabel>}
      <SelectValidate
        defaultValue={defaultValue}
        getOptionLabel={(option) => option.display_name}
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
    </ContainerLabelInput>
  );
}
