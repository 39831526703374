import Axios from 'axios';
import urls from 'constants/urls';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ContainerPDF } from './styled';
import { api } from 'services/api/api';

export default function PublicValidateCertificate({ match }) {
  const { params } = match;
  const { registrationId } = params;

  const [certificate, setCertificate] = useState(null);
  const [hasError, setHasError] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const getCertificate = async () => {
    const response = await api
      .get(
        `/api/internal/students/academic-events/certificate/${registrationId}/validate`
      )
      .catch((error) => {
        return error;
      })
      .then((response) => {
        return response.data;
      });

    return response;
  };

  const transformCertificate = async () => {
    const response = await getCertificate();
    if (response) {
      const base64 = response?.certificate_base64;
      const pdfContent = Buffer.from(base64, 'base64');
      const pdfBlob = new Blob([pdfContent], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setCertificate(pdfUrl);
    }
  };

  useEffect(() => {
    transformCertificate();
  }, []);

  if (!certificate)
    return (
      <ContainerPDF>
        <span>Não foi encontrado nenhum certificado!</span>
      </ContainerPDF>
    );

  return (
    <ContainerPDF>
      <Document file={certificate} className="Document">
        <Page pageNumber={1} />
      </Document>
    </ContainerPDF>
  );
}
