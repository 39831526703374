import React, { useContext, useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { OportunitiesContext } from '../../../Context/OportunitiesContext';
import {
  AboutTitle,
  DescImg,
  Img,
  MultiStepContainer,
  SubTitle,
  Title,
  Wrapper,
} from '../../../Styles/formStyled';
import extensionProjectIcon from 'assets/img/extension-project-medium.svg';
import Input from '../../Form/input';
import InputDesc from '../../Form/inputDesc';
import InputOrientation from '../../Form/inputOrientation';
import FileInput from '../../Form/fileInput';
import FooterForm from '../../FooterForm';
import StepIndicator from '../../StepIndicator';
import * as Yup from 'yup';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionSection/context/ExtensionProjectsContext';

export function StepTwo({ prevFormStep }) {
  const formRef = useRef(null);
  const { formData, setFormValues, setStep, modalIsOpen } = useContext(
    OportunitiesContext
  );
  const { imageChanged, setImageChanged } = useContext(
    ExtensionProjectsContext
  );
  const [nameTouched, setNameTouched] = useState(false);
  const [descriptionTouched, setDescriptionTouched] = useState(false);
  const [orientationTouched, setOrientationTouched] = useState(false);

  const isEdit = !!formData.uuid;

  const schema = Yup.object().shape({
    name: Yup.string().required('Informe o nome do projeto'),
    attraction_image: Yup.mixed()
      .test('fileSize', 'O arquivo deve ser menor que 2MB', (value) => {
        if (value) {
          return value.size <= 2000000;
        }
        return true;
      })
      .test(
        'fileFormat',
        'Somente são aceitos arquivos JPEG ou PNG',
        (value) => {
          if (value) {
            return value.type === 'image/jpeg' || value.type === 'image/png';
          }
          return true;
        }
      ),
    description: Yup.string().test(
      'sizeError',
      'Limite de 2000 caracteres excedido',
      (value) => {
        if (stripHtml(value).replace(/\s/g, '').length <= 2000) {
          return true;
        }
      }
    ),
    orientation: Yup.string().test(
      'sizeError',
      'Limite de 2000 caracteres excedido',
      (value) => {
        if (stripHtml(value).replace(/\s/g, '').length <= 2000) {
          return true;
        }
      }
    ),
  });

  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  async function handleSubmit() {
    if (formRef.current) {
      let validatedData;
      try {
        const data = formRef?.current?.getData();
        validatedData = await schema.validate(data, {
          abortEarly: false,
        });
        formRef.current.setErrors({});
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
      if (validatedData) {
        setFormValues(validatedData);
        formRef.current.reset();
        setStep(2);
      }
    }
  }

  return (
    <Form
      ref={formRef}
      initialData={formData}
      onSubmit={handleSubmit}
      style={{ marginTop: '16px' }}
    >
      <MultiStepContainer>
        <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
      </MultiStepContainer>
      <Wrapper>
        <DescImg>
          <Img src={extensionProjectIcon} />
          <Title>Projeto de Extensão</Title>
        </DescImg>
        <AboutTitle>Divulgação</AboutTitle>
        <Input
          name="name"
          placeholder="Digite aqui..."
          label="Nome*"
          onChange={(e) => {
            if (nameTouched) {
              if (e.target.value === '') {
                formRef.current.setFieldError('name', 'Campo obrigatório');
              } else {
                formRef.current.setFieldError('name', '');
              }
            } else if (e.target.value !== '') {
              setNameTouched(true);
            }
          }}
        />
        <InputDesc
          name="description"
          placeholder="Digite aqui..."
          label="Descrição"
          modalIsOpen={modalIsOpen}
          handleChange={(e) => {
            if (descriptionTouched) {
              if (stripHtml(e).replace(/\s/g, '').length <= 2000) {
                formRef.current.setFieldError('description', '');
              } else {
                formRef.current.setFieldError(
                  'description',
                  'Limite de 2000 caracteres excedido'
                );
              }
            } else if (e != '') {
              setDescriptionTouched(true);
            }
          }}
        />
        <InputOrientation
          name="orientation"
          placeholder="Digite aqui..."
          label="Orientações do passo a passo"
          modalIsOpen={modalIsOpen}
          handleChange={(e) => {
            if (orientationTouched) {
              if (stripHtml(e).replace(/\s/g, '').length <= 2000) {
                formRef.current.setFieldError('orientation', '');
              } else {
                formRef.current.setFieldError(
                  'orientation',
                  'Limite de 2000 caracteres excedido'
                );
              }
            } else if (e != '') {
              setOrientationTouched(true);
            }
          }}
        />
        <Title
          style={{
            color: '#6d6d6d',
            fontWeight: 'bold',
            fontSize: '16px',
            margin: '16px 0 0 0',
          }}
        >
          Imagem de destaque <br />{' '}
          <SubTitle>
            Adicione uma imagem em formato JPEG ou PNG com dimensão de 1600 X
            838
          </SubTitle>
        </Title>
        <FileInput
          formRef={formRef}
          name="attraction_image"
          accept=".jpeg,.png,.jpg"
          onChangeFile={() => setImageChanged(true)}
        />
      </Wrapper>
      <FooterForm haveNext={true} haveBack={true} prevsState={prevFormStep} />
    </Form>
  );
}
