import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { array, func, number, object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import JobHeaderTab, { Container } from './styled';

const InternalTab = (props) => {
  const { classes, activeTab, handleTabChange, tabs } = props;

  return (
    <Container>
      <AppBar position="static" className={classes.appBarHolder}>
        {tabs && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            scrollButtons="on"
            scrollable
            classes={{
              indicator: classes.tabIndicator,
              scrollButtons: classes.scrollButtons,
              flexContainer: classes.borderContainer,
            }}
          >
            {tabs.map((tabName) => (
              <Tab
                style={{ zIndex: 2 }}
                key={tabName}
                label={tabName}
                className={classes.tabButton}
              />
            ))}
          </Tabs>
        )}
      </AppBar>
    </Container>
  );
};

InternalTab.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
};

export default withStyles(JobHeaderTab)(InternalTab);
