import backofficeApi from 'services/api/backofficeApi';
const getCourses = () => {
  const response = backofficeApi
    .get(`/courses`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilterCourses = (params) => {
  const response = backofficeApi
    .get(`/courses?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCourseDetail = (id) => {
  const response = backofficeApi
    .get(`/courses/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveCourse = (data) => {
  const response = backofficeApi
    .post(`/courses`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const editCourse = (data, id) => {
  const response = backofficeApi
    .patch(`/courses/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteCourses = (id) => {
  const response = backofficeApi
    .delete(`/courses/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCoursesOptions = (params = true) => {
  const response = backofficeApi
    .options(`/courses?all=${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCoursesFilterOptions = () => {
  const response = backofficeApi
    .options(`/courses`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getFilterCourses,
  getCourses,
  getCourseDetail,
  deleteCourses,
  getCoursesOptions,
  getCoursesFilterOptions,
  editCourse,
  saveCourse,
};
