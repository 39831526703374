import { authenticatedAPI } from 'services/api/api';
import httpRequest from 'services/university/HttpRequest';
import { studentFilterAdapter } from '../../adapters/student/studentListAdapter';
import qs from 'querystring';

const createNewCourseArea = async (data) => {
  const response = authenticatedAPI
    .post('/api/internal/companies/jobs/areas', data)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getRegistrationFormOptions = async (universities = null) => {
  const response = authenticatedAPI
    .options(`/api/internal/companies/jobs`, { data: universities })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCandidatesOptions = async (id) => {
  const response = authenticatedAPI
    .options(`/api/internal/companies/jobs/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveJobOffer = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/jobs`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateJobOffer = async (id, data) => {
  const response = authenticatedAPI
    .patch(`/api/internal/companies/jobs/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getProfessionByCourseId = async (courseId) => {
  // internal/companies/jobs/þrofessions?course=1
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs/professions?course=${courseId}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getMarketRequirementsByCourseId = async (courseQueryString) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/companies/jobs/market-requirements?${courseQueryString}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCompanyJobDetails = async (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCompanyPartnerJobsOpenings = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs/partners?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteJobOffer = async (id) => {
  const response = await authenticatedAPI
    .delete(`/api/internal/companies/jobs/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCandidates = async (jobOfferId, pageQS) => {
  const response = await authenticatedAPI
    .get(`/api/internal/companies/jobs/${jobOfferId}/applications?${pageQS}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getPartnerCandidates = async (jobOfferId, pageQS) => {
  const response = await authenticatedAPI
    .get(
      `/api/internal/companies/partner/jobs/${jobOfferId}/applications?${pageQS}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCandidateDetail = async (jobOfferId, applicationId) => {
  const response = await authenticatedAPI
    .get(
      `/api/internal/companies/jobs/${jobOfferId}/applications/${applicationId}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getApplicationOptions = async (jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .options(
      `api/internal/companies/jobs/${jobOfferId}/applications/${applicationId}/answer`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const returnJobApplicationCompany = async (jobOfferId, applicationId, data) => {
  return await httpRequest.post(
    `/api/internal/companies/jobs/${jobOfferId}/applications/${applicationId}/answer`,
    data || { mark_as: 'interesting' }
  );
};

const setCompanyCurriculumScore = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/score`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const createNewInterview = async (interview, jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .post(
      `/api/internal/companies/jobs/${jobOfferId}/applications/${applicationId}/interview`,
      interview
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getInterviewId = async (interviewId, jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/companies/jobs/${jobOfferId}/applications/${applicationId}/interview/${interviewId}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateInterview = async (interview, jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .put(
      `/api/internal/companies/jobs/${jobOfferId}/applications/${applicationId}/interview/${interview.id}`,
      interview
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const checkResendNotification = async (jobOfferId) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs/${jobOfferId}/students/notifications`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const resendNotification = async (jobOfferId) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/jobs/${jobOfferId}/students/notifications`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const companyFilterApplications = async (jobId, values) => {
  const queryString = studentFilterAdapter(values, false);
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs/${jobId}/applications?${queryString}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const partnerFilterApplications = async (jobId, values) => {
  const queryString = studentFilterAdapter(values, false);
  const response = authenticatedAPI
    .get(
      `/api/internal/companies/partner/jobs/${jobId}/applications?${queryString}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const companyApplicationFilterOptions = async (jobOfferId) => {
  const response = authenticatedAPI
    .options(`api/internal/companies/jobs/${jobOfferId}/applications`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const partnerCompanyApplicationFilterOptions = async (jobOfferId) => {
  const response = authenticatedAPI
    .options(`api/internal/companies/partner/jobs/${jobOfferId}/applications`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const hasExpiredJobOffers = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs/expired`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};
const getMacroAreas = async (universities) => {
  if (universities && universities.length > 0) {
    const response = authenticatedAPI
      .get(`api/internal/companies/jobs/macro-areas?${universities}`)
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  } else {
    const response = authenticatedAPI
      .get(`api/internal/companies/jobs/macro-areas`)
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
};

const getCourses = async (data) => {
  const queryString = {
    ...(data.universities && {
      universities: data.universities.map((university) => university.value),
    }),
    ...(data.macroAreas && {
      macroAreas: data.macroAreas.map((macroArea) => macroArea.value),
    }),
  };
  const response = authenticatedAPI
    .get(`api/internal/companies/jobs/courses?${qs.stringify(queryString)}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const sendFeedback = async (id, feedback) => {
  const response = authenticatedAPI
    .put(`internal/companies/job-offer/${id}/feedback`, { feedback })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getAdditionalAndNewAreas = async () => {
  const response = authenticatedAPI
    .get('api/internal/companies/jobs/additional-and-new-areas')
    .then((res) => res.data)
    .catch((err) => err);
  return response;
};

export {
  getMarketRequirementsByCourseId,
  companyApplicationFilterOptions,
  companyFilterApplications,
  setCompanyCurriculumScore,
  getProfessionByCourseId,
  getRegistrationFormOptions,
  saveJobOffer,
  updateJobOffer,
  getCompanyPartnerJobsOpenings,
  getCompanyJobDetails,
  deleteJobOffer,
  getCandidates,
  getCandidateDetail,
  getApplicationOptions,
  returnJobApplicationCompany,
  getCandidatesOptions,
  createNewCourseArea,
  createNewInterview,
  getInterviewId,
  updateInterview,
  checkResendNotification,
  resendNotification,
  getPartnerCandidates,
  partnerFilterApplications,
  partnerCompanyApplicationFilterOptions,
  hasExpiredJobOffers,
  getMacroAreas,
  getCourses,
  sendFeedback,
  getAdditionalAndNewAreas,
};
