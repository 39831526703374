import axios from 'axios';
import URLS from '../../../../constants/urls';

import { authenticatedAPI } from 'services/api/api';

const generateQueryStates = (states) => {
  if (states) return `states=${states.join('&states=')}`;
  return '';
};

const getNetworkingCounts = async () => {
  const response = await authenticatedAPI
    .get(`api/internal/students/networking/companies/count`)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompaniesConnected = async (
  limit = 9,
  offset = 0,
  queryString = ''
) => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/companies?limit=${limit}&offset=${offset}&${queryString}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getWithVacancies = async (limit = 9, offset = 0, queryString = '') => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/companies-actives-offers?limit=${limit}&offset=${offset}&${queryString}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompaniesFavorites = async (
  limit = 9,
  offset = 0,
  queryString = ''
) => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/favorite-companies?limit=${limit}&offset=${offset}&${queryString}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const postFavorite = async (id) => {
  const data = { company: id };
  const response = await authenticatedAPI
    .post(`api/internal/students/networking/favorite-companies`, {
      company: id,
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const deleteFavorite = async (id) => {
  const data = { company: id };
  const response = await authenticatedAPI
    .delete(`api/internal/students/networking/favorite-companies/${id}`)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompaniesFilterOptions = async (states = '') => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/companies/filters/options?${generateQueryStates(
        states
      )}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompaniesActiveJobOffersFilterOptions = async (states = '') => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/active-offers/filters/options?${generateQueryStates(
        states
      )}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompaniesFavoritesFilterOptions = async (states = '') => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/favorite-companies/filters/options?${generateQueryStates(
        states
      )}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export {
  deleteFavorite,
  postFavorite,
  getCompaniesConnected,
  getWithVacancies,
  getCompaniesFavorites,
  getCompaniesFilterOptions,
  getCompaniesActiveJobOffersFilterOptions,
  getCompaniesFavoritesFilterOptions,
  getNetworkingCounts,
};
