import { authenticatedAPI } from 'services/api/api';

const getCoursesOptions = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/university/curriculum-matrix/courses/`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getSubjectsCourse = async (id, query = '') => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/curriculum-matrix/courses/${id}/disciplines?${query}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const postCreateMatrix = async (object) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/curriculum-matrix/`, { ...object })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getMatricesCourse = async (courseId, query = '&limit=8') => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/curriculum-matrix/?course=${courseId}${query}&is_active=true`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getDisabledMatricesCourse = async (courseId, query = '') => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/curriculum-matrix/?course=${courseId}&is_active=false${query}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const addDisciplinesInMatrix = (matrixId, disciplines) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/curriculum-matrix/${matrixId}`, {
      disciplines: disciplines,
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const removeDisciplinesInMatrix = (matrixId, disciplines) => {
  const response = authenticatedAPI
    .patch(
      `/api/internal/university/curriculum-matrix/${matrixId}?remove_disciplines=true`,
      {
        disciplines: disciplines,
      }
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateDiscipline = (disciplineId, data) => {
  return authenticatedAPI
    .patch(
      `/api/internal/university/curriculum-matrix/disciplines/${disciplineId}/`,
      data
    )
    .then((res) => res)
    .catch((error) => error);
};

const patchEditMatrix = (matrixId, data) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/curriculum-matrix/${matrixId}`, {
      ...data,
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const setMatrixActive = (matrixId) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/curriculum-matrix/${matrixId}`, {
      is_active: true,
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const setMatrixActual = (matrixId) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/curriculum-matrix/${matrixId}`, {
      is_actual: true,
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getMatrixData = (matrixId) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/curriculum-matrix/${matrixId}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const reactivateMatrices = (course, items) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/curriculum-matrix/reactivate/`, {
      course: course,
      matrixes: items,
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const desactiveMatrix = (matrixId) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/curriculum-matrix/${matrixId}`, {
      is_active: false,
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getCoursesOptions,
  getSubjectsCourse,
  postCreateMatrix,
  getMatricesCourse,
  addDisciplinesInMatrix,
  removeDisciplinesInMatrix,
  getMatrixData,
  getDisabledMatricesCourse,
  setMatrixActive,
  reactivateMatrices,
  patchEditMatrix,
  setMatrixActual,
  desactiveMatrix,
  updateDiscipline,
};
