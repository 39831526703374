import React, { useContext, useEffect } from 'react';
import { func, shape } from 'prop-types';
import { getInternshipAuthData } from 'services/internship';
import urls from 'constants/urls';
import BaseLayoutContext from 'contexts/base-layout';
import { tokenService } from 'auth/jwt/token.service';

export const InternshipRedirect = ({ openSnackbar, history }) => {
  useEffect(() => {
    try {
      const fetchInternshipAuth = async () => {
        const response = await getInternshipAuthData();
        if (response.message) {
          openSnackbar(
            'Falha ao redirecionar para o módulo de estágio. Tente novamente mais tarde.',
            true
          );
        } else {
          const url = urls.FRONT_INTERNSHIP_URL;
          const token = tokenService.token;
          window.open(`${url}/login/?wlt=${token}`, '_self');
        }
      };

      fetchInternshipAuth();
    } catch (err) {
      openSnackbar(
        'Falha ao redirecionar para o módulo de estágio. Tente novamente mais tarde.',
        true
      );
    }
  }, []);

  return <div />;
};

InternshipRedirect.propTypes = {
  openSnackbar: func.isRequired,
  history: shape.isRequired,
};
