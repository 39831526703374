import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import qs from 'querystring';
import { func, shape } from 'prop-types';

import {
  getProfessions,
  deleteProfession,
  getProfessionsOptions,
  getFilterProfessions,
} from 'services/backoffice/professions';

import {
  getAreas,
  deleteAreas,
  getFilterAreas,
  getAreasOptions,
} from 'services/backoffice/areas';

import {
  getCourses,
  deleteCourses,
  getFilterCourses,
  getCoursesFilterOptions,
} from 'services/backoffice/courses';

import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import headerBackgroundImage from 'assets/img/companies-jobs.jpg';

import DataTable from 'components/DataTable/DataTable';
import Button from 'components/CustomButtons/Button';
import { Add } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

import ProfessionForm from './components/ProfessionForm';
import ProfessionDetail from './components/ProfessionDetail';
import ProfessionCells from './components/ProfessionCells';
import ProfessionTableHead from './components/ProfessionTableHead';

import AreaForm from './components/AreaForm';
import AreaDetail from './components/AreaDetail';
import AreaCells from './components/AreaCells';
import AreasTableHead from './components/AreasTableHead';

import CourseForm from './components/CourseForm';
import CourseDetail from './components/CourseDetail';
import CourseCells from './components/CourseCells';
import CourseTableHead from './components/CourseTableHead';
import ProfessionFilter from './components/ProfessionFilter';

function Profession({
  handleIsLoadingState,
  openSnackbar,
  openModalWithContent,
  closeModal,
  metadata,
}) {
  const [contentLoad, setContentLoad] = useState(false);
  const [data, setData] = useState([]);
  const [areas, setAreas] = useState([]);
  const [courses, setCourses] = useState([]);
  const [activeTabContent, setActiveTabContent] = useState(0);
  const [professionValues, setProfessionValues] = useState({});
  const [coursesValues, setCoursesValues] = useState({});

  const [changeFormCourse, setchangeFormCourse] = useState({
    id: 0,
    control: false,
  });

  const [changeForm, setChangeForm] = useState({
    id: 0,
    control: false,
  });
  const [saveFormAndOpenProfessions, setSaveFormAndOpenProfessions] = useState({
    id: 0,
    response: {},
  });
  const [saveFormAndOpenCourses, setsaveFormAndOpenCourses] = useState({
    id: 0,
    response: {},
  });
  const divRef = useRef(null);

  function createProfessionData(id, name, published, createdAt) {
    return { id, name, published, createdAt };
  }

  function createAreaData(id, name, subgroup, createdAt) {
    return { id, name, subgroup, createdAt };
  }

  async function filter(params) {
    if (divRef.current) {
      divRef.current.scrollIntoView();
    }
    if (activeTabContent === 0) {
      const response = await getFilterProfessions(qs.stringify(params));
      const resFactory = await response?.map((profession) =>
        createProfessionData(
          profession.id,
          profession.name,
          profession.published,
          profession.created_at
        )
      );
      setData(resFactory);
    } else if (activeTabContent === 1) {
      const response = await getFilterCourses(qs.stringify(params));
      const resFactory = await response.map((profession) =>
        createCourseData(
          profession.id,
          profession.name,
          profession.macro_area,
          profession.created_at
        )
      );
      setCourses(resFactory);
    } else if (activeTabContent === 2) {
      const response = await getFilterAreas(qs.stringify(params));
      const resFactory = await response.map((profession) =>
        createAreaData(
          profession.id,
          profession.name,
          profession.subgroup,
          profession.created_at
        )
      );
      setAreas(resFactory);
    }
  }

  const fetchProfessionsData = useCallback(async (reload = false) => {
    if (reload) {
      setContentLoad(false);
    }
    const response = await getProfessions();

    if (!response.message) {
      const newData = [];

      response.map((profession) =>
        newData.push(
          createProfessionData(
            profession.id,
            profession.name,
            profession.published,
            profession.created_at
          )
        )
      );

      setData(newData);
    } else {
      handleIsLoadingState(false);
      openSnackbar(
        'Falha ao carregar as atuações! Tente novamente mais tarde.',
        true
      );
    }

    if (reload) {
      setContentLoad(true);
    }
  }, []);

  async function updateProfessionValues(professionData) {
    const newProfessionValues = { ...professionValues };
    if (!professionData.subgroup) {
      await newProfessionValues.courses.push({
        label: professionData.name,
        value: professionData.id,
      });
    } else {
      if (professionData.subgroup.value === 'default') {
        await newProfessionValues.defaultAreas.push({
          label: professionData.name,
          value: professionData.id,
        });
      }
      if (professionData.subgroup.value === 'additional_courses') {
        await newProfessionValues.aditionalCourses.push({
          label: professionData.name,
          value: professionData.id,
        });
      }
      if (professionData.subgroup.value === 'market_tendencies') {
        await newProfessionValues.marketTendencies.push({
          label: professionData.name,
          value: professionData.id,
        });
      }
    }

    setProfessionValues(newProfessionValues);
  }

  async function updateCoursesValues(Data) {
    const newCoursesValues = { ...coursesValues };

    if (Data.subgroup && Data.subgroup.value === 'market_tendencies') {
      await newCoursesValues.marketTendencies.push({
        label: Data.name,
        value: Data.id,
      });
    }
  }

  const fetchAreaData = useCallback(async (reload = false) => {
    if (reload) setContentLoad(false);
    const response = await getAreas();
    if (!response.message) {
      const newArea = [];

      response.map((area) =>
        newArea.push(
          createAreaData(area.id, area.name, area.subgroup, area.created_at)
        )
      );
      setAreas(newArea);
    } else {
      handleIsLoadingState(false);
      openSnackbar(
        'Falha ao carregar as áreas! Tente novamente mais tarde.',
        true
      );
    }
    if (reload) setContentLoad(true);
  }, []);

  const handleEditProfession = useCallback(
    async (professionData, responseData = false) => {
      if (responseData) {
        await updateProfessionValues(responseData);
      }

      openModalWithContent(
        <ProfessionForm
          loadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          openModal={openModalWithContent}
          closeModal={closeModal}
          updateData={fetchProfessionsData}
          professionId={professionData.id || professionData}
          isEdit
          canPublish={metadata.permissions.professions.publish}
          addArea={handleAddArea}
          addCourse={handleAddCourse}
          setProfessionValues={setProfessionValues}
          professionValues={professionValues}
        />,
        professionData.name || professionValues.name,
        null,
        () => setProfessionValues({})
      );
    },
    [professionValues]
  );

  const handleAddArea = useCallback(
    (areaType = false, professionId, shouldReturnProfessionForm = false) => {
      openModalWithContent(
        <AreaForm
          loadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          openModal={openModalWithContent}
          closeModal={closeModal}
          updateData={fetchAreaData}
          areaType={areaType}
          professionId={professionId}
          shouldReturnProfessionForm={shouldReturnProfessionForm}
          editProfession={handleEditProfession}
          addProfession={handleAddProfession}
          setProfessionValues={setProfessionValues}
          setSaveFormAndOpenProfessions={setSaveFormAndOpenProfessions}
        />,
        'Cadastrar Área',
        null,
        () => defineOnExitingFunction(shouldReturnProfessionForm, professionId)
      );
    },
    []
  );

  const handleAddCourse = useCallback(
    (professionId, shouldReturnProfessionForm = false) => {
      openModalWithContent(
        <CourseForm
          loadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          openModal={openModalWithContent}
          closeModal={closeModal}
          updateData={fetchCoursesData}
          addArea={handleAddArea}
          professionId={professionId}
          shouldReturnProfessionForm={shouldReturnProfessionForm}
          editProfession={handleEditProfession}
          addProfession={handleAddProfession}
          setCoursesValues={setCoursesValues}
          setProfessionValues={setProfessionValues}
          setsaveFormAndOpenCourses={setsaveFormAndOpenCourses}
        />,
        'Cadastrar Curso',
        null,
        () => {
          defineOnExitingFunction(shouldReturnProfessionForm, professionId);
          setCoursesValues({});
        }
      );
    },
    []
  );

  const handleAddProfession = async (responseData = false) => {
    if (responseData) {
      await updateProfessionValues(responseData);
    }

    openModalWithContent(
      <ProfessionForm
        loadingState={handleIsLoadingState}
        openSnackbar={openSnackbar}
        openModal={openModalWithContent}
        closeModal={closeModal}
        updateData={fetchProfessionsData}
        addArea={handleAddArea}
        addCourse={handleAddCourse}
        setProfessionValues={setProfessionValues}
        professionValues={professionValues}
      />,
      'Cadastrar Atuação',
      null,
      () => setProfessionValues({})
    );
  };

  async function removeItem(selected, item) {
    const newItem = item;

    for (const [, select] of selected.entries()) {
      const index = await newItem.findIndex((i) => i.id === select);
      index && (await newItem.splice(index, 1));
    }

    return newItem;
  }

  const handleDeleteProfession = useCallback(
    async (selected) => {
      handleIsLoadingState(true);

      const errors = [];

      for (const [, id] of selected.entries()) {
        const response = id && (await deleteProfession(id));

        response.message && errors.push(id);
      }

      if (errors.length > 0) {
        openSnackbar(
          `Falha ao deletar os itens com o id: ${errors.toString()}`,
          true
        );
      } else {
        if (selected.length === data.length) {
          setData([]);
        } else {
          const newData = await removeItem(selected, data);
          setData(newData);
        }
        openSnackbar('Foram deletados todos os itens selecionados.');
      }

      handleIsLoadingState(false);
      return data;
    },
    [data]
  );

  const handleProfessionDetail = useCallback((professionData) => {
    openModalWithContent(
      <ProfessionDetail
        loadingState={handleIsLoadingState}
        openSnackbar={openSnackbar}
        professionId={professionData.id}
      />,
      professionData.name
    );
  }, []);

  const fetchCoursesData = useCallback(async (reload = false) => {
    if (reload) setContentLoad(false);
    const response = await getCourses();

    if (!response.message) {
      const newCourses = [];

      response.map((course) =>
        newCourses.push(
          createCourseData(
            course.id,
            course.name,
            course.macro_area,
            course.created_at
          )
        )
      );
      setCourses(newCourses);
    } else {
      handleIsLoadingState(false);
      openSnackbar(
        'Falha ao carregar as áreas! Tente novamente mais tarde.',
        true
      );
    }
    if (reload) setContentLoad(true);
  }, []);

  function createCourseData(id, name, macroArea, createdAt) {
    return { id, name, macroArea, createdAt };
  }

  const handleAddAreaCourse = useCallback(
    (
      areaType = false,
      courseId,
      shouldReturnProfessionForm = false,
      isCourse = false
    ) => {
      openModalWithContent(
        <AreaForm
          loadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          openModal={openModalWithContent}
          closeModal={closeModal}
          updateData={fetchAreaData}
          areaType={areaType}
          professionId={courseId}
          shouldReturnProfessionForm={shouldReturnProfessionForm}
          isCourse={isCourse}
          editProfession={handleEditCourse}
          addProfession={handleAddCourse}
          setProfessionValues={setCoursesValues}
          setsaveFormAndOpenCourses={setsaveFormAndOpenCourses}
          setSaveFormAndOpenProfessions={setSaveFormAndOpenProfessions}
        />,
        'Cadastrar Área',
        null,
        () =>
          defineOnExitingFunctionCourse(shouldReturnProfessionForm, courseId)
      );
    },
    []
  );

  const handleEditCourse = useCallback(
    async (courseData, responseData = false) => {
      if (responseData) {
        await updateCoursesValues(responseData);
      }
      openModalWithContent(
        <CourseForm
          loadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          openModal={openModalWithContent}
          closeModal={closeModal}
          updateData={fetchCoursesData}
          courseId={courseData.id}
          isEdit
          addArea={handleAddAreaCourse}
          coursesValues={coursesValues}
          setCoursesValues={setCoursesValues}
        />,
        courseData.name || coursesValues.name,
        null,
        () => {
          setCoursesValues({});
        }
      );
    },
    [coursesValues]
  );

  const handleCourseDetail = useCallback((courseData) => {
    openModalWithContent(
      <CourseDetail
        loadingState={handleIsLoadingState}
        openSnackbar={openSnackbar}
        courseId={courseData.id}
      />,
      courseData.name
    );
  }, []);

  const handleDeleteCourse = useCallback(
    async (selected) => {
      handleIsLoadingState(true);

      const errors = [];

      for (const [, id] of selected.entries()) {
        const response = id && (await deleteCourses(id));

        response.message && errors.push(id);
      }

      if (errors.length > 0) {
        openSnackbar(`Falha ao deletar os itens: ${errors.toString()}`, true);
      } else {
        if (selected.length === courses.length) {
          setCourses([]);
        } else {
          const newCourses = await removeItem(selected, courses);
          setCourses(newCourses);
        }
        openSnackbar('Foram deletados todos os itens selecionados.');
      }

      handleIsLoadingState(false);
      return courses;
    },
    [courses]
  );

  const handleDeleteArea = useCallback(
    async (selected) => {
      handleIsLoadingState(true);

      const errors = [];

      for (const [, id] of selected.entries()) {
        const response = id && (await deleteAreas(id));

        response.message && errors.push(id);
      }

      if (errors.length > 0) {
        openSnackbar(`Falha ao deletar os itens: ${errors.toString()}`, true);
      } else {
        if (selected.length === areas.length) {
          setAreas([]);
        } else {
          const newAreas = await removeItem(selected, areas);
          setAreas(newAreas);
        }
        openSnackbar('Foram deletados todos os itens selecionados.');
      }

      handleIsLoadingState(false);
      return areas;
    },
    [areas]
  );

  const handleEditArea = useCallback((areaData) => {
    openModalWithContent(
      <AreaForm
        loadingState={handleIsLoadingState}
        openSnackbar={openSnackbar}
        openModal={openModalWithContent}
        closeModal={closeModal}
        updateData={fetchAreaData}
        areaId={areaData.id}
        isEdit
      />,
      areaData.name
    );
  }, []);

  const handleAreaDetail = useCallback((areaData) => {
    openModalWithContent(
      <AreaDetail
        loadingState={handleIsLoadingState}
        openSnackbar={openSnackbar}
        areaId={areaData.id}
      />,
      areaData.name
    );
  }, []);

  const handleTabChange = useCallback((event, value) => {
    setActiveTabContent(value);
  }, []);

  function defineOnExitingFunction(shouldReturnProfessionForm, professionId) {
    if (shouldReturnProfessionForm) {
      setChangeForm({
        id: professionId,
        control: !changeForm.control,
        saved: false,
      });
    }
  }

  function defineOnExitingFunctionCourse(
    shouldReturnProfessionForm,
    professionId
  ) {
    if (shouldReturnProfessionForm) {
      setchangeFormCourse({
        id: professionId,
        control: !changeForm.control,
        saved: false,
      });
    }
  }

  useEffect(() => {
    if (saveFormAndOpenProfessions.id !== 0) {
      if (saveFormAndOpenProfessions.id === null) {
        handleAddProfession(saveFormAndOpenProfessions.response);
      } else {
        handleEditProfession(
          saveFormAndOpenProfessions.id,
          saveFormAndOpenProfessions.response
        );
      }
    }
  }, [saveFormAndOpenProfessions]);

  useEffect(() => {
    if (saveFormAndOpenCourses.id !== 0) {
      if (saveFormAndOpenCourses.id === null) {
        handleAddCourse(saveFormAndOpenCourses.response);
      } else {
        handleEditCourse(
          saveFormAndOpenCourses.id,
          saveFormAndOpenCourses.response
        );
      }
    }
  }, [saveFormAndOpenCourses]);

  useEffect(() => {
    if (changeForm.id !== 0) {
      if (changeForm.id) {
        handleEditProfession(changeForm.id);
      } else {
        handleAddProfession();
      }
    }
  }, [changeForm]);

  useEffect(() => {
    if (changeFormCourse.id !== 0) {
      if (changeFormCourse.id) {
        handleEditCourse(changeFormCourse, coursesValues);
      } else {
        handleAddCourse();
      }
    }
  }, [changeFormCourse]);

  async function fetchData() {
    await Promise.all([
      fetchProfessionsData(),
      fetchAreaData(),
      fetchCoursesData(),
    ]);
    setContentLoad(true);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    contentLoad && (
      <div ref={divRef}>
        <CompanyHeader
          title="Atuações"
          activeTab={activeTabContent}
          handleTabChange={handleTabChange}
          backgroundImage={headerBackgroundImage}
          tabs={[
            `Atuações (${data.length})`,
            `Cursos (${courses.length})`,
            `Áreas (${areas.length})`,
          ]}
        />
        <Grid container justify="center" wrap style={{ gridColumnGap: '3em' }}>
          {activeTabContent === 0 && (
            <Fragment>
              {metadata.permissions.professions.add && (
                <Grid
                  item
                  xs
                  md={12}
                  sm={12}
                  justify="flex-end"
                  style={{ display: 'flex', paddingRight: 55 }}
                >
                  <Button round onClick={() => handleAddProfession()}>
                    Cadastrar atuação
                    <Add />
                  </Button>
                </Grid>
              )}
              <Grid item xs md={8} sm={8}>
                <DataTable
                  tableData={data}
                  loadingState={handleIsLoadingState}
                  handleDelete={handleDeleteProfession}
                  handleEdit={handleEditProfession}
                  handleDetail={handleProfessionDetail}
                  DataCells={ProfessionCells}
                  DataTableHead={ProfessionTableHead}
                  tableTitle="Atuações"
                  permissions={metadata.permissions.professions}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sm={12}
                style={{
                  marginTop: 17,
                }}
              >
                <ProfessionFilter
                  filterOptions={getProfessionsOptions}
                  filterProfessions={filter}
                  activeTabContent={activeTabContent}
                />
              </Grid>
            </Fragment>
          )}

          {activeTabContent === 1 && (
            <Fragment>
              {metadata.permissions.courses.add && (
                <Grid
                  item
                  xs
                  md={8}
                  sm={8}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Button round onClick={handleAddCourse}>
                    Cadastrar Curso
                    <Add />
                  </Button>
                </Grid>
              )}
              <Grid item xs md={8} sm={8}>
                <DataTable
                  tableData={courses}
                  loadingState={handleIsLoadingState}
                  handleDelete={handleDeleteCourse}
                  handleEdit={handleEditCourse}
                  handleDetail={handleCourseDetail}
                  DataCells={CourseCells}
                  DataTableHead={CourseTableHead}
                  tableTitle="Cursos"
                  permissions={metadata.permissions.courses}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sm={12}
                style={{
                  marginTop: 17,
                }}
              >
                <ProfessionFilter
                  filterProfessions={filter}
                  filterOptions={getCoursesFilterOptions}
                  activeTabContent={activeTabContent}
                />
              </Grid>
            </Fragment>
          )}
          {activeTabContent === 2 && (
            <Fragment>
              {metadata.permissions.areas.add && (
                <Grid
                  item
                  xs
                  md={8}
                  sm={8}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Button round onClick={handleAddArea}>
                    Cadastrar Área
                    <Add />
                  </Button>
                </Grid>
              )}
              <Grid item xs md={8} sm={8}>
                <DataTable
                  tableData={areas}
                  loadingState={handleIsLoadingState}
                  handleDelete={handleDeleteArea}
                  handleEdit={handleEditArea}
                  handleDetail={handleAreaDetail}
                  DataCells={AreaCells}
                  DataTableHead={AreasTableHead}
                  tableTitle="Áreas"
                  permissions={metadata.permissions.areas}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sm={12}
                style={{
                  marginTop: 17,
                }}
              >
                <ProfessionFilter
                  filterOptions={getAreasOptions}
                  filterProfessions={filter}
                  activeTabContent={activeTabContent}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </div>
    )
  );
}

Profession.propTypes = {
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  openModalWithContent: func.isRequired,
  closeModal: func.isRequired,
  metadata: shape({}).isRequired,
};

export default Profession;
