import styled from 'styled-components';

export const TextArea = styled.textarea`
  width: 100%;
  max-width: 732px;
  height: 120px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;

  font-size: 13px;
  font-family: 'Roboto', sans-serif;

  padding: 10px;

  ::-webkit-input-placeholder {
    color: #b8b8b8;
  }
  input:-moz-placeholder {
    color: #b8b8b8;
  }
  textarea:-moz-placeholder {
    color: #b8b8b8;
  }
`;

export const Question = styled.label`
  font-size: 14px;
  color: #606062;
  width: 100%;
  max-width: 631px;

  padding-top: 36px;
  font-weight: 700;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Box = styled.div`
  width: 100%;
  max-width: 732px;

  color: ${(props) => (props.countText === 0 ? 'red' : 'black')};

  text-align: end;
`;
