import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import HeaderBackground from 'assets/SettingsPage/Profile/HeaderBackground.png';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Wrapper,
  WhiteCard,
  GreenButton,
  WrapperLoadmore,
  ContainerHeaderFilter,
  SearchInput,
} from './Styled';

import IESCompanyAPI from './Service/API';
import HorizontalCard from './UIComponents/HorizontalCard';
import FullScreenModal from './UIComponents/FullScreenModal';
import CardSkeleton from './UIComponents/HorizontalCard/skeleton';
import CompanyHeaderTab from './UIComponents/Tabs';
import BaseLayoutContext from 'contexts/base-layout';
import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';

export default function EngineIesCompany({ handleIsLoadingState }) {
  const [universities, setUniversities] = useState([]);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [selectedUnivesityId, setSelectedUniversityId] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [paginationNext, setPaginationNext] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [debouncedValue, setDebouncedValue] = useState('');

  const { universityColor } = useContext(BaseLayoutContext);

  const value = getDebouncedValue(searchValue, 500);

  useEffect(() => {
    handleApplyFilters(filterState);
  }, [value]);

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  function getDebouncedValue(value, delay) {    
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }

  function getQueryVariablesList(filters, iesName) {
    if (iesName){
      return { ['name']:iesName, ...filters}
    } else {
      return { ...filters };
    }
  }

  const handleApplyFilters = (filters) => {
    setFilterState(filters);
    setAppliedFilters(filters);
    
    const createFilter = new createFilterObject();
    const [param, _] = createFilter.convert(getQueryVariablesList(filters, value));

    getUniversities(`?${param}`);
  };

  const getUniversities = async (query = '', concat) => {
    setLoading(true);
    const { count, next, results } = await IESCompanyAPI.getUniversities(query);
    setResultsCount(count || 0);
    setPaginationNext(next?.split(`?`)[1] || null);
    setLoading(false);

    if (concat) {
      setUniversities((prevs) => [...prevs, ...results || []]);
      return;
    }
    setUniversities(results || []);
  };

  const openModal = useCallback((id, tab) => {
    if (tab) {
      setActiveTab(tab);
    }

    setSelectedUniversityId(id);
    setFullScreenOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setFullScreenOpen(false);
  }, []);

  const handleClickLoadMore = () => {
    getUniversities(`?${paginationNext}`, true);
  };

  if (fullScreenOpen) {
    return (
      <>
        <CompanyHeader title="Conexões" backgroundImage={HeaderBackground} />
        <FullScreenModal
          id={selectedUnivesityId}
          closeModal={closeModal}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </>
    );
  }

  return (
    <>
      <CompanyHeader title="Conexões" backgroundImage={HeaderBackground} />
      <Wrapper>
        <WhiteCard>
          <CompanyHeaderTab
            universityColor={universityColor}
            activeTab={0}
            iesTotalCount={resultsCount}
            filterState={filterState}
            setFilterState={setFilterState}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            handleApplyFilters={handleApplyFilters}
          />
          <ContainerHeaderFilter>
            <SearchInput
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Pesquisar por nome"
              value={searchValue}
            />
          </ContainerHeaderFilter>
          <HorizontalCard
            universities={universities}
            openModal={openModal}
            setActiveTab={setActiveTab}
            loading={loading}
          />
          {loading && <CardSkeleton />}
          {paginationNext && (
            <WrapperLoadmore>
              <GreenButton onClick={handleClickLoadMore}>Ver mais</GreenButton>
            </WrapperLoadmore>
          )}
        </WhiteCard>
      </Wrapper>
    </>
  );
}
