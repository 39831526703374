import React, { useState, useEffect } from 'react';
import { shape, func, bool } from 'prop-types';
import {
  FormHelperText,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  ListItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { FastField } from 'formik';
import SearchableSelect from 'react-select';
import classNames from 'classnames';
import DateTimePicker from 'components/DateTimePicker';
import { Mixpanel } from 'utils/mixpanel';
import useDefineColor from 'constants/colors';

const ExperienceCareer = ({
  classes,
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
  isEdit,
  setFieldValue,
  handleInputChange,
  locationOptions,
  areasOptions,
  knowledgesOptions,
  setFieldTouched,
  getDedicatedHours,
  sectionOptions,
  experienceTypesOptions,
  countriesOptions,
  setExperienceFormChosen,
  universityColor,
}) => {
  const [isCareerForm, setIsCareerForm] = useState(false);
  const [showDescription, setShowDescription] = useState(true);
  const [charCount, setCharCount] = useState(0);
  const maxChars = 200;

  //To perform local testing when creating experiences in the student profile
  // const mockCountries = [
  //   {
  //     value: '004',
  //     phone: '0093',
  //     iso: 'AF',
  //     iso3: 'AFG',
  //     label: 'Afeganistão',
  //     fulllabel: 'República Islâmica do Afeganistão',
  //   },
  // ];

  useEffect(() => {
    if (isCareerForm) {
      Mixpanel.track('Abriu formulário Experiência de Carreira');
      if (!isEdit) {
        setExperienceFormChosen('careerExperience');
      }
    }
  }, [isCareerForm]);

  useEffect(() => {
    if (isEdit) {
      if (values.description && values.description.length > 0) {
        setShowDescription(false);
        setCharCount(values.description.length);
      }
    }
  }, [isEdit, values.description]);

  return (
    <ListItem className={classes.listItem}>
      <Grid container justify="center" spacing={24}>
        <Grid item md={12} xs={12}>
          <FastField
            className={classes.inputField}
            InputProps={{
              className: classes.input,
            }}
            InputLabelProps={{
              FormLabelClasses: {
                focused: classes.inputField,
              },
            }}
            name="role"
            variant="filled"
            id="role"
            label="Cargo"
            placeholder="Ex.: Analista de sistemas"
            onChange={(event) => {
              handleChange(event);
              setIsCareerForm(true);
            }}
            onBlur={handleBlur}
            error={!!errors.role && !!touched.role}
            value={values.role}
            component={TextField}
            required
          />
          {errors.role && touched.role ? (
            <FormHelperText className={classes.helperText}>
              {errors.role}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12}>
          <FastField
            className={classes.inputField}
            InputProps={{
              className: classes.input,
            }}
            InputLabelProps={{
              FormLabelClasses: {
                focused: classes.inputField,
              },
            }}
            name="company"
            variant="filled"
            id="company"
            label="Empresa"
            placeholder="Informe o nome comercial da empresa"
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.company && !!touched.company}
            value={values.company}
            component={TextField}
            required
          />
          {errors.company && touched.company ? (
            <FormHelperText className={classes.helperText}>
              {errors.company}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="location"
              shrink
              required
              error={!!errors.experienceType && !!touched.experienceType}
            >
              Tipo de Experiência
            </InputLabel>

            <SearchableSelect
              name="experienceType"
              id="experienceType"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              menuPlacement="bottom"
              isSearchable
              classNamePrefix="react-select"
              className={classes.selectSection}
              onBlur={(event) => setFieldTouched('experienceType', event.label)}
              onInputChange={handleInputChange}
              onChange={(event) => {
                if (event) {
                  setFieldValue('experienceType', event);
                }
              }}
              options={experienceTypesOptions || []}
              value={values.experienceType}
              required
              placeholder="Selecione"
            />
          </FormControl>
          {!!errors.experienceType && !!touched.experienceType && (
            <FormHelperText className={classes.helperText}>
              {errors.experienceType}
            </FormHelperText>
          )}
        </Grid>
        <Grid item md={values.country ? 6 : 12} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="country"
              shrink
              required
              error={errors.country && touched.country}
            >
              País
            </InputLabel>

            <SearchableSelect
              name="country"
              id="country"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              menuPlacement="bottom"
              isSearchable
              classNamePrefix="react-select"
              className={classNames([
                classes.selectSection,
                classes.selectSectionAutoHeight,
                classes.fullWidth,
              ])}
              onBlur={(event) => setFieldTouched('country', event.label)}
              onChange={(event) => {
                if (event) {
                  setFieldValue('country', event);
                  setFieldValue('location', null);
                } else {
                  setFieldValue('country', '');
                }
              }}
              options={countriesOptions || []}
              value={values.country}
              placeholder="Selecione"
            />
          </FormControl>
          {!!errors.country && !!touched.country && (
            <FormHelperText className={classes.helperText}>
              {errors.country}
            </FormHelperText>
          )}
        </Grid>
        {values.country && values.country.label === 'Brasil' ? (
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel
                htmlFor="location"
                shrink
                required
                error={!!errors.location && !!touched.location}
              >
                Cidade
              </InputLabel>
              <SearchableSelect
                name="location"
                id="location"
                isClearable={false}
                InputLabel={{
                  shrink: true,
                }}
                menuPlacement="bottom"
                isSearchable
                classNamePrefix="react-select"
                className={classes.selectSection}
                onBlur={(event) => setFieldTouched('location', event.label)}
                onInputChange={handleInputChange}
                onChange={(event) => {
                  if (event) {
                    setFieldValue('location', event);
                  }
                }}
                options={locationOptions || []}
                value={values.location}
                placeholder="Local sede da empresa"
              />
            </FormControl>
            {!!errors.location && !!touched.location && (
              <FormHelperText className={classes.helperText}>
                {errors.location}
              </FormHelperText>
            )}
          </Grid>
        ) : (
          values.country && (
            <Grid item md={6} xs={12}>
              <FastField
                className={classes.inputField}
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: classes.inputField,
                  },
                }}
                name="location"
                required
                variant="filled"
                id="location"
                label="Cidade"
                placeholder="Ex.: Nova Iorque"
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.location && !!touched.location}
                value={values.location}
                component={TextField}
              />
              {errors.location && touched.location ? (
                <FormHelperText className={classes.helperText}>
                  {errors.location}
                </FormHelperText>
              ) : null}
            </Grid>
          )
        )}

        <Grid item md={12} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="area"
              shrink
              required
              error={!!errors.area && !!touched.area}
            >
              Principal Competência/Especialidade
            </InputLabel>
            {((isEdit && values.area) || !isEdit) && (
              <SearchableSelect
                name="area"
                id="area"
                isClearable={false}
                InputLabel={{
                  shrink: true,
                }}
                menuPlacement="bottom"
                isSearchable
                classNamePrefix="react-select"
                className={classes.selectSection}
                onBlur={(event) => setFieldTouched('area', event.label)}
                onInputChange={handleInputChange}
                onChange={(event) => {
                  if (event) {
                    setFieldValue('area', event);
                  }
                }}
                options={areasOptions || []}
                value={values.area}
                placeholder="Digite ou Escolha"
              />
            )}
          </FormControl>
          {!!errors.area && !!touched.area && (
            <FormHelperText className={classes.helperText}>
              {errors.area}
            </FormHelperText>
          )}
        </Grid>

        <Grid item md={12} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="knowledges"
              shrink
              required
              error={!!errors.knowledges && !!touched.knowledges}
            >
              Outros Conhecimentos e Competências
            </InputLabel>

            <SearchableSelect
              name="knowledges"
              id="knowledges"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              menuPlacement="bottom"
              isSearchable
              isMulti
              classNamePrefix="react-select"
              className={classNames([
                classes.selectSection,
                classes.selectSectionAutoHeight,
              ])}
              onBlur={(event) => setFieldTouched('knowledges', event.label)}
              onInputChange={handleInputChange}
              onChange={(event) => {
                if (event.length <= 5) {
                  setFieldValue('knowledges', event);
                }
              }}
              options={knowledgesOptions || []}
              value={values.knowledges}
              placeholder="Digite ou Escolha"
            />
          </FormControl>
          {!!errors.knowledges ? (
            !!touched.knowledges && (
              <FormHelperText className={classes.helperText}>
                {errors.knowledges}
              </FormHelperText>
            )
          ) : (
            <FormHelperText className={classes.helperText}>
              Até 5 conhecimentos
            </FormHelperText>
          )}
        </Grid>

        <Grid item md={12} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="dedicatedHours"
              shrink
              required
              error={!!errors.dedicatedHours && !!touched.dedicatedHours}
            >
              Horas Dedicadas
            </InputLabel>
            {((isEdit && values.dedicatedHours) || !isEdit) && (
              <SearchableSelect
                name="dedicatedHours"
                id="dedicatedHours"
                isClearable={false}
                InputLabel={{
                  shrink: true,
                }}
                menuPlacement="bottom"
                isSearchable
                classNamePrefix="react-select"
                className={classes.selectSection}
                onBlur={(event) =>
                  setFieldTouched('dedicatedHours', event.label)
                }
                onChange={(event) => {
                  if (event) {
                    setFieldValue('dedicatedHours', event);
                  }
                }}
                options={getDedicatedHours(24, 'Hora') || []}
                defaultValue={values.dedicatedHours}
                placeholder="Tempo médio de dedicação diária"
              />
            )}
          </FormControl>
          {!!errors.dedicatedHours && !!touched.dedicatedHours && (
            <FormHelperText className={classes.helperText}>
              {errors.dedicatedHours}
            </FormHelperText>
          )}
        </Grid>

        <Grid item md={12} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="section"
              shrink
              required
              error={errors.section && touched.section}
            >
              Setor
            </InputLabel>
            {((isEdit && values.section) || !isEdit) && (
              <SearchableSelect
                name="section"
                id="section"
                isClearable={false}
                InputLabel={{
                  shrink: true,
                }}
                menuPlacement="bottom"
                isSearchable
                classNamePrefix="react-select"
                className={classNames([
                  classes.selectSection,
                  classes.fullWidth,
                ])}
                onBlur={(event) => setFieldTouched('section', event.label)}
                onInputChange={handleInputChange}
                onChange={(event) => {
                  if (event) {
                    setFieldValue('section', event);
                  } else {
                    setFieldValue('section', '');
                  }
                }}
                options={sectionOptions || []}
                value={values.section}
                placeholder="Setor de atuação da empresa"
              />
            )}
          </FormControl>
          {!!errors.section && !!touched.section && (
            <FormHelperText className={classes.helperText}>
              {errors.section}
            </FormHelperText>
          )}
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container justify="center" spacing={24}>
            <Grid item md={!values.isCurrentJob ? 6 : 12} xs={12}>
              <DateTimePicker
                className={(classes.textFieldDatePicker, classes.inputField)}
                InputProps={{
                  className: [
                    classes.input,
                    classes.selectSection,

                    classes.selectSectionCard,
                  ],
                }}
                InputLabelProps={{
                  shrink: true,
                  FormLabelClasses: {
                    focused: classes.inputField,
                  },
                }}
                variant="filled"
                name="startedAt"
                id="startedAt"
                type="date"
                label="Data de Início"
                required
                placeholder="Ex.: 17/11/2021"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.startedAt}
                error={errors.startedAt && touched.startedAt}
              />
              {errors.startedAt && touched.startedAt ? (
                <FormHelperText className={classes.helperText}>
                  {errors.startedAt}
                </FormHelperText>
              ) : null}
            </Grid>
            {!values.isCurrentJob && (
              <Grid item md={6} xs={12}>
                <DateTimePicker
                  className={(classes.textFieldDatePicker, classes.inputField)}
                  InputProps={{
                    className: [
                      classes.input,
                      classes.selectSection,

                      classes.selectSectionCard,
                    ],
                  }}
                  InputLabelProps={{
                    shrink: true,
                    FormLabelClasses: {
                      focused: classes.inputField,
                    },
                  }}
                  variant="filled"
                  name="endedAt"
                  id="endedAt"
                  type="date"
                  label="Data de Término"
                  placeholder="Ex.: 17/11/2022"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endedAt}
                  error={errors.endedAt && touched.endedAt}
                />
                {errors.endedAt && touched.endedAt && (
                  <FormHelperText className={classes.helperText}>
                    {errors.endedAt}
                  </FormHelperText>
                )}
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              {showDescription ? (
                <p style={{ textAlign: 'center' }}>
                  Quer falar mais sobre essa experiência?{' '}
                  <a
                    href="#"
                    onClick={() => setShowDescription(false)}
                    style={{
                      color: universityColor,
                      textDecoration: 'underline',
                    }}
                  >
                    Clique aqui e adicione uma descrição.
                  </a>
                </p>
              ) : (
                <>
                  <FastField
                    className={classes.inputField}
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    name="description"
                    variant="filled"
                    id="description"
                    label="Descrição (Opcional)"
                    placeholder="Descreva quais foram os seus resultados, suas conquistas e onde você mais se destacou nessa experiência."
                    onChange={(event) => {
                      if (event.target.value.length <= maxChars) {
                        handleChange(event);
                        setCharCount(event.target.value.length);
                      }
                    }}
                    onBlur={handleBlur}
                    error={!!errors.role && !!touched.role}
                    value={values.description}
                    component={TextField}
                    rows={4}
                    multiline
                    inputProps={{ maxLength: maxChars }}
                  />
                  <div
                    style={{
                      textAlign: 'right',
                      marginTop: 5,
                      fontSize: 12,
                      color: 'gray',
                    }}
                  >
                    CARACTERES: {charCount}/{maxChars}
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        <ListItem
          className={classNames([
            classes.listItem,
            classes.listItemPaddingTopZero,
          ])}
        >
          <FormControlLabel
            style={{ justifyContent: 'center !important' }}
            classes={{
              label: classes.checkboxLabel,
            }}
            labelPlacement="center"
            label="Estou atualmente neste emprego"
            control={
              <Checkbox
                id="isCurrentJob"
                name="isCurrentJob"
                checked={values.isCurrentJob}
                classes={{
                  checked:
                    classes[`${useDefineColor(universityColor)}checkBox`],
                }}
                onChange={handleChange}
                color="default"
                tabIndex={-1}
                disableRipple
              />
            }
          />
        </ListItem>
      </Grid>
    </ListItem>
  );
};

ExperienceCareer.defaultProps = {
  setExperienceFormChosen: undefined,
};

ExperienceCareer.propTypes = {
  classes: shape.isRequired,
  errors: shape.isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  touched: shape.isRequired,
  values: shape.isRequired,
  isEdit: bool.isRequired,
  setFieldValue: func.isRequired,
  setFieldTouched: func.isRequired,
  handleInputChange: func.isRequired,
  locationOptions: shape.isRequired,
  areasOptions: shape.isRequired,
  getDedicatedHours: shape.isRequired,
  sectionOptions: shape.isRequired,
  knowledgesOptions: shape.isRequired,
  experienceTypesOptions: shape.isRequired,
  countriesOptions: shape.isRequired,
  setExperienceFormChosen: func,
};

export default ExperienceCareer;
