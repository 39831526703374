import React, { useContext, useEffect } from 'react';
import ListExtensionProject from './ListExtensionProject';

import {
  Container,
  ContainerDashed,
  Content,
  SeeMoreButton,
  SubTitle,
  Title,
  Wrapper,
} from './styled';
import { ExtensionProjectsContext } from '../../../context/ExtensionProjectsContext';
import Loading from '../../Loading';
import { formatNumber } from 'utils/formatNumber';
import GenericMessage from 'components/GenericMessage';

export default function ToFinishProjects({ setStatus }) {
  const {
    futureExtension,
    inProgressExtension,
    toFinishProjects,
    setPaginationOneCurrentPage,
    setPaginationTwoCurrentPage,
    setPaginationThreeCurrentPage,
    activeTabExtensionProject,
    setCurrentPageToFinishProject,
    toFinishProjectCount,
    toFinishLoading,
    countToFinishedProjects,
  } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    setPaginationOneCurrentPage(0);
    setPaginationTwoCurrentPage(0);
    setPaginationThreeCurrentPage(0);
  }, [activeTabExtensionProject]);

  function handleNextPage() {
    setCurrentPageToFinishProject((currentPage) => currentPage + 10);
  }

  return (
    <Container>
      <Content>
        {inProgressExtension !== null &&
        inProgressExtension.length === 0 &&
        toFinishProjects !== null &&
        toFinishProjects.length === 0 &&
        futureExtension !== null &&
        futureExtension.length === 0 ? (
          <GenericMessage title="Não identificamos nenhum Projeto de Extensão a finalizar!" />
        ) : (
          <Wrapper>
            <Title marginTop="14px">
              A finalizar ({formatNumber(countToFinishedProjects)})
            </Title>
            <SubTitle>
              Aqui você pode ver seus Projetos de Extensão que já passaram da
              data de realização.
            </SubTitle>
            {toFinishProjects !== null && toFinishProjects.length === 0 ? (
              <GenericMessage title="Não identificamos nenhum Projeto de Extensão a finalizar!" />
            ) : (
              <ListExtensionProject
                setStatus={setStatus}
                array={toFinishProjects}
              />
            )}
            {toFinishProjects?.length < toFinishProjectCount && (
              <SeeMoreButton
                onClick={handleNextPage}
                disabled={toFinishLoading}
              >
                {toFinishLoading ? <Loading color={'#FFF'} /> : 'Ver mais'}
              </SeeMoreButton>
            )}
          </Wrapper>
        )}
      </Content>
    </Container>
  );
}
