import React from 'react';
import ExtensionProjectsProvider from './context/ExtensionProjectsContext';
import Engine from './ExtensionEngine';
import OportunitiesProvider from '../Opportunities/Context/OportunitiesContext';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import oportunidades from 'assets/img/oportunidades.png';

export default function Extensions() {
  return (
    <OportunitiesProvider>
      <ExtensionProjectsProvider>
        <CompanyHeader
          title="Projetos de extensão"
          activeTab={0}
          handleTabChange={() => null}
          backgroundImage={oportunidades}
          tabs={null}
        />
        <Engine />
      </ExtensionProjectsProvider>
    </OportunitiesProvider>
  );
}
