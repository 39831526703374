import React, { Fragment } from 'react';
import cx from 'classnames';
import { arrayOf, shape } from 'prop-types';
import BaseLayoutContext from 'contexts/base-layout';
import MyProfileContext from 'contexts/my-profile';
import CompanyContext from 'contexts/company';
import ModalsContext from 'components/modals/contexts/modalContext';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';

import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Error from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// core components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import FullScreenDialog from 'components/FullScreenDialog/FullScreenDialog';
import DnaDialog from 'components/Dialog/Dialog';
import AcceptanceTerm from 'components/AcceptanceTerm/AcceptanceTerm';
import WorkInAreaDialog from 'components/WorkInArea/WorkInAreaDialog';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle';

import Notifications from 'components/Notifications/Notifications';

import image from 'assets/img/sidebar-5.jpg';
import logotypo from 'assets/img/wl.png';

import { notificationsAdapter } from 'adapters/notifications/notificationsAdapter';
import { Helmet } from 'react-helmet';

import { Mixpanel, onExitingModalEvent } from 'utils/mixpanel';
import NewSocioeconomicReport from '../components/NewSocioeconomicReport';
import PasswordFirstAccess from '../components/PasswordFirstAccess';
import TalkModal from '../components/TalkModal';
import defineUrlFront from '../utils/defineUrlFront';
import useDefineColor from 'constants/colors';
import NewFullScreenDialog from 'components/FullScreenDialog/NewFullScreenDialog';
import CurriculumContextProvider from '../contexts/CurriculumContext';

let ps;

class BaseLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalCEPOpen: false,
      mobileOpen: false,
      miniActive: false,
      snackbarOpen: false,
      snackbarMessage: null,
      isLoading: true,
      modalContentIsOpen: false,
      dnaDialogIsOpen: false,
      dialogContent: null,
      dialogButtonLabel: null,
      dialogCloseButtonLabel: null,
      myProfileContext: null,
      modalColorScheme: null,
      notificationsOpened: false,
      notifications: {},
      nextNotifications: {},
      showNextNotifications: false,
      requestFunc: null,
      exitingFunction: null,
      modalName: null,
      logoSrc: '',
      pageTitle: '',
      talkModal: {
        open: false,
        config: null,
        onApprove: null,
        onDenied: null,
      },
      modalDialogTitleDisable: false,
      deactivateScrollModalWithContent: false,
      viewUpdateModal: false,
      displayModal: false,
      renderGoogleTagManagerScript: this.renderGoogleTagManagerScript,
      sidebarVisible: true,
      showTutorialJourney: false,
    };
    this.resizeFunction = this.resizeFunction.bind(this);

    this.cookieName = 'viewModal';
    this.setCookie = this.setCookie.bind(this);
    this.getCookie = this.getCookie.bind(this);
  }
  async componentDidMount() {
    const { metadata, academic_profile } = this.props;
    if (metadata && metadata.role === 'student') {
      const studentId = academic_profile.external_id;
      const studentNameArray = academic_profile.name.split(' ');
      const studentEmail = academic_profile.email;

      Mixpanel.identify(studentId);
      Mixpanel.people.set({
        $first_name: studentNameArray[0],
        $last_name: studentNameArray[1],
        $email: studentEmail,
      });
    }
    if (metadata && metadata.university_logo)
      this.setState({ logoSrc: metadata.university_logo });
    else if (metadata && metadata.company_logo)
      this.setState({ logoSrc: metadata.company_logo });
    else this.setState({ logoSrc: logotypo });

    this.defineTitle();

    if (
      navigator.platform.indexOf('Win') > -1 ||
      navigator.platform.indexOf('Linux') > -1
    ) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);

    metadata && (await this.setRequest());

    this.state.requestFunc && (await this.makeNotificationRequest());

    if (navigator.platform.indexOf('iPhone') > -1 && this.state.mobileOpen) {
      ps = new PerfectScrollbar(this.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }

    this.verifyOpenModal();
  }

  verifyOpenModal() {
    const { metadata } = this.props;
    if (
      metadata?.role !== 'company' ||
      (metadata?.role === 'company' && metadata?.macro_areas === true)
    )
      return;

    if (metadata.role === 'company') {
      const storedValue = this.getCookie(this.cookieName);
      if (!storedValue) {
        const newValue = { alreadyDisplayed: false };
        this.setCookie(newValue);

        if (!newValue?.alreadyDisplayed) {
          this.setState({ displayModal: true });
        }
      } else {
        const { alreadyDisplayed } = JSON.parse(storedValue);
        if (!alreadyDisplayed) {
          this.setState({ displayModal: true });
          this.setCookie({ alreadyDisplayed: true });
        }
      }
    }
  }

  getCookie(name) {
    const cookieString = document.cookie;
    const cookie = cookieString
      .split(';')
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith(`${name}=`));

    return cookie ? cookie.split('=')[1] : null;
  }

  setCookie = (value) => {
    document.cookie = `${this.cookieName}=${JSON.stringify(value)}; path=/`;
  };

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    document.body.style.overflow = 'unset';
    window.removeEventListener('resize', this.resizeFunction);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  setMiniActive = (miniActive) => {
    this.setState({ miniActive });
  };

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  updateMyProfileContext = (myProfileContext) => {
    this.setState({
      myProfileContext,
    });
  };

  updateCompanyContext = (companyContext) => {
    this.setState({
      companyContext,
    });
  };

  openModalWithContent = (
    ModalContent,
    modalTitle,
    modalColorScheme,
    exitingFunction,
    disableBackDrop,
    modalName,
    dialogTitleDisable,
    isNewModalDialog = false
  ) => {
    this.setState({
      modalContentIsOpen: true,
      modalContent: ModalContent,
      modalTitle,
      modalColorScheme,
      exitingFunction,
      disableBackDrop,
      modalName,
      modalDialogTitleDisable: dialogTitleDisable,
      isNewModalDialog,
    });
  };

  deactivateScrollModalWithContent = (deactivate) => {
    if (this.state.modalContentIsOpen) {
      this.setState({
        deactivateScrollModalWithContent: deactivate,
      });
    }
  };

  handleCloseModal = (exitReason) => {
    const { modalName } = this.state;

    if (exitReason) {
      onExitingModalEvent(modalName);
    }

    this.setState({
      modalContentIsOpen: false,
      modalContent: null,
    });
  };

  openDnaDialog = (
    dialogTitle,
    dialogContent,
    buttonLabel,
    dialogCallback,
    closeButtonLabel,
    closeCallback
  ) => {
    this.setState({
      dnaDialogIsOpen: true,
      dialogCallback,
      closeCallback,
      dialogTitle,
      dialogContent,
      dialogButtonLabel: buttonLabel,
      dialogCloseButtonLabel: closeButtonLabel,
    });
  };

  isModalCEPOpen = () => {
    const { metadata, professional_status } = this.props;
    if (metadata?.role !== 'student') {
      return true;
    }
    if (
      !metadata.has_zip_code ||
      (metadata.need_update_professional_satisfaction &&
        professional_status.is_employed) ||
      (metadata.need_update_professional_satisfaction &&
        professional_status.is_employed)
    ) {
      return false;
    }
    return true;
  };

  openTalkModal = (config, onApprove, onDenied) => {
    this.setState({
      talkModal: {
        open: true,
        config,
        onApprove,
        onDenied,
      },
    });
  };

  closeTalkModal = () => {
    this.setState({
      talkModal: {
        open: false,
      },
    });
  };

  handleCloseDnaDialog = () => {
    this.setState({
      dnaDialogIsOpen: false,
      dialoglCallback: null,
      dialogTitle: null,
    });
  };

  handleUpdate = ({ history }) => {
    history.push({
      pathname: '/empresas/meu-perfil',
      state: { updateConfig: true },
    });
    this.setCookie({
      alreadyDisplayed: true,
    });
  };

  handleCloseModalCompanyUpdate = () => {
    this.setState({ displayModal: false });
    this.setCookie({
      alreadyDisplayed: true,
    });
  };

  handleIsLoadingState = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  handleOpenTutorial = (isOpen) => {
    this.setState({
      showTutorialJourney: isOpen,
    });
  };

  generateLinkJob = (jobId) => {
    const slugUniversity = this.props.metadata.university_slug;
    const accessLink = `${defineUrlFront()}/#/vaga/${slugUniversity}/${jobId}`;
    navigator.clipboard.writeText(accessLink);
    this.handleOpenSnackbarWithMessage(
      `
        O link foi copiado com sucesso!
        Apenas os seus estudantes conseguirão
        visualizar a vaga após fazer o login.
      `
    );
  };

  handleOpenSnackbarWithMessage = (message, isError, time = null) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      snackError: isError,
    });
    if (time) {
      setTimeout(() => {
        this.handleCloseSnackbar();
      }, time);
    }
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackbarOpen: false,
      snackbarMessage: null,
    });
  };

  toggleNotificationDrawer = (event) => {
    event.persist();

    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    this.setState({
      notificationsOpened: !this.state.notificationsOpened,
    });
  };

  setRequest = async () => {
    const { role } = this.props.metadata;
    if (role === 'student') {
      // this.setState({ requestFunc: getStudentNotifications });  frq
    }
  };

  makeNotificationRequest = async () => {
    const response = await this.state.requestFunc();

    if (!response.message) {
      if (response.next) {
        await this.getNextNotifications(response.next);
      }
      this.setState({ notifications: notificationsAdapter(response) });
    }
  };

  getNextNotifications = async (nextUrl) => {
    const urlParams = nextUrl.match(/\?.+/g);
    const response = await this.state.requestFunc(urlParams);

    if (!response.message) {
      this.setState({ nextNotifications: notificationsAdapter(response) });
    }
  };

  handleShowNextNotifications = async () => {
    const {
      nextNotifications,
      notifications,
      showNextNotifications,
    } = this.state;

    if (showNextNotifications) {
      const newNotifications = nextNotifications;
      newNotifications.results = [
        ...notifications.results,
        ...nextNotifications.results,
      ];

      this.setState({
        notifications: newNotifications,
      });

      if (nextNotifications.next) {
        await this.getNextNotifications(nextNotifications.next);
      }

      return;
    }

    this.setState({ showNextNotifications: true });
  };

  defineTitle = () => {
    const { metadata, name } = this.props;
    let newPageTitle = 'Workalove';

    if (metadata && metadata.role === 'student') {
      newPageTitle = `${metadata.university} | Workalove | Estudante | ${
        name || ''
      }`;
    } else if (metadata && metadata.role === 'university') {
      newPageTitle = `${metadata.university} | Workalove | IE`;
    } else if (metadata && metadata.role === 'company') {
      newPageTitle = `${metadata.company_name} | Workalove | Empresa`;
    }

    this.setState({ pageTitle: newPageTitle });
  };

  toggleSidebarVisibility = () => {
    this.setState((prevState) => ({
      sidebarVisible: !prevState.sidebarVisible,
    }));
  };

  renderGoogleTagManagerScript = () => {
    const { metadata } = this.props;
    if (
      metadata !== undefined &&
      metadata.role === 'student' &&
      process.env.NODE_ENV === 'production'
    ) {
      if (metadata.university_id === 136) {
        return (
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-W43DVDX7');`}
          </script>
        );
      } else if (metadata.university_id === 134) {
        return (
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TMZ22ZG3');
            `}
          </script>
        );
      }
    }
  };

  render() {
    const {
      classes,
      metadata,
      university_color,
      globalState,
      setGlobalState,
      academic_profile,
      childComponent: ChildComponent,
      routes,
      professional_status,
      ...rest
    } = this.props;
    const {
      pageTitle,
      logoSrc,
      mobileOpen,
      miniActive,
      myProfileContext,
      companyContext,
      modalContentIsOpen,
      exitingFunction,
      modalContent,
      modalTitle,
      modalColorScheme,
      disableBackDrop,
      dnaDialogIsOpen,
      dialogCallback,
      closeCallback,
      dialogContent,
      dialogTitle,
      dialogButtonLabel,
      dialogCloseButtonLabel,
      snackbarOpen,
      snackError,
      snackbarMessage,
      notificationsOpened,
      notifications,
      nextNotifications,
      showNextNotifications,
      isLoading,
      showTutorialJourney,
      talkModal,
      modalDialogTitleDisable,
      deactivateScrollModalWithContent,
      isNewModalDialog,
      renderGoogleTagManagerScript,
      sidebarVisible,
    } = this.state;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
    const propsCopy = { ...this.props };
    delete propsCopy.classes;

    return (
      <div className={classes.wrapper} id={'scrollWrapper'}>
        <Helmet>
          <title>{pageTitle}</title>
          {renderGoogleTagManagerScript()}
        </Helmet>
        <Fragment>
          {sidebarVisible && (
            <Sidebar
              routes={routes}
              logotypo={logoSrc}
              hasUniversityLogo={
                !!(
                  metadata &&
                  (metadata.university_logo || metadata.company_logo)
                )
              }
              image={image}
              handleDrawerToggle={this.handleDrawerToggle}
              open={mobileOpen}
              color="blue"
              bgColor="black"
              miniActive={miniActive}
              isCompany={metadata && metadata.role === 'company'}
              isStudent={metadata && metadata.role === 'student'}
              universityColor={metadata && metadata.university_color}
              role={metadata?.role}
              {...rest}
            />
          )}
          <div
            className={mainPanel}
            style={!sidebarVisible ? { width: '100%' } : {}}
            ref="mainPanel"
            id={'scrollMainPanel'}
          >
            <Header
              {...(sidebarVisible && {
                sidebarMinimize: this.sidebarMinimize.bind(this),
              })}
              miniActive={miniActive}
              openModalWithContent={this.openModalWithContent}
              routes={routes}
              handleDrawerToggle={this.handleDrawerToggle}
              toggleNotificationDrawer={this.toggleNotificationDrawer}
              metadata={metadata}
              isStudent={metadata && metadata.role === 'student'}
              universityColor={metadata && metadata.university_color}
              sidebarVisible={sidebarVisible}
              {...rest}
            />
            <main className={classes.content}>
              <BaseLayoutContext.Provider
                value={{
                  ...propsCopy,
                  updateCompanyContext: this.updateCompanyContext,
                  updateMyProfileContext: this.updateMyProfileContext,
                  openModalWithContent: this.openModalWithContent,
                  isStudent: metadata && metadata.role === 'student',
                  universityColor: metadata && metadata.university_color,
                  closeModal: this.handleCloseModal,
                  openDnaDialog: this.openDnaDialog,
                  showTutorialJourney: showTutorialJourney,
                  handleCloseDnaDialog: this.handleCloseDnaDialog,
                  handleOpenTutorial: this.handleOpenTutorial,
                  openSnackbar: this.handleOpenSnackbarWithMessage,
                  handleIsLoadingState: this.handleIsLoadingState,
                  openTalkModal: this.openTalkModal,
                  toggleNotificationDrawer: this.toggleNotificationDrawer,
                  handleDrawerToggle: this.handleDrawerToggle,
                  miniActive,
                  sidebarMinimize: this.sidebarMinimize.bind(this),
                  generateLinkJob: this.generateLinkJob,
                  setMiniActive: this.setMiniActive,
                  metadata: metadata,
                  globalState: globalState,
                  deactivateScrollModalWithContent: this
                    .deactivateScrollModalWithContent,
                  toggleSidebarVisibility: this.toggleSidebarVisibility,
                }}
              >
                <CurriculumContextProvider>
                  <MyProfileContext.Provider
                    value={{
                      ...myProfileContext,
                      isStudent: metadata && metadata?.role === 'student',
                      universityColor: metadata && metadata.university_color,
                    }}
                  >
                    <CompanyContext.Provider
                      value={{
                        ...companyContext,
                      }}
                    >
                      <ModalsContext>
                        <div className={classes.container}>
                          <CompanyContext.Consumer>
                            {(companyValues) => (
                              <MyProfileContext.Consumer>
                                {(profileValues) => {
                                  return (
                                    <BaseLayoutContext.Consumer>
                                      {(value) => (
                                        <div style={{ width: '100%' }}>
                                          <ChildComponent
                                            {...value}
                                            {...profileValues}
                                            {...companyValues}
                                          />
                                        </div>
                                      )}
                                    </BaseLayoutContext.Consumer>
                                  );
                                }}
                              </MyProfileContext.Consumer>
                            )}
                          </CompanyContext.Consumer>
                          {isNewModalDialog ? (
                            <NewFullScreenDialog
                              sidebarMinimize={this.sidebarMinimize.bind(this)}
                              miniActive={miniActive}
                              isOpen={modalContentIsOpen}
                              closeModal={this.handleCloseModal}
                              exitingFunction={exitingFunction}
                              content={modalContent}
                              dialogTitle={modalTitle}
                              colorScheme={modalColorScheme}
                              disableBackDrop={disableBackDrop}
                              dialogTitleDisable={modalDialogTitleDisable}
                              deactivateScroll={
                                deactivateScrollModalWithContent
                              }
                            />
                          ) : (
                            <FullScreenDialog
                              sidebarMinimize={this.sidebarMinimize.bind(this)}
                              miniActive={miniActive}
                              isOpen={modalContentIsOpen}
                              closeModal={this.handleCloseModal}
                              exitingFunction={exitingFunction}
                              content={modalContent}
                              dialogTitle={modalTitle}
                              colorScheme={modalColorScheme}
                              disableBackDrop={disableBackDrop}
                              dialogTitleDisable={modalDialogTitleDisable}
                              deactivateScroll={
                                deactivateScrollModalWithContent
                              }
                            />
                          )}
                          <DnaDialog
                            isOpen={dnaDialogIsOpen}
                            closeDialog={this.handleCloseDnaDialog}
                            dialogCallback={dialogCallback}
                            closeCallback={closeCallback}
                            dialogContent={dialogContent}
                            dialogTitle={dialogTitle}
                            buttonLabel={dialogButtonLabel}
                            closeButtonLabel={dialogCloseButtonLabel}
                            universityColor={
                              metadata && metadata.university_color
                            }
                          />
                          <TalkModal
                            openTalk={talkModal.open}
                            closeTalk={this.closeTalkModal}
                            config={talkModal.config}
                            onApprove={talkModal.onApprove}
                            onDenied={talkModal.onDenied}
                          />
                          <Snackbar
                            data-testid="snackbar"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={this.handleCloseSnackbar}
                          >
                            <SnackbarContent
                              className={cx({
                                [classes.success]: !snackError,
                                [classes.error]: snackError,
                              })}
                              action={[
                                <IconButton
                                  key="close"
                                  aria-label="Close"
                                  color="inherit"
                                  className={classes.close}
                                  onClick={this.handleCloseSnackbar}
                                >
                                  <CloseIcon />
                                </IconButton>,
                              ]}
                              aria-describedby="client-snackbar"
                              message={
                                <span
                                  id="client-snackbar"
                                  className={classes.message}
                                >
                                  {snackError ? (
                                    <Error className={classes.icon} />
                                  ) : (
                                    <CheckCircleIcon className={classes.icon} />
                                  )}
                                  {snackbarMessage}
                                </span>
                              }
                            />
                          </Snackbar>
                          <Notifications
                            state={notificationsOpened}
                            toggleNotificationDrawer={
                              this.toggleNotificationDrawer
                            }
                            notifications={notifications}
                            nextNotifications={nextNotifications}
                            showNextNotifications={showNextNotifications}
                            handleShowNextNotifications={
                              this.handleShowNextNotifications
                            }
                          />
                        </div>
                        {academic_profile && (
                          <WorkInAreaDialog
                            isOpen={
                              globalState &&
                              globalState.workInAreaModal &&
                              globalState.has_watched_tour &&
                              globalState.acceptTerm
                            }
                            studentName={academic_profile.name}
                            setClose={propsCopy.setGlobalState}
                          />
                        )}
                        {this.isModalCEPOpen() && (
                          <AcceptanceTerm
                            acceptTerm={globalState && globalState.acceptTerm}
                            handleOpenTutorial={this.handleOpenTutorial}
                            globalState={globalState}
                            confirmTerm={propsCopy.setGlobalState}
                            termUrl={globalState && globalState.termUrl}
                            metadata={metadata}
                          />
                        )}
                        {this.isModalCEPOpen() && (
                          <NewSocioeconomicReport
                            openModalWithContent={this.openModalWithContent}
                            metadata={metadata}
                            globalState={globalState}
                            professional_status={professional_status}
                            academic_profile={academic_profile}
                            setGlobalState={setGlobalState}
                            classes={classes}
                          />
                        )}
                        {this.isModalCEPOpen() && (
                          <PasswordFirstAccess
                            classes={classes}
                            globalState={globalState}
                            changePassword={propsCopy.setGlobalState}
                            userName={propsCopy.username}
                            metadata={metadata}
                            openSnackbar={this.handleOpenSnackbarWithMessage}
                          />
                        )}
                      </ModalsContext>
                    </CompanyContext.Provider>
                  </MyProfileContext.Provider>
                </CurriculumContextProvider>
              </BaseLayoutContext.Provider>
            </main>
            <Footer fluid location={this.props.location} />
          </div>
          <div
            className={cx({
              [classes[
                `${
                  metadata?.university_color
                    ? useDefineColor(metadata && metadata.university_color)
                    : 'dnaColor'
                }loadingHolder`
              ]]: true,
              [classes.showLoading]: isLoading,
            })}
          >
            <div className={classes.spinner}>
              <div className={classes.rect1} />
              <div className={classes.rect2} />
              <div className={classes.rect3} />
              <div className={classes.rect4} />
              <div className={classes.rect5} />
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

BaseLayout.propTypes = {
  classes: shape.isRequired,
  metadata: shape.isRequired,
  globalState: shape.isRequired,
  routes: arrayOf(shape).isRequired,
  academic_profile: shape.isRequired,
};

export default withStyles(dashboardStyle)(BaseLayout);
