import { Form } from '@unform/web';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import DatePickerCustom from '../../../Opportunities/Components/Form/datePicker';
import {
  CompanyTitle,
  CompletedButton,
  ContainerButtons,
  ContainerSelectiveProcess,
  FooterForm,
  Title,
  ToCompanyContainer,
  WrapperDates,
  WrapperMail,
  WrapperSelectiveProcess,
} from './style';

import { PreviewButton } from './Buttons/PreviewButton';
import Input from '../../../Opportunities/Components/Form/input';
import Radio from '../../components/form/radio';
import SearchableSelectUn from '../../components/form/searchableSelect';

import * as Yup from 'yup';
import { schemaValidationStepThree } from './useCases/schemaValidation';
import { PrevsButton } from '../../../Opportunities/Styles/formStyled';

import { createJobOffer, editJobOffer, getCompanys } from '../../services';

import {
  IModalConfigCreateCompany,
  IModalConfigCreateCompanyError,
  IModalConfigCreateJobErrorInternal,
  IModalConfigCreateJobInternal,
  IModalConfigEditJobErrorInternal,
  IModalConfigEditJobInternal,
} from './useCases/iModalConfig';

import LoadingModal from '../loadingModal';
import { JobOfferContext } from '../../context/jobOfferContext';
import {
  colorOrRace,
  disability,
  genderIndentity,
} from '../../../../JobOfferRebuild/Components/ExternalJobOffer/useCases/SelectOptions';
import { payloadCreateJob } from './helpers/payloadCreateJob';
import SearchableSelectUnAllValues from '../form/searchableSelectAllValues';

export default function StepThreeJobOffer() {
  const {
    prevsJobOfferStep,
    data: contextData,
    setData,
    stepThreeRef,
    dataFunction,
    setCompanys,
    companys,
  } = useContext(JobOfferContext);

  const [selectiveProcess, setSelectiveProcess] = useState(false);

  const returnDataStepThree = () => {
    return stepThreeRef.current ? stepThreeRef?.current?.getData() : {};
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [currentModalConfig, setCurrentModalConfig] = useState(
    IModalConfigCreateCompany
  );

  const [jobToCompany, setJobToCompany] = useState(false);

  const radioOptions = [
    {
      id: '1',
      value: true,
      label:
        'Quero promover a diversidade e inclusão de pessoas levando em consideração raça, identidade de gênero e pessoa com deficiência.',
      type: 'checkbox',
    },
  ];

  const companyOptions = [
    {
      id: 'company1',
      value: true,
      label: 'sim',
      type: 'radio',
    },
    {
      id: 'company2',
      value: false,
      label: 'não',
      type: 'radio',
    },
  ];

  const handleCheckSelectiveProcess = (e) => {
    setData((prev) => ({
      ...prev,
      administrative_selection_process: e.target ? e.target.checked : false,
      disability: [],
      color_or_race: [],
      gender_identity: [],
    }));
    stepThreeRef.current.setFieldValue('disability', []);
    stepThreeRef.current.setFieldValue('color_or_race', []);
    stepThreeRef.current.setFieldValue('gender_identity', []);

    if (e.target.checked) {
      return setSelectiveProcess(true);
    }
    setSelectiveProcess(false);
  };

  useEffect(() => {
    const getCompany = async () => {
      const companys = await getCompanys();

      setCompanys(companys);
    };

    if (
      contextData.administrative_selection_process &&
      contextData.administrative_selection_process === true
    ) {
      setSelectiveProcess(true);
    }
    if (contextData.company_name) {
      setJobToCompany(true);
      stepThreeRef.current.setFieldValue('company', false);
      stepThreeRef.current.setFieldValue(
        'companyValues',
        contextData.company_organization_number
      );
    }

    if (!contextData.company_name && contextData.isEditJob) {
      stepThreeRef.current.setFieldValue('company', true);
    }

    getCompany();
  }, [contextData]);

  const handleSetModalState = () => {
    setModalOpen(false);
  };

  const returnCurrentFunction = () => {
    if (currentModalConfig === IModalConfigCreateCompanyError) {
      return handleSetModalState();
    }
    if (currentModalConfig === IModalConfigCreateJobErrorInternal) {
      return handleSetModalState();
    }

    if (currentModalConfig === IModalConfigEditJobErrorInternal) {
      return handleSetModalState();
    }

    return () => {};
  };

  const editJob = async (data) => {
    let responseProfession = {};

    setModalOpen(true);
    setCurrentModalConfig(IModalConfigEditJobInternal);

    const responseEdit = await editJobOffer(
      contextData.jobId,
      payloadCreateJob(data, contextData, responseProfession)
    );

    if (responseEdit.message) {
      return setCurrentModalConfig(IModalConfigEditJobErrorInternal);
    }

    setModalOpen(false);
    dataFunction.handlePageChange('limit=0');
    setData({});
    dataFunction.setOpenModalPublishJob(false);
  };

  const handleSelectToCompany = async (e) => {
    if (e.target.value === 'false') {
      setJobToCompany(true);
      return;
    }

    if (contextData.isEditJob) {
      delete contextData.companyValues;
    }
    setJobToCompany(false);
  };

  const createJob = async (data) => {
    let responseProfession = {};

    setModalOpen(true);
    setCurrentModalConfig(IModalConfigCreateJobInternal);

    const responseJOB = await createJobOffer(
      payloadCreateJob(data, contextData, responseProfession)
    );

    if (responseJOB.message) {
      return setCurrentModalConfig(IModalConfigCreateJobErrorInternal);
    }

    setModalOpen(false);
    dataFunction.handlePageChange('limit=0');
    setData({});
    dataFunction.setOpenModalPublishJob(false);
  };
  const handleSubmit = async (data) => {
    const formattedData = {
      ...data,
      start_offer_at: moment(data.start_offer_at).format('YYYY-MM-DD'),
      end_offer_at: moment(data.end_offer_at).format('YYYY-MM-DD'),
    };
    stepThreeRef.current.setErrors({});
    try {
      await schemaValidationStepThree.validate(formattedData, {
        abortEarly: false,
      });

      if (!contextData.isEditJob) {
        return createJob(formattedData);
      }

      return editJob(formattedData);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        stepThreeRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    const allData = stepThreeRef?.current?.getData();
  }, []);

  return (
    <div>
      <LoadingModal
        modalOpenState={modalOpen}
        config={currentModalConfig}
        buttonFunction={returnCurrentFunction}
      />
      <Form
        ref={stepThreeRef}
        onSubmit={handleSubmit}
        initialData={contextData}
      >
        <Title>Período de Divulgação*</Title>
        <WrapperDates>
          <DatePickerCustom
            minDate={new Date()}
            name="start_offer_at"
            placeholder="De"
          />
          <DatePickerCustom
            minDate={new Date()}
            name="end_offer_at"
            placeholder="Até"
          />
        </WrapperDates>

        <WrapperMail>
          <Input
            name="contact_email"
            label="E-mail para Notificação de Novas Candidaturas"
            placeholder="Insira o e-mail de uma pessoa que tenha acesso a plataforma Workalove"
          />
        </WrapperMail>

        <WrapperSelectiveProcess>
          <h5>Processo Seletivo Afirmativo </h5>
          <Radio
            name="administrative_selection_process"
            options={radioOptions}
            onChange={handleCheckSelectiveProcess}
          />
        </WrapperSelectiveProcess>

        {selectiveProcess && (
          <ContainerSelectiveProcess>
            <SearchableSelectUn
              name="disability"
              isClearable
              isMulti
              label="PCD - Selecione possíveis deficiências"
              options={disability}
            />
            <SearchableSelectUn
              name="color_or_race"
              isClearable
              label="Raça/Cor"
              isMulti
              options={colorOrRace}
            />
            <SearchableSelectUn
              name="gender_identity"
              isClearable
              isMulti
              label="Identidade de Gênero"
              options={genderIndentity}
            />
          </ContainerSelectiveProcess>
        )}

        <ToCompanyContainer>
          <CompanyTitle>Esta vaga é para sua empresa?</CompanyTitle>
          <Radio
            name="company"
            options={companyOptions}
            onChange={handleSelectToCompany}
          />
        </ToCompanyContainer>

        {jobToCompany && (
          <SearchableSelectUnAllValues
            name="companyValues"
            isClearable
            options={companys}
            placeholder="Selecione a empresa"
          />
        )}

        <FooterForm>
          <PrevsButton onClick={prevsJobOfferStep} />
          <ContainerButtons>
            <PreviewButton
              stepThreeData={returnDataStepThree()}
              handleSubmit={handleSubmit}
            >
              Pré-Visualizar Vaga
            </PreviewButton>
            <CompletedButton> Publicar </CompletedButton>
          </ContainerButtons>
        </FooterForm>
      </Form>
    </div>
  );
}
