import React, { useRef, useState, useContext, useEffect } from 'react';
import {
  BaseContainer,
  Box,
  Card,
  Wrapper,
  ContaineForm,
  Divider,
  SaveButton,
  ContainerList,
} from './style';
import { Form } from '@unform/web';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import { caseSelectFormatType } from './useCases/typeSelectInterface';
import LinkComponent from './components/LinkComponent';
import FileComponent from './components/FileComponent';
import ListFiles from './components/ListFiles';
import ModalInformation from 'components/informationModal';
import ListLinks from './components/ListLinks';
import SearchableSelectChallenge from 'views/StaffPages/Challenges/components/Inputs/Select/SearchableSelect';
import BaseLayoutContext from 'contexts/base-layout';
import { generateUUID } from './utils/generateUUID';

export default function ModalUploadEvidences({
  open,
  functionCloseAndUpdate,
  idPost,
  functionPostFile,
  functionDeleteEvidence,
  acceptFileTypes,
  evidenceFiles,
  evidenceLinks,
  functionPostLink,
  functionPutLink,
  headerTitle,
  subTitle,
  uniqueUploadType,
  limitUpload,
  acceptFormats,
  buttonLabel,
  companiesManagement,
  postDocumentTwoCreateFile,
  functionResetList,
  stateResetList,
  limit,
  currentPage,
  verifyStatus,
  fromStudent,
  isFromSettingsLogo,
  nameTypeDoc,
  ctaDownloadPreview,
  limitSizeDocument,
  messageLimitSize,
}) {
  const formRef = useRef(null);
  const formRefLink = useRef(null);
  const modalContainer = useRef(null);

  const [typeSelected, setTypeSelected] = useState('');
  const [ArrayLinks, setArrayLinks] = useState([]);
  const [arrayFiles, setArrayFiles] = useState([]);
  const [isEditLink, setIsEditLink] = useState(false);
  const [selectedEditUUID, setSelectedEditUUID] = useState('');

  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalInformationConfig, setModalInformationConfig] = useState({});

  const [usingPreviewPdf, setUsingPreviewPdf] = useState(false);

  const [itemWaitingDelete, setItemWaitingDelete] = useState([]);

  const { openSnackbar, universityColor } = useContext(BaseLayoutContext);

  const verifyStatusActions = (item) => {
    if (verifyStatus) {
      return !(item.analyze && Object.keys(item.analyze).length > 0);
    }
    return true;
  };

  const uploadComponents = {
    link: (
      <LinkComponent
        functionPostLink={functionPostLink}
        functionPutLink={functionPutLink}
        ArrayLinks={ArrayLinks}
        setArrayLinks={setArrayLinks}
        formRefLink={formRefLink}
        isEditLink={isEditLink}
        selectedEditUUID={selectedEditUUID}
        setIsEditLink={setIsEditLink}
        idPost={idPost}
        uniqueUploadType={uniqueUploadType}
      />
    ),

    uniqueLinkNotUsed: (
      <LinkComponent
        functionPostLink={functionPostLink}
        functionPutLink={functionPutLink}
        ArrayLinks={ArrayLinks}
        setArrayLinks={setArrayLinks}
        formRefLink={formRefLink}
        isEditLink={isEditLink}
        selectedEditUUID={selectedEditUUID}
        setIsEditLink={setIsEditLink}
        idPost={idPost}
        uniqueUploadType={uniqueUploadType}
        uniqueLink={true}
      />
    ),

    file: (
      <FileComponent
        setModalInformationConfig={setModalInformationConfig}
        acceptFileTypes={acceptFileTypes}
        arrayFiles={arrayFiles}
        setFilesList={setArrayFiles}
        limitUpload={limitUpload}
        idPost={idPost}
        functionPostFile={functionPostFile}
        setModalInformationOpen={setModalInformationOpen}
        uniqueUploadType={uniqueUploadType}
        companiesManagement={companiesManagement}
        postDocumentTwoCreateFile={postDocumentTwoCreateFile}
        evidenceFiles={evidenceFiles}
        functionResetList={functionResetList}
        stateResetList={stateResetList}
        limit={limit}
        currentPage={currentPage}
        isFromSettingsLogo={isFromSettingsLogo}
        itemWaitingDelete={itemWaitingDelete}
        functionDeleteEvidence={functionDeleteEvidence}
        nameTypeDoc={nameTypeDoc}
        limitSizeDocument={limitSizeDocument}
        messageLimitSize={messageLimitSize}
      />
    ),
  };

  useEffect(() => {
    if (evidenceFiles.length > 0) {
      setArrayFiles(evidenceFiles);
    }

    if (evidenceLinks.length > 0) {
      setArrayLinks(evidenceLinks);
    }
  }, [evidenceFiles, evidenceLinks]);

  const returnComponentType = uploadComponents[typeSelected] || null;

  const optionsSelect = acceptFormats
    ? acceptFormats.map((item) =>
        caseSelectFormatType.find((type) => type.value === item)
      )
    : [caseSelectFormatType[0], caseSelectFormatType[1]];

  const returnSelect = () => {
    if (uniqueUploadType) {
      return uploadComponents[uniqueUploadType];
    }

    return (
      <div>
        <SearchableSelectChallenge
          name="format"
          placeholder="Selecione"
          label="Formato"
          options={optionsSelect}
          onChange={handleChangeSelectType}
          isSearchable={false}
        />

        {returnComponentType}
      </div>
    );
  };

  const handleChangeSelectType = (e) => {
    const type = e.value || e;
    setIsEditLink(false);
    setTypeSelected(type);
  };

  const handleClickEditLink = (item) => {
    setSelectedEditUUID(item.id);

    const valueLink = caseSelectFormatType.find(
      (cases) => cases.type === item.type
    );
   
    formRef.current.setFieldValue('format', valueLink);

    setTimeout(() => {
      formRefLink?.current?.setFieldValue('evidenceName', item.name);
      formRefLink?.current?.setFieldValue('link', item.link);
    }, 20);
    setIsEditLink(true);
  };

  if (!open) {
    return null;
  }

  const closeInformationModal = () => {
    setModalInformationOpen(false);
  };

  const handleClickCloseOrSave = async () => {
    functionCloseAndUpdate(arrayFiles);
    setTypeSelected('');
    setArrayLinks([]);
    setArrayFiles([]);
    setSelectedEditUUID('');
    setUsingPreviewPdf(false);

    if (companiesManagement) {
      openSnackbar('O seu documento foi adicionado com sucesso!', false);
    }
  };

  const handleClickClose = async () => {
    functionCloseAndUpdate(arrayFiles);
    setTypeSelected('');
    setArrayLinks([]);
    setArrayFiles([]);
    setSelectedEditUUID('');
    setUsingPreviewPdf(false);
  };

  const closeClickingOutside = async (e) => {
    if (modalContainer.current === e.target) {
      functionCloseAndUpdate(arrayFiles);
      setTypeSelected('');
      setArrayLinks([]);
      setArrayFiles([]);
      setSelectedEditUUID('');
      setUsingPreviewPdf(false);
    }
  };

  const handleDeleteLink = async (item) => {
    const filterArray = ArrayLinks.filter((filter) => filter.id !== item.id);
    setArrayLinks(filterArray);
    await functionDeleteEvidence(idPost, item.id);
  };

  return (
    <BaseContainer
      ref={modalContainer}
      onClick={closeClickingOutside}
      usingPreviewPdf={usingPreviewPdf}
    >
      <ModalInformation
        universityColor={universityColor}
        modalOpenState={modalInformationOpen}
        config={modalInformationConfig}
        buttonConfirmFunction={modalInformationConfig.buttonConfirmFunction}
        buttonCancelFunction={closeInformationModal}
      />
      <Box>
        <Wrapper>
          <Card universityColor={universityColor}>
            <CloseButton onClick={handleClickClose} />
            <h3>{headerTitle}</h3>
            <p>{subTitle}</p>
            <ContaineForm>
              <Form ref={formRef} onSubmit={() => {}}>
                {returnSelect()}
              </Form>
            </ContaineForm>

            <Divider></Divider>
            <ContainerList>
              {ArrayLinks.map((item) => (
                <ListLinks
                  handleClickEdit={handleClickEditLink}
                  handleDeleteLink={handleDeleteLink}
                  item={item}
                  canUseActions={verifyStatusActions(item)}
                  setModalInformationConfig={setModalInformationConfig}
                  setModalInformationOpen={setModalInformationOpen}
                />
              ))}
              {arrayFiles.map((item) => (
                <ListFiles
                  isFromSettingsLogo={isFromSettingsLogo}
                  setItemWaitingDelete={setItemWaitingDelete}
                  setUsingPreviewPdf={setUsingPreviewPdf}
                  filesList={arrayFiles}
                  setModalInformationConfig={setModalInformationConfig}
                  setModalInformationOpen={setModalInformationOpen}
                  functionDeleteEvidence={functionDeleteEvidence}
                  setFilesList={setArrayFiles}
                  idPost={idPost}
                  item={item}
                  canUseActions={verifyStatusActions(item)}
                  companiesManagement={companiesManagement}
                  functionResetList={functionResetList}
                  stateResetList={stateResetList}
                  limit={limit}
                  currentPage={currentPage}
                  fromStudent={fromStudent}
                  ctaDownloadPreview={ctaDownloadPreview}
                  key={generateUUID()}
                />
              ))}
            </ContainerList>
            <SaveButton universityColor={universityColor}>
              <button onClick={handleClickCloseOrSave}>{buttonLabel}</button>
            </SaveButton>
          </Card>
        </Wrapper>
      </Box>
    </BaseContainer>
  );
}
