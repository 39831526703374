import { authenticatedAPI } from 'services/api/api';

const getCountries = async () => {
  const response = authenticatedAPI
    .get('api/internal/students/locations/countries')
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptions = async () => {
  const response = authenticatedAPI
    .options(`/api/internal/students/me/life-experience`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveLifeExperience = async (lifeExperience) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/life-experience`, lifeExperience)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateLifeExperience = async (lifeExperience) => {
  const response = authenticatedAPI
    .patch(
      `/api/internal/students/me/life-experience/${lifeExperience.id}`,
      lifeExperience
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteLifeExperience = async (lifeExperienceId) => {
  const response = authenticatedAPI
    .delete(`/api/internal/students/me/life-experience/${lifeExperienceId}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getCountries,
  getOptions,
  saveLifeExperience,
  deleteLifeExperience,
  updateLifeExperience,
};
