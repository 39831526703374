import { create } from 'zustand';

const initialState = {
  globalStore: {
    acceptTerm: false,
    hasSurveys: null,
  },
};

export const GlobalStore = create((set) => ({
  globalStore: initialState.globalStore,

  changeGlobalStore: (state) =>
    set(() => {
      return { globalStore: state };
    }),
  updateGlobalStore: (key, value) =>
    set((state) => {
      const data = { ...state.globalStore, [key]: value };
      return (state.globalStore = data);
    }),
}));

export const actionsGlobalStore = (() => {
  const changeGlobalStore = GlobalStore.getState().changeGlobalStore;
  const updateGlobalStore = GlobalStore.getState().updateGlobalStore;

  return { changeGlobalStore, updateGlobalStore };
})();

export const useGlobalStore = () => {
  const globalStore = GlobalStore((state) => state.globalStore);
  return { globalStore };
};
