import BaseLayoutContext from 'contexts/base-layout';
import React, { useCallback, useContext, useState } from 'react';
import ExtensionProjectsStudentsProvider from '../../../Context/ExtensionProjectStudentContext';
import InsideTabs from './components/InsideTabs';
import ListExtesionProject from './components/ListProjectExtesion';
import ListSubscribeProject from './components/ListSubscribeProjects';
import { Container, Subtitle, WhiteBox } from '../../Styles/BaseComponent';
import ResponsiveTitle from '../../UIComponents/TitleResponsive';
import { getExtesions } from 'services/student/extensionProject';


export default function ExtesionProjectEngine({
  countExtesionProject,
  countEnrolledExtesionProject,
  countOpenedExtesionProject,
  extensionProjectCount,
  setActiveTab
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const { academic_profile, metadata, universityColor } = useContext(BaseLayoutContext);
  const [extesionProjectData, setExtesionProjectData] = useState([]);

  const getExtesionProject = async (query = 'limit=10') => {
    const { results } = await getExtesions(query);
    setExtesionProjectData(results);
  }

  const tabs = [
    `Projetos de Extensão (${countOpenedExtesionProject})`,
    `Projetos que me inscrevi (${countEnrolledExtesionProject})`,
  ];

  const handleTabChange = useCallback((_, value) => {
    setCurrentTab(value);
  }, []);

  const updateActiveTab = useCallback((value) => {
    setActiveTab(value);
  }, []);

  const propsPrincipalList = {
    extesionProjectData,
    getExtesionProject,
    countExtesionProject,
    currentTab,
    metadata,
    updateActiveTab,
  };

  const components = {
    0: <ListExtesionProject {...propsPrincipalList} />,
    1: <ListSubscribeProject />,
  };

  const returnCurrentComponentTab = () => {
    return components[currentTab];
  };

  return (
    <ExtensionProjectsStudentsProvider
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      extensionProjectCount={extensionProjectCount}
      getExtesionProject={getExtesionProject}
    >
    <Container>
      <div>
        <ResponsiveTitle
          lgText="Projeto de Extensão"
          smText="Projeto de extensão"
          xsText="Projetos de Extensão"
          universityColor={universityColor}
        />
          <Subtitle>
            <strong>{academic_profile.name},</strong> sua instituição de ensino
            destacou <strong>{countOpenedExtesionProject}</strong> projetos de
            extensão para o seu desenvolvimento profissional.
          </Subtitle>
       </div>
        <WhiteBox>
          <InsideTabs
            tabs={tabs}
            activeTab={currentTab}
            handleTabChange={handleTabChange}
          />
          {returnCurrentComponentTab()}
        </WhiteBox>
      </Container>
    </ExtensionProjectsStudentsProvider>
  );
}
