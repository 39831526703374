import React, { useContext } from 'react';
import { ButtonReward } from './styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import useMediaQuery from 'hooks/useMediaQuery';

const ButtonViewReward = ({ onClick }) => {
  const { universityColor } = useContext(ProfileContext);
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <ButtonReward onClick={onClick} universityColor={universityColor}>
      {isMobile ? 'Conquistas' : 'Minhas conquistas'}
    </ButtonReward>
  );
};

export default ButtonViewReward;
