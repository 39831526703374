class TokenService {
  _token = '';
  _refreshToken = '';
  storage;
  constructor() {
    this.storage = window === window.parent ? localStorage : sessionStorage;
  }

  get token() {
    if (this._token) {
      return this._token;
    } else {
      const token = this.storage.getItem('token');
      if (token) {
        this._token = token;
        return token;
      }
      return '';
    }
  }

  /**
   * @function
   * @param {string} token
   */
  set token(token) {
    this.storage.setItem('token', token);
    this._token = token;
  }

  get refreshToken() {
    if (this._refreshToken) {
      return this._refreshToken;
    } else {
      const token = this.storage.getItem('refreshToken');
      if (token) {
        this._refreshToken = token;
        return token;
      }
      return '';
    }
  }

  /**
   * @function
   * @param {string} token
   */
  set refreshToken(token) {
    this.storage.setItem('refreshToken', token);
    this._refreshToken = token;
  }

  setTokens(token, refreshToken) {
    return new Promise((resolve) => {
      this.token = token;
      this.refreshToken = refreshToken;

      const validity = setInterval(() => {
        const localToken = this.storage.getItem('token');
        if (token === localToken) {
          clearInterval(validity);
          resolve(null);
        }
      }, 300);
    });
  }

  clearTokens() {
    this.token = '';
    this.refreshToken = '';
    this.storage.clear();
  }
}

export const tokenService = new TokenService();
