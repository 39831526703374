import { authenticatedAPI } from 'services/api/api';
import { studentFilterAdapter } from 'adapters/student/studentListAdapter';
import dataProductsAPI from 'services/api/dataProductsApi';

// payload igual ao mock
const createNewReport = async (payload) => {
  const response = authenticatedAPI
    .post('api/internal/university/survey', payload)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const reportOptions = async () => {
  const response = authenticatedAPI
    .options('api/internal/university/survey')
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getReports = async (filters) => {
  const queryString = studentFilterAdapter(filters, false);

  const response = authenticatedAPI
    .get(`api/internal/university/survey?${queryString}`, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const reportAlreadyExists = async (data) => {
  const response = authenticatedAPI
    .post('api/internal/university/survey/exists', data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const unpublishReport = async (id) => {
  const response = authenticatedAPI
    .post(`api/internal/university/survey/${id}/unpublish`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getReportsChartData = async (filters) => {
  const queryString = studentFilterAdapter(filters, false);

  const response = dataProductsAPI
    .get(`university/survey/results?${queryString}`)
    .then((res) => res.data.result)
    .catch((error) => error);

  return response;
};

export {
  createNewReport,
  reportOptions,
  reportAlreadyExists,
  unpublishReport,
  getReports,
  getReportsChartData,
};
