import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.25);
  color: #606062;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 55px;
  margin-left: auto;
  display: flex;
  align-items: ${(props) => (props.centerModal ? 'center' : 'flex-start')};
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-width: 425px) {
    align-items: flex-end;
    padding-bottom: 0px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 30px;
  border-radius: 12px;
  background-color: #fff;

  @media (max-width: 425px) {
    max-width: 100%;
    padding-bottom: 70px;
  }
`;

export const Flag = styled.div`
  width: fit-content;
  padding: 5px 10px;
  color: #fff;
  background-color: #3492e9;
  font-weight: 400;
  font-size: 14px;
  border-radius: 3px;
`;

export const Title = styled.p`
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600;
  color: #606062;
`;

export const Description = styled.p`
  margin-top: 10px;
  font-size: 16px;
  color: #606062;
  font-weight: 400;
  line-height: 20px;
`;

export const BoxImage = styled.div`
  width: 100%;
  max-width: 299px;
  height: 201px;
  margin: 20px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 425px) {
    margin: 20px auto;
  }
`;

export const Button = styled.button`
  background-color: ${({ outlined, universityColor }) =>
    outlined ? '#fff' : universityColor || '#009291'};
  border: 1px solid
    ${({ outlined, universityColor }) =>
      outlined ? universityColor || '#009291' : '#fff'};
  color: ${({ outlined, universityColor }) =>
    outlined ? universityColor || '#009291' : '#fff'};
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  max-width: ${({ outlined, lastButton }) =>
    outlined ? '118px' : lastButton ? '177px' : '154px'};

  height: 36px;
  cursor: pointer;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ position }) =>
    position === 'center' ? 'center' : 'space-between'};
`;

export const Dots = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: ${(props) =>
    props.isActive ? props.universityColor || '#009291' : '#D9D9D9'};
  cursor: pointer;
`;

export const ContainerDots = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 10px;
`;
