import { api } from 'services/api/api';

const sendEmail = async (data) => {
  try {
    const response = await api.post(`/api/password_reset/`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const sendEmailStudent = async (data) => {
  try {
    const response = await api.post(
      `/api/internal/students/password-reset`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const sendUniversityEmail = async (data) => {
  return (await api.post(`/api/internal/university/password-reset`, data)).data;
};

const verifyRecoverToken = async (data) => {
  return (await api.post(`/api/password_reset/validate_token/`, data)).data;
};

const confirmNewPassword = async (data) => {
  try {
    const response = await api.post(`/api/password_reset/confirm/`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const emailExists = async (data) => {
  try {
    const response = await api.post(`/api/internal/students/me/email`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const emailBlackList = async (data) => {
  try {
    const response = await api.post(
      `/api/internal/students/me/email-blacklisted`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export {
  sendEmail,
  confirmNewPassword,
  emailExists,
  emailBlackList,
  sendUniversityEmail,
  verifyRecoverToken,
  sendEmailStudent,
};
