import React, { useEffect, useState, useContext } from 'react';
import { object } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import LogoWl from 'assets/img/wl.png';
import { Grid } from '@material-ui/core';
import { getOptionsProfile } from '../../services/student/profile';
import BaseLayoutContext from 'contexts/base-layout';
import ProfessionalStatusEngine from 'views/ProfessionalStatus/ProfessionalStatusEngine';
import { actionsGlobalStore } from 'stores/globalState.store';

const NewSocioeconomicReport = ({
  openModalWithContent,
  metadata,
  classes,
  academic_profile,
  professional_status,
  globalState,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const { universityColor } = useContext(BaseLayoutContext);
  const { updateGlobalStore } = actionsGlobalStore;

  useEffect(() => {
    if (metadata && metadata.role === 'student') {
      getOptionsProfile().then((response) => {
        let surveys = response.surveys;
        if (response.answered_survey) {
          response.answered_survey.forEach((answeredSurvey) => {
            surveys = surveys.filter((s) => s.id !== answeredSurvey.ses_survey);
          });
        }
        if (
          response.surveys &&
          response.surveys.length > 0 &&
          response.answered_survey &&
          surveys.length !== 0
        ) {
          setShowDialog(true);
          updateGlobalStore('hasSurveys', true);
        } else {
          setShowDialog(false);
          updateGlobalStore('hasSurveys', false);
        }
      });
    }
  }, []);

  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      disableBackdropClick
    >
      <DialogContent style={{ width: 395 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img width="200" src={LogoWl} alt="Editar" />
          </Grid>
          <Grid item xs={12} md={12}>
            <DialogTitle id="alert-dialog-slide-title">
              Sua universidade tem um novo relatório socioeconômico ativo para
              você responder.
            </DialogTitle>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ padding: '4px 22px 4px', textAlign: 'justify' }}
          >
            O preenchimento do relatório é rápido e vai ajudar demais sua
            Instituição de ensino a entender melhor a situação do corpo
            discente.
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.CustomDialogActions}>
        <Button
          onClick={() => {
            openModalWithContent(
              <ProfessionalStatusEngine
                classes={classes}
                academicProfile={academic_profile}
                metadata={metadata}
                professional_status={professional_status}
                globalState={globalState}
              />,
              'Meu Status Profissional',
              '',
              () => {},
              true
            );
            setShowDialog(false);
          }}
          style={{
            borderRadius: 140,
            fontSize: 12,
            margin: '3px 32px 15px',
            backgroundColor: `${universityColor}`,
          }}
          variant="contained"
          color="primary"
        >
          CONFIRMAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewSocioeconomicReport.propTypes = {
  classes: object,
};

export default withStyles(ProfessionalExperienceFormStyles)(
  NewSocioeconomicReport
);
