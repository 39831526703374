import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';
import { authenticatedAPI } from 'services/api/api';

const getInterests = async () => {
  try {
    const response = await authenticatedAPI.options(
      `/api/internal/students/me/interests`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getSelectedInterests = async () => {
  try {
    const response = await authenticatedAPI.get(
      `/api/internal/students/me/interests`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const saveInterests = async (data) => {
  try {
    const response = await authenticatedAPI.post(
      `/api/internal/students/me/interests`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export { getInterests, saveInterests, getSelectedInterests };
