import { authenticatedAPI } from 'services/api/api';

class IESCompanyAPI {
  getUniversities = (query) => {
    return authenticatedAPI
      .get(`api/internal/companies/ies${query}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getUniversityData = (id) => {
    return authenticatedAPI
      .get(`api/internal/companies/ies/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getUniversityCourses = (id, query) => {
    return authenticatedAPI
      .get(`api/internal/companies/ies/${id}/courses${query}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getFilters = (query) => {
    return authenticatedAPI
      .options(`api/internal/companies/ies${query}`)
      .then((response) => response.data)
      .catch((error) => error);
  };
}

export default new IESCompanyAPI();
