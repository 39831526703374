import JWTInterceptor from './JWTInterceptor';
import axios from 'axios';
import URLS from 'constants/urls';

import { authInternship, authValidate } from 'services/authenticatedInternship';
import { tokenService } from './token.service';

const JwtInterceptor = new JWTInterceptor();

const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.headers = {
    Authorization: `Bearer ${tokenService.token}`,
    ...config.headers,
  };
  config.withCredentials = false;
  return config;
});

JwtInterceptor.setupInterceptors(authenticatedAPI);

const api = axios.create({
  baseURL: URLS.API_URL,
});

const publicInternshipAPI = axios.create({
  baseURL: URLS.API_INTERNSHIP_URL,
});

const authenticatedInternshipAPI = axios.create({
  baseURL: URLS.API_INTERNSHIP_URL,
});

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function authenticateAndValidate(config, retries = 3, delayTime = 1000) {
  try {
    const data = await authInternship();
    const auth = await authValidate({
      token: tokenService.token,
      user: data.user,
    });
    config.withCredentials = false;
    config.headers = {
      Authorization: `Bearer ${tokenService.token}`,
      ...config.headers,
    };
    return config;
  } catch (err) {
    if (retries > 0) {
      await delay(delayTime);
      return authenticateAndValidate(config, retries - 1, delayTime);
    } else {
      throw new Error(`Authentication failed `, { err });
    }
  }
}

authenticatedInternshipAPI.interceptors.request.use((config) =>
  authenticateAndValidate(config)
);

export {
  authenticatedAPI,
  api,
  publicInternshipAPI,
  authenticatedInternshipAPI,
};
