import React, { useEffect, useState, useContext, useRef } from 'react';

import Gamification from './UIComponents/Gamification/Gamification';
import GenericSection from './UIComponents/GenericSection/GenericSection';
import ProfileHeaderStudent from './UIComponents/ProfileHeader/ProfileHeaderStudent';
import BaseLayoutContext from 'contexts/base-layout';

import {
  AlertArea,
  ContainerAreas,
  ContainerCurriculumItems,
  ContentBody,
  FlexContainer,
  FlexItem,
} from './styles/BaseLayouSection';

import { getLanguages } from 'services/student/languages';

import { InterestsContent } from './UIComponents/GenericSection/CardContent/Interests';
import { PersonalityContent } from './UIComponents/GenericSection/CardContent/Personality';
import { BehavioralContent } from './UIComponents/GenericSection/CardContent/Behavioral';
import { ExperiencesContent } from './UIComponents/GenericSection/CardContent/Experiences';
import { ValuesContent } from './UIComponents/GenericSection/CardContent/Values';
import { LanguagesContent } from './UIComponents/GenericSection/CardContent/Languages';
import { ExtraCoursesContent } from './UIComponents/GenericSection/CardContent/ExtraCourses';
import { PortfolioContent } from './UIComponents/GenericSection/CardContent/Portfolio';
import { CompetencesContent } from './UIComponents/GenericSection/CardContent/Competences';

import InterestHelpContent from 'views/MyProfile/components/Interests/InterestHelpContent';
import LanguageHelpContent from 'views/MyProfile/components/language/LanguageHelpContent';
import ValuesTestHelpContent from 'views/MyProfile/components/ValuesTest/ValuesTestHelpContent';
import ExtraCoursesHelpContent from 'views/MyProfile/components/ExtraCourse/ExtraCoursesHelpContent';
import BehavioralTestHelpContent from 'views/MyProfile/components/BehavioralTest/BehavioralTestHelpContent';
import PersonalityTestHelpContent from 'views/MyProfile/components/PersonalityTest/PersonalityTestHelpContent';
import PortfolioProjectsHelpContent from 'views/MyProfile/components/PortfolioProjects/PortfolioProjectsHelpContent';
import TechnicalCompetenceHelpContent from 'views/MyProfile/components/TechnicalCompetence/TechnicalCompetenceHelpContent';
import ProfessionalExperienceHelpContent from 'views/MyProfile/components/Experiences/ProfessionalExperienceHelpContent';

import { wrapperIconsProfile } from './Utils/wrapperIcons';
import { AcademicInformations } from './UIComponents/StudentsAcademicInformations/AcademicInformations';
import { PersonalInformations } from './UIComponents/StudentsAcademicInformations/PersonalInformations';
import ProfileProvider from './Context/ProfileContext';
import { getExtraCourses } from 'services/student/extraCourses';
import SkeletonGamification from './UIComponents/GenericSection/SkeletonSection';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate';
import { getCurriculum } from 'services/student/curriculumData';
import {
  checkCurriculumFilled,
  getStudentGamification,
} from 'services/student/profile';

import OrganizationModalComponent from './UIComponents/OrganizationModalComponent';
import { registerAccessedCurriculum } from 'services/student/registerActivityStudent';
import { PublicProfileLink } from './UIComponents/StudentsAcademicInformations/PublicProfileLink';
import { TutorialModal } from './UIComponents/Gamification/Components/TutorialModal';

import {
  contentStudentJourneyStepOne,
  contentStudentJourneyStepTwo,
} from './UIComponents/Gamification/Components/TutorialModal/Constants/studentJourney';
import { tutorialConfirmation } from 'services/tutorialApi';
import { actionsProfileStore, ProfileStore } from 'stores/profile.store';
import { actionsTutorialStore, useTutorialStore } from 'stores/tutorial.store';
import { useGlobalStore } from 'stores/globalState.store';

export default function ProfileEngine(props) {
  const {
    handleIsLoadingState,
    technical_competences,
    academic_profile,
    metadata,
  } = props;

  const { updateCard } = actionsProfileStore;
  const { globalStore } = useGlobalStore((state) => state.globalStore);

  const {
    IconInterests,
    IconCompetence,
    IconProjectsPortfolio,
    IconExperiences,
    IconCapacitation,
    IconLanguage,
    IconValues,
    IconBehavioral,
    IconPersonality,
  } = wrapperIconsProfile();

  const {
    universityColor,
    profile_id,
    showTutorialJourney,
    handleOpenTutorial,
  } = useContext(BaseLayoutContext);
  const [languagesState, setLanguagesState] = useState([]);
  const [extraCourses, setExtraCourses] = useState([]);
  const [profileData, setProfileData] = useState(academic_profile);
  const [showRaceOrColor, setShowRaceOrColor] = useState(false);
  const [showDisability, setShowDisability] = useState(false);
  const [showIdentityGender, setShowIdentityGender] = useState(false);
  const [gamificationData, setGamificationData] = useState(null);
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);
  const [modalReward, setModalRewards] = useState(false);
  const [tutorial, setTutorial] = useState(null);
  const [tutorialContentObjects, setTutorialContentObjects] = useState(
    contentStudentJourneyStepTwo
  );
  const [gamificationLevel, setGamificationLevel] = useState(null);

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const getLang = async () => {
    const resLanguages = await getLanguages();
    setLanguagesState(resLanguages);
  };

  const handleExtraCourses = async () => {
    const resExtraCourses = await getExtraCourses();
    setExtraCourses(resExtraCourses);
  };

  const fetchGamificationLevel = async () => {
    const data = await getStudentGamification(profile_id);
    setGamificationLevel(data.profile_level);
  };

  const fetchCurriculum = async () => {
    handleIsLoadingState(true);
    setModalRewards(false);
    const curriculum = await getCurriculum();
    const { data } = curriculum;
    if (data !== null) {
      setCurriculumData(data);
    }
  };

  const handleCurriculum = async () => {
    const status = await checkCurriculumFilled();
    if (status) {
      await fetchCurriculum();
      setGenerateCurriculumModal(true);
    }
  };

  useEffect(() => {
    if (generateCurriculumModal === true) {
      registerAccessedCurriculum([curriculumData.curriculum_id]);
    }
  }, [generateCurriculumModal]);

  useEffect(() => {
    getLang();
    handleExtraCourses();
  }, []);

  useEffect(() => {
    updateCard('languages', languagesState);
  }, [languagesState]);

  useEffect(() => {
    updateCard('extraCourses', extraCourses);
  }, [extraCourses]);

  const cardData = ProfileStore((state) => state.cardData);
  const { unviewedTutorials } = useTutorialStore(
    (state) => state.unviewedTutorials
  );
  const { updateTutorial } = actionsTutorialStore;

  const [sections, setSections] = useState([
    {
      title: 'Interesses*',
      gamificationRef: 'interestsRef',
      cardDataKey: 'careerInterests',
      cardDataKeyTwo: 'lifeInterests',
      iconSection: <IconInterests color={universityColor} />,
      time: true,
      content: InterestsContent,
      helpContent: <InterestHelpContent universityColor={universityColor} />,
    },
    {
      title: 'Sua personalidade*',
      gamificationRef: 'personalityRef',
      cardDataKey: 'personality',
      iconSection: <IconPersonality color={universityColor} />,
      time: true,
      content: PersonalityContent,
      helpContent: (
        <PersonalityTestHelpContent universityColor={universityColor} />
      ),
    },
  ]);

  const [visibleItemsSection, setVisibleItemsSection] = useState([
    {
      title: 'Seus Valores',
      gamificationRef: 'valuesRef',
      cardDataKey: 'values',
      iconSection: <IconValues color={universityColor} />,
      time: true,
      content: ValuesContent,
      helpContent: <ValuesTestHelpContent universityColor={universityColor} />,
    },
    {
      title: 'Idiomas',
      gamificationRef: 'languagesRef',
      cardDataKey: 'languages',
      iconSection: <IconLanguage color={universityColor} />,
      time: true,
      content: LanguagesContent,
      helpContent: <LanguageHelpContent universityColor={universityColor} />,
    },
    {
      title: 'Formação e Capacitação',
      gamificationRef: 'extraCoursesRef',
      cardDataKey: 'extraCourses',
      iconSection: <IconCapacitation color={universityColor} />,
      info: `Selecione as 3 Formações e Certificações desejáveis, caso contrário, serão apresentadas as 3 últimas cadastradas.`,
      time: false,
      content: ExtraCoursesContent,
      helpContent: (
        <ExtraCoursesHelpContent universityColor={universityColor} />
      ),
    },
    {
      title: 'Experiências',
      gamificationRef: 'experiencesRef',
      cardDataKey: 'careerExperience',
      cardDataKeyTwo: 'lifeExperience',
      iconSection: <IconExperiences color={universityColor} />,
      info: `Selecione as 3 Experiências desejáveis, caso contrário, serão apresentadas as 3 últimas cadastradas.`,
      time: false,
      content: ExperiencesContent,
      helpContent: (
        <ProfessionalExperienceHelpContent universityColor={universityColor} />
      ),
    },
    {
      title: 'Portfólio de projetos',
      gamificationRef: 'portfolioRef',
      iconSection: <IconProjectsPortfolio color={universityColor} />,
      info: `Selecione os 3 Portfólios desejáveis, caso contrário, serão apresentados os 3 últimos cadastrados.`,
      cardDataKey: 'portfolio',
      time: false,
      content: PortfolioContent,
      helpContent: (
        <PortfolioProjectsHelpContent universityColor={universityColor} />
      ),
    },
  ]);

  const [rewards, setRewards] = useState([
    {
      title: 'Perfil Comportamental*',
      gamificationRef: 'behavioralRef',
      cardDataKey: 'behavioral',
      iconSection: <IconBehavioral color={universityColor} />,
      time: true,
      content: BehavioralContent,
      helpContent: (
        <BehavioralTestHelpContent universityColor={universityColor} />
      ),
    },
  ]);

  useEffect(() => {
    if (technical_competences.average > 0) {
      const competenceSection = {
        title: 'Competências',
        iconSection: <IconCompetence color={universityColor} />,
        info:
          'Sua formação acadêmica foi transformada em Competências de Mercado',
        cardDataKey: 'technicalCompetences',
        time: false,
        content: CompetencesContent,
        helpContent: (
          <TechnicalCompetenceHelpContent universityColor={universityColor} />
        ),
      };
      setRewards([...rewards, competenceSection]);
    }
  }, [technical_competences]);

  const renderMapLevelTwo = (array) => {
    if (gamificationData === null) {
      return <SkeletonGamification />;
    }
    return array.map((item) => (
      <FlexItem>
        <GenericSection
          info={item.info}
          time={item.time}
          title={item.title}
          gamificationRef={item.gamificationRef}
          cardData={cardData}
          CardContent={item.content}
          IconSection={item.iconSection}
          cardDataKey={item.cardDataKey}
          cardDataKeyTwo={item.cardDataKeyTwo}
          helpContent={item.helpContent}
          shouldHideItem={gamificationData?.profile_level === 1}
          {...props}
        />
      </FlexItem>
    ));
  };

  const behavioralBlur =
    cardData?.behavioral?.is_done === true
      ? false
      : gamificationData?.profile_level < 3;

  const renderQuestionnaire = (array) => {
    if (gamificationData === null) {
      return <SkeletonGamification />;
    }

    return array.map((item) => (
      <FlexItem>
        <GenericSection
          info={item.info}
          time={item.time}
          title={item.title}
          gamificationRef={item.gamificationRef}
          cardData={cardData}
          CardContent={item.content}
          IconSection={item.iconSection}
          cardDataKey={item.cardDataKey}
          cardDataKeyTwo={item.cardDataKeyTwo}
          helpContent={item.helpContent}
          shouldHideItem={behavioralBlur}
          updateQuizz={item.updateQuizz}
          {...props}
        />
      </FlexItem>
    ));
  };

  useEffect(() => {
    let updatedTutorials = unviewedTutorials?.some(
      (item) => item.name === 'student_journey'
    );

    if (updatedTutorials) {
      fetchGamificationLevel();
    }
  }, [metadata, unviewedTutorials]);

  useEffect(() => {
    if (gamificationLevel === 1) {
      setTutorialContentObjects(contentStudentJourneyStepOne(universityColor));
    } else if (gamificationLevel === 2) {
      setTutorialContentObjects(contentStudentJourneyStepTwo(universityColor));
    }
  }, [gamificationLevel]);

  useEffect(() => {
    if (
      !globalStore.hasSurveys === true &&
      globalStore.acceptTerm &&
      metadata.has_zip_code &&
      metadata.need_update_professional_satisfaction === false &&
      metadata.professional_status_defined
    ) {
      let findTutorial = unviewedTutorials?.find(
        (item) => item.name === 'student_journey'
      );

      if (findTutorial && gamificationLevel) {
        handleOpenTutorial(true);
        setTutorial(findTutorial);
      }
    }
  }, [gamificationLevel, metadata, globalStore]);

  const handleConfirmTutorial = async () => {
    handleOpenTutorial(false);

    await tutorialConfirmation(tutorial.id);
    updateTutorial('student_journey');
  };

  const renderTutorialModal = () => {
    if (showTutorialJourney) {
      return (
        <TutorialModal
          contentObjects={tutorialContentObjects}
          configTutorial={{
            tutorialFinished: () => handleConfirmTutorial(),
            executeOnSkip: () => handleConfirmTutorial(),
            buttonTextNext: 'Próximo',
            buttonTextSkip: 'Pular',
            buttonTextFinish: 'Completar Jornada',
            buttonTextFinishIcon: true,
          }}
          modalOpenState={showTutorialJourney}
          setModalOpenState={handleOpenTutorial}
        />
      );
    }
  };

  return (
    <ProfileProvider
      gamificationData={gamificationData}
      setGamificationData={setGamificationData}
      handleCurriculum={handleCurriculum}
      modalReward={modalReward}
      setModalRewards={setModalRewards}
    >
      <OrganizationModalComponent
        universityColor={universityColor}
        curriculumData={curriculumData}
        profileData={profileData}
        {...props}
      />

      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          setModalRewards={setModalRewards}
          profileData={profileData}
          showActions={false}
        />
      )}
      <ProfileHeaderStudent
        profileData={profileData}
        setProfileData={setProfileData}
        showRaceOrColor={showRaceOrColor}
        setShowRaceOrColor={setShowRaceOrColor}
        showDisability={showDisability}
        setShowDisability={setShowDisability}
        showIdentityGender={showIdentityGender}
        setShowIdentityGender={setShowIdentityGender}
        {...props}
      />
      {renderTutorialModal()}

      <ContentBody>
        <Gamification cardData={cardData} {...props} />

        <FlexContainer>
          <ContainerCurriculumItems>
            {sections.map((item) => (
              <GenericSection
                info={item.info}
                time={item.time}
                title={item.title}
                cardData={cardData}
                CardContent={item.content}
                IconSection={item.iconSection}
                cardDataKey={item.cardDataKey}
                cardDataKeyTwo={item.cardDataKeyTwo}
                helpContent={item.helpContent}
                shouldHideItem={false}
                updateQuizz={item.updateQuizz}
                gamificationRef={item.gamificationRef}
                {...props}
              />
            ))}
            <AlertArea
              universityColor={universityColor}
              display={gamificationData?.profile_level === 1}
            >
              <p>
                Para desbloquear as sessões abaixo,{' '}
                <strong> conclua todas as etapas do nível 1.</strong>
              </p>
            </AlertArea>
            {renderMapLevelTwo(visibleItemsSection)}
            <AlertArea
              universityColor={universityColor}
              display={behavioralBlur}
            >
              <p>
                Para desbloquear o questionário,{' '}
                <strong> conclua todas as etapas do nível 1 e 2.</strong>
              </p>
            </AlertArea>

            {renderQuestionnaire(rewards)}
          </ContainerCurriculumItems>

          <ContainerAreas>
            <PublicProfileLink />
            <PersonalInformations
              profileData={profileData}
              setProfileData={setProfileData}
              {...props}
            />
            <AcademicInformations
              universityColor={universityColor}
              {...props}
            />
          </ContainerAreas>
        </FlexContainer>
      </ContentBody>
    </ProfileProvider>
  );
}
