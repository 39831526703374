import React, { useContext, useEffect, useRef, useState } from 'react';
import CompanyHeader from '../../../components/ViewsHeader/CompanyViewsHeader';

//Assets
import oportunidades from '../../../assets/img/oportunidades.png';

//Resources
import OportunitiesProvider from './Context/OportunitiesContext';
import TabOne from './Components/TabsComponents/TabOne';
import TabTwo from './Components/TabsComponents/TabTwo';
import TabThree from './Components/TabsComponents/TabThree';
import TabExtensionProjects from './Components/TabsComponents/TabExtensionProjects';
import ExtensionProjectsProvider from './Context/ExtensionProjectsContext';
import { ModalSuccessCreateProject } from './Components/ExtensionProjects/components/Modal/ModalSuccessCreateProject';
import AuthContext from '../../../contexts/authentication';
import ListPublishedByTheGroupProvider from './Context/ListPublishedByTheGroupContext';
import ListOfPublishedLearningProvider from './Context/ListOfPublishedLearningContext';

import { formatNumber } from 'utils/formatNumber';
import {
  countAllAttractions,
  countExtensionProjects,
  countAttractionsEducationalGroup,
} from './services';

export default function ({
  handleIsLoadingState,
  isOnModal,
  defaultCompetence,
  handleSubmitOportunity,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const { metadata } = useContext(AuthContext);
  const hasExtensionProjects = metadata && metadata.has_extension_project;
  const educationalGroup =
    metadata && metadata.staff_role === 'educational_group_board';
  const [countSumAttractions, setCountSumAttractions] = useState(0);
  const [countSumChallenges, setCountSumChallenges] = useState(0);
  const [countSumExtensionProjects, setCountSumExtensionProjects] = useState(0);
  const [countCountGroupAttractions, setCountGroupAttractions] = useState(0);
  const [filterOptions, setFilterOptions] = useState({});

  const getCountSumAttractions = async () => {
    const data = await countAllAttractions();
    const { attractions_sum, connections_challenges_count } = data;
    setCountSumAttractions(attractions_sum);
    setCountSumChallenges(connections_challenges_count);
  };

  const getCountSumExtensionProjects = async () => {
    const data = await countExtensionProjects();
    const { all_projects_sum } = data;
    setCountSumExtensionProjects(all_projects_sum);
  };

  const getCountSumEducationalGroup = async () => {
    const data = await countAttractionsEducationalGroup();
    const { sum_group_universities_attractions } = data;
    setCountGroupAttractions(sum_group_universities_attractions);
  };

  useEffect(() => {
    getCountSumAttractions();
    getCountSumEducationalGroup();
    getCountSumExtensionProjects();
  }, [activeTab]);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const renderizeTab = (step) => {
    if (step === 0) {
      return (
        <TabOne
          handleIsLoadingState={handleIsLoadingState}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          defaultCompetence={defaultCompetence}
          handleSubmitOportunity={handleSubmitOportunity}
          isOnModal={isOnModal}
          educationalGroup={educationalGroup}
        />
      );
    }
    if (step === 1) {
      return (
        <TabTwo
          handleIsLoadingState={handleIsLoadingState}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          defaultCompetence={defaultCompetence}
          educationalGroup={educationalGroup}
        />
      );
    }
    if (!educationalGroup && step === 2) {
      return (
        <TabThree
          handleIsLoadingState={handleIsLoadingState}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          defaultCompetence={defaultCompetence}
        />
      );
    }
    if (hasExtensionProjects && step === 3) {
      return (
        <TabExtensionProjects
          handleIsLoadingState={handleIsLoadingState}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          defaultCompetence={defaultCompetence}
        />
      );
    }
  };

  const topTab = useRef(null);

  const ScrollTopTab = () => {
    if (topTab) {
      topTab.current.scrollIntoView({ alignToTop: true });
    }
  };

  const countNumber = () => {
    if (!educationalGroup) {
      return `(${formatNumber(countSumAttractions)})`;
    } else {
      return `(${formatNumber(countCountGroupAttractions)})`;
    }
  };

  useEffect(() => {
    ScrollTopTab();
  }, [activeTab]);

  const tabLabels = [
    `Cadastrar`,
    `Conteúdos Publicadas (${formatNumber(
      educationalGroup ? countCountGroupAttractions : countSumAttractions
    )})`,
  ];

  if (!educationalGroup) {
    tabLabels.push(`Desafios Empresas (${formatNumber(countSumChallenges)})`);
  }

  return (
    <div ref={topTab}>
      <OportunitiesProvider>
        <ListOfPublishedLearningProvider>
          <ListPublishedByTheGroupProvider>
            <ExtensionProjectsProvider
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            >
              {!isOnModal && (
                <CompanyHeader
                  title="Oportunidades de Aprendizagem"
                  activeTab={activeTab}
                  handleTabChange={handleTabChange}
                  backgroundImage={oportunidades}
                  tabs={tabLabels}
                />
              )}
              {renderizeTab(activeTab)}
              <ModalSuccessCreateProject />
            </ExtensionProjectsProvider>
          </ListPublishedByTheGroupProvider>
        </ListOfPublishedLearningProvider>
      </OportunitiesProvider>
    </div>
  );
}
