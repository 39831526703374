import { authenticatedAPI } from 'services/api/api';

const getOptionsCoursesChallenges = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/challenges/courses`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};
const getChallenges = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/challenges?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAdditionalInfosChallenge = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/challenges/${id}/update`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const unPublishChallenge = async (event) => {
  let url = `api/internal/companies/attractions/challenges/${event.id}/unpublished`;
  const response = authenticatedAPI
    .patch(url, { is_published: false })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const createNewChallenge = async (data) => {
  const formData = new FormData();
  const newData = Object.assign(data, {
    target_audiences: {
      courses: [...data.courses],
    },
  });

  if (data.attraction_document) {
    formData.append('attraction_document', data.attraction_document);
    delete data.attraction_document;
  }
  if (data.attraction_image) {
    formData.append('attraction_image', data.attraction_image);
    delete data.attraction_image;
  }

  formData.append('name', data.name);
  formData.append('access_link', data.access_link ? data.access_link : '');
  formData.append('value', data.value ? data.value : 0);
  formData.append('data', JSON.stringify(newData));

  formData.append(
    'target_audiences',
    JSON.stringify([
      {
        courses: [...data.courses],
      },
    ])
  );

  const response = authenticatedAPI
    .post('/api/internal/companies/attractions/challenges', formData, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=something',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const updateChallenge = async (data) => {
  let url = `api/internal/companies/attractions/challenges/${data.id}/update`;

  if (data.remove_attraction_image) {
    url += `?remove_attraction_image=true`;
  }

  if (data.remove_attraction_document) {
    url += `?remove_attraction_document=true`;
  }

  const formData = new FormData();
  const newData = Object.assign(data, {
    target_audiences: {
      courses: [...data.courses],
    },
  });

  formData.append(
    'attraction_document',
    data.attraction_document ? data.attraction_document : ''
  );

  formData.append(
    'attraction_image',
    data.attraction_image ? data.attraction_image : ''
  );

  formData.append('name', data.name);
  formData.append('access_link', data.access_link ? data.access_link : '');
  formData.append('value', data.value ? data.value : 0);
  formData.append('data', JSON.stringify(newData));

  formData.append(
    'target_audiences',
    JSON.stringify([
      {
        courses: [...data.courses],
      },
    ])
  );

  const response = authenticatedAPI
    .put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export {
  getOptionsCoursesChallenges,
  getChallenges,
  createNewChallenge,
  updateChallenge,
  unPublishChallenge,
  getAdditionalInfosChallenge,
};
