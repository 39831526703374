import { authenticatedAPI } from 'services/api/api';

const getListConnectedCompanies = (query) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies/invites/connected?${query}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getListDisconnectCompanies = (query) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies/invites/disconnected?${query}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const companyPreRegistration = (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/companies/invites/create`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const companyDocumentsPreRegistration = (id, data) => {
  const response = authenticatedAPI
    .patch(
      `/api/internal/university/companies/invites/document/update/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const postDocumentOneCreateFile = (id, data, config) => {
  const dataFile = new FormData();

  dataFile.append('agreement_document_one_file', data);

  const response = authenticatedAPI
    .patch(
      `/api/internal/university/companies/invites/document/update/${id}`,
      dataFile,
      config
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const postDocumentTwoCreateFile = (id, data, config) => {
  const dataFile = new FormData();

  dataFile.append('agreement_document_two_file', data);

  const response = authenticatedAPI
    .patch(
      `/api/internal/university/companies/invites/document/update/${id}`,
      dataFile,
      config
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const deleteDocument = (id, data) => {
  const response = authenticatedAPI
    .patch(
      `/api/internal/university/companies/invites/document/update/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getListInviteCreated = (query) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies/invites/invite-created?${query}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getListInviteSent = (query) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies/invites/invite-sent?${query}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getListInviteAnswered = (query) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies/invites/invite-answered?${query}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getListCanceledInvite = (query) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies/invites/canceled-invite?${query}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCompanyProfile = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies/invites/detail/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const disconnectCompany = (id) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/companies/invites/disconnect/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const connectCompany = (id) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/companies/invites/connect/${id}`)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const unInvite = (id) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/companies/invites/cancel/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const sendInvitation = (id) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/companies/invites/send/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getUpdateForm = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/university/companies/invites/update/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const updateForm = (id, data) => {
  const response = authenticatedAPI
    .put(`api/internal/university/companies/invites/update/${id}`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getConnectedCompaniesFilters = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/companies/invites/filters/options/connected`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getDisconnectCompaniesFilters = () => {
  const response = authenticatedAPI
    .get(
      `api/internal/university/companies/invites/filters/options/disconnected`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getInviteCreatedFilters = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/companies/invites/filters/options/created`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getInviteSentFilter = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/companies/invites/filters/options/invited`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getInviteAnsweredFilter = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/companies/invites/filters/options/answered`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCanceledInviteFilter = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/companies/invites/filters/options/canceled`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const connectionAndDisconnection = (data) => {
  const response = authenticatedAPI
    .patch(
      `api/internal/university/companies/invites/mass-status-control`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const countTabs = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/companies/invites/count`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const isComapanyAndInviteRegistered = (cnpj) => {
  const response = authenticatedAPI
    .get(
      `api/internal/university/companies/invites/exists?organization_number=${cnpj}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCitiesOptions = (states, tab) => {
  const statesQuery = `states=${states.join('&states=')}`;

  const response = authenticatedAPI
    .get(
      `api/internal/university/companies/invites/filters/options/${tab}?${statesQuery}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getMacroAreas = async () => {
  const response = await authenticatedAPI
    .get('api/internal/university/companies/invites/macro-areas')
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const createDocument = async (data, id) => {
  const newDocument = await authenticatedAPI
    .post(
      `api/internal/university/companies/invites/${id}/document/create`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);

  return newDocument;
};

const getDocumentsById = (companyId) => {
  const documents = authenticatedAPI
    .get(
      `api/internal/university/companies/invites/${companyId}/documents/list`
    )
    .then((res) => res.data)
    .catch((err) => err);

  return documents;
};

const deleteDocuments = async (companyId, documentId) => {
  const res = authenticatedAPI
    .delete(
      `api/internal/university/companies/invites/${companyId}/document/update/${documentId}`
    )
    .then((res) => res)
    .catch((err) => err);
  return res;
};

const updateDocument = async (companyId, documentId, data) => {
  const res = authenticatedAPI
    .patch(
      `api/internal/university/companies/invites/${companyId}/document/update/${documentId}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => err);

  return res;
};

const getCompanyVacancies = async (companyId, params) => {
  const res = await authenticatedAPI
    .get(`api/internal/university/jobs/company/${companyId}?${params}`)
    .then((res) => res.data)
    .catch((err) => err);
  return res;
};

export {
  getListConnectedCompanies,
  getListDisconnectCompanies,
  companyPreRegistration,
  getListInviteCreated,
  getListInviteSent,
  getListInviteAnswered,
  getListCanceledInvite,
  getCompanyProfile,
  disconnectCompany,
  connectCompany,
  unInvite,
  sendInvitation,
  companyDocumentsPreRegistration,
  getUpdateForm,
  updateForm,
  getConnectedCompaniesFilters,
  getDisconnectCompaniesFilters,
  connectionAndDisconnection,
  getInviteCreatedFilters,
  getInviteSentFilter,
  getInviteAnsweredFilter,
  getCanceledInviteFilter,
  countTabs,
  postDocumentOneCreateFile,
  postDocumentTwoCreateFile,
  isComapanyAndInviteRegistered,
  deleteDocument,
  getCitiesOptions,
  getMacroAreas,
  createDocument,
  getDocumentsById,
  deleteDocuments,
  updateDocument,
  getCompanyVacancies,
};
