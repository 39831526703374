export const defineInternshipBackUrl = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return 'http://localhost:8001';
  }

  if (hostname.includes('.dev')) {
    return 'https://api-internship-dev.worka.love';
  }

  return 'https://api-internship-prd.worka.love';
};

export const defineInternshipFrontUrl = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return 'http://localhost:3001';
  }

  return `https://${hostname}/estagios`;
};
