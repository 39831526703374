import { authenticatedAPI } from 'services/api/api';

const listPartners = async () => {
  const response = authenticatedAPI
    .get('/api/internal/companies/partners')
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const updatePartners = async (id) => {
  const response = authenticatedAPI
    .put(`/api/internal/companies/company-pre-registration/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const deletePartners = async (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/companies/company-pre-registration/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const organizationNumberExists = async (organizationNumber) => {
  const data = { organization_number: organizationNumber };

  const response = authenticatedAPI
    .post(`/api/internal/companies/companies/existing`, data)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const hasPartners = async () => {
  const response = authenticatedAPI
    .get('api/internal/companies/ies/connection/exists')
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  listPartners,
  updatePartners,
  deletePartners,
  organizationNumberExists,
  hasPartners,
};
