export const configModalDefault = {
  bubbleText: 'Se liga!',
  ParagraphText: `Em parceria com a TOEFL ITP (Mastertest Educational), oferecemos um <strong>teste de nivelamento</strong> em inglês <strong>100% gratuito</strong>.`,
  TwoParagraphText: ` Siga as instruções e, ao final do teste, conte pra gente qual o seu grau de conhecimento.`,
  buttonConfirmText: 'OK, VAMOS LÁ!',
  buttonCloseX: true,
  style: {
    gapParagraph: 5,
    textAlign: 'center',
    fontSize: '20px',
    sizeIconPerson: '78px',
    colorBgButtonX: '#ececec',
    lineHeight: '25px',
    maxWidth: '100%',
    position: 'fixed',
    top: '0px',
    height: '200px',
    color: '#606062',
    alignIcon: 'center',
  },
};

export const configModalMobile = {
  bubbleText: 'Atenção!',
  ParagraphText: `Em parceria com a TOEFL ITP (Mastertest Educational), oferecemos um <strong>teste de nivelamento</strong> em inglês <strong>100% gratuito</strong>.`,
  TwoParagraphText: `Para realizar o teste acesse o sistema pelo seu computador.`,
  buttonConfirmText: 'OK, ENTENDI!',
  buttonCloseX: false,
  style: {
    gapParagraph: 5,
    textAlign: 'center',
    fontSize: '20px',
    sizeIconPerson: '78px',
    colorBgButtonX: '#ececec',
    lineHeight: '25px',
    maxWidth: '100%',
    position: 'fixed',
    top: '0px',
    height: '200px',
    color: '#606062',
    alignIcon: 'center',
  },
};
