import React, { useEffect, useState, useContext } from 'react';
import { func, object, bool, string } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import LogoWl from 'assets/img/wl.png';
import { Grid } from '@material-ui/core';
import { termAccept } from 'services/acceptanceTerm';
import BaseLayoutContext from 'contexts/base-layout';
import { actionsGlobalStore, useGlobalStore } from 'stores/globalState.store';

const AcceptanceTerm = ({
  globalState,
  confirmTerm,
  termUrl,
  metadata,
  handleOpenTutorial,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const { globalStore } = useGlobalStore((state) => state.globalStore);
  const { updateGlobalStore } = actionsGlobalStore;

  useEffect(() => {
    let need_update_professional_satisfaction = false;
    let has_zip_code = false;

    if (metadata != undefined && metadata.role === 'student') {
      need_update_professional_satisfaction =
        metadata?.need_update_professional_satisfaction;
      has_zip_code = metadata?.has_zip_code;
    }
    if (
      termUrl &&
      metadata &&
      globalStore.acceptTerm === false &&
      !need_update_professional_satisfaction &&
      has_zip_code
    ) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, []);

  async function handleAcceptTerm() {
    setShowDialog(false);
    const { is_assign } = await termAccept(metadata);
    confirmTerm({ acceptTerm: is_assign });
    updateGlobalStore('acceptTerm', is_assign);

    let findTutorial = globalState?.unviewedTutorials?.find(
      (item) => item.name === 'student_journey'
    );

    if (findTutorial) {
      handleOpenTutorial(true);
    }
  }

  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      disableBackdropClick
    >
      <DialogContent style={{ width: 395 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img width="200" src={LogoWl} alt="Editar" />
          </Grid>
          <Grid item xs={12} md={12}>
            <DialogTitle id="alert-dialog-slide-title">
              Atualizamos o nosso Termo de Uso e Política de Privacidade
            </DialogTitle>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ padding: '4px 22px 4px', textAlign: 'justify' }}
          >
            Entendemos que ao continuar utilizando a plataforma, você está de
            acordo com os novos termos. Para consultá-los, para acessar estes
            links:{' '}
            <a target="_blank" href={termUrl}>
              Termo de Uso e Política de Privacidade.
            </a>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleAcceptTerm(metadata)}
          style={{
            borderRadius: 140,
            fontSize: 12,
            margin: '3px 32px 15px',
            backgroundColor: universityColor ? universityColor : 'primary',
            color: '#fff',
          }}
          variant="contained"
          color={universityColor ? universityColor : 'primary'}
        >
          CONFIRMAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AcceptanceTerm.propTypes = {
  classes: object,
  acceptTerm: bool,
  confirmTerm: func.isRequired,
  termUrl: string,
  metadata: object,
};

export default withStyles(ProfessionalExperienceFormStyles)(AcceptanceTerm);
