import axios from 'axios';
import JWTInterceptor from './JWTInterceptor';
import { tokenService } from './token.service';

import URLS from 'constants/urls';
import { authenticatedAPI } from './api.service';

const jwtInterceptor = new JWTInterceptor();

const backofficeApi = axios.create({
  baseURL: `${URLS.API_URL}/api/internal/backoffice`,
});

backofficeApi.interceptors.request.use(async (config) => {
  config.headers = {
    Authorization: `Bearer ${tokenService.token}`,
    ...config.headers,
  };
  config.withCredentials = false;
  return config;
});

jwtInterceptor.setupInterceptors(backofficeApi);

export { backofficeApi };
