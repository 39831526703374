import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';
import { authenticatedAPI } from 'services/api/api';

const saveProfessionalStatus = async (
  {
    professionalStatusProps,
    workInArea,
    professionalOccupationArea,
    isOpenToOffers,
    selectedOffers,
  },
  socioeconomicStatus
) => {
  const sendData = {
    professional_status: professionalStatusProps,

    professional_working_in_formation_area:
      professionalStatusProps !== 'unemployed' ? workInArea : false,
    professional_is_open_to_offer: isOpenToOffers,
    professional_occupation_area: professionalOccupationArea,
  };

  if (selectedOffers.length) {
    sendData.professional_open_to_offers = selectedOffers;
  }
  if (
    socioeconomicStatus.ses_survey !== 0 &&
    Object.keys(socioeconomicStatus.answers).length > 0
  ) {
    sendData.socioeconomic_status = socioeconomicStatus;
  }

  try {
    await authenticatedAPI.post(
      `/api/internal/students/me/professional-status`,
      sendData
    );
    window.location.reload();
  } catch (error) {
    return error;
  }
};
const updateProfessionalStatus = async (workInArea) => {
  const payload = { professional_working_in_formation_area: workInArea };
  const response = authenticatedAPI
    .post(`/api/internal/students/me/working-area`, payload)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateProfessionalStatusModal = async (data) => {
  const payload = data;
  const response = authenticatedAPI
    .patch(`/api/internal/students/me/professional-status`, payload)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  saveProfessionalStatus,
  updateProfessionalStatus,
  updateProfessionalStatusModal,
};
