import { authenticatedAPI } from 'services/api/api';
import axios from 'axios';

class AcademicModelingApi {
  baseUrl = `/api/internal/university/academic-modeling`;
  CancelToken = axios.CancelToken;
  cancelGetFinishedDisciplinesRequest;

  getCourses = async (queryParams = '?limit=20&offset=0') => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses${queryParams}`)
      .then((res) => res)
      .catch((error) => error);
  };

  getDisciplines = async (id, query = '') => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${id}/disciplines?${query}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  updateWorkload = async (id, workload) => {
    return authenticatedAPI
      .put(`${this.baseUrl}/disciplines/${id}`, {
        workload: workload,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  getDisciplinesMetrics = async (courseId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/disciplines/metrics`)
      .then((res) => res)
      .catch((error) => error);
  };

  putDisciplineMatrix = async (courseId, query = '') => {
    return authenticatedAPI
      .put(`${this.baseUrl}/courses/${courseId}/matrix?${query}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getDisciplinesOptions = async (courseId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/disciplines/options`)
      .then((res) => res)
      .catch((error) => error);
  };

  getProfessions = async (courseId, query = '') => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/professions?${query}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getEgressProfile = async (courseId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/egress-profile`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  putCreateEgressProfileData = async (id, data) => {
    return authenticatedAPI
      .put(`${this.baseUrl}/courses/${id}/egress-profile?`, { ...data })
      .then((res) => res.data)
      .catch((error) => error);
  };

  putCreateEgressProfileProfession = async (id, query) => {
    return authenticatedAPI
      .put(`${this.baseUrl}/courses/${id}/egress-profile?${query}`)
      .then((res) => res.data)
      .catch((error) => error);
  };
  getDisciplineDetails = async (disciplineId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/disciplines/${disciplineId}`)
      .then((res) => res)
      .catch((error) => error);
  };
  getTags = async (disciplineId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/disciplines/${disciplineId}/tags`)
      .then((res) => res)
      .catch((error) => error);
  };
  createTag = async (disciplineId, tag) => {
    return authenticatedAPI
      .post(`${this.baseUrl}/disciplines/${disciplineId}/tags`, tag)
      .then((res) => res)
      .catch((error) => error);
  };
  updateTag = async (disciplineId, tag) => {
    return authenticatedAPI
      .put(`${this.baseUrl}/disciplines/${disciplineId}/tags/${tag.id}`, tag)
      .then((res) => res)
      .catch((error) => error);
  };
  deleteTag = async (disciplineId, tagId) => {
    return authenticatedAPI
      .delete(`${this.baseUrl}/disciplines/${disciplineId}/tags/${tagId}`)
      .then((res) => res)
      .catch((error) => error);
  };
  getFinishedDisciplines = async (courseId, page, search = '') => {
    const offset = (page - 1) * 20;
    let query = `?limit=20&offset=${offset}`;
    query = search ? `${query}&search=${search}` : query;
    this.cancelGetFinishedDisciplines();
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/finished-disciplines${query}`, {
        cancelToken: new this.CancelToken((c) => {
          this.cancelGetFinishedDisciplinesRequest = c;
        }),
      })
      .then((res) => res)
      .catch((error) => error);
  };
  cancelGetFinishedDisciplines = () => {
    if (this.cancelGetFinishedDisciplinesRequest) {
      this.cancelGetFinishedDisciplinesRequest();
    }
  };

  getContemplatedMarketRequirements = async (
    courseId,
    queryParams = '?limit=20&offset=0'
  ) => {
    return authenticatedAPI
      .get(
        `${this.baseUrl}/courses/${courseId}/contemplated-market-requirements${queryParams}`
      )
      .then((res) => res.data)
      .catch((error) => error);
  };

  getContemplatedMarketTendencies = async (
    courseId,
    queryParams = '?limit=20&offset=0'
  ) => {
    return authenticatedAPI
      .get(
        `${this.baseUrl}/courses/${courseId}/contemplated-market-tendencies${queryParams}`
      )
      .then((res) => res.data)
      .catch((error) => error);
  };

  getMarketRequirementsMetrics = async (courseId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/market-requirements/metrics`)
      .then((res) => res)
      .catch((error) => error);
  };

  getMarketTendenciesMetrics = async (courseId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/market-tendencies/metrics`)
      .then((res) => res)
      .catch((error) => error);
  };

  getAssociationsMarket = async (disciplineId, associationId) => {
    return authenticatedAPI
      .get(
        `${this.baseUrl}/courses/${disciplineId}/market/association/${associationId}`
      )
      .then((res) => res.data)
      .catch((error) => error);
  };

  getNotContemplatedMarketRequirements = async (
    courseId,
    queryParams = '?limit=20&offset=0'
  ) => {
    return authenticatedAPI
      .get(
        `${this.baseUrl}/courses/${courseId}/not-contemplated-market-requirements${queryParams}`
      )
      .then((res) => res.data)
      .catch((error) => error);
  };

  getNotContemplatedMarketTendencies = async (
    courseId,
    queryParams = '?limit=20&offset=0'
  ) => {
    return authenticatedAPI
      .get(
        `${this.baseUrl}/courses/${courseId}/not-contemplated-market-tendencies${queryParams}`
      )
      .then((res) => res.data)
      .catch((error) => error);
  };

  getDisciplinesCreateTag = async (disciplineId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${disciplineId}/market/disciplines`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getTagsCreateTag = async (disciplineId, filter = '') => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${disciplineId}/market/tags${filter}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  createAssociation = async (data) => {
    return authenticatedAPI
      .post(`/api/internal/university/associations`, data)
      .then((res) => res)
      .catch((error) => error);
  };

  updateAssociation = async (associationId, data) => {
    return authenticatedAPI
      .patch(`/api/internal/university/associations/${associationId}`, data)
      .then((res) => res)
      .catch((error) => error);
  };
  updateDisciplines = async (courseId, associationId, data) => {
    return authenticatedAPI
      .patch(
        `${this.baseUrl}/courses/${courseId}/market/association/${associationId}`,
        data
      )
      .then((res) => res)
      .catch((error) => error);
  };
  publishCourseAnalysis = async (courseId) => {
    return authenticatedAPI
      .post(`${this.baseUrl}/courses/${courseId}/analysis`)
      .then((res) => res)
      .catch((error) => error);
  };

  getRequerimentsReport = async (courseId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/market-requirements/report`)
      .then((res) => res.data)
      .catch((error) => error);
  };
  getTendeciesReport = async (courseId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/market-tendencies/report`)
      .then((res) => res.data)
      .catch((error) => error);
  };
  updateCurrentStep = async (courseId) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/courses/${courseId}/steps`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getTagsWithFilter = (param) => {
    return authenticatedAPI
      .get(`${this.baseUrl}/principal-tags?keyword=${param}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  createTagBaseSelect = async (disciplineId, tagId, data) => {
    return authenticatedAPI
      .post(
        `${this.baseUrl}/disciplines/${disciplineId}/principal-tag/${tagId}/tags
      `,
        data
      )
      .then((res) => res)
      .catch((error) => error);
  };
}

export default new AcademicModelingApi();
