import moment from 'moment';
import React, { useContext, useState } from 'react';
import {
  AlertTest,
  CallToAction,
  Container,
  ContainerPersonality,
  Paragraph,
  Section,
  Title,
} from './style';
import PersonalityTest from 'views/MyProfile/components/PersonalityTest/PersonalityTest';
import ModalInformation from 'components/informationModal';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import { wrapperIconsProfile } from 'views/Profile/Utils/wrapperIcons';

export function PersonalityContent(props) {
  const {
    cardData,
    cardDataKey,
    openModalWithContent,
    gamificationRef,
    academic_profile,
  } = props;
  const [controlInformationModal, setControlInformationModal] = useState(false);

  const { universityColor, fetchGamificationData, sectionRef } = useContext(
    ProfileContext
  );
  const { IoIosArrowForward } = wrapperIconsProfile();

  const callToPersonalityTest = () => {
    setControlInformationModal(false);
    openModalWithContent(
      mergeContextsHelper(
        <PersonalityTest
          cardData={cardData}
          cardDataKey={cardDataKey}
          fetchGamificationData={fetchGamificationData}
        />
      ),
      'Questionário de Personalidade',
      null,
      () => null,
      false,
      'personality'
    );
  };

  const config = {
    modalWidth: 500,
    bubbleText: `Olá, ${academic_profile?.name}!`,
    ParagraphText: `Recomendo que você tenha um tempinho dedicado para realizar o seu
    questionário com calma, pois, após realizar a conclusão o seu
    resultado ficará exposto em seu currículo e você só poderá refazê-lo
    daqui <b>6 meses!</b>`,
    buttonConfirmText: 'Estou com tempo, bora lá!',
    buttonConfirmFunction: callToPersonalityTest,
    buttonCloseX: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '92%',
    },
  };

  const showCTAPersonality = () => {
    if (cardData[cardDataKey].is_done === false) {
      return (
        <CallToAction
          showButton={cardData[cardDataKey].is_done === false}
          universityColor={universityColor}
          onClick={() => setControlInformationModal(true)}
        >
          Realizar questionário <IoIosArrowForward />
        </CallToAction>
      );
    }

    if (cardData[cardDataKey].is_enabled) {
      return (
        <CallToAction
          showButton={cardData[cardDataKey].is_enabled}
          universityColor={universityColor}
          onClick={() => setControlInformationModal(true)}
        >
          Refazer questionário <IoIosArrowForward />
        </CallToAction>
      );
    }
  };

  return (
    <Container ref={sectionRef[gamificationRef]}>
      <Section>
        {controlInformationModal && (
          <ModalInformation
            universityColor={universityColor}
            modalOpenState={controlInformationModal}
            config={config}
            buttonConfirmFunction={config.buttonConfirmFunction}
            closeModalFunction={() => setControlInformationModal(false)}
          />
        )}
        {cardData[cardDataKey]?.results?.map(
          ({ verbose_name, description }, index, array) => (
            <ContainerPersonality>
              <Title>{verbose_name}</Title>
              <Paragraph>{description}</Paragraph>
              {index !== array.length - 1 && (
                <div className="divider-control" />
              )}
            </ContainerPersonality>
          )
        )}
        {showCTAPersonality()}
        {!cardData[cardDataKey]?.is_enabled && (
          <AlertTest showAlert={!cardData[cardDataKey].is_enabled}>
            Este questionário poderá ser refeito em{' '}
            {moment(cardData[cardDataKey].next_date).format('DD/MM/YYYY')}
          </AlertTest>
        )}
      </Section>
    </Container>
  );
}
