import { authenticatedAPI } from 'services/api/api';
import dataProductsAPI from 'auth/jwt/dataProductsApi.service';

export const getTalents = async (filters = '', offset = 0, limit = 20) =>
  await dataProductsAPI.get(
    `/company/filter-talents/students?offset=${offset}&limit=${limit}&${filters}`
  );

export const getIndications = async (filters = '', offset = 0, limit = 20) =>
  await dataProductsAPI.get(
    `/company/filter-talents/nominee-students?offset=${offset}&limit=${limit}&${filters}`
  );

export const getHeadFilters = async () =>
  await dataProductsAPI.get('/company/filter-talents/filter-options');

export const indicateForJob = async ({ student, jobOffer }) =>
  await authenticatedAPI.post('/api/internal/companies/students/indicate', {
    student,
    job_offer: jobOffer,
  });

export const getIndicationJobs = async (id) =>
  (
    await authenticatedAPI.get(
      `/api/internal/companies/students/${id}/indicated-offers`
    )
  ).data;

export const getCities = async (params) =>
  await dataProductsAPI.get(`/company/filter-talents/filter-options?${params}`);
