import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { authenticatedAPI } from 'services/api/api';
import dataProductsAPI from 'services/api/dataProductsApi';

const getIndicatorsData = async () => {
  const response = dataProductsAPI
    .get(`/university/analytics`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getInsightData = async (filters) => {
  const queryString = queryParamsFromFilterObject(filters);
  const response = dataProductsAPI
    .get(`university/insights?group=true&${queryString}`)
    .then((res) => res.data.result)
    .catch((error) => error);

  return response;
};

const getInsightSpreadsheet = async () => {
  const response = dataProductsAPI
    .get(`university/insights/download`, {
      responseType: 'arraybuffer',
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getInsightOptions = async () => {
  const response = dataProductsAPI
    .get(`/university/insights/options`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getIndicatorsData,
  getInsightData,
  getInsightSpreadsheet,
  getInsightOptions,
};
