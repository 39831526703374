import React, { useState, useContext, useEffect } from 'react';
import {
  BoxInformation,
  Container,
  RemoveCandidateButton,
  BoxImage,
  ContainerInformation,
  FlagStatus,
  Name,
  AcademicInformation,
  ContactInformation,
  ContainerContact,
  Divider,
  ButtonFeedback,
  StatusStudent,
  ButtonSeeEvidences,
} from './styled';
import placeholderImage from 'assets/img/placeholder.jpg';

import { MdOutlineAlternateEmail, MdOutlineSmartphone } from 'react-icons/md';
import { BiMessageAlt } from 'react-icons/bi';
import { CgFileDocument } from 'react-icons/cg';
import ModalSendYourFeedback from '../../../ModalFeedbackStudent';
import ModalViewFeedback from '../../../ModalViewFeedback';
import {
  getFeedbackStudent,
  getStudentEvidenceApi,
} from 'views/StaffPages/Opportunities/services';
import { ExtensionProjectsContext } from '../../../../../context/ExtensionProjectsContext';
import ModalShowEvidence from 'components/ModalShowEvidence';

export default function EnrolledStudents({
  registeredStudent,
  getStudents,
  limitPerPage,
  offset,
  status,
  showStatus,
  setScore,
  finalization,
}) {
  const [openModalFeedbackStudent, setOpenModalFeedbackStudent] = useState(
    false
  );

  const [openModalViewFeedback, setOpenModalViewFeedback] = useState(false);
  const [feedbackStudent, setFeedbackStudent] = useState({});

  const { extensionProjectId } = useContext(ExtensionProjectsContext);

  const [modalEvidencesOpen, setModalEvidencesOpen] = useState(false);

  const returnStyledFlag = {
    canceled: false,
    removed: false,
    removed_in_finalization: false,
    approved: true,
    enrolled: true,
  };

  const returnLabelFlag = {
    removed: 'Estudante removido',
    canceled: 'Estudante cancelou',
    approved: 'Estudante aprovado',
    enrolled: 'Inscrito',
    removed_in_finalization: 'Estudante removido',
  };

  const getStudentEvidence = async () => {
    setModalEvidencesOpen(true);
  };

  const handleSendYourFeedback = () => {
    setOpenModalFeedbackStudent(true);
  };

  const handleViewFeedback = async (feedbackId) => {
    const data = await getFeedbackStudent(feedbackId);
    setFeedbackStudent(data);
    setOpenModalViewFeedback(true);
  };

  useEffect(() => {
    setScore && setScore(registeredStudent.score);
  }, []);

  return (
    <Container>
      <ModalShowEvidence
        open={modalEvidencesOpen}
        setOpen={setModalEvidencesOpen}
        id={extensionProjectId}
        useTab={false}
        studentId={registeredStudent.id}
        uniqueStudent={true}
        seeAllEvidencesStudent={false}
      />
      <div className="wrapper">
        <BoxImage>
          <img
            src={registeredStudent.avatar}
            alt="avatar"
            onError={(event) => {
              event.target.src = placeholderImage;
              event.target.onerror = null;
            }}
          />
        </BoxImage>
        <ContainerInformation>
          <BoxInformation>
            <Name> {registeredStudent.name} </Name>

            <AcademicInformation>
              {registeredStudent &&
                registeredStudent.courses.map((item) => (
                  <div className="wrapperFlex">
                    <p>{item.course__name}</p>
                    <span> - </span>
                    <p> {item.semester}° semestre</p>
                  </div>
                ))}
            </AcademicInformation>

            <ContainerContact>
              <ContactInformation>
                <MdOutlineSmartphone size={14} color="#009291" />
                {registeredStudent.mobile_phone}
              </ContactInformation>
              <ContactInformation>
                <MdOutlineAlternateEmail size={15} color="#009291" />
                {registeredStudent.email}
              </ContactInformation>
            </ContainerContact>
            {registeredStudent.evidences > 0 && (
              <ButtonSeeEvidences onClick={getStudentEvidence}>
                <CgFileDocument size={18} color="#FFF" />
                Visualizar evidências ({registeredStudent.evidences})
              </ButtonSeeEvidences>
            )}

            {registeredStudent.feedback_id !== null && (
              <ButtonFeedback
                onClick={() =>
                  handleViewFeedback(registeredStudent.feedback_id)
                }
              >
                <BiMessageAlt size={16} color="fff" />
                Visualizar Feedback
              </ButtonFeedback>
            )}
          </BoxInformation>
          {returnLabelFlag[registeredStudent.status] === 'Inscrito' &&
            status === true && (
              <RemoveCandidateButton onClick={() => handleSendYourFeedback()}>
                Remover estudante
              </RemoveCandidateButton>
            )}

          {returnLabelFlag[registeredStudent.status] !== 'Inscrito' && (
            <StatusStudent
              statusStudent={returnLabelFlag[registeredStudent.status]}
            >
              {returnLabelFlag[registeredStudent.status]}
            </StatusStudent>
          )}

          {openModalFeedbackStudent && (
            <ModalSendYourFeedback
              isOpen={openModalFeedbackStudent}
              closeModalFeedbackStudent={setOpenModalFeedbackStudent}
              student={registeredStudent}
              getStudents={getStudents}
              limitPerPage={limitPerPage}
              offset={offset}
              finalization={finalization}
            />
          )}

          {openModalViewFeedback && (
            <ModalViewFeedback
              isOpen={openModalViewFeedback}
              setOpenModalFeedback={setOpenModalViewFeedback}
              feedback={feedbackStudent}
            />
          )}
        </ContainerInformation>
      </div>
      <Divider />
    </Container>
  );
}
