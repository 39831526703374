import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const UnInput = styled.input`
  border: ${(props) =>
    props.error ? '0.6px solid #FFCECE' : '1px solid #d0d0d0'};
  width: 100%;
  padding: 12px;
  background-color: ${(props) => (props.error ? '#FFEDED' : '#fff')};
  border-radius: 4px;
  margin-bottom: 4px;
  display: flex;
  position: relative;
`;

export const UnInputLink = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  padding: 12px 30px;
  margin-bottom: 10px;

  &:focus {
    border-bottom: 1px solid #009291;
  }
`;
export const UnImageLink = styled.img`
  width: 20px;
  position: absolute;
  top: 30px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 15px;
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
