import React from 'react';

export const matchTexts = {
  matchOne: {
    range: '0% - 33,9%',
    description:
      'Nesse nível, seu perfil profissional se encontra <strong>distante</strong> do que a atuação está exigindo no momento.',
  },
  matchTwo: {
    range: '34% - 66,9%',
    description:
      'Nesse nível, seu perfil profissional se encontra <strong>mais próximo</strong> do que a atuação está exigindo no momento.',
  },
  matchThree: {
    range: '67% - 99,9%',
    description:
      'Nesse nível, suas habilidades e perfil profissional <strong>combinam</strong> com o que a atuação está exigindo no momento.',
  },
  matchFour: {
    range: '100%',
    description:
      'Uau! Nesse nível, suas habilidades e perfil profissional <strong>combinam totalmente</strong> com as atuações exigidas no momento.',
  },
};

const FormattedText = ({ text, highlight }) => {
  const match = text.split(highlight);
  return (
    <span>
      {match.map((match, index) => (
        <React.Fragment key={index}>
          <p dangerouslySetInnerHTML={{ __html: match }} />
          {index < match.length - 1 && <strong>{highlight}</strong>}
        </React.Fragment>
      ))}
    </span>
  );
};

const getHighlight = (matchLevel) => {
  switch (matchLevel) {
    case 'matchOne':
      return 'matchOne';
    case 'matchTwo':
      return 'matchTwo';
    case 'matchThree':
      return 'matchThree';
    case 'matchFour':
      return 'matchFour';
    default:
      return '';
  }
};

export { FormattedText, getHighlight };
