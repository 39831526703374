import { authenticatedAPI } from 'services/api/api';

export const tutorialConfirmation = async (idTutorial) => {
  const data = {
    tutorial: idTutorial,
  };
  try {
    const response = await authenticatedAPI.post(
      `api/internal/tutorial/complete`,
      data
    );
    return response.data;
  } catch (err) {
    throw new Error('Failed to confirm tutorial');
  }
};
