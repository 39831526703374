import { authenticatedAPI } from 'services/api/api';

export const getLoginConfig = async (type) => {
  let response;
  try {
    if (type == 'company') {
      response = await authenticatedAPI.get(
        `api/internal/university/settings/login-companies`
      );
      return response.data.hasOwnProperty('image') ? response.data : null;
    } else {
      response = await authenticatedAPI.get(
        `api/internal/university/settings/login?type=${type}`
      );
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createLoginConfig = async (data) => {
  try {
    const response = await authenticatedAPI.post(
      `api/internal/university/settings/login/create`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const editLoginConfig = async (data, type) => {
  try {
    const response = await authenticatedAPI.patch(
      `api/internal/university/settings/login?type=${type}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const postImageSettings = (id, backgroundImage) => {
  const dataFile = new FormData();
  dataFile.append('background_image', backgroundImage);
  const response = authenticatedAPI
    .put(`api/internal/university/settings/login/${id}/image`, dataFile, {
      headers: {
        'Content-Type': 'application/x-zip-compressed',
      },
    })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const postImageSettingsCompany = async (_, backgroundImage) => {
  try {
    const dataFile = new FormData();
    dataFile.append('background_image', backgroundImage);
    const response = await authenticatedAPI.put(
      `api/internal/university/settings/login-companies`,
      dataFile,
      {
        headers: {
          'Content-Type': 'application/x-zip-compressed',
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteImageLoginConfig = (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/university/settings/login/${id}/image`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const deleteImageLoginConfigCompany = async (id) => {
  try {
    const response = await authenticatedAPI.delete(
      `api/internal/university/settings/login-companies`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const editImageLoginConfig = async (id, backgroundImage) => {
  try {
    const dataFile = new FormData();
    dataFile.append('background_image', backgroundImage);

    const response = authenticatedAPI.patch(
      `/api/internal/university/settings/login/${id}/image`,
      dataFile
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getImageLoginConfig = async (id) => {
  try {
    const response = await authenticatedAPI.get(
      `api/internal/university/settings/login/${id}/image`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getImageLoginCompany = async () => {
  try {
    const response = await authenticatedAPI.get(
      `api/internal/university/settings/login-companies`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const postImageSeal = async (_, seal) => {
  try {
    const dataFile = new FormData();
    dataFile.append('seal', seal);
    const response = await authenticatedAPI.post(
      `api/internal/university/settings/seal/create`,
      dataFile,
      {
        headers: {
          'Content-Type': 'application/x-zip-compressed',
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getImageSeal = async () => {
  try {
    const response = await authenticatedAPI.get(
      `api/internal/university/settings/seal`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteImageSeal = async () => {
  try {
    const response = await authenticatedAPI.delete(
      `/api/internal/university/settings/seal/delete`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
