import { authenticatedAPI } from 'services/api/api';

const updatePortfolioProjects = async (data) => {
  try {
    const response = await authenticatedAPI.patch(
      `/api/internal/students/me/portfolio/${data.id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const deletePortfolioProject = async (projectId) => {
  try {
    const response = await authenticatedAPI.delete(
      `/api/internal/students/me/portfolio/${projectId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const savePortfolioProjects = async (data) => {
  try {
    const response = await authenticatedAPI.post(
      `/api/internal/students/me/portfolio`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getOptions = async () => {
  try {
    const response = await authenticatedAPI.options(
      `/api/internal/students/me/portfolio`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getProjects = async () => {
  try {
    const response = await authenticatedAPI.get(
      `/api/internal/students/me/projects`
    );
    return response.data.projects;
  } catch (error) {
    return error;
  }
};

export {
  deletePortfolioProject,
  getOptions,
  updatePortfolioProjects,
  savePortfolioProjects,
  getProjects,
};
