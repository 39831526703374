import { authenticatedAPI } from 'services/api/api';

const getProfessions = async () => {
  try {
    const response = await authenticatedAPI.get(
      `/api/internal/students/me/professions`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateFavoriteProfessions = async (favorites) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/professions/favorite`, favorites)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export { getProfessions, updateFavoriteProfessions };
