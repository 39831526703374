import React, { useContext } from 'react';
import {
  Title,
  Paragraph,
  ContainerCards,
  Card,
  IconImg,
  Text,
  Infos,
  TextComposition,
  IndicatorStep,
  IndicatorStepBullet,
} from '../styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import useStepTwoSorting from './useStepTwoSorting';
import { handleScrollIntoView } from 'views/Profile/Utils/resources';
import { profileTexts } from 'views/Profile/Utils/profileTexts';

export default function StepTwo() {
  const { stepTwoData, sectionRef, gamificationLevel } = useContext(
    ProfileContext
  );
  const gamificationItems = useStepTwoSorting(stepTwoData);
  const { returnColorText } = profileTexts();

  const handleClickIcon = (item) => {
    if (gamificationLevel > 1) {
      handleScrollIntoView(sectionRef[item.gamificationRef]);
    }
  };

  return (
    <>
      <Title>Jornada do protagonismo</Title>
      <Paragraph>
        Chegou a hora de se destacar! Preencha seus Valores e indique ao menos
        10 conhecimentos que você já adquiriu. Complete essa etapa e conquiste o
        selo de Perfil Campeão!
      </Paragraph>
      <ContainerCards>
        {gamificationItems.map((item, index) => (
          <Card key={index}>
            <IconImg
              disabled={gamificationLevel === 1}
              onClick={() => handleClickIcon(item)}
            >
              {item.icon}
            </IconImg>
            <TextComposition>
              <Text
                returnColor={returnColorText(stepTwoData, item.key)}
                onClick={() => handleClickIcon(item)}
                disabled={gamificationLevel === 1}
              >
                {item.title}
              </Text>
              <Infos>{item.info}</Infos>
            </TextComposition>
            {index < gamificationItems.length - 1 && (
              <IndicatorStep color={item.color}>
                <IndicatorStepBullet color={item.color} />
              </IndicatorStep>
            )}
          </Card>
        ))}
      </ContainerCards>
    </>
  );
}
