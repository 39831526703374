import { getCities, getUfsByNumber } from '../../services';

const jobOfferAdapterExternal = async (data, setCacheContext) => {
  delete data.required_formation;

  const getCurrentCities = async (arrayUfs) => {
    const response = await getCities(arrayUfs);
    setCacheContext(response);
  };

  const mapUfs = (ufs) => {
    const currentUfs = ufs.map((uf) => uf).join('|');
    getCurrentCities(currentUfs);
  };

  const getUfs = async () => {
    if (data.locations != null && data.locations.length) {
      const dataLocations = data.locations;
      const numberOfUfs = dataLocations
        .map((item) => {
          return item.value.substring(0, 2);
        })
        .join('|');
      const response = await getUfsByNumber(numberOfUfs);

      if (Array.isArray(response)) {
        const mappedUfs = response.map((item) => item.sigla);
        mapUfs(mappedUfs);
        return mappedUfs;
      }
      mapUfs(response.sigla.split());
      return response.sigla.split();
    }
  };

  return {
    ...data,
    isInternalIes: true,
    isEditJob: true,
    uf: await getUfs(),
    locations: (data.locations != null && data.locations.length)
      ? data.locations.map((item) => {
          return item.value;
        })
      : [],
    jobId: data.id,
    courses: data.external.courses,
    divulgation_link: data.external.divulgation_link,
    job_email: data.external.job_email,
    modality_type: data.external.modality_type,
  };
};

export { jobOfferAdapterExternal };
