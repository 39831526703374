import axios from 'axios';
import URLS from 'constants/urls';
import { studentFilterAdapter } from 'adapters/student/studentListAdapter';
import { authenticatedAPI } from 'services/api/api';

const getProfessionalStatusReport = async (filters) => {
  const queryString = studentFilterAdapter(filters, false);

  const response = await authenticatedAPI
    .get(`/api/internal/university/reports/professional-status?${queryString}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getStudentProfessionalReportOptions = async () => {
  const response = await authenticatedAPI
    .options(`/api/internal/university/reports/professional-status`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};
export { getProfessionalStatusReport, getStudentProfessionalReportOptions };
