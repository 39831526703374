import { tokenService } from './token.service';
import { authService } from './auth.service';
class JWTInterceptor {
  failedQueue;
  isRefreshing;

  constructor() {
    this.failedQueue = [];
    this.isRefreshing = false;
  }

  processQueue(error, token = '') {
    this.failedQueue.forEach((requests) => {
      if (error) {
        requests.reject(error);
      } else {
        requests.resolve(token);
      }
    });

    this.failedQueue = [];
  }

  getToken = () => tokenService.token;

  setupInterceptors(axiosInstance) {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._isRetry) {
          if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
              this.failedQueue.push({ resolve, reject });
            });
          }
          try {
            await authService.refresh();
            axiosInstance.defaults.headers[
              'Authorization'
            ] = `Bearer ${tokenService.token}`;
            this.processQueue(null, tokenService.token);
            originalRequest.headers[
              'Authorization'
            ] = `Bearer ${tokenService.token}`;
            return axiosInstance(originalRequest);
          } catch (e) {
            this.processQueue(e, null);
            return Promise.reject(e);
          } finally {
            this.isRefreshing = false;
          }
        }
      }
    );
  }
}

export default JWTInterceptor;
