import { authenticatedAPI } from 'services/api/api';
import qs from 'querystring';

const createNewEvent = async (data) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));

  const response = authenticatedAPI
    .post('/api/internal/companies/attractions/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const createEventNew = async (data) => {
  const formData = new FormData();
  const newData = Object.assign(data, {
    target_audiences: {
      macro_areas: [...data.macro_areas],
      courses: [...data.courses],
      semesters: [...data.semesters],
    },
  });

  if (data.attraction_document) {
    formData.append('attraction_document', data.attraction_document);
    delete data.attraction_document;
  }
  if (data.attraction_image) {
    formData.append('attraction_image', data.attraction_image);
    delete data.attraction_image;
  }
  formData.append('name', data.name);
  formData.append('access_link', data.access_link ? data.access_link : '');
  formData.append('type', data.type);
  formData.append('is_free', data.is_free ? data.is_free : true);
  formData.append('end_at', '');
  formData.append('start_at', '');
  formData.append(
    'deadline_registration',
    data.deadline_registration ? data.deadline_registration : ''
  );
  formData.append('value', data.value ? data.value : 0);
  formData.append('has_limited_vacancies', false);
  formData.append(
    'target_audiences',
    JSON.stringify([
      {
        macro_areas: [...data.macro_areas],
        courses: [...data.courses],
        semesters: [...data.semesters],
      },
    ])
  );
  formData.append('data', JSON.stringify(newData));

  const response = authenticatedAPI
    .post('/api/internal/companies/attractions/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const updateEvent = async (data) => {
  let url = `/api/internal/companies/attractions/${data.id}`;

  if (data.remove_attraction_image) {
    url += `?remove_attraction_image=true`;
  }

  if (data.remove_attraction_document) {
    url += `?remove_attraction_document=true`;
  }

  const formData = new FormData();
  const newData = Object.assign(data, {
    target_audiences: {
      macro_areas: [...data.macro_areas],
      courses: [...data.courses],
      semesters: [...data.semesters],
    },
  });

  formData.append(
    'attraction_document',
    data.attraction_document ? data.attraction_document : ''
  );
  formData.append(
    'attraction_image',
    data.attraction_image ? data.attraction_image : ''
  );
  formData.append('name', data.name);
  formData.append('access_link', data.access_link ? data.access_link : '');
  formData.append('type', data.type);
  formData.append('is_free', data.is_free ? data.is_free : true);
  formData.append('end_at', '');
  formData.append('start_at', '');
  formData.append(
    'deadline_registration',
    data.deadline_registration ? data.deadline_registration : ''
  );
  formData.append('value', data.value ? data.value : 0);
  formData.append('has_limited_vacancies', false);
  formData.append(
    'target_audiences',
    JSON.stringify([
      {
        macro_areas: [...data.macro_areas],
        courses: [...data.courses],
        semesters: [...data.semesters],
      },
    ])
  );
  formData.append('data', JSON.stringify(newData));

  const response = authenticatedAPI
    .put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const unPublishAttraction = async (event) => {
  let url = `/api/internal/companies/attractions/${event.id}`;
  const response = authenticatedAPI
    .patch(url, { is_published: false })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const deleteEvent = async (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/companies/attractions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getEvents = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=event`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getPrograms = async (setPrograms) => {
  setPrograms(null);
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=program`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getArticles = async (setArticle) => {
  setArticle(null);
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=article`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getSeries = async (setSeries) => {
  setSeries(null);
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=serie`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getEbooks = async (setEbooks) => {
  setEbooks(null);
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=e_book`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTrails = async (setTrails) => {
  setTrails(null);
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=trail`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCourses = async (setCourse) => {
  setCourse(null);
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=course`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getChallenges = async (setChallenge) => {
  setChallenge(null);
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=challenge`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAnnouncements = async (setAnnouncements) => {
  setAnnouncements(null);
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/?type=announcements`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getEventById = async (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getUniversitiesOptions = async (queryString = '') => {
  const response = authenticatedAPI
    .options(`/api/internal/companies/attractions/${queryString}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCoursesByUniversityOptions = async (universitiesId) => {
  const ids = universitiesId.map((element) => element.value);

  const queryString = qs.stringify({ university: ids });
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/courses/?${queryString}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const uploadImg = async (event) => {
  const dataFile = new FormData();
  dataFile.append('attraction_image', event.attraction_image);

  const response = authenticatedAPI
    .put(`/api/internal/companies/attractions/${event.id}`, dataFile)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  uploadImg,
  getEvents,
  getSeries,
  getTrails,
  getEbooks,
  getCourses,
  updateEvent,
  deleteEvent,
  getPrograms,
  getArticles,
  getEventById,
  getChallenges,
  createEventNew,
  createNewEvent,
  getAnnouncements,
  unPublishAttraction,
  getUniversitiesOptions,
  getCoursesByUniversityOptions,
};
