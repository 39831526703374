import { authenticatedAPI } from 'services/api/api';
import { studentFilterAdapter } from '../../adapters/student/studentListAdapter';
import qs from 'querystring';

import { isMasterLet } from 'views/StaffPages/jobOpeningsRebuild/JobController/PublishedJobsTabs';

const createNewCourseArea = async (data) => {
  const response = authenticatedAPI
    .post('/api/internal/university/jobs/areas', data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getRegistrationFormOptions = async () => {
  const response = authenticatedAPI
    .options(`/api/internal/university/jobs`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilterJobs = async (params) => {
  const response = authenticatedAPI.get(
    `/api/internal/university/jobs?${params}`
  );
  return response;
};

const getCandidatesOptions = async (id) => {
  const response = authenticatedAPI
    .options(`/api/internal/university/jobs/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveJobOffer = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/jobs`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateJobOffer = async (id, data) => {
  const response = authenticatedAPI
    .patch(`/api/internal/university/jobs/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getProfessionByCourseId = async (courseId) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs/professions?course=${courseId}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getMarketRequirementsByCourseId = async (courseQueryString) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/jobs/market-requirements?${courseQueryString}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getUniversityJobsOpenings = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getUniversityJobDetails = async (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteJobOffer = async (id) => {
  const response = await authenticatedAPI
    .delete(`/api/internal/university/jobs/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCandidates = async (jobOfferId, pageQS) => {
  const response = await authenticatedAPI
    .get(`/api/internal/university/jobs/${jobOfferId}/applications?${pageQS}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const postUniversityJobAnalysis = async (id, analysis) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/jobs/${id}/analysis/${analysis}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCandidateDetail = async (jobOfferId, applicationId) => {
  const response = await authenticatedAPI
    .get(
      `/api/internal/university/jobs/${jobOfferId}/applications/${applicationId}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getUniversityApplicationOptions = async (jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .options(
      `api/internal/university/jobs/${jobOfferId}/applications/${applicationId}/answer`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getApplicationFilterOptions = async (jobOfferId) => {
  const response = authenticatedAPI
    .options(`api/internal/university/jobs/${jobOfferId}/applications`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const returnJobApplicationUniversity = async (
  jobOfferId,
  applicationId,
  data
) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .post(
        `api/internal/educational-group/jobs/${jobOfferId}/applications/${applicationId}/answer`,
        data || { mark_as: 'interesting' }
      )
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .post(
      `/api/internal/university/jobs/${jobOfferId}/applications/${applicationId}/answer`,
      data || { mark_as: 'interesting' }
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const setCurriculumScore = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/score`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const createNewInterview = async (interview, jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .post(
      `/api/internal/university/jobs/${jobOfferId}/applications/${applicationId}/interview`,
      interview
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getInterviewId = async (interviewId, jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/jobs/${jobOfferId}/applications/${applicationId}/interview/${interviewId}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateInterview = async (interview, jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .put(
      `/api/internal/university/jobs/${jobOfferId}/applications/${applicationId}/interview/${interview.id}`,
      interview
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const cancelInterview = async (interview, jobOfferId, applicationId) => {
  const response = authenticatedAPI
    .delete(
      `/api/internal/university/jobs/${jobOfferId}/applications/${applicationId}/interview/${interview.id}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const checkResendNotification = async (jobOfferId) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs/${jobOfferId}/students/notifications`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const resendNotification = async (jobOfferId) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/jobs/${jobOfferId}/students/notifications`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const universityFilterApplications = async (jobId, values) => {
  const queryString = studentFilterAdapter(values, false);
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs/${jobId}/applications?${queryString}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCourses = async (data) => {
  const queryString = {
    macroAreas: data.macroAreas.map((macroArea) => macroArea.value),
  };
  const response = authenticatedAPI
    .get(`api/internal/university/jobs/courses?${qs.stringify(queryString)}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const sendFeedback = async (id, feedback) => {
  const response = authenticatedAPI
    .put(`internal/university/job-offer/${id}/feedback`, { feedback })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};
const getAdditionalAndNewAreas = async () => {
  const response = authenticatedAPI
    .get('api/internal/university/jobs/additional-and-new-areas')
    .then((res) => res.data)
    .catch((err) => err);
  return response;
};
export {
  universityFilterApplications,
  getApplicationFilterOptions,
  setCurriculumScore,
  getProfessionByCourseId,
  getRegistrationFormOptions,
  saveJobOffer,
  updateJobOffer,
  getUniversityJobsOpenings,
  getUniversityJobDetails,
  deleteJobOffer,
  getCandidates,
  getCandidateDetail,
  getUniversityApplicationOptions,
  returnJobApplicationUniversity,
  getFilterJobs,
  getCandidatesOptions,
  createNewCourseArea,
  createNewInterview,
  getInterviewId,
  updateInterview,
  cancelInterview,
  checkResendNotification,
  resendNotification,
  getMarketRequirementsByCourseId,
  postUniversityJobAnalysis,
  getCourses,
  sendFeedback,
  getAdditionalAndNewAreas,
};
