import React, {
  useEffect,
  useRef,
  useState,
  Fragment,
  useContext,
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import '../../Styles/Reactpicker.css';
import { useField } from '@unform/core';
import { UnInputLabel } from '../../Styles/unformComponentsStyled';
import MaskedInput from 'react-maskedinput';
import { ContainerLabelInput, ErrorWrapper } from '../../Styles/formStyled';
import { createGlobalStyle } from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import BaseLayoutContext from 'contexts/base-layout';

registerLocale('ptBR', ptBR);

export default function DatePickerCustom({
  placeholder,
  label,
  name,
  handleUpdate,
  customRef,
  ...rest
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const datepickerRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker__input-container input {
      height: 38px;
    }
  `;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: customRef ? customRef.current : datepickerRef.current,
      path: 'props.selected',
      setValue: (ref, value) => {
        if (typeof value === 'string') {
          const [year, month, day] = value
            .split('-')
            .map((num) => parseInt(num, 10));
          const localDate = new Date(year, month - 1, day);
          ref.setSelected(localDate);
          return;
        }

        ref.setSelected(value);
      },
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        const [year, month, day] = defaultValue
          .split('-')
          .map((num) => parseInt(num, 10));
        const localDate = new Date(year, month - 1, day);
        setStartDate(localDate);
        return;
      }
      setStartDate(new Date(defaultValue));
    }
  }, [defaultValue]);

  const handleChange = (e) => {
    setStartDate(e);

    if (handleUpdate) {
      handleUpdate(e);
    }
  };

  return (
    <ContainerLabelInput error={error} color={universityColor}>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <DatePicker
        className={`react-datepicker__input-container input ${
          error ? 'input-error' : ''
        }`}
        ref={customRef ? customRef : datepickerRef}
        locale="ptBR"
        isClearable
        dateFormat="dd/MM/yyyy"
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1).toUpperCase()}
        selected={startDate}
        placeholderText={placeholder}
        onChange={handleChange}
        onFocus={clearError}
        customInput={
          <MaskedInput
            value={startDate}
            placeholder="dd/MM/yyyy"
            mask="11/11/1111"
            maskstring="dd/mm/yyyy"
          />
        }
        {...rest}
      />
      <DatePickerWrapperStyles />
      <ErrorWrapper
        style={{
          marginTop: '4px',
          textAlign: 'left',
          lineHeight: '17px'
        }}
      >
        {error && (
          <Fragment>
            <div
              style={{
                'display': 'flex',
                'align-self': 'flex-start'
              }}
            >
              <AiOutlineCloseCircle size={18} color={'#f10909'} />
            </div>
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </ContainerLabelInput>
  );
}
