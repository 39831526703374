import React, { useEffect, useRef, useState } from 'react';

import { Container } from './styled';
import { useVisualizationStudentContext } from '../../Contexts/VisualizationStudent';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';

import ListingCheckIn from './Listing/ListingCheckIn';
import ListingFinished from './Listing/ListingFinished';
import ListingAvaiablesEvents from './Listing/ListingAvaiablesEvents';
import ScanQrCodeModal from '../ScanQrCodeModal';
import Arrow from '../Arrow';
import FormFinalization from './Listing/Finalization';
import { CoreHandlers } from '../../Handlers/CoreHandlers';
import ModalInformation from 'components/informationModal';
import Pagination from 'components/CustomPagination';
import { StudentHandlers } from '../../Handlers/StudentHandlers';

export default function StudentVisualizationEngine({ id, uuid }) {
  const [finalizationStep, setFinalizationStep] = useState(1);
  const visualizationContextInstance = useVisualizationContext();
  const visualizationStudentContextInstance = useVisualizationStudentContext();
  const {
    currentTab,
    setStudentViewModalOpen,
    detailsObject,
    setDetailsObject,
    emailToExport,
    modalInformationEmailSentOpen,
    setModalInformationEmailSentOpen,
    selectedEvent,
    setSelectedEvent,
  } = visualizationContextInstance;

  const CoreHandlersFactory = CoreHandlers(visualizationContextInstance);
  const studentHandlerFactory = StudentHandlers(
    _,
    visualizationStudentContextInstance
  );

  const {
    showScanQrCodeModal,
    setShowScanQrCodeModal,
    listingPaginationData,
    currentPage,
    limit,
    setCurrentPage,
    setLimit,
    searchQuery,
    setSelectedStudent,
    setCheckedStudents,
    setCheckedAll,
    setTypeChecked,
  } = visualizationStudentContextInstance;

  if (detailsObject && Object.keys(detailsObject).length === 0 && id && uuid) {
    CoreHandlersFactory.getDetails(id);
    setShowScanQrCodeModal(true);
  }

  const handlePageChange = async (page) => {
    const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
    setCurrentPage(page);
    studentHandlerFactory.getListingStudentEvent(
      detailsObject.id,
      searchQuery.current,
      newPage
    );
  };

  const handleLimitChange = async (limit) => {
    const newPage = `limit=${limit}&offset=${(1 - 1) * limit || 0}`;
    setCurrentPage(1);

    setLimit(limit);
    await studentHandlerFactory.getListingStudentEvent(
      detailsObject.id,
      searchQuery.current,
      newPage
    );
  };

  useEffect(() => {
    if (selectedEvent?.id) {
      CoreHandlersFactory.getDetails(selectedEvent.id);
    }
  }, [selectedEvent]);

  const listings = {
    0: <ListingAvaiablesEvents />,
    1: <ListingCheckIn />,
    2: <FormFinalization step={finalizationStep} setStep={setFinalizationStep} />,
    3: <ListingFinished />,
  };

  const currentListing = listings[currentTab];

  if (showScanQrCodeModal) {
    return (
      <>
        <Arrow
          titleArrow="Voltar"
          onClick={() => {
            setSelectedEvent({});
            setShowScanQrCodeModal(false);
          }}
        />
        <ScanQrCodeModal id={id} uuid={uuid} />
      </>
    );
  }

  return (
    <>
      <ModalInformation
        modalOpenState={modalInformationEmailSentOpen}
        config={{
          bubbleText: `Lista exportada com sucesso!`,
          ParagraphText: `A lista foi exportada com sucesso e enviada para o e-mail ${emailToExport.current}. `,
          buttonConfirmText: 'OK, ENTENDI',
        }}
        buttonConfirmFunction={() => setModalInformationEmailSentOpen(false)}
      />
      <Arrow
        titleArrow="Voltar"
        onClick={() => {
          setSelectedEvent({});
          setStudentViewModalOpen(false);
          setCurrentPage(1);
          setSelectedStudent({});
          setCheckedStudents([]);
          setCheckedAll(false);
          setTypeChecked(null);
          searchQuery.current = '';
          setDetailsObject({});
        }}
      />
      {currentListing}
      {finalizationStep === 1 && (
        <Pagination
          currentPage={currentPage}
          totalCount={listingPaginationData?.count || 0}
          limit={limit}
          onPageChange={handlePageChange}
          setLimit={handleLimitChange}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}
