import {
  authenticatedAPI,
  api,
  authenticatedInternshipAPI,
  publicInternshipAPI,
} from 'auth/jwt/api.service';
export {
  authenticatedAPI,
  publicInternshipAPI,
  api,
  authenticatedInternshipAPI,
};
