import { useState, useEffect, useContext } from 'react';
import { IconPersonality } from 'assets/img/iconsCurriculum/iconPersonality';
import { IconBehavioral } from 'assets/img/iconsCurriculum/iconBehavioral';
import { IconValues } from 'assets/img/iconsCurriculum/iconValues';
import { IconInterests } from 'assets/img/iconsCurriculum/iconInterests';
import { profileTexts } from 'views/Profile/Utils/profileTexts';
import { PersonalInfo } from 'assets/img/iconsCurriculum/personalInfo';

import { ColorInfo } from './styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

const useStepOneSortingJobOffers = (navigateToComponent) => {
  const { stepOneData } = useContext(ProfileContext);
  const [iconsData, setIconsData] = useState([]);
  const { getInfoColorOne, getIconColorOne } = profileTexts();

  const getSortedIcons = () => {
    const {
      interests,
      behavioral_attributes,
      personality_attributes,
      personal_info,
      career_interests,
      life_interests,
    } = stepOneData;

    const items = [
      {
        key: 'personal_info',
        title: 'Dados Pessoais',
        icon: (
          <PersonalInfo size={28} color={getIconColorOne('personal_info')} />
        ),
        completed: personal_info === 1,
        onClick: () => navigateToComponent('personal_info'),
      },
      {
        key: 'life_interests',
        title: 'Interesses de Vida',
        icon: <IconInterests color={getIconColorOne('life_interests')} />,
        completed: life_interests <= 1 && life_interests !== 0,
        onClick: () => navigateToComponent('life_interests'),
      },
      {
        key: 'career_interests',
        title: 'Interesses de Carreira',
        icon: <IconInterests color={getIconColorOne('career_interests')} />,
        completed: career_interests <= 1 && career_interests !== 0,
        onClick: () => navigateToComponent('career_interests'),
      },
      {
        key: 'personality_attributes',
        title: 'Personalidade',
        icon: (
          <IconPersonality color={getIconColorOne('personality_attributes')} />
        ),
        completed: personality_attributes === 1,
        onClick: () => navigateToComponent('personality_attributes'),
      },
    ];

    const sortedItems = items.sort((a, b) =>
      a.completed === b.completed ? 0 : a.completed ? 1 : -1
    );

    const filteredItems = sortedItems.filter((item) => !item.completed);

    let totalItems = {
      personal_info: 1,
      personality_attributes: 1,
      career_interests: 1,
      life_interests: 1,
    };

    const mappedItems = filteredItems.map((item) => ({
      ...item,
      info: (
        <ColorInfo color={getInfoColorOne(item.key)}>
          <i>
            {stepOneData[item.key] === totalItems[item.key]
              ? 'Concluído'
              : 'Não concluído'}
          </i>
        </ColorInfo>
      ),
    }));

    return mappedItems;
  };

  useEffect(() => {
    if (stepOneData) {
      setIconsData(getSortedIcons());
    } else {
      setIconsData([]);
    }
  }, [stepOneData]);

  return iconsData;
};

export default useStepOneSortingJobOffers;
