import { authService } from 'auth/jwt/auth.service';
import { api } from 'services/api/api';

const getUniversityLogos = async (slug) => {
  const response = await api.get(`/api/internal/${slug}/logo`);
  return response;
};

const getInfoStudent = async (university, type) => {
  try {
    const response = await api.get(
      `/api/internal/${university}/login?type=${type}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const login = authService.login;

const isAuthenticated = authService.isAuthenticated;

export { isAuthenticated, login, getUniversityLogos, getInfoStudent };
