import React, { useEffect, useState, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import { IoIosArrowBack } from 'react-icons/io';
import {
  ContainerInfo,
  Header,
  Container,
  BackButton,
  FragmentColor,
  ButtonDetailsModal,
  TextName,
  Box,
  ContainerContent,
  TextParagraph,
  BoxModal,
  ContainerColumn,
  ContainerMatch,
  ButtonTransparent,
  ContentIcon,
  ContainerCenter,
  ContainerLogo,
  ListParagraph,
  ContainerIconAndText,
  CircleMatch,
  NumberMatchParagraph,
  ParagraphMatch,
  ContainerParagraphMatch,
  IndicateButton,
  ButtonJobShare,
  IndicationRow,
  ContainerDataInformation,
  ContainerDataExpired,
} from './styled';
import LogoNetworkNotFound from 'assets/Network/logoCompanyNotFound';
import { SkeletonDetails } from './SkeletonDetails';
import { ModalUnderstendMatch } from '../Modals/ModalUnderstendMatch';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import { wrapperIconsJobOffers } from '../utils/wrapperIconsJobOffers';
import { CompanyDetail } from 'components/CompanyDetail/CompanyDetail';
import {
  deleteFavorite,
  getCompaniesFavorites,
  postFavorite,
} from 'views/Student/Network/Services/Services';
import { genericAdapter } from 'adapters/genericAdapter';
import BaseLayoutContext from 'contexts/base-layout';
import { BsLink45Deg } from 'react-icons/bs';
import {
  getJobPartnersDetails,
  getJobDetails,
} from 'views/StaffPages/jobOpeningsRebuild/services/index';
import defineUrlFront from 'utils/defineUrlFront';
import {
  getJobDetails as getJobdetailsCompany,
  getJobPartnersDetails as getJobPartnersDetailsCompany,
} from 'views/JobOfferRebuild/services';
import { capitalizeFirstLetter } from 'utils/stringUtils';

export function JobDetailsApplications({
  jobId,
  handleCloseModal,
  universityColor = '#009291',
  match,
  isUniversityScope = false,
  isCompanyScope = false,
  isFromPartners = false,
  canIndication = false,
  fullWidth = false,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [detailsApplicationsJobs, setDetailsApplicationsJobs] = useState([]);
  const {
    IconSuitcase,
    IconLocation,
    IconHouse,
    IconPCD,
    IconCheck,
  } = wrapperIconsJobOffers();

  const [companyDetails, setCompanyDetail] = useState(false);
  const [companyDetailId, setCompanyDetailId] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);
  const [withVacanciesData, setWithVacanciesData] = useState([]);
  const [favoritesData, setFavoritesData] = useState([]);
  const [reloadIntern, setReloadIntern] = useState(false);

  const fetchDetailsApplicationsJobOffers = async (jobId) => {
    const data = await JobOffersApi.getDetailsApplicationsJobs(jobId);
    setDetailsApplicationsJobs && setDetailsApplicationsJobs(data);
  };
  const { metadata, openSnackbar } = useContext(BaseLayoutContext);

  useEffect(() => {
    isCompanyScope
      ? getDetailsCompany(jobId)
      : isUniversityScope
      ? getDetails(jobId)
      : fetchDetailsApplicationsJobOffers(jobId);
  }, []);

  const getDetails = async (id) => {
    const data = isFromPartners
      ? await getJobPartnersDetails(id)
      : await getJobDetails(id);
    setDetailsApplicationsJobs && setDetailsApplicationsJobs({ data });
  };

  const getDetailsCompany = async (id) => {
    const data = isFromPartners
      ? await getJobPartnersDetailsCompany(id)
      : await getJobdetailsCompany(id);
    setDetailsApplicationsJobs && setDetailsApplicationsJobs({ data });
  };

  const detailsJob = detailsApplicationsJobs?.data;
  const hasLogo = Boolean(detailsJob && detailsJob.logo);

  const onClickOpenModal = () => {
    setOpenModal(true);
  };

  const onClickCloseModal = () => {
    setOpenModal(false);
  };

  const generateLinkJob = (jobId) => {
    const accessLink = `${defineUrlFront()}/#/vaga/${
      metadata.university_slug
    }/${jobId}`;
    navigator.clipboard.writeText(accessLink);
    openSnackbar(
      `O link foi copiado com sucesso!
        Apenas os seus estudantes conseguirão
        visualizar a vaga após fazer o login.
      `
    );
  };

  const onClickCompanyDetails = (e) => {
    e && e.preventDefault();
    setCompanyDetailId(jobId);
    setCompanyDetail(true);
  };

  const closeCompanyDetails = () => {
    setCompanyDetail(false);
  };

  const forceReloadIntern = () => {
    setReloadIntern(!reloadIntern);
  };

  const handleFavoriteCompany = async (data) => {
    const { id, isFavorite } = data;
    let companiesDataLocal = companiesData;
    let withVacanciesDataLocal = withVacanciesData;
    if (companiesData.filter((company) => company.id === id)) {
      companiesDataLocal.forEach((item) => {
        if (item.id === id) {
          item.isFavorite = !isFavorite;
        }
      });
      setCompaniesData(companiesDataLocal);
      forceReloadIntern();
    }
    if (withVacanciesData.filter((company) => company.id === id)) {
      withVacanciesDataLocal.forEach((item) => {
        if (item.id === id) {
          item.isFavorite = !isFavorite;
        }
      });
      setWithVacanciesData(withVacanciesDataLocal);
      forceReloadIntern();
    }
    if (!isFavorite) {
      const response = postFavorite(id);
    } else {
      const response = deleteFavorite(id);
    }
    const responseFavorites = await getCompaniesFavorites();
    setFavoritesData(genericAdapter(responseFavorites.data.results));
  };

  function pluralize(quantity, singular, plural) {
    return quantity === 1 ? `${quantity} ${singular}` : `${quantity} ${plural}`;
  }

  const quantity = detailsJob?.quantity;
  const returnQuantity =
    quantity !== null
      ? pluralize(quantity, 'vaga disponível', 'vagas disponíveis')
      : null;

  const returnComponentStatus = () => {
    const endOfferData = moment(detailsJob.end_offer_at).format('DD/MM/YYYY');
    const startOfferData = moment(detailsJob.start_offer_at).format(
      'DD/MM/YYYY'
    );
    const expiredEndOfferData = moment(detailsJob.end_offer_at)
      .add(1, 'days')
      .format('DD/MM/YYYY');
    const createdAtData = detailsJob.created_at
      ? moment(detailsJob.created_at).format('DD/MM/YYYY')
      : startOfferData;

    if (detailsJob.status === 'Vaga expirada') {
      return (
        <TextParagraph isItalicStyle>
          Expirada em {expiredEndOfferData}
        </TextParagraph>
      );
    }

    if (!detailsJob.status) {
      return (
        <ContainerDataInformation>
          <TextParagraph isItalicStyle>
            Publicada em {createdAtData}
          </TextParagraph>
          <ContainerDataExpired>
            Inscrições abertas até {endOfferData}
          </ContainerDataExpired>
        </ContainerDataInformation>
      );
    }

    return (
      <TextParagraph isItalicStyle>Finalizada em {endOfferData}</TextParagraph>
    );
  };

  return (
    <>
      {companyDetails === true && jobId && (
        <CompanyDetail
          id={detailsJob.company_id}
          closeModal={closeCompanyDetails}
          handleFavorite={handleFavoriteCompany}
          student={!isUniversityScope}
          isUniversityScope={isUniversityScope}
          fullWidth={fullWidth}
        />
      )}
      {openModal && (
        <ModalUnderstendMatch
          closeModal={onClickCloseModal}
          detailsJob={detailsJob}
        />
      )}
      {detailsJob === null || detailsJob === undefined ? (
        <SkeletonDetails
          hasIcon={true}
          handleCloseModal={handleCloseModal}
          universityColor={universityColor}
        />
      ) : (
        <BoxModal fullWidth={fullWidth}>
          <Container>
            <ContainerInfo>
              <FragmentColor color={universityColor}>
                <BackButton
                  onClick={(e) => handleCloseModal(e)}
                  color={universityColor}
                >
                  <IoIosArrowBack />
                  <p>Voltar</p>
                </BackButton>
              </FragmentColor>
              {!isCompanyScope && (
                <Header>
                  <ContainerLogo img={hasLogo && detailsJob?.logo}>
                    {!hasLogo && (
                      <LogoNetworkNotFound color={universityColor} />
                    )}
                  </ContainerLogo>
                  {detailsJob.company_id !== null ? (
                    <ButtonDetailsModal
                      color={universityColor}
                      onClick={() => onClickCompanyDetails()}
                    >
                      Visualizar perfil da empresa
                    </ButtonDetailsModal>
                  ) : null}
                  <TextName>{detailsJob.company_name}</TextName>
                </Header>
              )}

              <Box>
                <ContainerContent>
                  <ContainerColumn>
                    {canIndication ? (
                      <IndicationRow>
                        <ButtonJobShare
                          onClick={() => generateLinkJob(detailsJob?.id)}
                        >
                          <BsLink45Deg size={25} color="#009291" />
                          Copiar link da publicação
                        </ButtonJobShare>
                        <IndicateButton jobs={detailsJob}>
                          Indicar estudante
                        </IndicateButton>
                      </IndicationRow>
                    ) : (
                      <ButtonJobShare
                        onClick={() => generateLinkJob(detailsJob?.id)}
                      >
                        <BsLink45Deg size={25} color="#009291" />
                        Copiar link da publicação
                      </ButtonJobShare>
                    )}
                    <TextParagraph isCode>
                      CÓDIGO: {detailsJob.id}
                    </TextParagraph>

                    <TextParagraph isTitleDetails>
                      {detailsJob.name}
                    </TextParagraph>

                    {returnComponentStatus()}
                  </ContainerColumn>

                  {match && (
                    <ContainerColumn isContentEnd>
                      <CircleMatch match={match}>
                        <ContainerParagraphMatch>
                          <NumberMatchParagraph match={match}>
                            {match}%
                          </NumberMatchParagraph>
                          <ParagraphMatch>MATCH</ParagraphMatch>
                        </ContainerParagraphMatch>
                      </CircleMatch>

                      <ButtonTransparent
                        onClick={onClickOpenModal}
                        color={universityColor}
                      >
                        Detalhes do match
                      </ButtonTransparent>
                    </ContainerColumn>
                  )}
                </ContainerContent>

                <ContainerContent>
                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconHouse color={universityColor} />
                    </ContentIcon>
                    <TextParagraph>
                      {isUniversityScope || isCompanyScope
                        ? detailsJob.external?.modality_type?.label ??
                          detailsJob.modality_type?.label ??
                          ''
                        : detailsJob.modality}
                    </TextParagraph>
                  </ContainerIconAndText>

                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconSuitcase color={universityColor} />
                    </ContentIcon>
                    <TextParagraph>
                      {isUniversityScope || isCompanyScope
                        ? detailsJob.application_type[0].label
                        : detailsJob.application_type}
                    </TextParagraph>
                  </ContainerIconAndText>

                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconLocation color={universityColor} />
                    </ContentIcon>
                    {(detailsJob.locations !== null && detailsJob.locations.length > 0) ? (
                      detailsJob.locations.map((location) => (
                        <TextParagraph>{location.label}</TextParagraph>
                      ))
                    ) : (
                      <TextParagraph>Não informado</TextParagraph>
                    )}
                  </ContainerIconAndText>

                  {detailsJob.quantity !== null ? (
                    <ContainerIconAndText>
                      <ContentIcon universityColor={universityColor}>
                        <IconCheck color={universityColor} />
                      </ContentIcon>
                      <TextParagraph>{returnQuantity}</TextParagraph>
                    </ContainerIconAndText>
                  ) : null}

                  {detailsJob.disability && detailsJob.disability.length > 0 ? (
                    <ContainerIconAndText>
                      <ContentIcon universityColor={universityColor}>
                        <IconPCD color={universityColor} />
                      </ContentIcon>
                      <TextParagraph>Exclusiva para PCD</TextParagraph>
                    </ContainerIconAndText>
                  ) : null}
                </ContainerContent>

                {detailsJob.description ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Atividades desenvolvidas pelo profissional
                      </TextParagraph>
                      <TextParagraph>
                        {ReactHtmlParser(detailsJob.description)}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.required_academic_progress ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Requisitos acadêmicos
                      </TextParagraph>
                      <TextParagraph>
                        {detailsJob.required_academic_progress}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.course_progress ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Andamento do curso desejado
                      </TextParagraph>
                      <TextParagraph>
                        {isUniversityScope || isCompanyScope
                          ? detailsJob.course_progress.label
                          : detailsJob.course_progress}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.courses_matching_rule?.courses &&
                detailsJob.courses_matching_rule?.courses.length ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Cursos relacionados
                      </TextParagraph>
                      <ul>
                        {detailsJob.courses_matching_rule.courses.map(
                          (attribute, index) => (
                            <ListParagraph key={index}>
                              {capitalizeFirstLetter(attribute.label)}
                            </ListParagraph>
                          )
                        )}
                      </ul>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.required_behavioral_attributes &&
                detailsJob.required_behavioral_attributes.length ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Requisitos comportamentais
                      </TextParagraph>
                      <ul>
                        {isUniversityScope || isCompanyScope
                          ? detailsJob.required_behavioral_attributes.map(
                              (attribute, index) => (
                                <ListParagraph key={index}>
                                  {capitalizeFirstLetter(attribute.label)}
                                </ListParagraph>
                              )
                            )
                          : detailsJob.required_behavioral_attributes.map(
                              (attribute, index) => (
                                <ListParagraph key={index}>
                                  {capitalizeFirstLetter(attribute.label)}
                                </ListParagraph>
                              )
                            )}
                      </ul>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.required_professional_sectors &&
                detailsJob.required_professional_sectors.length > 0 ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Requisitos profissionais
                      </TextParagraph>
                      <TextParagraph>
                        {detailsJob.required_professional_sectors.map(
                          (sector, index) => (
                            <ListParagraph key={index}>
                              {capitalizeFirstLetter(sector)}
                            </ListParagraph>
                          )
                        )}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.required_attributes ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Pré-requisitos obrigatórios
                      </TextParagraph>
                      <TextParagraph>
                        {detailsJob.required_attributes}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.required_additional_courses_areas &&
                detailsJob.required_additional_courses_areas.length > 0 ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Competências técnicas
                      </TextParagraph>
                      <ul>
                        {isUniversityScope || isCompanyScope
                          ? detailsJob.required_additional_courses_areas.map(
                              (attribute, index) => (
                                <ListParagraph key={index}>
                                  {capitalizeFirstLetter(attribute.label)}
                                </ListParagraph>
                              )
                            )
                          : detailsJob.required_additional_courses_areas.map(
                              (attribute, index) => (
                                <ListParagraph key={index}>
                                  {capitalizeFirstLetter(attribute.label)}
                                </ListParagraph>
                              )
                            )}
                      </ul>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {(!isUniversityScope && detailsJob.shift) ||
                (isUniversityScope &&
                  detailsJob.shift &&
                  detailsJob.shift.length > 0) ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>Turno</TextParagraph>
                      <TextParagraph>
                        {isUniversityScope || isCompanyScope
                          ? detailsJob.shift
                              .map((item) => item.label)
                              .join(', ')
                          : detailsJob.shift.join(', ')}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.perks_offered &&
                detailsJob.perks_offered.length > 0 ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>Benefícios</TextParagraph>
                      <ul>
                        {detailsJob.perks_offered.map((perks, index) => (
                          <ListParagraph key={index}>
                            {capitalizeFirstLetter(perks.label ?? perks)}
                          </ListParagraph>
                        ))}
                      </ul>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.another_perks ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Detalhes dos benefícios
                      </TextParagraph>
                      <TextParagraph>
                        {ReactHtmlParser(detailsJob.another_perks)}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.disability && detailsJob.disability.length > 0 ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>PCD</TextParagraph>
                      <ul>
                        {detailsJob.disability.map((disability, index) => (
                          <ListParagraph key={index}>
                            {capitalizeFirstLetter(disability.label)}
                          </ListParagraph>
                        ))}
                      </ul>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.external && (
                  <>
                    {detailsJob.external.divulgation_link !== null &&
                      detailsJob.external.divulgation_link !== '' && (
                        <ContainerContent>
                          <ContainerColumn>
                            <TextParagraph isTitleDetails>
                              Link de acesso
                            </TextParagraph>
                            <TextParagraph isOtherExternal>
                              <a href={detailsJob.external.divulgation_link} target='_blank'>
                                {detailsJob.external.divulgation_link}
                              </a>
                            </TextParagraph>
                          </ContainerColumn>
                        </ContainerContent>
                      )}

                    {detailsJob.external.job_email !== null &&
                      detailsJob.external.job_email !== '' && (
                        <ContainerContent>
                          <ContainerColumn>
                            <TextParagraph isTitleDetails>
                              E-mail para contato
                            </TextParagraph>
                            <TextParagraph isOtherExternal>
                              {detailsJob.external.job_email}
                            </TextParagraph>
                          </ContainerColumn>
                        </ContainerContent>
                      )}
                  </>
                )}

                {detailsJob.contact_email !== null && (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        E-mail para notificações
                      </TextParagraph>
                      <TextParagraph isOtherExternal>
                        {detailsJob.contact_email}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                )}

                {detailsJob.salary !== '0.00' ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Faixa salárial
                      </TextParagraph>
                      <TextParagraph>
                        R${' '}
                        {new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                        }).format(detailsJob.salary)}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}
              </Box>
            </ContainerInfo>
          </Container>
        </BoxModal>
      )}
    </>
  );
}
