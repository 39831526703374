import {
  CallToAction,
  Chip,
  ChipContainer,
  Container,
  Divider,
  EditButton,
  Section,
  Title,
} from './style';
import { IoIosArrowForward } from 'react-icons/io';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import InterestForm from 'views/MyProfile/components/Interests/InterestForm';
import { useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

export function InterestsContent({
  cardDataKey,
  cardDataKeyTwo,
  gamificationRef,
  cardData,
  openModalWithContent,
  academic_profile,
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const { fetchGamificationData, sectionRef } = useContext(ProfileContext);

  const callToLifeInterestForm = () => {
    openModalWithContent(
      mergeContextsHelper(
        <InterestForm
          selectedInterests={cardData[cardDataKeyTwo]}
          cardDataKeyTwo={cardDataKeyTwo}
          lifeInterest
          academic_profile={academic_profile}
          fetchGamificationData={fetchGamificationData}
        />
      ),
      'Adicionar interesses'
    );
  };

  const callToCareerInterestForm = () => {
    openModalWithContent(
      mergeContextsHelper(
        <InterestForm
          selectedInterests={cardData[cardDataKey]}
          cardDataKey={cardDataKey}
          academic_profile={academic_profile}
          fetchGamificationData={fetchGamificationData}
        />
      ),
      'Adicionar interesses'
    );
  };

  const showCTAInterests = (key, funcAction) => {
    if (cardData[key]?.length === 0) {
      return (
        <CallToAction
          showButton={cardData[key]?.length === 0}
          universityColor={universityColor}
          onClick={funcAction}
        >
          Adicionar interesses <IoIosArrowForward />
        </CallToAction>
      );
    }

    if (cardData[key]?.length > 0) {
      return (
        <EditButton
          showButton={cardData[key]?.length > 0}
          universityColor={universityColor}
          onClick={funcAction}
        >
          Editar <IoIosArrowForward />
        </EditButton>
      );
    }
  };

  return (
    <Container ref={sectionRef[gamificationRef]}>
      <Section>
        <Title>Carreira</Title>
        <ChipContainer margin={cardData[cardDataKey].length > 0}>
          {cardData[cardDataKey]?.map(({ label }) => (
            <Chip> {label} </Chip>
          ))}
        </ChipContainer>
        {showCTAInterests(cardDataKey, callToCareerInterestForm)}
      </Section>
      <Divider />
      <Section>
        <Title>Vida</Title>
        <ChipContainer margin={cardData[cardDataKeyTwo].length > 0}>
          {cardData[cardDataKeyTwo]?.map(({ label }) => (
            <Chip> {label} </Chip>
          ))}
        </ChipContainer>
        {showCTAInterests(cardDataKeyTwo, callToLifeInterestForm)}
      </Section>
    </Container>
  );
}
