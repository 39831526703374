import OportunidadesGroup from 'assets/img/OportunidadesGroup.svg';
import Group448 from 'assets/Oportutinites/Group448.svg';
import {
  Content,
  coursesAndEvents,
} from '../utils/handleFunctions';

const randomId = Math.floor(Math.random() * 1000);

export const PrincipalCardsObjects = [
  {
    id: randomId,
    image: OportunidadesGroup,
    title: 'Eventos e Cursos',
    subtitle: 'Eventos, Cursos e Programas',
    handleCall: coursesAndEvents,
  },
  {
    id: randomId,
    image: Group448,
    title: 'Conteúdos',
    subtitle: 'Artigos, E-books, Trilhas, Séries, Comunicados e Desafios',
    handleCall: Content,
  },
];
