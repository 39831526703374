import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Container,
  Title,
  WrapperContent,
  ButtonAddProject,
  ContainerSpan,
  ContainerFilterAndAddProject,
} from './styled';
import InternalTab from './components/InternalTab';
import PublishedProjects from './components/Tabs/PublishedProjects';
import ToFinishProjects from './components/Tabs/ToFinishProjects';
import { ExtensionProjectsContext } from './context/ExtensionProjectsContext';
import ModalTrackEnrollments from './components/Modal/ModalTrackEnrollments';
import FinishedProjects from './components/Tabs/FinishedProjects';
import ModalUploadEvidences from '../../../components/UploadEvidences';
import ModalFinishProject from './components/Modal/ModalFinishProject';
import { FilterContainer, ContainerHeader } from './styled';

import { FilterPublishedExtensionProjects } from './components/Filter/FilterPublishedExtensionProjects';
import { FilterToFinishedExtensionProjects } from './components/Filter/FilterToFinishedExtensionProjects';
import { FilterFinishedExtensionProjects } from './components/Filter/FilterFinishedExtensionProjects';

import {
  deleteEvidence,
  postCreateFileEvidence,
  postCreateLinkEvidence,
  putEditLinkEvidence,
} from './services';
import { cancelRequests } from './services';
import { formatNumber } from 'utils/formatNumber';
import BaseLayoutContext from 'contexts/base-layout';
import { handleCreateExtensionProject } from '../Opportunities/utils/handleFunctions';
import { OportunitiesContext } from 'views/StaffPages/Opportunities/Context/OportunitiesContext';
import Modal from 'views/StaffPages/Opportunities/Components/modal';
import {
  getCacheOptions,
  getCompetencies,
  getExtensionProjectOptions,
  getInterests,
  OportunitiesEducationalGroupUniversities,
  OportunitiesMacroAreas,
} from './services';
import { getUnits } from 'views/StaffPages/jobOpeningsRebuild/services';

import AuthContext from 'contexts/authentication';
import ModalInformation from '../../../components/informationModal';

export default function ExtensionProjectsEngine() {
  const { metadata } = useContext(AuthContext);

  const {
    modalEnrolledStudents,
    usingFormUpload,
    activeTabExtensionProject,
    setActiveTabExtensionProject,
    modalFinishProject,
    setUsingFormUpload,
    idPostEvidence,
    evidenceFiles,
    evidenceLinks,
    getAllToFinishProjects,
    queryFilter,
    setQueryFilter,
    getCountProjects,
    countPublishedSum,
    countToFinishedProjects,
    countFinishedhedSum,
    openSuccessModal,
    setOpenSuccessModal
  } = useContext(ExtensionProjectsContext);

  const [status, setStatus] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterState, setFilterState] = useState({});
  const [filters, setFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});

  const { handleIsLoadingState } = useContext(BaseLayoutContext);
  const educationalGroup =
    metadata && metadata.staff_role === 'educational_group_board';
  const {
    setModalIsOpen,
    setModalContent,
    setMacroAreas,
    setInterests,
    setCompetencies,
    setCacheOptions,
    setExtensionProjectOptions,
    setUnities,
    setEducationalGroupUniversities,
  } = useContext(OportunitiesContext);

  useEffect(() => {
    async function getAll() {
      if (educationalGroup === true) {
        const responseEducationalGroupUniversities = await OportunitiesEducationalGroupUniversities();
        setEducationalGroupUniversities(responseEducationalGroupUniversities);
      }
      const result = await OportunitiesMacroAreas(educationalGroup, '');
      setMacroAreas(result);
      const responseInterests = await getInterests();
      setInterests(responseInterests);
      const responseCompetencies = await getCompetencies();
      setCompetencies(responseCompetencies);
      const responseExtensionProjectOption = await getExtensionProjectOptions();
      setExtensionProjectOptions(responseExtensionProjectOption);
      const responseUnities = await getUnits();
      setUnities(responseUnities);

      const cachedOptions = await getCacheOptions(educationalGroup);
      setCacheOptions([
        ...cachedOptions[0],
        ...cachedOptions[1],
        ...cachedOptions[2],
      ]);
      handleIsLoadingState(false);
    }

    getAll();
  }, []);

  useEffect(() => {
    getCountProjects();
    handleIsLoadingState(false);
  }, [activeTabExtensionProject]);

  useEffect(() => {
    setQueryFilter(null);
  }, [activeTabExtensionProject, queryFilter]);

  useEffect(() => {
    setFilterState({});
  }, [activeTabExtensionProject]);
  const Tabs = [
    {
      activeTab: 0,
      component: (
        <PublishedProjects
          setStatus={setStatus}
          activeTab={activeTabExtensionProject}
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <ToFinishProjects
          setStatus={setStatus}
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <FinishedProjects
          setStatus={setStatus}
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
      ),
    },
  ];

  const Filters = [
    {
      activeTab: 0,
      component: (
        <FilterPublishedExtensionProjects
          filterState={filterState}
          setFilterState={setFilterState}
          appliedFilters={appliedFilters}
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          setAppliedFilters={setAppliedFilters}
          activeTab={activeTabExtensionProject}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <FilterToFinishedExtensionProjects
          filterState={filterState}
          setFilterState={setFilterState}
          appliedFilters={appliedFilters}
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          setAppliedFilters={setAppliedFilters}
          activeTab={activeTabExtensionProject}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <FilterFinishedExtensionProjects
          filterState={filterState}
          setFilterState={setFilterState}
          appliedFilters={appliedFilters}
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          setAppliedFilters={setAppliedFilters}
          activeTab={activeTabExtensionProject}
        />
      ),
    },
  ];

  const handleTabChange = (_, value) => {
    cancelRequests();
    setActiveTabExtensionProject(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);

    return returnTabs.component;
  };

  const renderFilters = (step) => {
    const returnFilters = Filters.find((tab) => tab.activeTab === step);

    return returnFilters.component;
  };

  if (modalEnrolledStudents) {
    return <ModalTrackEnrollments status={status} />;
  }

  if (modalFinishProject) {
    return <ModalFinishProject />;
  }

  const functionCloseUpdateModal = () => {
    setUsingFormUpload(false);
    getAllToFinishProjects(appliedFilters, true);
  };

  const addProject = () => {
    handleCreateExtensionProject(
      setModalIsOpen,
      setModalContent,
    );
  };

  const configSuccessModal = {
    modalWidth: 400,
    bubbleText: `Projeto publicado com sucesso!`,
    ParagraphText: `Acompanhe o projeto cadastrado na aba projetos publicados.`,
    buttonConfirmText: 'OK, ENTENDI',
    showButtonCancel: false,
    buttonCloseX: false,
    bubble: {
      style: {
        textAlign: 'left',
      }
    },
    style: {
      textAlign: 'center',
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '92%',
    },
  };

  return (
    <>
      <Modal />
      <Container>
        <ModalUploadEvidences
          open={usingFormUpload}
          evidenceFiles={evidenceFiles}
          evidenceLinks={evidenceLinks}
          acceptFormats={['file', 'link']}
          headerTitle={'Upload de evidências'}
          acceptFileTypes={['PNG', 'JPEG', 'PDF']}
          functionCloseAndUpdate={functionCloseUpdateModal}
          idPost={idPostEvidence}
          functionPostFile={postCreateFileEvidence}
          functionDeleteEvidence={deleteEvidence}
          functionPostLink={postCreateLinkEvidence}
          functionPutLink={putEditLinkEvidence}
          buttonLabel={'Salvar Evidência(s)'}
        />
        <ContainerHeader>
          <ContainerSpan>
            <Title>Projetos de Extensão</Title>
            <span>
              Aqui você pode acompanhar seus projetos de extensão cadastrados e
              acessar os projetos que foram encerrados.
            </span>
          </ContainerSpan>
          <ContainerFilterAndAddProject>
            <ButtonAddProject onClick={addProject}>
              Cadastrar projeto de extensão
            </ButtonAddProject>

            <FilterContainer>
              {renderFilters(activeTabExtensionProject)}
            </FilterContainer>
          </ContainerFilterAndAddProject>
        </ContainerHeader>
        <WrapperContent>
          <InternalTab
            activeTab={activeTabExtensionProject}
            handleTabChange={handleTabChange}
            tabs={[
              `Projetos publicados (${formatNumber(countPublishedSum)})`,
              `Projetos a finalizar (${formatNumber(countToFinishedProjects)})`,
              `Projetos finalizados (${formatNumber(countFinishedhedSum)})`,
            ]}
          />
          {renderPrincipalTabs(activeTabExtensionProject)}
        </WrapperContent>
      </Container>
      <ModalInformation
        modalOpenState={openSuccessModal}
        config={configSuccessModal}
        buttonConfirmFunction={() => {
          setOpenSuccessModal(false);
        }}
      />
    </>
  );
}
