import { tokenService } from './token.service';
import { api, authenticatedAPI } from './api.service';
import qs from 'querystring';
class AuthService {
  constructor() {}

  /**
   * @function
   * @param {string} loginUrl
   * @param {{
   *  username:string,
   *  password:string,
   * university:string}} data
   * @returns {Promise}
   */
  login = async (loginUrl = '', data) => {
    try {
      const response = await api.post('/api/token', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const { access, refresh } = response.data;
        await tokenService.setTokens(access, refresh);

        const res = await authenticatedAPI.get('/api/internal/me');
        return { isLogged: true, data: res.data };
      }
    } catch (e) {
      return {
        isLogged: false,
        data: e.response ? e.response.data : e,
      };
    }
  };

  loginByToken = async (urlWithQuery) => {
    // We have this because we still connect to the CRSF validation in the backend
    // when we remove this dependency from the backend, we can remove this
    if (window.parent !== window) {
      await this.logout();
    }

    const university = window.location.hash.split('/')[2];
    const query = urlWithQuery.split('?')[1];
    const queryObj = qs.parse(query);

    const data = {
      authentication_token: queryObj.authentication_token,
      university,
      origin_url: queryObj.origin_url,
      scope: 'JWT',
    };

    try {
      const response = await api.post(
        `/api/internal/new-authenticate/students`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        const { access, refresh } = response.data;
        await tokenService.setTokens(access, refresh);

        const res = await authenticatedAPI.get('/api/internal/me');
        return { isLogged: true, data: res.data };
      }
    } catch (e) {
      return {
        isLogged: false,
        data: e.response ? e.response.data : e,
      };
    }
  };

  isAuthenticated = async () => {
    const query = window.location.href.split('?')[1];
    const queryObj = qs.parse(query);
    if (queryObj && queryObj.authentication_token !== undefined) {
      return this.loginByToken(window.location.href);
    }

    try {
      if (tokenService.refreshToken) {
        await this.refresh();
        const res = await authenticatedAPI.get('/api/internal/me');
        return { isLogged: true, data: res.data };
      } else {
        throw Error('Token is not defined');
      }
    } catch (error) {
      if (tokenService.refreshToken) {
        await this.refresh();
      }
      return { isLogged: false, data: error.response };
    }
  };

  logout = async () => {
    tokenService.clearTokens();
    return { origin_url: null };
  };

  refresh = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.post(
          `/api/token/refresh`,
          {
            refresh: tokenService.refreshToken,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.status === 200) {
          const { access = '' } = response.data;
          tokenService.token = access;
          resolve(true);
        }
      } catch (e) {
        reject(false);
      }
    });
  };
}

export const authService = new AuthService();
