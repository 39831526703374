import React, { useState, useRef, Fragment, useEffect } from 'react';
import {
  BaseContainer,
  Box,
  Card,
  Wrapper,
  WrapperHeader,
  ContainerFilter,
  ContainerTitleHeader,
} from './style';

import Pagination from 'components/CustomPagination';
import ListLinks from './ListLinks';
import ListFiles from './ListFiles';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import LoadingUpdate from 'components/LoadingUpdate/LoadigUpdate';
import TabHeader from './tabHeader';
import {
  deleteEvidence,
  getAllStudentEvidenceApi,
  getEvidences,
  getStudentEvidenceApi,
} from 'views/StaffPages/Opportunities/services';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import FilterModal from 'components/FilterModal';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { evidenceStatus, evidenceTypes } from './resources/FilterOptions';

export default function ModalShowEvidence({
  open,
  setOpen,
  id,
  useTab = true,
  studentId,
  uniqueStudent,
  seeAllEvidencesStudent,
  getAllToFinishProjects,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [usingPreviewPdf, setUsingPreviewPdf] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [arrayLinks, setArrayLinks] = useState([]);
  const [arrayFiles, setArrayFiles] = useState([]);

  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const modalContainer = useRef(null);

  useEffect(() => {
    if (open) {
      controllRequests();
    }
  }, [activeTab, open]);

  if (!open) {
    return null;
  }

  const separeteResults = (results) => {
    setArrayFiles([]);
    setArrayLinks([]);
    const files = results.filter(
      (item) => item.type === 'file' || item.type === 'image'
    );
    const links = results.filter(
      (item) => item.type === 'link' || item.type === 'link_unique'
    );
    setArrayLinks(links);
    setArrayFiles(files);
    setIsLoading(false);
  };

  const controllRequests = async (query, reset, filterParam) => {
    setIsLoading(true);
    const filter = filterParam ? queryParamsFromFilterObject(filterParam) : '';
    const mountedQuery = query
      ? `${query}&${filter}`
      : `limit=5&offset=0&${filter}`;

    if (reset) {
      setCurrentPage(1);
    }

    if (uniqueStudent && !seeAllEvidencesStudent) {
      const { results, count } = await getStudentEvidenceApi(
        id,
        studentId,
        mountedQuery
      );
      setCount(count);
      separeteResults(results);
      return;
    }
    if ((useTab && activeTab === 0) || seeAllEvidencesStudent) {
      const { results, count } = await getAllStudentEvidenceApi(
        id,
        mountedQuery
      );
      setCount(count);
      separeteResults(results);
      return;
    }
    if (useTab && activeTab === 1) {
      const { results, count } = await getEvidences(id, mountedQuery);
      setCount(count);
      separeteResults(results);
      return;
    }
    setIsLoading(false);
  };

  const handlePageChange = async (page) => {
    const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
    await controllRequests(newPage);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    handlePageChange(page);
  };

  const handleClickClose = () => {
    setCurrentPage(1);
    setLimit(5);
    setCount(0);
    setActiveTab(0);
    setUsingPreviewPdf(false);
    setIsLoading(false);
    setArrayLinks([]);
    setArrayFiles([]);
    setFilterState({});
    setAppliedFilters({});
    setOpen(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const closeClickingOutside = (e) => {
    if (modalContainer.current === e.target) {
      setOpen(false);
    }
  };

  const titleHeader = () => {
    return (
      <ContainerTitleHeader>
        <h3>Analisar evidências</h3>
        <p>
          {seeAllEvidencesStudent &&
            'Aqui você pode realizar a análise das evidências enviadas pelos seus estudantes.'}
        </p>
      </ContainerTitleHeader>
    );
  };

  const currentHeader = useTab ? (
    <TabHeader
      onChange={handleTabChange}
      tabs={[
        'Evidências dos estudantes',
        'Evidências da Instituição de ensino',
      ]}
      activeTab={activeTab}
    />
  ) : (
    titleHeader()
  );

  const createMapLinks = () => {
    return arrayLinks.map((item) => (
      <ListLinks
        item={item}
        idPost={id}
        activeTab={activeTab}
        refreshFiles={controllRequests}
        functionDeleteEvidence={deleteEvidence}
        getAllToFinishProjects={getAllToFinishProjects}
      />
    ));
  };

  const createMapFiles = () => {
    return arrayFiles.map((item) => (
      <ListFiles
        setUsingPreviewPdf={setUsingPreviewPdf}
        idPost={id}
        item={item}
        activeTab={activeTab}
        refreshFiles={controllRequests}
        functionDeleteEvidence={deleteEvidence}
        getAllToFinishProjects={getAllToFinishProjects}
      />
    ));
  };

  const createMaps = () => {
    if (!isLoading) {
      if (arrayFiles.length === 0 && arrayLinks.length === 0 && !isLoading) {
        return (
          <div
            style={{
              width: '100%',
              height: '77px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p>Ops! No momento não existem evidências publicadas</p>
          </div>
        );
      }

      const links = createMapLinks();
      const files = createMapFiles();

      return (
        <Fragment>
          {links}
          {files}
        </Fragment>
      );
    }
  };

  const handleClearFilter = () => {
    setFilterState({});
    controllRequests(null, true, null);
  };

  const handleApplyFilters = (objectFilter) => {
    setFilterState(objectFilter);
    setAppliedFilters(objectFilter);
    controllRequests(null, true, { ...objectFilter });
  };

  return (
    <BaseContainer
      ref={modalContainer}
      onClick={closeClickingOutside}
      usingPreviewPdf={usingPreviewPdf}
    >
      <Box>
        <Wrapper>
          <Card>
            <CloseButton onClick={handleClickClose} />
            <WrapperHeader>
              {currentHeader}
              <FilterModal
                filterState={filterState}
                setFilterState={setFilterState}
                width="500px"
                marginLeft="-357"
                onClean={handleClearFilter}
                appliedFilters={appliedFilters}
                setAppliedFilters={handleApplyFilters}
              >
                <ContainerFilter>
                  {activeTab === 0 && !uniqueStudent && (
                    <Filter
                      name={'student'}
                      label={'Estudantes'}
                      type={FilterType.TEXT}
                    />
                  )}
                  {activeTab === 1 && !uniqueStudent && (
                    <Filter
                      name={'staff_name'}
                      label={'Usuário'}
                      type={FilterType.TEXT}
                    />
                  )}
                  <Filter
                    name={['start_date', 'end_date']}
                    label={'Período do upload'}
                    type={FilterType.DATE_RANGE}
                    settings={{
                      placeholder: ['Data inicial', 'Data final'],
                    }}
                  />
                  <Filter
                    label="Tipo de evidência"
                    name={'type'}
                    type={FilterType.SINGLE_SELECT}
                    options={evidenceTypes}
                  />
                  {activeTab === 0 || uniqueStudent ? (
                    <Filter
                      label="Status da análise"
                      name={'status'}
                      type={FilterType.SINGLE_SELECT}
                      options={evidenceStatus}
                    />
                  ) : null}
                </ContainerFilter>
              </FilterModal>
            </WrapperHeader>
            {isLoading && <LoadingUpdate />}
            {createMaps()}

            <Pagination
              showLimit={false}
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={count}
              limit={limit}
              onPageChange={onPageChange}
              setLimit={setLimit}
              setCurrentPage={setCurrentPage}
            />
          </Card>
        </Wrapper>
      </Box>
    </BaseContainer>
  );
}
