import React, { useContext } from 'react';
import {
  Title,
  Paragraph,
  ContainerCards,
  Card,
  IconImg,
  Text,
  Infos,
  InfosOne,
  TextComposition,
  ContainerInformation,
  ContainerIconTooltip,
  ContainerText,
  CardIndicator,
  IndicatorStep,
  IndicatorStepBullet,
} from '../styled';
import { IconBlocked } from 'assets/img/iconsCurriculum/iconBlocked';
import { IconCurriculumReward } from 'assets/img/iconsCurriculum/iconCurriculumReward';

import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import useStepOneSorting from './useStepOneSorting';
import { IconInformation } from 'assets/img/iconsCurriculum/iconInformation';
import Tooltip from 'components/NewTooltip';
import { handleScrollIntoView } from 'views/Profile/Utils/resources';
import { profileTexts } from 'views/Profile/Utils/profileTexts';

export default function StepOne() {
  const {
    stepOneData,
    gamificationLevel,
    universityColor,
    sectionRef,
  } = useContext(ProfileContext);
  const gamificationItems = useStepOneSorting(stepOneData);
  const levelOne = gamificationLevel === 1;
  const moreLevelOne = gamificationLevel > 1;
  const { returnColorText } = profileTexts();
  const returnColorOne = () => {
    return moreLevelOne ? universityColor : '#606062';
  };

  const returnIconLevelOne = () => {
    if (gamificationLevel <= 1) {
      return <IconBlocked color="#C4C4C4" />;
    }
    if (gamificationLevel > 1) {
      return <IconCurriculumReward color={universityColor} />;
    }
  };

  const returnTitleLevelOne = () => {
    if (levelOne) {
      return 'Recompensa';
    }
    if (gamificationLevel > 1) {
      return `Recompensa Resgatada`;
    }
  };

  return (
    <>
      <Title>Jornada do autoconhecimento</Title>
      <Paragraph>
        Complete os itens abaixo para ter acesso ao seu currículo do futuro e
        avançar para a próxima etapa.
      </Paragraph>

      <ContainerCards>
        {gamificationItems.map((item, index) => (
          <CardIndicator key={index}>
            <ContainerText>
              <IconImg
                onClick={() =>
                  handleScrollIntoView(sectionRef[item.gamificationRef])
                }
              >
                {item.icon}
              </IconImg>

              <TextComposition>
                <Text
                  returnColor={returnColorText(stepOneData, item.key)}
                  onClick={() =>
                    handleScrollIntoView(sectionRef[item.gamificationRef])
                  }
                >
                  {item.title}
                </Text>
                <Infos>{item.info}</Infos>
              </TextComposition>
            </ContainerText>
            <IndicatorStep color={item.color}>
              <IndicatorStepBullet color={item.color} />
            </IndicatorStep>
          </CardIndicator>
        ))}

        <Card>
          <IconImg disabled>{returnIconLevelOne()}</IconImg>
          <TextComposition>
            <ContainerInformation>
              <Text
                activedColor={moreLevelOne === true}
                universityColor={universityColor}
                disabled
              >
                {returnTitleLevelOne()}
              </Text>
              {moreLevelOne ? (
                <Tooltip
                  text={`Agora você pode gerar o seu Currículo do Futuro, 
                    acessar as vagas mais alinhadas ao seu perfil e 
                    começar a jornada do protagonismo!`}
                  colorSchema={'black'}
                  direction={'left'}
                  minWidth="470px"
                  isMultiLine={true}
                >
                  <ContainerIconTooltip>
                    <IconInformation color={'#606062'} />
                  </ContainerIconTooltip>
                </Tooltip>
              ) : null}
            </ContainerInformation>
            <InfosOne>Currículo Profissional</InfosOne>
          </TextComposition>
        </Card>
      </ContainerCards>
    </>
  );
}
