import React, { useRef, useContext, useState, useEffect } from 'react';
import LogosAnimated from '../UIComponents/LogosAnimated';
import { Form } from '@unform/web';

import logoWorkaloveColor from 'assets/img/icons/logoWorkaloveLaranja.png';
import AuthContext from 'contexts/authentication';
import { errors } from 'constants/errors';
import {
  Container,
  ContainerLogin,
  ContainerAllLogos,
  Titles,
  Paragraph,
  ButtonLogin,
  ContainerWorkalove,
  FragmentContainer,
  ContainerInCenter,
  ContainerTitles,
  ContainerSubTitles,
  BoxLogos,
  Footer,
  ButtonPassword,
  ContainerButtonBack,
} from '../styles';
import { login } from 'services/login/authenticate';
import BaseLayoutContext from 'contexts/base-layout';
import InputUsernameLogin from '../UIComponents/InputUsername';
import InputPasswordLogin from '../UIComponents/InputPassword';
import subdomainService from 'services/subdomain.service';
import { defaultsSubdomain } from 'constants/subdomains';
import LoginForgotPassword from './ForgotPassword';
import { EmailPlus } from 'assets/ImgsLogin/IconsLogin';
import VectorArrowBackSvg from '../UIComponents/VectorArrowBack';
import {
  verifyRecoverToken,
  confirmNewPassword,
} from 'services/login/recoverPassword';

export default function LoginUniversity({
  match,
  handleIsLoadingState,
  isRecovery = false,
  history,
}) {
  const { routerOnUserLogin, isInvalidAuthenticationToken } = useContext(
    AuthContext
  );
  const { openSnackbar } = useContext(BaseLayoutContext);
  const dnaColor = '#009291';
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [contentLogin, setContentLogin] = useState(true);
  const [contentEmailSuccess, setContentEmailSuccess] = useState(false);
  const [modalEmailSent, setModalEmailSent] = useState(false);
  const [recoveryErrors, setRecoveryErros] = useState({
    password: '',
    confirmPassword: '',
  });
  const [token, setToken] = useState('');
  const [tokenExpired, setTokenExpired] = useState(false);

  const formLoginUniversity = useRef();
  const formRecoveryUniversity = useRef();
  const subdomain = subdomainService.getSubdomain();
  const urlUniversity = '/login/universidade';

  useEffect(() => {
    if (isRecovery) {
      const token = new URLSearchParams(window.location.hash.split('?')[1]).get(
        'token'
      );
      if (!token || token == null) {
        history.push(urlUniversity);
      }
      setToken(token);
      (async () => {
        try {
          await verifyRecoverToken({ token });
        } catch (e) {
          setTokenExpired(true);
          handleForgotPasswordClick();
        }
      })();
    }
    if (history.location?.state?.recoverSuccess) {
      openSnackbar('Senha alterada com sucesso');
    }
  }, []);

  const handleLogin = async (data) => {
    handleIsLoadingState(true);
    const loginData = { ...data, university: true };
    let loginUniversity = await login(
      subdomain.toLocaleLowerCase() === defaultsSubdomain.university
        ? urlUniversity
        : match.path,
      loginData
    );

    if (loginUniversity.isLogged) {
      if (match.params?.id && match.params?.uuid) {
        loginUniversity.data['params'] = match.params;
      }

      if (match.params?.universidade && match.params?.id) {
        loginUniversity.data['params'] = match.params;
      }
      routerOnUserLogin(
        loginUniversity.data,
        undefined,
        window.location.href.split('?')[1]
      );
    } else {
      if (loginUniversity.data.detail) {
        handleIsLoadingState(false);
        return;
      }
      const message = errors.hasOwnProperty(loginUniversity.data.code)
        ? errors[loginUniversity.data.code]
        : 'Não foi possível realizar o seu login. Tente novamente';
      handleIsLoadingState(false);
      openSnackbar(message, true);
    }
  };

  const confirmRecovery = async (data) => {
    setRecoveryErros({});
    const validatePasswordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    if (!validatePasswordRegex.test(data.password)) {
      setRecoveryErros({
        password:
          'A senha deve conter no mínimo 8 caracteres, uma letra e um número.',
      });
      return;
    }
    if (data.password !== data.confirmPassword) {
      setRecoveryErros({ confirmPassword: 'As senhas devem ser iguais.' });
      return;
    }
    try {
      const response = await confirmNewPassword({ ...data, token });
      openSnackbar('Senha alterada com sucesso', false);
      if (response.status.toLocaleLowerCase() === 'ok') {
        history.push({
          pathname: '/login/universidade',
          state: {
            recoverSuccess: true,
          },
        });
      }
    } catch (e) {
      openSnackbar('Falha ao alterar senha, tente novamente.', true);
    }
  };

  function handleForgotPasswordClick() {
    setShowForgotPassword(true);
    setContentLogin(false);
  }

  const showSendEmailSuccess = () => {
    setContentEmailSuccess(true);
    setShowForgotPassword(false);
  };

  const hideSendEmailSuccess = () => {
    if (tokenExpired) {
      history.push('/login/universidade');
    }
    setContentEmailSuccess(false);
    setShowForgotPassword(false);
    setContentLogin(true);
  };
  return (
    <Container color={dnaColor}>
      <BoxLogos color={dnaColor}>
        <ContainerAllLogos>
          <LogosAnimated color={dnaColor} />
          <div>
            <Titles isLogo>
              <strong>Transformando vidas pela educação.</strong>
            </Titles>
            <Paragraph>
              Acelerar a carreira de seus alunos agora pode ser rápido, prático
              e integrado à nova dinâmica do mercado de trabalho.
            </Paragraph>
          </div>
        </ContainerAllLogos>
      </BoxLogos>

      {contentLogin === false && showForgotPassword && (
        <LoginForgotPassword
          isUniversityLogin
          modalEmailSent={modalEmailSent}
          setModalEmailSent={setModalEmailSent}
          contentLogin={contentLogin}
          setContentLogin={setContentLogin}
          showForgotPassword={showForgotPassword}
          setShowForgotPassword={setShowForgotPassword}
          showSendEmailSuccess={showSendEmailSuccess}
          expiredToken={tokenExpired}
        />
      )}

      {contentLogin && (
        <ContainerLogin>
          <FragmentContainer>
            <ContainerInCenter>
              <img src={logoWorkaloveColor} alt="" />
            </ContainerInCenter>
            {!isRecovery && (
              <ContainerTitles color={'#606062'}>
                <p>Seja bem-vindo!</p>
                <p>Faça login em sua conta.</p>
              </ContainerTitles>
            )}
            {isRecovery && (
              <>
                <ContainerTitles isRecovery>
                  <p>Nova senha</p>
                </ContainerTitles>
                <ContainerSubTitles>
                  <p>Preencha os campos abaixo com a sua nova senha.</p>
                </ContainerSubTitles>
              </>
            )}
            {!isRecovery && (
              <div className="form">
                <Form ref={formLoginUniversity} onSubmit={handleLogin}>
                  <InputUsernameLogin
                    name="username"
                    label="Usuário"
                    universityColor={dnaColor}
                    placeholder="Digite aqui..."
                  />

                  <InputPasswordLogin
                    name="password"
                    label="Senha"
                    type="password"
                    universityColor={dnaColor}
                    placeholder="Digite aqui..."
                  />
                  <ButtonPassword
                    color={dnaColor}
                    onClick={handleForgotPasswordClick}
                    type="button"
                    marginTop="8px"
                    data-testid="button-forgot-password"
                  >
                    Esqueceu sua senha?
                  </ButtonPassword>
                  <span></span>
                  <ButtonLogin
                    color={dnaColor}
                    type="submit"
                    data-testid="button-login"
                  >
                    Entrar
                  </ButtonLogin>
                </Form>
              </div>
            )}
            {isRecovery && (
              <div className="form">
                <Form ref={formRecoveryUniversity} onSubmit={confirmRecovery}>
                  <InputPasswordLogin
                    name="password"
                    label="Senha"
                    type="password"
                    universityColor={dnaColor}
                    placeholder="Digite aqui..."
                    errorMessage={recoveryErrors.password}
                    buttonShowPassword
                  />

                  <InputPasswordLogin
                    name="confirmPassword"
                    label="Confirmar Senha"
                    type="password"
                    universityColor={dnaColor}
                    placeholder="Digite aqui..."
                    errorMessage={recoveryErrors.confirmPassword}
                    buttonShowPassword
                  />
                  <span></span>
                  <ButtonLogin color={dnaColor} type="submit">
                    Confirmar
                  </ButtonLogin>
                </Form>
              </div>
            )}
          </FragmentContainer>
          <ContainerWorkalove>
            <Footer>
              By <strong>Workalove</strong>
            </Footer>
          </ContainerWorkalove>
        </ContainerLogin>
      )}
      {contentEmailSuccess && (
        <>
          <ContainerLogin>
            <FragmentContainer>
              <div className="infoContent">
                <ContainerButtonBack
                  isUniversityLogin
                  onClick={hideSendEmailSuccess}
                >
                  <VectorArrowBackSvg color={'#009291'} />
                </ContainerButtonBack>
                <div className="infoIcon">
                  <EmailPlus />
                </div>
                <div className="infoTitle">
                  <p>Tudo Certo!</p>
                </div>
                <div className="infoBody">
                  <p>
                    Encaminhamos para o e-mail cadastrado as instruções para a
                    redefinição da sua senha!
                  </p>
                  <p>
                    Caso você não tenha mais acesso a este e-mail, entre em
                    contato com suporte@workalove.com e solicite a atualização
                    dos dados no sistema.
                  </p>
                </div>
              </div>
            </FragmentContainer>
            <ContainerWorkalove>
              <Footer>
                By <strong>Workalove</strong>
              </Footer>
            </ContainerWorkalove>
          </ContainerLogin>
        </>
      )}
    </Container>
  );
}
