import axios from 'axios';
import { authenticatedAPI } from 'services/api/api';

const CancelToken = axios.CancelToken;
let cancelGetStudentsRequest;
let cancelGetFiltersRequest;

class CsmApi {
  cancelRequests = () => {
    cancelGetStudentsRequest && cancelGetStudentsRequest();
    cancelGetFiltersRequest && cancelGetFiltersRequest();
  };
  getStudentCounts = async (egressed) => {
    const egressQuery = egressed ? '?is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.get(
        `/api/internal/university/students-count${egressQuery}`
      );
      return response.data;
    } catch (err) {
      return err;
    }
  };

  getStudentsByCategory = async (category, queryParams, egressed) => {
    const filtersString = queryParams ? `?${queryParams}` : '';
    const newCategory = category.replace(/_/g, '-');
    const crateParameterFinishedSystem =
      newCategory === 'finished-system' ? 'finished-by-system' : newCategory;
    const egressQuery = egressed ? '&is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.get(
        `/api/internal/university/students-${crateParameterFinishedSystem}${filtersString}${egressQuery}`,
        {
          cancelToken: new CancelToken(function executor(c) {
            cancelGetStudentsRequest = c;
          }),
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  getFilterOptions = async (category, egressed) => {
    const newCategory = category.replace(/_/g, '-');
    const crateParameterFinishedSystem =
      newCategory === 'finished-system' ? 'finished-by-system' : newCategory;
    const egressQuery = egressed ? '?is_egress_menu=true' : '';
    try {
      return await authenticatedAPI.get(
        `/api/internal/university/students-${crateParameterFinishedSystem}/filter${egressQuery}`,
        {
          cancelToken: new CancelToken(function executor(c) {
            cancelGetFiltersRequest = c;
          }),
        }
      );
    } catch (error) {
      return error;
    }
  };

  getCitiesOptions = async (category, states, egressed) => {
    const statesQuery = `states=${states.join('&states=')}`;
    const newCategory = category.replace(/_/g, '-');
    const crateParameterFinishedSystem =
      newCategory === 'finished-system' ? 'finished-by-system' : newCategory;
    const egressQuery = egressed ? '&is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.get(
        `/api/internal/university/students-${crateParameterFinishedSystem}/filter?${statesQuery}${egressQuery}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  generateSpreadsheetAndSendToEmail = async (category, egressed) => {
    const newCategory = category.replace(/_/g, '-');
    const crateParameterFinishedSystem =
      newCategory === 'finished-system' ? 'finished-by-system' : newCategory;
    const egressQuery = egressed ? '?is_egress_menu=true' : '';
    try {
      return await authenticatedAPI.get(
        `/api/internal/university/students-${crateParameterFinishedSystem}/file${egressQuery}`
      );
    } catch (error) {
      return error;
    }
  };
}

export default new CsmApi();
