import React, { useContext } from 'react';
import StepThreeJobOffer from './components/steps/stepThree';
import StepOneJobOffer from './components/steps/stepOne';
import StepTwoJobOffer from './components/steps/stepTwo';

import {
  WrapperSteps,
  WrapperStepIndicator,
  HeaderStep,
  TitleRegister,
} from './style';

import bag from '../../../assets/JobOffer/bag.svg';
import { formSubTitle } from './useCases/formSubTitles';
import { JobOfferContext } from './context/jobOfferContext';
import { useScrollToDiv } from '../../JobOfferRebuild/hooks/scrollToCurrentDiv';
import StepIndicator from './components/StepIndicator';
import ExternalJobOffer from './ExternalJobOffer';

export default function EngineSteps() {
  const { jobOfferStep, externalJobOffer } = useContext(JobOfferContext);

  const steps = [
    <StepOneJobOffer />,
    <StepTwoJobOffer />,
    <StepThreeJobOffer />,
  ];

  const [formDiv] = useScrollToDiv(jobOfferStep);

  const currentPublicationType = () => {
    if (externalJobOffer) {
      return <ExternalJobOffer />;
    }

    return (
      <div style={{ padding: '0px 30px' }}>
        <WrapperStepIndicator>
          <StepIndicator currentStep={jobOfferStep} StepsQuantity={3} />
        </WrapperStepIndicator>

        <HeaderStep>
          <img src={bag} />
          <TitleRegister>Cadastrar Vaga</TitleRegister>
        </HeaderStep>
        <p>{formSubTitle[jobOfferStep]}</p>

        {steps[jobOfferStep]}
      </div>
    );
  };

  return <WrapperSteps ref={formDiv}>{currentPublicationType()}</WrapperSteps>;
}
