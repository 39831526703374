import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';
import { tokenService } from './token.service';
import JWTInterceptor from './JWTInterceptor';

const jwtInterceptor = new JWTInterceptor();

const dataProductsAPI = axios.create({
  baseURL: URLS.DATA_PRODUCTS_URL,
});

dataProductsAPI.interceptors.request.use(async (config) => {
  config.headers = {
    Authorization: `Bearer ${tokenService.token}`,
    ...config.headers,
  };
  return config;
});

jwtInterceptor.setupInterceptors(dataProductsAPI);

export default dataProductsAPI;
