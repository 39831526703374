import React, { useContext, useState } from 'react';
import { Form } from '@unform/web';
import Input from 'components/UnformFields/inputField';
import { ContainerForm, ContainerLink } from './style';
import * as Yup from 'yup';
import { schemaValidationLink } from './schemaValidation';
import LinkInput from 'components/UnformFields/linkInput';
import { generateUUID } from '../../utils/generateUUID';
import BaseLayoutContext from 'contexts/base-layout';

export default function LinkComponent({
  ArrayLinks,
  setArrayLinks,
  formRefLink,
  isEditLink,
  setIsEditLink,
  selectedEditUUID,
  idPost,
  functionPost,
  functionPostLink,
  functionPutLink,
  uniqueUploadType,
  uniqueLink,
}) {
  const [isRequestBeingProcessed, setIsRequestBeingProcessed] = useState(false);
  const { universityColor } = useContext(BaseLayoutContext);

  const handleAcceptSubmit = async (data) => {
    const name = data.evidenceName;
    const link = data.link;

    const mountedLink = {
      id: generateUUID(),
      link: link,
      name: name,
      createdNow: true,
    };

    if (isEditLink) {
      const currentLink = ArrayLinks.find(
        (item) => item.id === selectedEditUUID
      );
      const filterArray = ArrayLinks.filter(
        (item) => item.id !== selectedEditUUID
      );
      const putLink = await functionPutLink(
        idPost,
        currentLink.id,
        mountedLink,
        uniqueLink
      );
      setArrayLinks([...filterArray, putLink]);
      formRefLink.current.reset()
      setIsEditLink(false);

      return;
    }

    const linkCreated = await functionPostLink(idPost, mountedLink, uniqueLink);
    setArrayLinks((prevs) => [...prevs, linkCreated]);
    formRefLink.current.reset()
  };

  const handleSubmit = async () => {
    const data = formRefLink.current.getData();
    formRefLink.current.setErrors({});

    try {
      setIsRequestBeingProcessed(true);
      const schema = schemaValidationLink;
      await schema.validate(data, {
        abortEarly: false,
      });
      await handleAcceptSubmit(data);
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRefLink.current.setErrors(validationErrors);
      }
    } finally {
      setIsRequestBeingProcessed(false);
    }
  };

  return (
    <ContainerForm>
      <Form ref={formRefLink}>
        {!uniqueUploadType && (
          <p>
            Caso você queira subir mais de um formato, basta inserir os links de
            sua escolha e depois alterar o formato de publicação.
          </p>
        )}
        <Input name="evidenceName" placeholder="Digite o nome da evidência" />
        <ContainerLink universityColor={universityColor}>
          <LinkInput
            name="link"
            universityColor={universityColor}
            placeholder="Adicione seu link aqui"
          />
          <button
            type="submit"
            disabled={isRequestBeingProcessed}
            onClick={() => handleSubmit()}
          >
            {isEditLink ? 'Editar evidência' : 'Adicionar'}
          </button>
        </ContainerLink>
      </Form>
    </ContainerForm>
  );
}
