import httpRequest from 'services/university/HttpRequest';

class FairAndEventsService {
  buildListingUrl = (type, query, additionalType) => {
    let baseUrl = `api/internal/university/academic-events/${type}`;
    let fullQuery = query;

    if (
      type === 'finished' &&
      ['finished', 'canceled'].includes(additionalType)
    ) {
      const typeQueryParam = `type=${additionalType}`;
      fullQuery = fullQuery ? `${fullQuery}&${typeQueryParam}` : typeQueryParam;
    }

    return `${baseUrl}/?${fullQuery}`;
  };

  async getSelectOptions(param = '') {
    return await httpRequest.get(
      `api/internal/university/academic-events/options?${param}`
    );
  }

  async getAssociatedAreas(param) {
    return await httpRequest.get(
      `api/internal/university/academic-events/options?associated_areas=true&${param}`
    );
  }

  async getDataByZipCode(cep) {
    const zipCodeOnlyDigits = cep.replace('-', '');
    return await httpRequest.externalGet(
      `https://viacep.com.br/ws/${zipCodeOnlyDigits}/json/`
    );
  }

  async postCreate(data) {
    const formData = new FormData();
    formData.append('event_cover', data.event_cover);
    formData.append('data', JSON.stringify(data));
    return await httpRequest.post(
      `api/internal/university/academic-events/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  async getListings(type, query = '', additionalType, signal) {
    const url = this.buildListingUrl(type, query, additionalType);
    return await httpRequest.get(url, { ...signal });
  }

  async getEventDetail(id) {
    return await httpRequest.get(
      `api/internal/university/academic-events/${id}/`
    );
  }

  async patchEdit(data, id) {
    const formData = new FormData();
    formData.append('event_cover', data.event_cover);
    formData.append('data', JSON.stringify(data));
    return await httpRequest.patch(
      `api/internal/university/academic-events/${id}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  async getOptionsFilter(param = '') {
    return await httpRequest.get(
      `api/internal/university/academic-events/options/?${param}`
    );
  }

  async getAwaitingStartFilterOptions() {
    return await httpRequest.get(
      `api/internal/university/academic-events/awaiting-start/filter-options/`
    );
  }

  async getOngoingFilterOptions() {
    return await httpRequest.get(
      `api/internal/university/academic-events/ongoing/filter-options/`
    );
  }

  async getToFinishFilterOptions() {
    return await httpRequest.get(
      `api/internal/university/academic-events/to-finish/filter-options/`
    );
  }

  async getFinishedFilterOptions() {
    return await httpRequest.get(
      `api/internal/university/academic-events/finished/filter-options/`
    );
  }

  async patchUnpublishEvent(id, feedback) {
    return await httpRequest.patch(
      `api/internal/university/academic-events/${id}/unpublish/`,
      {
        feedback,
      }
    );
  }

  async getEventStudents(eventId, query = '', pagination = '', signal) {
    return await httpRequest.get(
      `api/internal/university/academic-events/check-in/${eventId}?search=${query}&${pagination}`,
      { ...signal }
    );
  }

  async putRemoveStudentFromEvent(studentUuid, feedback) {
    return await httpRequest.put(
      `api/internal/university/academic-events/registration/${studentUuid}/cancel/`,
      { feedback }
    );
  }

  async putBulkRemoveStudentFromEvent(studentUuids, feedback) {
    return await httpRequest.post(
      `api/internal/university/academic-events/registration/bulk/cancel/`,
      { registration_uuids: studentUuids, feedback }
    );
  }

  async postConfirmPresenceStudent(studentUuid) {
    return await httpRequest.post(
      `api/internal/university/academic-events/check-in/${studentUuid}/create/`
    );
  }

  async putRemovePresenceStudent(studentUuid, checkInId) {
    return await httpRequest.put(
      `api/internal/university/academic-events/check-in/${studentUuid}/update/${checkInId}`,
      { active: false }
    );
  }

  async postReSendCertificate(studentUuid) {
    return await httpRequest.post(
      `api/internal/university/academic-events/certificate/${studentUuid}`
    );
  }

  async getStudentEventCertificate(studentUuid) {
    return await httpRequest.get(
      `api/internal/university/academic-events/certificate/${studentUuid}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/pdf',
        },
      }
    );
  }

  async postBulkRemoveStudents(students, eventId) {
    return await httpRequest.post(
      `api/internal/university/academic-events/check-in/${eventId}/bulk/cancel`,
      { check_in_ids: students }
    );
  }

  async postBulkConfirmStudent(students, eventId) {
    return await httpRequest.post(
      `api/internal/university/academic-events/check-in/${eventId}/bulk`,
      { registration_uuids: students }
    );
  }

  async getRegisterStudentEventDetail(eventId, registerId) {
    return await httpRequest.get(
      `api/internal/university/academic-events/${eventId}/registration/${registerId}`
    );
  }

  async putFinishEvent(eventId, feedback) {
    return await httpRequest.put(
      `api/internal/university/academic-events/${eventId}/finish`,
      {
        about_event_day: feedback,
      }
    );
  }

  async getExportList(eventId) {
    return await httpRequest.get(
      `api/internal/university/academic-events/report/${eventId}`
    );
  }

  getImageFromBase64 = async (base64, fileName, suffix) => {
    const urlImg = `data:application/pdf;base64, ${base64}`;
    try {
      const response = await fetch(urlImg);
      const blob = await response.blob();
      const file = new File([blob], fileName, {
        type: `image/${suffix.replace('.', '')}`,
      });
      return file;
    } catch (error) {
      throw error;
    }
  };

  async getEmailToSend() {
    return await httpRequest.get(`api/internal/me/email`);
  }

  async getAmounts() {
    return await httpRequest.get(
      'api/internal/university/academic-events/amounts/'
    );
  }

  async getEventRegistrationCounters(eventId) {
    return await httpRequest.get(
      `api/internal/university/academic-events/registration/count/${eventId}`
    );
  }
}

export default new FairAndEventsService();
