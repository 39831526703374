import styled from 'styled-components';

const getBackgroundColor = (props) => {
  return props.universityColor ? props.universityColor : '#009291';
};

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const IconImg = styled.img`
  width: 10px;
  height: 15px;
`;

export const HeaderPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: space-between;
`;

export const ButtonPagination = styled.button`
  width: 32px;
  height: 32px;
  margin-left: ${(props) => (props.hasMarginRight ? '14px' : '')};

  background-color: ${(props) =>
    props.disabled ? '#c0c0c0' : getBackgroundColor};

  border-radius: 50%;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export const HeaderLevels = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const LevelOneAndTwo = styled.div`
  position: relative;
  width: 74px;
  height: 30px;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: ${getBackgroundColor};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#ffffff'};
    opacity: 0.1;
    border-radius: 4px;
  }
`;

export const LevelThree = styled.div`
  color: ${(props) => (props.levelThree ? '#ab5501' : '#fff')};
  font-weight: 900;

  border-radius: 4px;
  width: 74px;
  height: 30px;

  background: ${(props) =>
    props.levelThree
      ? `linear-gradient(
      90deg,
      rgba(255, 245, 218, 1) 0%,
      rgba(251, 209, 46, 1) 50%,
      rgba(244, 121, 41, 1) 100%
    )`
      : '#c0c0c0'};

  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerReward = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;
