import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconTime } from 'assets/img/iconsCurriculum/iconTime';
import useMediaQuery from 'hooks/useMediaQuery';

import {
  IconContainer,
  Informations,
  Title,
  HeaderContainer,
  ContainerInfos,
  InfoWrapper,
  TimeContainer,
  ContainerTime,
} from './styled';
import moment from 'moment';
import { IconInfoAcademicStatus } from 'assets/img/iconsCurriculum/iconInfoAcademicStatus';
import { ProfileStore } from 'stores/profile.store';

const GenericHeader = (props) => {
  const {
    universityColor,
    title,
    IconSection,
    time,
    info,
    cardData,
    cardDataKey,
    disabled,
  } = props;

  const mobile = useMediaQuery('(min-width: 1313pxpx)');

  const updatedCardData = ProfileStore((state) => state.cardData);
  const isChecked = () => {
    const options = {
      careerInterests: () =>
        !!(
          Array.isArray(updatedCardData[cardDataKey]) &&
          updatedCardData[cardDataKey].length &&
          Array.isArray(updatedCardData['lifeInterests']) &&
          updatedCardData['lifeInterests'].length
        ),

      personality: () =>
        !!(
          updatedCardData[cardDataKey].hasOwnProperty('is_done') &&
          updatedCardData[cardDataKey].is_done
        ),
      values: () =>
        !!(
          updatedCardData[cardDataKey].hasOwnProperty('is_done') &&
          updatedCardData[cardDataKey].is_done
        ),
      languages: () =>
        !!(
          Array.isArray(updatedCardData[cardDataKey]) &&
          updatedCardData[cardDataKey].length
        ),
      extraCourses: () =>
        !!(
          Array.isArray(updatedCardData[cardDataKey]) &&
          updatedCardData[cardDataKey].length
        ),
      careerExperience: () =>
        !!(
          !!(
            Array.isArray(updatedCardData[cardDataKey]) &&
            updatedCardData[cardDataKey].length
          ) &&
          !!(
            Array.isArray(updatedCardData['lifeExperience']) &&
            updatedCardData['lifeExperience'].length
          )
        ),
      portfolio: () =>
        !!(
          Array.isArray(updatedCardData[cardDataKey]) &&
          updatedCardData[cardDataKey].length
        ),
      behavioral: () =>
        !!(
          updatedCardData[cardDataKey].hasOwnProperty('is_done') &&
          updatedCardData[cardDataKey].is_done
        ),
    };

    return options.hasOwnProperty(cardDataKey) ? options[cardDataKey]() : false;
  };

  const controlTextTime = () => {
    if (!time && info) {
      return <Informations show={true}>{info}</Informations>;
    }

    if (time && !info) {
      return (
        <ContainerTime>
          <IconTime /> 5 minutos
        </ContainerTime>
      );
    }

    if (time && cardData[cardDataKey]?.is_enabled) {
      return (
        <ContainerTime>
          <IconTime /> 5 minutos
        </ContainerTime>
      );
    }

    if (time && !cardData[cardDataKey]?.is_enabled) {
      return (
        <ContainerTime>
          <IconTime /> 5 minutos{' '}
          <Informations show={mobile}>
            • Este questionário poderá ser refeito em{' '}
            {moment(cardData[cardDataKey]?.next_date).format('DD/MM/YYYY')}
          </Informations>
        </ContainerTime>
      );
    }
  };

  return (
    <HeaderContainer>
      <IconContainer universityColor={universityColor}>
        {IconSection}
      </IconContainer>
      <ContainerInfos disabled={disabled}>
        <Title>
          {title} {isChecked() && <IconInfoAcademicStatus />}
        </Title>

        <InfoWrapper>
          <TimeContainer>{controlTextTime()}</TimeContainer>
        </InfoWrapper>
      </ContainerInfos>
    </HeaderContainer>
  );
};

GenericHeader.propTypes = {
  universityColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  time: PropTypes.string,
  infos: PropTypes.string,
};

export default GenericHeader;
