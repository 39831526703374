import { authenticatedAPI } from 'services/api/api';

class ProfileAPI {
  getUniversityData = () => {
    return authenticatedAPI
      .get(`/api/internal/university/settings`)
      .then((res) => res)
      .catch((error) => error);
  };

  putUniversityData = (data) => {
    return authenticatedAPI
      .put(`/api/internal/university/settings`, data)
      .then((res) => res)
      .catch((error) => error);
  };

  postUniversityLogo = (data) => {
    const formData = new FormData();

    formData.append('logo', data);

    return authenticatedAPI
      .post(`/api/internal/university/settings/logo/create`, formData)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteUniversityLogo = () => {
    return authenticatedAPI
      .delete(`/api/internal/university/settings/logo/delete`)
      .then((res) => res)
      .catch((error) => error);
  };

  postImages = (data) => {
    const formData = new FormData();
    formData.append('image', data);

    return authenticatedAPI
      .post(`/api/internal/university/settings/photos/create`, formData)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteImages = (id) => {
    return authenticatedAPI
      .delete(`/api/internal/university/settings/photos/${id}`)
      .then((res) => res)
      .catch((error) => error);
  };

  getDataPreview = () => {
    return authenticatedAPI
      .get(`/api/internal/university/settings/preview`)
      .then((res) => res)
      .catch((error) => error);
  };

  getCourses = (query) => {
    return authenticatedAPI
      .get(`/api/internal/university/settings/preview/courses${query}`)
      .then((res) => res)
      .catch((error) => error);
  };
}

export default new ProfileAPI();
