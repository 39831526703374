import { authenticatedAPI } from 'services/api/api';

class sendEmailApi {
  getOptionsActiveStudents = async (egressed) => {
    const queryEgressed = egressed ? '?is_egress_menu=true' : '';

    return await authenticatedAPI
      .get(`api/internal/university/email-list/active-options${queryEgressed}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getListage = async (query, egressed) => {
    const egressQuery = egressed ? '&is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.get(
        `api/internal/university/email-list?${query} ${egressQuery}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getCampaign = async (query, egressed) => {
    const egressQuery = egressed ? '&is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.get(
        `api/internal/university/email-list/campaign?${query} ${egressQuery}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getListageDetails = async (id) => {
    return await authenticatedAPI
      .get(`api/internal/university/email-list/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  };
  getListageDetailsCampaign = async (id) => {
    return await authenticatedAPI
      .get(`api/internal/university/email-list/campaign/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getUniversityData = async (id) => {
    return await authenticatedAPI
      .get(`api/internal/university/email-list/campaign/preview-email`)
      .then((response) => response.data)
      .catch((error) => error);
  };
  getCampaignMetrics = async (id) => {
    return await authenticatedAPI
      .get(`api/internal/university/email-list/campaign/${id}/metrics`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  putEdit = async (data, id) => {
    return await authenticatedAPI
      .put(`api/internal/university/email-list/${id}`, data)
      .then((response) => response.data)
      .catch((error) => error);
  };
  getOptionsInactiveStudents = async (egressed) => {
    const queryEgressed = egressed ? '?is_egress_menu=true' : '';

    return await authenticatedAPI
      .get(
        `api/internal/university/email-list/inactive-options${queryEgressed}`
      )
      .then((response) => response.data)
      .catch((error) => error);
  };

  getStudentCount = async (query, egressed) => {
    const egressQuery = egressed ? '&is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.get(
        `api/internal/university/email-list/students/count?${query}${egressQuery}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  postCreateList = async (data, egressed) => {
    const egressQuery = egressed ? '?is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.post(
        `api/internal/university/email-list${egressQuery}`,
        data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getCitiesOptions = async (category, states) => {
    const statesQuery = `states=${states.join('&states=')}`;
    return authenticatedAPI
      .get(`/api/internal/university/email-list/${category}?${statesQuery}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getOptionsCampaign = async (egressed) => {
    const egressQuery = egressed ? '?is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.get(
        `api/internal/university/email-list/campaign/options${egressQuery}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getCountLists = async (query, egressed) => {
    const egressQuery = egressed ? '&is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.get(
        `api/internal/university/email-list/campaign/lists-total?${query}${egressQuery}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  postCreateCampaign = async (data, egressed) => {
    const egressQuery = egressed ? '?is_egress_menu=true' : '';
    try {
      const response = await authenticatedAPI.post(
        `api/internal/university/email-list/campaign${egressQuery}`,
        data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
}

export default new sendEmailApi();
