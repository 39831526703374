import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { login } from 'services/login/authenticate';
import { errors } from 'constants/errors';
import AuthContext from 'contexts/authentication';
import Button from 'components/CustomButtons/Button';
import {
  termAccept,
  getTermCompanyNotAuthenticate,
} from 'services/acceptanceTerm';
import classNames from 'classnames';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import {
  Input,
  ColorInput,
  DivColors,
  NameLabel,
  DivColorsInput,
  ButtonChangePassword,
} from './styles';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  FormHelperText,
  Grid,
  TextField,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';
import SearchableSelect from 'react-select';
import CompanyLogoUpload from 'components/CustomUpload/CompanyLogoUpload.jsx';

import { maskCnpj, maskInputPhone } from 'utils/inputMasks';
import {
  cnpjRegex,
  handleFieldMask,
  validateCNPJ,
  mobilePhoneNumberRegex,
} from 'utils/validation';
import checkFormFields from 'utils/checkFormFields';

import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { object, func, bool, string } from 'prop-types';
import {
  isCompanyRegistered,
  isUniversityExists,
  isStaffEmailExists,
  getFormOptions,
  getCompanyPreRegisterData,
  saveCompany,
  uploadLogo,
  updateCompany,
} from 'services/companies/';
import {
  companyPreRegisteredDataAdapter,
  companyAPIAdapter,
  loadCompanyAdapter,
} from 'adapters/company/companyAdapter';

import ZipCodeForm from 'components/ZipCodeForm/ZipCodeForm';
// Third party components
import StepWizard from 'react-step-wizard';
import Nav from './PaginationBullets';
import useDefineColor from 'constants/colors';
import UploadedAvatarCustom from 'components/UploadedAvatarCustom/UploadedAvatarCustom';
import SendEmail from 'views/RecoverPassword/SendEmail';

const CompanyRegistrationSchema = Yup.object().shape({
  companyAlias: Yup.string().required('Você deve informar o nome fantasia.'),
  companyName: Yup.string().required(
    'Você deve informar a razão social da empresa.'
  ),
  companyInterests: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher ao menos 1 interesse.')
    .max(5, 'Você deve escolher no máximo 5 interesses para manter o foco.'),
  organizationNumber: Yup.string()
    .matches(cnpjRegex, { message: 'Informe um CNPJ válido.' })
    .test(
      'organizationNumberValidation',
      'Informe um CNPJ válido.',
      (value) => {
        if (value) {
          return validateCNPJ(value);
        }
        return false;
      }
    )
    .required('Este campo é obrigatório.'),
  activityType: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher no mínimo 1 tipo de atividade.')
    .max(5, 'Você deve escolher no máximo 5 tipos de atividade.'),
  macroAreas: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(1, 'Você deve escolher no mínimo 1 tipo de Área de contratação')
    .max(5, 'Você deve escolher no máximo 5 tipos de Área de contratação.'),

  companyColor: Yup.string()
    .notRequired()
    .test(
      'companyColorValidation',
      'Informe uma cor hexadecimal válida',
      function (value) {
        if (!value || value.length === 0) {
          return true;
        }
        return /^#([0-9a-f]{3}){1,2}\b/i.test(value);
      }
    ),
  companySkills: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher 1 competência.')
    .max(4, 'Você deve escolher 4 competências.'),
  companyValues: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher 1 valor.')
    .max(3, 'Você deve escolher 3 valores.'),
  companyType: Yup.string()
    .required('Você deve escolher 1 tipo da empresa.')
    .typeError('Você deve escolher 1 tipo da empresa.'),
  companySize: Yup.string()
    .required('Você deve escolher 1 porte da empresa.')
    .typeError('Você deve escolher 1 porte da empresa.'),
  companyDescription: Yup.string().required('Este campo é obrigatório.'),
  zipCode: Yup.string()
    .min(9, 'O CEP deve conter 8 dígitos.')
    .max(9, 'O CEP deve conter 8 dígitos.')
    .required('Informação Obrigatória'),
  street: Yup.string().required('Informação Obrigatória'),
  city: Yup.string().required('Informação Obrigatória.'),
  addressState: Yup.string().required('Informação Obrigatória'),
  district: Yup.string().required('Informação Obrigatória'),
  addressNumber: Yup.number()
    .typeError('Precisa ser um número inteiro. Ex: 40')
    .required('Informação Obrigatória'),
  staffName: Yup.string().required(
    'Por gentileza, informe o nome do responsável pela empresa.'
  ),
  staffRole: Yup.object().shape({
    label: Yup.string()
      .required('Selecione uma opção')
      .typeError('Por gentileza, informe o nome do responsável pela empresa.'),
    value: Yup.string().required('Selecione uma opção'),
  }),
  staffEmail: Yup.string()
    .required('Este campo é obrigatório')
    .email('Você deve informar um e-mail válido.'),
  staffPhone: Yup.string()
    .matches(mobilePhoneNumberRegex, 'Informe um número de telefone válido.')
    .required('Este campo é obrigatório.'),

  staffPasswordConfirmation: Yup.string().oneOf(
    [Yup.ref('staffPassword'), null],
    'Repita a senha digitada acima para prosseguir.'
  ),
  website: Yup.string()
    .notRequired()
    .max(512, 'O site deve ter no máximo 512 caracteres')
    .nullable(),
  useIntegrationAgent: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.boolean(),
    })
    .nullable(),
  staffSector: Yup.object().shape({
    label: Yup.string()
      .required('Selecione uma opção')
      .typeError('Por gentileza, informe a área de atuação.'),
    value: Yup.string().required('Selecione uma opção'),
  }),
});

class CompanyRegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.hasAnotherRole = false;
    this.openModalWithContent = props.openModalWithContent;
    this.state = {
      formMount: false,
      acceptedPolitics: false,
      companyActitivies: [],
      companyInterests: [],
      companySkills: [],
      companyValues: [],
      companyTypes: [],
      companySizes: [],
      isCreateCompany: false,
      secondPageFields: [
        'staffRole',
        'staffPhone',
        'staffName',
        'staffEmail',
        'companyInterests',
        'companySkills',
        'companyValues',
      ],
      firstPageFields: [
        'companyAlias',
        'companyName',
        'organizationNumber',
        'website',
        'activityType',
        'companyType',
        'macroAreas',
        'companySize',
        'companyDescription',
        'zipCode',
        'street',
        'addressNumber',
        'complement',
        'district',
        'city',
        'addressState',
      ],
      companyLogo: null,
      origin: '',
      termUrl: '',
    };
    this.wizard = React.createRef();
    this.wizardHolder = React.createRef();
  }

  async componentDidMount() {
    const { state, search } = this.props.location;
    const { id } = this.props.match.params;
    if (id && search) {
      await this.getCompanyPreRegister(id);
      this.setState({ isCreateCompany: true });
    } else if (id) {
      this.setState({ origin: id });
      const response = await isUniversityExists(id);
      !response.exists && this.handleInvalidLink();
    }

    if (state && state.hasOwnProperty('organizationNumber')) {
      this.setState({
        initialValues: {
          organizationNumber: state.organizationNumber,
        },
      });
    }

    const { companyUpdate, universityColor } = this.props;

    const formOptions = await getFormOptions();
    const term = await getTermCompanyNotAuthenticate();
    const companyInterests = formOptions.interests;
    const activitiesType = formOptions.sectors;
    const companyTypes = formOptions.company_types;
    const companySizes = formOptions.company_sizes;
    const companySkills = formOptions.company_skills;
    const companyValues = formOptions.company_values;
    const staffRoles = formOptions.staff_roles;
    const companyMacroAreas = formOptions.company_macro_areas;
    const staffSector = formOptions.staff_sectors;

    this.setState({
      companyInterests,
      companyActitivies: activitiesType,
      companyTypes,
      companySizes,
      companySkills,
      companyValues,
      validateToNextStepOnForm: '',
      staffRoles,
      staffSector,
      companyMacroAreas: companyMacroAreas,
      termUrl: term.file_term,
      initialValues: {
        avatar: '',
        companyInterests: [],
        companySkills: [],
        companyTypes: [],
        companySizes: [],
        companyValues: [],
        staffRoles: [],
        staffSector: [],
        companyAlias: '',
        companyName: '',
        organizationNumber: '',
        website: '',
        activityType: [],
        companyType: '',
        macroAreas: [],
        companySize: '',
        companyDescription: '',
        staffName: '',
        staffEmail: '',
        staffPhone: '',
        staffRole: '',
        staffAnotherRole: '',
        staffPassword: '',
        staffPasswordConfirmation: '',
        city: '',
        street: '',
        addressNumber: '',
        addressState: '',
        district: '',
        complement: '',
        zipCode: '',
        id: '',
        origin: this.state.origin,
        companyColor: '#D9D9D9',
        macroAreas: [],
        useIntegrationAgent: {
          label: companyUpdate.use_integration_agent ? 'Sim' : 'Não',
          value: companyUpdate.use_integration_agent ? true : false,
        },
        ...this.state.initialValues,
      },
    });

    if (companyUpdate) {
      this.clearValidationField();
      const companyReponse = loadCompanyAdapter(companyUpdate);

      this.setState({
        acceptedPolitics: true,
        initialValues: {
          ...this.state.initialValues,
          companyName: companyReponse.companyName,
          companyAlias: companyReponse.companyAlias,
          organizationNumber: companyReponse.organizationNumber,
          website: companyReponse.website,
          companyDescription: companyReponse.companyDescription,
          city: companyReponse.city,
          street: companyReponse.street,
          addressNumber: companyReponse.addressNumber,
          addressState: companyReponse.addressState,
          district: companyReponse.district,
          complement: companyReponse.complement,
          zipCode: companyReponse.zipCode,
          companyType: companyReponse.companyType,
          activityType: companyReponse.activityType,
          companySize: companyReponse.companySize,
          companyInterests: companyReponse.companyInterests,
          companySkills: companyReponse.companySkills,
          companyTypes: companyReponse.companyTypes,
          companySizes: companyReponse.companySizes,
          companyValues: companyReponse.companyValues,
          staffRoles: companyReponse.staffRoles,
          staffSector: companyReponse.staffSector,
          staffName: companyReponse.staffName,
          staffEmail: companyReponse.staffEmail,
          staffPhone: companyReponse.staffPhone,
          staffRole: companyReponse.staffRole,
          staffAnotherRole: companyReponse.staffAnotherRole,
          avatar: companyReponse.logo,
          companyColor: companyReponse.companyColor,
          macroAreas: companyReponse.macroAreas,
        },
      });
    }
    this.setState({ formMount: true });
  }

  clearValidationField = () => {
    const removeCnpj = this.state.firstPageFields.filter(
      (el) => el !== 'organizationNumber'
    );
    this.setState({
      firstPageFields: removeCnpj,
    });
  };

  handleInvalidLink = () => {
    this.props.openDnaDialog(
      'Link de registro inválido',
      'Você deseja continuar o seu cadastro mesmo assim?',
      'Sim',
      () => {
        this.props.history.push('/empresas/cadastro');
      },
      'Não',
      () => {
        this.props.handleIsLoadingState(true);
        setTimeout(() => this.props.history.push('/#/login'), 700);
      }
    );
  };

  getCompanyPreRegister = async (id) => {
    let companyPreRegister = await getCompanyPreRegisterData(id);

    if (companyPreRegister.message) {
      this.handleInvalidLink();
    } else {
      companyPreRegister = companyPreRegisteredDataAdapter(companyPreRegister);
      this.setState({
        initialValues: {
          addressNumber: companyPreRegister.addressNumber,
          addressState: companyPreRegister.addressState,
          city: companyPreRegister.city,
          companyName: companyPreRegister.companyName,
          complement: companyPreRegister.complement,
          district: companyPreRegister.district,
          inviteId: companyPreRegister.inviteId,
          organizationNumber: companyPreRegister.organizationNumber,
          staffEmail: companyPreRegister.staffEmail,
          staffName: companyPreRegister.staffName,
          staffRole: companyPreRegister.staffRole,
          staffPhone: companyPreRegister.staffPhone,
          street: companyPreRegister.street,
          validationToken: companyPreRegister.validationToken,
          zipCode: companyPreRegister.zipCode,
          staffSector: companyPreRegister.staffSector,
        },
      });
    }
  };

  validateAnotherRole(value) {
    let error;
    if (!value) {
      return 'Por gentileza, informe a área que atua na empresa.';
    }
    return error;
  }

  cnpjExists = async (value) => {
    let exists;
    const { companyUpdate } = this.props;
    if (value && !companyUpdate) {
      const isRegistered = await isCompanyRegistered(value);
      if (isRegistered.message) {
        this.props.openSnackbarFn(
          `Erro ${isRegistered.message} ao consultar CNPJ. Entre em contato com o suporte.`,
          true
        );
        return false;
      }
      if (isRegistered.exists) {
        exists = 'Este CNPJ já consta em nossos registros!';
        return exists;
      }
    }
  };

  validPassWordConfirm = (value) => {
    let required;
    const { companyUpdate } = this.props;
    if (!companyUpdate && !value) {
      required = 'Este campo é obrigatório.';
      return required;
    }
  };

  requiredStaffPassword = (value) => {
    let required;
    const { companyUpdate } = this.props;
    if (value && value.length < 8) {
      required = 'Sua senha precisar ter no mínimo 8 caracteres.';
      return required;
    }
    if (!companyUpdate && !value) {
      required = 'Este campo é obrigatório.';
      return required;
    }
  };

  validateEmail = async (value) => {
    let exists;
    if (value && value !== this.state.initialValues.staffEmail) {
      const isRegistered = await isStaffEmailExists(value);
      if (isRegistered.message) {
        this.props.openSnackbarFn(
          `Erro ${isRegistered.message} ao consultar o Email. Entre em contato com o suporte.`,
          true
        );
        return false;
      }
      if (isRegistered.exists) {
        exists =
          'Esse e-mail já está vinculado a uma empresa cadastrada na Workalove!';
        return exists;
      }
    }
  };

  onStepChange = () => {
    /** TODO: Do this better */
    const container = ReactDOM.findDOMNode(this.wizardHolder.current);
    setTimeout(() => {
      container.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 800);
  };

  acceptTerms = ({ target }) => {
    this.setState({
      acceptedPolitics: target.checked,
    });
  };

  setFile = (file) => {
    this.setState({
      companyLogo: file,
    });
  };

  render() {
    const {
      classes,
      isEdit,
      openSnackbar,
      handleIsLoadingState,
      universityColor,
      openDnaDialog,
      companyUpdate,
    } = this.props;

    return (
      <Grid
        container
        className={classes.fullScreenGridContainer}
        ref={this.wizardHolder}
      >
        <AuthContext.Consumer>
          {({ routerOnUserLogin }) => {
            return (
              <Grid
                item
                xs
                md={12}
                className={classes.fullScreenGridCompanyRegistration}
              >
                {this.state.initialValues && (
                  <Formik
                    enableReinitialize
                    initialValues={this.state.initialValues}
                    validationSchema={CompanyRegistrationSchema}
                    validateOnChange={false}
                    onSubmit={async (values, actions) => {
                      if (companyUpdate) {
                        handleIsLoadingState(true);
                        delete values.organizationNumber;
                        delete values.staffPassword;
                        delete values.staffPasswordConfirmation;
                        if (values.companyColor === '') {
                          values.companyColor = '#D9D9D9';
                        }

                        if (
                          this.state.initialValues.staffEmail ===
                          values.staffEmail
                        ) {
                          delete values.staffEmail;
                        }
                        const update = await updateCompany(
                          companyAPIAdapter(values)
                        );

                        if (update.message) {
                          openSnackbar(
                            `Erro ao atualizar os dados. Se o erro persistir, entre em contato com o suporte avisando sobre o erro: ${update.message}`,
                            true
                          );
                        } else if (this.state.companyLogo) {
                          await uploadLogo(this.state.companyLogo);
                        }
                        this.props.history.push('/empresas/meu-perfil');
                        actions.setSubmitting(false);
                        window.location.reload();
                        return;
                      }

                      const newCompany = await saveCompany(
                        companyAPIAdapter(values)
                      );

                      if (newCompany.message) {
                        openSnackbar(
                          `Erro ao gravar seus dados. Se o erro persistir, entre em contato com o suporte avisando sobre o erro: ${newCompany.message}`,
                          true
                        );
                        actions.setSubmitting(false);
                        this.props.handleIsLoadingState(false);
                        return;
                      }
                      actions.setSubmitting(false);
                      handleIsLoadingState(true);
                      setTimeout(async () => {
                        const loginUrl = '/login/empresa';
                        const data = {
                          username: values.staffEmail,
                          password: values.staffPassword,
                          company: true,
                        };

                        const loginUser = await login(loginUrl, data);
                        if (loginUser.isLogged) {
                          if (this.state.companyLogo) {
                            await uploadLogo(this.state.companyLogo);
                          }
                          actions.setSubmitting(false);
                          termAccept({ role: 'company' });
                          setTimeout(() => {
                            routerOnUserLogin(loginUser.data);
                          }, 600);
                        } else {
                          const message = errors.hasOwnProperty(
                            loginUser.data.code
                          )
                            ? errors[loginUser.data.code]
                            : 'Não foi possível realizar o seu login. Tente novamente';
                          handleIsLoadingState(false);
                          openSnackbar(message, true);
                        }
                        actions.setSubmitting(false);
                        handleIsLoadingState(false);
                      }, 500);
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                      isSubmitting,
                    }) => (
                      <form
                        onSubmit={async (event) => {
                          event.preventDefault();
                          if (Object.keys(errors).length > 0) {
                            if (Object.keys(errors)[0] === 'staffEmail') {
                              openSnackbar(
                                'Esse e-mail já está vinculado a uma empresa cadastrada na Workalove!',
                                true
                              );
                            } else {
                              await checkFormFields(
                                this.state.secondPageFields,
                                setFieldTouched,
                                errors
                              );
                              openSnackbar(
                                'Erro! Verifique os campos do formulário.',
                                true
                              );
                            }
                          } else {
                            handleSubmit();
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                          }
                        }}
                      >
                        {isEdit && (
                          <input
                            type="hidden"
                            id="inviteId"
                            name="inviteId"
                            value={values.inviteId}
                          />
                        )}
                        <input
                          type="hidden"
                          id="validationToken"
                          name="validationToken"
                          value={values.validationToken}
                        />
                        <input
                          type="hidden"
                          id="origin"
                          name="origin"
                          value={values.origin}
                        />
                        <List className={classes.list}>
                          <StepWizard
                            ref={this.wizard}
                            nav={
                              <Nav
                                classes={classes}
                                openSnackbar={openSnackbar}
                                checkForm={() =>
                                  checkFormFields(
                                    this.state.firstPageFields,
                                    setFieldTouched,
                                    errors
                                  )
                                }
                              />
                            }
                            onStepChange={this.onStepChange}
                          >
                            <div>
                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <Grid
                                  container
                                  row
                                  className={classes.infoCompanyContainer}
                                >
                                  <Grid item md={7}>
                                    <List>
                                      {/* Company alias */}
                                      <ListItem
                                        className={
                                          classes.listItemCompanyRegistration
                                        }
                                      >
                                        <h5 className={classes.listTitle}>
                                          Sobre a empresa
                                        </h5>
                                        <FastField
                                          autoFocus
                                          className={classes.inputField}
                                          InputProps={{
                                            className: classes.input,
                                          }}
                                          InputLabelProps={{
                                            FormLabelClasses: {
                                              focused: classes.inputField,
                                            },
                                          }}
                                          name="companyAlias"
                                          variant="filled"
                                          id="companyAlias"
                                          label="Nome comercial (como sua empresa é conhecida)"
                                          placeholder="Ex.: Workalove"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={
                                            errors.companyAlias &&
                                            touched.companyAlias
                                          }
                                          value={values.companyAlias}
                                          component={TextField}
                                          required
                                        />
                                        {errors.companyAlias &&
                                        touched.companyAlias ? (
                                          <FormHelperText
                                            className={classes.helperText}
                                          >
                                            {errors.companyAlias}
                                          </FormHelperText>
                                        ) : null}
                                      </ListItem>

                                      {/* Company social name */}
                                      <ListItem
                                        className={
                                          classes.listItemCompanyRegistration
                                        }
                                      >
                                        <FastField
                                          className={classes.inputField}
                                          InputProps={{
                                            className: classes.input,
                                          }}
                                          InputLabelProps={{
                                            FormLabelClasses: {
                                              focused: classes.inputField,
                                            },
                                          }}
                                          name="companyName"
                                          variant="filled"
                                          id="companyName"
                                          label="Razão social"
                                          placeholder="Ex.: DNA da Educação"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={
                                            errors.companyName &&
                                            touched.companyName
                                          }
                                          value={values.companyName}
                                          component={TextField}
                                          required
                                        />
                                        {errors.companyName &&
                                        touched.companyName ? (
                                          <FormHelperText
                                            className={classes.helperText}
                                          >
                                            {errors.companyName}
                                          </FormHelperText>
                                        ) : null}
                                      </ListItem>

                                      {/* Organization Number */}

                                      <ListItem
                                        className={
                                          classes.listItemCompanyRegistration
                                        }
                                      >
                                        <FastField
                                          className={classes.inputField}
                                          InputProps={{
                                            className: classes.input,
                                          }}
                                          InputLabelProps={{
                                            FormLabelClasses: {
                                              focused: classes.inputField,
                                            },
                                          }}
                                          disabled={!!isEdit || companyUpdate}
                                          name="organizationNumber"
                                          variant="filled"
                                          id="organizationNumber"
                                          label="CNPJ"
                                          placeholder="Ex.: 74.449.408/0001-81"
                                          onKeyUp={(event) => {
                                            handleFieldMask(event, maskCnpj);
                                            handleChange(event);
                                          }}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={
                                            !!errors.organizationNumber &&
                                            !!touched.organizationNumber
                                          }
                                          value={values.organizationNumber}
                                          component={TextField}
                                          validate={this.cnpjExists}
                                          required
                                        />
                                        {!!errors.organizationNumber &&
                                          !!touched.organizationNumber && (
                                            <FormHelperText
                                              className={classes.helperText}
                                            >
                                              {errors.organizationNumber}
                                            </FormHelperText>
                                          )}
                                      </ListItem>

                                      {/* Website */}
                                      <ListItem
                                        className={
                                          classes.listItemCompanyRegistration
                                        }
                                      >
                                        <FastField
                                          className={classes.inputField}
                                          InputProps={{
                                            className: classes.input,
                                          }}
                                          InputLabelProps={{
                                            FormLabelClasses: {
                                              focused: classes.inputField,
                                            },
                                          }}
                                          disabled={!!isEdit}
                                          name="website"
                                          variant="filled"
                                          id="website"
                                          label="Website"
                                          placeholder="Ex.: www.website.com.br"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={
                                            !!errors.website &&
                                            !!touched.website
                                          }
                                          value={values.website}
                                          component={TextField}
                                        />
                                        {!!errors.website &&
                                          !!touched.website && (
                                            <FormHelperText
                                              className={classes.helperText}
                                            >
                                              {errors.website}
                                            </FormHelperText>
                                          )}
                                      </ListItem>
                                      <ListItem
                                        className={
                                          classes.listItemCompanyRegistration
                                        }
                                      >
                                        {!this.state.isCreateCompany && (
                                          <div
                                            style={{
                                              width: '100%',
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                          >
                                            <DivColors>
                                              <NameLabel>
                                                Principal cor da marca
                                              </NameLabel>
                                              <DivColorsInput>
                                                <ColorInput
                                                  type="color"
                                                  value={values.companyColor}
                                                  onChange={(event) => {
                                                    const {
                                                      value,
                                                    } = event.target;
                                                    handleChange(
                                                      'companyColor'
                                                    )(value);
                                                  }}
                                                />
                                                <Input
                                                  value={values.companyColor}
                                                  onChange={(event) => {
                                                    const {
                                                      value,
                                                    } = event.target;
                                                    handleChange(
                                                      'companyColor'
                                                    )(value);
                                                  }}
                                                  isColorInput={true}
                                                />
                                              </DivColorsInput>
                                            </DivColors>
                                            {!!errors.companyColor &&
                                              !!touched.companyColor && (
                                                <FormHelperText
                                                  style={{ marginLeft: 0 }}
                                                  className={classes.helperText}
                                                >
                                                  {errors.companyColor}
                                                </FormHelperText>
                                              )}
                                          </div>
                                        )}
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid
                                    item
                                    md={5}
                                    className={classes.companyLogoHolder}
                                  >
                                    <UploadedAvatarCustom
                                      setFile={this.setFile}
                                      widthImageDisplay="200px"
                                      heightImageDisplay="200px"
                                      imageUrl={values.avatar}
                                      radius="4px"
                                    />
                                  </Grid>
                                </Grid>
                              </ListItem>
                              {/* Activitie type */}
                              {((companyUpdate && values.activityType.length) ||
                                !companyUpdate ||
                                this.state.formMount) && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="activityType"
                                      shrink
                                      error={
                                        errors.activityType &&
                                        touched.activityType
                                      }
                                      required
                                    >
                                      Tipo de atividade
                                    </InputLabel>
                                    <SearchableSelect
                                      name="activityType"
                                      id="activityType"
                                      isClearable={false}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      isMulti
                                      multiline
                                      rows={3}
                                      placeholder="Selecione"
                                      isSearchable
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'activityType',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          setFieldValue('activityType', event);
                                        } else {
                                          setFieldValue('activityType', '');
                                        }
                                      }}
                                      options={
                                        this.state.companyActitivies || []
                                      }
                                      defaultValue={values.activityType}
                                    />
                                  </FormControl>
                                  {!!errors.activityType &&
                                    !!touched.activityType && (
                                      <FormHelperText
                                        style={{ marginLeft: 35 }}
                                        className={classes.helperText}
                                      >
                                        {errors.activityType}
                                      </FormHelperText>
                                    )}
                                </ListItem>
                              )}
                              {/* Company type */}
                              {((companyUpdate && values.companyType) ||
                                !companyUpdate ||
                                this.state.formMount) && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="companyType"
                                      shrink
                                      error={
                                        errors.companyType &&
                                        touched.companyType
                                      }
                                      required
                                    >
                                      Tipo da empresa
                                    </InputLabel>
                                    <SearchableSelect
                                      name="companyType"
                                      id="companyType"
                                      isClearable={false}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      rows={3}
                                      placeholder="Selecione"
                                      isSearchable
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'companyType',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          setFieldValue('companyType', event);
                                        } else {
                                          setFieldValue('companyType', '');
                                        }
                                      }}
                                      options={this.state.companyTypes || []}
                                      defaultValue={values.companyType}
                                    />
                                  </FormControl>
                                  {!!errors.companyType &&
                                    !!touched.companyType && (
                                      <FormHelperText
                                        style={{ marginLeft: 35 }}
                                        className={classes.helperText}
                                      >
                                        {errors.companyType}
                                      </FormHelperText>
                                    )}
                                </ListItem>
                              )}
                              {/* Company size */}
                              {((companyUpdate && values.companySize) ||
                                !companyUpdate ||
                                this.state.formMount) && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="companyType"
                                      shrink
                                      error={
                                        errors.companySize &&
                                        touched.companySize
                                      }
                                      required
                                    >
                                      Porte da empresa
                                    </InputLabel>
                                    <SearchableSelect
                                      name="companySize"
                                      id="companySize"
                                      isClearable={false}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      rows={3}
                                      placeholder="Selecione"
                                      isSearchable
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'companySize',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          setFieldValue('companySize', event);
                                        } else {
                                          setFieldValue('companySize', '');
                                        }
                                      }}
                                      options={this.state.companySizes || []}
                                      defaultValue={values.companySize}
                                    />
                                  </FormControl>
                                  {!!errors.companySize &&
                                    !!touched.companySize && (
                                      <FormHelperText
                                        style={{ marginLeft: 35 }}
                                        className={classes.helperText}
                                      >
                                        {errors.companySize}
                                      </FormHelperText>
                                    )}
                                </ListItem>
                              )}
                              {/* macro areas */}
                              {((companyUpdate && values.macroAreas.length) ||
                                !companyUpdate ||
                                this.state.formMount) && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="macroAreas"
                                      shrink
                                      error={
                                        errors.activityType &&
                                        touched.activityType
                                      }
                                      required
                                    >
                                      Áreas com mais contratação
                                    </InputLabel>
                                    <SearchableSelect
                                      name="macroAreas"
                                      id="macroAreas"
                                      isClearable={false}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      isMulti
                                      multiline
                                      rows={3}
                                      placeholder="Selecione"
                                      isSearchable
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'macroAreas',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          setFieldValue('macroAreas', event);
                                        } else {
                                          setFieldValue('macroAreas', '');
                                        }
                                      }}
                                      options={
                                        this.state.companyMacroAreas || []
                                      }
                                      defaultValue={values.macroAreas}
                                    />
                                  </FormControl>
                                  {!!errors.macroAreas && !!touched.macroAreas && (
                                    <FormHelperText
                                      style={{ marginLeft: 0 }}
                                      className={classes.helperText}
                                    >
                                      {errors.macroAreas}
                                    </FormHelperText>
                                  )}
                                </ListItem>
                              )}

                              {/* Company description */}
                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <FastField
                                  className={classes.inputField}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                  InputLabelProps={{
                                    FormLabelClasses: {
                                      focused: classes.inputField,
                                    },
                                  }}
                                  name="companyDescription"
                                  variant="filled"
                                  id="companyDescription"
                                  label="Descrição da empresa"
                                  placeholder="Ex.: Software e Serviços"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    !!errors.companyDescription &&
                                    !!touched.companyDescription
                                  }
                                  value={values.companyDescription?.replace(
                                    /<\/?[^>]+(>|$)/g,
                                    ''
                                  )}
                                  component={TextField}
                                  multiline
                                  required
                                />
                                {!!errors.companyDescription &&
                                  !!touched.companyDescription && (
                                    <FormHelperText
                                      className={classes.helperText}
                                    >
                                      {errors.companyDescription}
                                    </FormHelperText>
                                  )}
                                <Divider
                                  height={1}
                                  className={classes.divider}
                                />
                              </ListItem>
                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <h5 className={classes.listTitle}>
                                  Endereço da empresa
                                </h5>
                                <Grid container>
                                  <ZipCodeForm
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    setFieldError={setFieldError}
                                    setFieldTouched={setFieldTouched}
                                  />
                                </Grid>
                                <Divider
                                  height={1}
                                  className={classes.divider}
                                />
                                <Button
                                  id="submitZipCode"
                                  className={classes.zipCodeButton}
                                  style={{ borderRadius: 4 }}
                                  round
                                  onClick={async () => {
                                    if (
                                      this.state.validateToNextStepOnForm !== ''
                                    ) {
                                      return openSnackbar(
                                        'Não foi possível fazer o upload da imagem. Verifique os erros em destaque e tente novamente.',
                                        true
                                      );
                                    }
                                    (await checkFormFields(
                                      this.state.firstPageFields,
                                      setFieldTouched,
                                      errors
                                    ))
                                      ? openSnackbar(
                                          'Preencha todos os campos obrigatórios.',
                                          true
                                        )
                                      : this.wizard.current.nextStep();
                                  }}
                                >
                                  Continuar
                                </Button>
                              </ListItem>
                            </div>
                            <div id="registrationPage2">
                              <span
                                style={{
                                  fontSize: 17,
                                  fontWeight: 500,
                                  color: '#606062',
                                  marginLeft: 4,
                                  marginBottom: 10,
                                }}
                              >
                                Dados de acesso
                              </span>

                              {/* staff name */}
                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <FastField
                                  className={classes.inputField}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                  InputLabelProps={{
                                    FormLabelClasses: {
                                      focused: classes.inputField,
                                    },
                                  }}
                                  name="staffName"
                                  variant="filled"
                                  id="staffName"
                                  label="Nome do responsável da empresa"
                                  placeholder="Ex.: Seu nome e Sobrenome"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    !!errors.staffName && !!touched.staffName
                                  }
                                  value={values.staffName}
                                  component={TextField}
                                  required
                                />
                                {errors.staffName && touched.staffName && (
                                  <FormHelperText
                                    className={classes.helperText}
                                  >
                                    {errors.staffName}
                                  </FormHelperText>
                                )}
                              </ListItem>

                              {/* staff role */}
                              {((companyUpdate && values.staffRole) ||
                                !companyUpdate ||
                                this.state.formMount) && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="staffRole"
                                      shrink
                                      error={
                                        errors.staffRole && touched.staffRole
                                      }
                                      required
                                    >
                                      Cargo
                                    </InputLabel>
                                    <SearchableSelect
                                      name="staffRole"
                                      id="staffRole"
                                      isClearable={false}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      rows={3}
                                      placeholder="Ex.: Fundador(a), Diretor(a), etc..."
                                      isSearchable
                                      required
                                      defaultValue={(() => {
                                        const defaultRole = values?.staffRole;
                                        if (defaultRole?.value === null) return;
                                        if (
                                          defaultRole &&
                                          defaultRole.value === 'another_area'
                                        ) {
                                          this.hasAnotherRole = true;
                                        } else {
                                          this.hasAnotherRole = false;
                                        }
                                        return defaultRole;
                                      })()}
                                      error={
                                        !!errors.staffRole &&
                                        !!touched.staffRole
                                      }
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                        classes.inputField,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'staffRole',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          this.hasAnotherRole =
                                            event.value.toUpperCase() ===
                                            'ANOTHER_AREA';
                                          setFieldValue('staffRole', event);
                                        } else {
                                          setFieldTouched(
                                            'staffRole',
                                            event.label
                                          );
                                        }
                                      }}
                                      options={this.state.staffRoles || []}
                                    />
                                  </FormControl>
                                  {errors.staffRole && touched.staffRole && (
                                    <FormHelperText
                                      className={classes.helperText}
                                    >
                                      {errors.staffRole.label}
                                    </FormHelperText>
                                  )}
                                </ListItem>
                              )}
                              {companyUpdate && this.state.formMount && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="staffSector"
                                      shrink
                                      error={
                                        errors.staffSector &&
                                        touched.staffSector
                                      }
                                      required
                                    >
                                      Setor
                                    </InputLabel>
                                    <SearchableSelect
                                      name="staffSector"
                                      id="staffSector"
                                      isClearable={false}
                                      InputLabel={{ shrink: true }}
                                      rows={3}
                                      placeholder="Ex.: Marketing, Tecnologia, etc..."
                                      isSearchable
                                      required
                                      defaultValue={(() => {
                                        const defaultSector =
                                          values?.staffSector;
                                        if (defaultSector?.value === null)
                                          return;
                                        if (
                                          defaultSector &&
                                          defaultSector.value === 'another_area'
                                        ) {
                                          this.hasAnotherRole = true;
                                        } else {
                                          this.hasAnotherRole = false;
                                        }
                                        return defaultSector;
                                      })()}
                                      error={
                                        !!errors.staffSector &&
                                        !!touched.staffSector
                                      }
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                        classes.inputField,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'staffSector',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          this.hasAnotherRole =
                                            event.value.toUpperCase() ===
                                            'ANOTHER_AREA';
                                          setFieldValue('staffSector', event);
                                        } else {
                                          setFieldTouched(
                                            'staffSector',
                                            event.label
                                          );
                                        }
                                      }}
                                      options={this.state.staffSector || []}
                                    />
                                  </FormControl>
                                  {errors.staffSector &&
                                    touched.staffSector && (
                                      <FormHelperText
                                        className={classes.helperText}
                                      >
                                        {errors.staffSector.label}
                                      </FormHelperText>
                                    )}
                                </ListItem>
                              )}

                              {/* staff another role */}
                              {this.hasAnotherRole && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <FastField
                                    className={classes.inputField}
                                    InputProps={{
                                      className: classes.input,
                                    }}
                                    InputLabelProps={{
                                      FormLabelClasses: {
                                        focused: classes.inputField,
                                      },
                                    }}
                                    name="staffAnotherRole"
                                    variant="filled"
                                    id="staffAnotherRole"
                                    label="Qual área? *"
                                    placeholder="Ex.: Marketing, Tecnologia, etc..."
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      !!errors.staffAnotherRole &&
                                      !!touched.staffAnotherRole
                                    }
                                    value={values.staffAnotherRole}
                                    component={TextField}
                                    validate={this.validateAnotherRole}
                                  />
                                  {errors.staffAnotherRole &&
                                    touched.staffAnotherRole && (
                                      <FormHelperText
                                        className={classes.helperText}
                                      >
                                        {errors.staffAnotherRole}
                                      </FormHelperText>
                                    )}
                                </ListItem>
                              )}

                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <FastField
                                  className={classes.inputField}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                  InputLabelProps={{
                                    FormLabelClasses: {
                                      focused: classes.inputField,
                                    },
                                  }}
                                  name="staffEmail"
                                  variant="filled"
                                  id="staffEmail"
                                  label="E-mail (este é o seu e-mail para acessar a plataforma)"
                                  placeholder="Ex.: seu.nome@dominio.com.br"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  validate={this.validateEmail}
                                  error={
                                    !!errors.staffEmail && !!touched.staffEmail
                                  }
                                  value={values.staffEmail}
                                  component={TextField}
                                  required
                                />
                                {errors.staffEmail && touched.staffEmail && (
                                  <FormHelperText
                                    className={classes.helperText}
                                  >
                                    {errors.staffEmail}
                                  </FormHelperText>
                                )}
                              </ListItem>

                              {/* Staff e-mail */}
                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <FastField
                                  className={classes.inputField}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                  InputLabelProps={{
                                    FormLabelClasses: {
                                      focused: classes.inputField,
                                    },
                                  }}
                                  name="staffEmail"
                                  variant="filled"
                                  id="staffEmail"
                                  label="E-mail do responsável"
                                  placeholder="Ex.: seu.nome@dominio.com.br"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  validate={this.validateEmail}
                                  error={
                                    !!errors.staffEmail && !!touched.staffEmail
                                  }
                                  value={values.staffEmail}
                                  component={TextField}
                                  required
                                />
                                {errors.staffEmail && touched.staffEmail && (
                                  <FormHelperText
                                    className={classes.helperText}
                                  >
                                    {errors.staffEmail}
                                  </FormHelperText>
                                )}
                              </ListItem>

                              {/* mobilePhone */}
                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <FastField
                                  className={classes.inputField}
                                  InputProps={{
                                    className: classes.input,
                                    ref: this.staffPhoneRef,
                                  }}
                                  InputLabelProps={{
                                    FormLabelClasses: {
                                      focused: classes.inputField,
                                    },
                                  }}
                                  name="staffPhone"
                                  variant="filled"
                                  id="staffPhone"
                                  label="Celular ou Telefone Fixo*"
                                  placeholder="(11) 99163-8247"
                                  onChange={(event) => {
                                    event.target.value = maskInputPhone(
                                      event.target.value
                                    );
                                    handleChange(event);
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    !!errors.staffPhone && !!touched.staffPhone
                                  }
                                  value={values.staffPhone}
                                  component={TextField}
                                />
                                {!!errors.staffPhone &&
                                  !!touched.staffPhone && (
                                    <FormHelperText
                                      className={classes.helperText}
                                    >
                                      {errors.staffPhone}
                                    </FormHelperText>
                                  )}
                                <Divider
                                  height={1}
                                  className={classes.divider}
                                />
                              </ListItem>

                              <div
                                style={{
                                  display: 'flex',
                                  position: 'relative',
                                }}
                              >
                                <FastField
                                  className={classes.inputField}
                                  InputProps={{
                                    className: classes.input,
                                    style: {
                                      backgroundColor: '#FFF',
                                    },
                                  }}
                                  InputLabelProps={{
                                    FormLabelClasses: {
                                      focused: classes.inputField,
                                    },
                                  }}
                                  name="mockPassword"
                                  variant="filled"
                                  id="mockPassword"
                                  type="password"
                                  label="*********"
                                  placeholder="Digite a senha mockada"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.mockPassword}
                                  component={TextField}
                                  disabled
                                />
                                <ButtonChangePassword
                                  onClick={() => {
                                    this.openModalWithContent(
                                      <SendEmail
                                        isCompanyConfig
                                        companyUsername={
                                          companyUpdate?.staff?.email
                                        }
                                      />
                                    );
                                  }}
                                >
                                  TROCAR SENHA
                                </ButtonChangePassword>
                              </div>

                              {/* Area */}
                              {((companyUpdate &&
                                values.companyInterests.length) ||
                                !companyUpdate ||
                                this.state.formMount) && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <h5 className={classes.listTitle}>
                                    Áreas de interesse
                                  </h5>
                                  <p className={classes.paragraph}>
                                    Essas informações serão direcionadas ao
                                    setor acadêmico das instituições de ensino
                                    parceiras para que elas possam trabalhar os
                                    temas com seus estudantes e docentes
                                    (pesquisas).
                                  </p>
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="companyInterests"
                                      shrink
                                      error={
                                        errors.companyInterests &&
                                        touched.companyInterests
                                      }
                                      required
                                    >
                                      Áreas de interesse
                                    </InputLabel>
                                    <SearchableSelect
                                      name="companyInterests"
                                      id="companyInterests"
                                      isClearable={false}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      isMulti
                                      multiline
                                      rows={3}
                                      placeholder="Selecione"
                                      isSearchable
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'companyInterests',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          setFieldValue(
                                            'companyInterests',
                                            event
                                          );
                                        } else {
                                          setFieldValue('companyInterests', '');
                                        }
                                      }}
                                      options={
                                        this.state.companyInterests || []
                                      }
                                      defaultValue={values.companyInterests}
                                    />
                                  </FormControl>
                                  {!!errors.companyInterests &&
                                    !!touched.companyInterests && (
                                      <FormHelperText
                                        style={{ marginLeft: 35 }}
                                        className={classes.helperText}
                                      >
                                        {errors.companyInterests}
                                      </FormHelperText>
                                    )}
                                  <Divider
                                    height={1}
                                    className={classes.divider}
                                  />
                                </ListItem>
                              )}

                              {/* Company skills */}
                              {((companyUpdate &&
                                values.companySkills.length) ||
                                !companyUpdate ||
                                this.state.formMount) && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <h5 className={classes.listTitle}>
                                    Quais são as competências que mais se
                                    destacam?
                                  </h5>
                                  <p className={classes.paragraph}>
                                    Queremos entender melhor o DNA do perfil
                                    profissional da sua empresa, selecione
                                    abaixo as 4 competências comportamentais que
                                    mais se destacam nos profissionais da
                                    empresa:
                                  </p>
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="companySkills"
                                      shrink
                                      error={
                                        errors.companySkills &&
                                        touched.companySkills
                                      }
                                    >
                                      Competências da empresa *
                                    </InputLabel>
                                    <SearchableSelect
                                      name="companySkills"
                                      id="companySkills"
                                      isClearable={false}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      isMulti
                                      multiline
                                      rows={3}
                                      placeholder="Selecione"
                                      isSearchable
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'companySkills',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          setFieldValue('companySkills', event);
                                        } else {
                                          setFieldValue('companySkills', '');
                                        }
                                      }}
                                      options={this.state.companySkills || []}
                                      defaultValue={values.companySkills}
                                    />
                                  </FormControl>
                                  {!!errors.companySkills &&
                                    !!touched.companySkills && (
                                      <FormHelperText
                                        style={{ marginLeft: 35 }}
                                        className={classes.helperText}
                                      >
                                        {errors.companySkills}
                                      </FormHelperText>
                                    )}
                                  <Divider
                                    height={1}
                                    className={classes.divider}
                                  />
                                </ListItem>
                              )}
                              {/* Company values */}
                              {((companyUpdate &&
                                values.companyValues.length) ||
                                !companyUpdate ||
                                this.state.formMount) && (
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <h5 className={classes.listTitle}>
                                    Quais são os valores da sua empresa?
                                  </h5>
                                  <p className={classes.paragraph}>
                                    Me conte um pouco mais sobre a cultura da
                                    sua empresa. Selecione até 3 temáticas que
                                    se aproximam mais da sua cultura.
                                  </p>
                                  <FormControl variant="filled" fullWidth>
                                    <InputLabel
                                      htmlFor="companyValues"
                                      shrink
                                      error={
                                        errors.companyValues &&
                                        touched.companyValues
                                      }
                                    >
                                      Valores da empresa *
                                    </InputLabel>
                                    <SearchableSelect
                                      name="companyValues"
                                      id="companyValues"
                                      isClearable={false}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      isMulti
                                      multiline
                                      rows={3}
                                      placeholder="Selecione"
                                      isSearchable
                                      classNamePrefix="react-select"
                                      className={classNames([
                                        classes.selectSection,
                                        classes.selectSectionAutoHeight,
                                      ])}
                                      onBlur={(event) =>
                                        setFieldTouched(
                                          'companyValues',
                                          event.label
                                        )
                                      }
                                      onInputChange={this.handleInputChange}
                                      onChange={(event) => {
                                        if (event) {
                                          setFieldValue('companyValues', event);
                                        } else {
                                          setFieldValue('companyValues', '');
                                        }
                                      }}
                                      options={this.state.companyValues || []}
                                      defaultValue={values.companyValues}
                                    />
                                  </FormControl>
                                  {!!errors.companyValues &&
                                    !!touched.companyValues && (
                                      <FormHelperText
                                        style={{ marginLeft: 35 }}
                                        className={classes.helperText}
                                      >
                                        {errors.companyValues}
                                      </FormHelperText>
                                    )}
                                  <Divider
                                    height={1}
                                    className={classes.divider}
                                  />
                                </ListItem>
                              )}
                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <h5 className={classes.listTitle}>
                                  Você utiliza algum Agente Integrador para
                                  vagas de estágio?
                                </h5>
                                <FormControl variant="filled" fullWidth>
                                  <InputLabel
                                    htmlFor="useIntegrationAgent"
                                    shrink
                                  >
                                    Selecione
                                  </InputLabel>
                                  <SearchableSelect
                                    name="useIntegrationAgent"
                                    id="useIntegrationAgent"
                                    isClearable={false}
                                    InputLabel={{ shrink: true }}
                                    placeholder="Selecione"
                                    isSearchable
                                    classNamePrefix="react-select"
                                    className={classNames([
                                      classes.selectSection,
                                      classes.selectSectionAutoHeight,
                                    ])}
                                    onBlur={(event) =>
                                      setFieldTouched(
                                        'useIntegrationAgent',
                                        event.label
                                      )
                                    }
                                    onChange={(event) => {
                                      if (event) {
                                        setFieldValue(
                                          'useIntegrationAgent',
                                          event
                                        );
                                      } else {
                                        setFieldValue(
                                          'useIntegrationAgent',
                                          null
                                        );
                                      }
                                    }}
                                    options={[
                                      { label: 'Sim', value: true },
                                      { label: 'Não', value: false },
                                    ]}
                                    defaultValue={values.useIntegrationAgent}
                                  />
                                </FormControl>
                              </ListItem>
                              {/* Password */}

                              <div
                                style={{
                                  display: companyUpdate ? 'none' : 'block',
                                }}
                              >
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <h5 className={classes.listTitle}>
                                    Dados para acesso
                                  </h5>
                                  <FastField
                                    className={classes.inputField}
                                    InputProps={{
                                      className: classes.input,
                                    }}
                                    InputLabelProps={{
                                      FormLabelClasses: {
                                        focused: classes.inputField,
                                      },
                                    }}
                                    name="staffPassword"
                                    variant="filled"
                                    id="staffPassword"
                                    type="password"
                                    label="Senha"
                                    placeholder="Crie uma senha com até 6 caracteres"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      !!errors.staffPassword &&
                                      !!touched.staffPassword
                                    }
                                    value={values.staffPassword}
                                    component={TextField}
                                    validate={this.requiredStaffPassword}
                                    required={false}
                                  />
                                  {errors.staffPassword &&
                                    touched.staffPassword && (
                                      <FormHelperText
                                        className={classes.helperText}
                                      >
                                        {errors.staffPassword}
                                      </FormHelperText>
                                    )}
                                  <p />
                                  <FastField
                                    className={classes.inputField}
                                    InputProps={{
                                      className: classes.input,
                                    }}
                                    InputLabelProps={{
                                      FormLabelClasses: {
                                        focused: classes.inputField,
                                      },
                                    }}
                                    name="staffPasswordConfirmation"
                                    variant="filled"
                                    id="staffPasswordConfirmation"
                                    type="password"
                                    label="Confirmação de senha"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      !!errors.staffPasswordConfirmation &&
                                      !!touched.staffPasswordConfirmation
                                    }
                                    value={values.staffPasswordConfirmation}
                                    component={TextField}
                                    validate={this.validPassWordConfirm}
                                    required={false}
                                  />
                                  {errors.staffPasswordConfirmation &&
                                    touched.staffPasswordConfirmation && (
                                      <FormHelperText
                                        className={classes.helperText}
                                      >
                                        {errors.staffPasswordConfirmation}
                                      </FormHelperText>
                                    )}
                                </ListItem>
                                <ListItem
                                  className={
                                    classes.listItemCompanyRegistration
                                  }
                                >
                                  <Divider
                                    height={1}
                                    className={classes.divider}
                                  />
                                  <FormControl
                                    component="fieldset"
                                    className={
                                      (classes.formControl, classes.fullWidth)
                                    }
                                  >
                                    <FormGroup className={classes.group}>
                                      <FormControlLabel
                                        classes={{
                                          root: classes.radioLabelControl,
                                          label: classes.radioLabel,
                                        }}
                                        label={
                                          <Fragment>
                                            Eu li e concordo com os{' '}
                                            <a
                                              target="_blank"
                                              className={
                                                classes[
                                                  `${useDefineColor(
                                                    universityColor
                                                  )}checkBox`
                                                ]
                                              }
                                              href={this.state.termUrl}
                                            >
                                              Termos de Uso da Plataforma
                                            </a>
                                          </Fragment>
                                        }
                                        labelPlacement="start"
                                        tabIndex={-1}
                                        control={
                                          <Checkbox
                                            classes={{
                                              checked:
                                                classes[
                                                  `${useDefineColor(
                                                    universityColor
                                                  )}checkBox`
                                                ],
                                            }}
                                            color="default"
                                            checked={
                                              this.state.acceptedPolitics
                                            }
                                            onClick={(event) => {
                                              this.acceptTerms(event);
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </ListItem>
                              </div>

                              {/* Save Button */}
                              <ListItem
                                className={classes.listItemCompanyRegistration}
                              >
                                <Button
                                  disabled={
                                    isSubmitting || !this.state.acceptedPolitics
                                  }
                                  type="submit"
                                  className={classes.saveButton}
                                >
                                  Salvar
                                </Button>
                              </ListItem>
                            </div>
                          </StepWizard>
                        </List>
                      </form>
                    )}
                  />
                )}
              </Grid>
            );
          }}
        </AuthContext.Consumer>
      </Grid>
    );
  }
}

CompanyRegistrationForm.propTypes = {
  classes: object,
  isEdit: bool,
  extraCourse: object,
  closeModal: func.isRequired,
  updateExtraCourseState: func,
  handleIsLoadingState: func.isRequired,
  universityColor: string,
  openSnackbar: func.isRequired,
  openDnaDialog: func,
  history: object,
  location: object,
  match: object,
  companyUpdate: object,
};
export default withStyles(ProfessionalExperienceFormStyles)(
  CompanyRegistrationForm
);
