import React, { Fragment, useState } from 'react';
import { shape, func, number } from 'prop-types';
import { format } from 'date-fns';

import { unpublishReport } from 'services/university/socioeconomicReport';
import { Divider } from '@material-ui/core';

import { ListDetail } from './styles';
import { TargetAudienceDetails } from './TargetAudienceDetails';
import { ChartDetails } from './ChartDetails';

const ReportDetail = ({ reportDetail, updateReport, index, reportOptions }) => {
  const [publish, setPublish] = useState(!reportDetail.unpublished_at);
  const { target_audience: targetAudience } = reportDetail;
  return (
    <ListDetail publish={publish}>
      <TargetAudienceDetails
        reportOptions={reportOptions}
        targetAudience={targetAudience}
      />
      {reportDetail?.chartData?.map((question) => {
        return (
          <Fragment>
            <Divider key={question.title} />
            <ChartDetails question={question} />
          </Fragment>
        );
      })}

      <span
        onClick={() =>
          unpublishReport(reportDetail.id).then(() => {
            setPublish(!publish);
            const newReport = reportDetail;
            newReport.unpublished_at = format(Date.now(), 'dd/MM/yyyy');
            updateReport(newReport, index);
          })
        }
      >
        {publish && `Despublicar`}
      </span>
    </ListDetail>
  );
};

ReportDetail.propTypes = {
  reportDetail: shape.isRequired,
  updateReport: func.isRequired,
  index: number.isRequired,
  reportOptions: shape.isRequired,
};

export default ReportDetail;
