import React, { useContext } from 'react';
import { IconLogout } from 'assets/img/iconsCurriculum/iconLogout';
import AuthContext from 'contexts/authentication';
import { ContainerButton, ButtonLogout } from './styled';

const ButtonLogoutStudent = (props) => {
  const auth = useContext(AuthContext);
  const { universityColor } = props;

  return (
    <ContainerButton>
      {auth.isLogged && window === window.parent ? (
        <ButtonLogout
          round
          onClick={auth.routerOnUserLogout}
          universityColor={universityColor}
        >
          <IconLogout color={universityColor || '#009291'} /> Sair
        </ButtonLogout>
      ) : null}
    </ContainerButton>
  );
};

export default ButtonLogoutStudent;
