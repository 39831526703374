import {
  Title,
  Container,
  HelpText,
  Question,
  HelpTextQuestion,
  TextArea,
  ViewEvidence,
  Wrapper,
} from './styled';
import { Form } from '@unform/web';
import UnformTextarea from './components/UnformTextArea';
import { useContext, useEffect, useState } from 'react';
import { ExtensionProjectsContext } from '../../../../../context/ExtensionProjectsContext';
import ModalShowEvidence from 'components/ModalShowEvidence';
import { getEvidences } from 'views/StaffPages/Opportunities/services';

export function StepOne({ refStepOne }) {
  const [modalShowEvidencesOpen, setModalOpenEvidencesOpen] = useState(false);
  const [evidencesCount, setEvidencesCount] = useState(0);
  const [arrayLinks, setArrayLinks] = useState([]);
  const [arrayFiles, setArrayFiles] = useState([]);

  const { formDataFinishProject, extensionProjectId } = useContext(
    ExtensionProjectsContext
  );

  const handleClickSeeEvidences = () => {
    setModalOpenEvidencesOpen(true);
  };

  const loadEvidences = async (query) => {
    const { results, count } = await getEvidences(extensionProjectId, query);
    setEvidencesCount(count);
    const files = results?.filter(
      (item) => item.file !== null || item.image !== null
    );
    const links = results?.filter(
      (item) => item.name !== '' && item.link !== ''
    );

    setArrayFiles(files);
    setArrayLinks(links);
  };

  useEffect(() => {
    loadEvidences('limit=5&offset=0');
  }, []);

  return (
    <Container>
      <ModalShowEvidence
        id={extensionProjectId}
        open={modalShowEvidencesOpen}
        setOpen={setModalOpenEvidencesOpen}
      />
      <Title> Finalizar projeto </Title>
      <HelpText>
        Aqui você pode fornecer informações sobre as pessoas que foram
        impactadas e participaram da ação, além de inserir documentos de
        evidência.
      </HelpText>

      <Form ref={refStepOne} initialData={formDataFinishProject}>
        <Wrapper>
          <Question>Público externo</Question>
          <HelpTextQuestion>
            Descreva quais e quantos foram os agentes externos (segurança
            pública, prefeitura, secretarias etc) que participaram dessa ação.
          </HelpTextQuestion>
        </Wrapper>
        <UnformTextarea
          name="externalPublic"
          maxLength={3000}
          useCount={true}
          placeholder="Exemplo: 10 oficiais de segurança pública,
          5 pessoas da prefeitura, 20 pessoas da secretaria da educação."
        />
        <Wrapper>
          <Question>Público impactado</Question>
          <HelpTextQuestion>
            Especifique os resultados da ação: quantas pessoas foram impactadas
            e/ou beneficiadas.
          </HelpTextQuestion>
        </Wrapper>

        <UnformTextarea
          name="impactedPublic"
          maxLength={3000}
          useCount={true}
          placeholder="Exemplo: 155 pessoas no total - 100 pessoas da comunidade,
            50 estudantes do curso de estética, 5 professores do curso de estética."
        />
        <Wrapper>
          <Question>Evidências</Question>

          <ViewEvidence type="button" onClick={handleClickSeeEvidences}>
            Visualizar evidências ({evidencesCount})
          </ViewEvidence>

          <HelpTextQuestion
            style={{ marginTop: '12px', fontSize: 12, maxWidth: 732 }}
          >
            Caso você precise fazer o upload de outras evidências, volte para a
            página inicial da aba "projetos de extensão” e clique no botão
            “adicionar evidências”.
          </HelpTextQuestion>
        </Wrapper>
      </Form>
    </Container>
  );
}
