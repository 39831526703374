import backofficeApi from 'services/api/backofficeApi';
class BackOfficeApi {
  getOptionsUniversitys = async () => {
    return await backofficeApi
      .get('/email-list/universities')
      .then((response) => response.data)
      .catch((error) => error);
  };

  deleteEmail = async (id) => {
    return await backofficeApi
      .delete(`/email-list/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  postCreateEmail = async (data) => {
    try {
      const response = await backofficeApi.post('/email-list', data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getEmails = async (term = '') => {
    return await backofficeApi
      .get(`/email-list?name=${term}`)
      .then((response) => response.data)
      .catch((error) => error);
  };
}

export default new BackOfficeApi();
