import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 180px;
  margin-bottom: 30px;
  padding: 24px 20px;
  border-radius: 10px;
  background-color: #fff;
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 525px) {
    order: 2;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e5e5e5;
  margin: 20px 0;
`;

export const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  flex: none;
  border-radius: 10px;
  border: 2px solid;
  border-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 15px 15px 0;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #606062;
  margin: 0;
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px !important;
  }
`;

export const InformationsIcons = styled.p`
  left: 14px;
  color: #8b8b8b;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
`;
export const InfoWrapper = styled.p`
  display: flex;
  align-items: center;
`;

export const Informations = styled.p`
  left: 14px;
  color: #8b8b8b;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin: 0 8px 0 0;

  @media (max-width: 1313px) {
    display: ${(props) => (props.show ? 'flex' : 'none')};
  }
`;

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  margin: 0 5px 0 0;
  gap: 5px;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  margin: 20px 0 0 0;
`;

export const HeaderPrincipal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
`;

export const Footer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 30px 0 10px 0;
`;

export const LifeAndCareerContainer = styled.div``;

export const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
