import React, { useState } from 'react';
import {
  ContainerExternalCards,
  ButtonSeeMore,
  ContainerFiltersPartners,
  ButtonFiltersPartners,
} from '../styled';

import GenericCardsJobOffers from '../Cards';
import { DetailsExternalJobs } from './DetailsExternalJobs';
import ModalInformation from 'components/informationModal';
import GenericMessage from 'components/GenericMessage';
import { ContainerButtonsPagination } from '../../styled';
import FilterLoading from 'components/FilterModal/components/FilterLoading';

const ExternalJobs = ({
  universityColor,
  listExternalJobOffers,
  fetchListExternalJobOffers,
}) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectUrl, setSelectUrl] = useState(null);
  const dataExternalJobOffers = listExternalJobOffers?.data?.results;
  const [openModalExternalJob, setOpenModalExternalJob] = useState(false);
  const isExternalJobs = true;

  const handleMoreItems = async () => {
    try {
      setIsLoading(true);
      const query = listExternalJobOffers.data.next.split('?');
      await fetchListExternalJobOffers(query[1], true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExternalApply = (url) => {
    window.open(url, '_blank');
  };

  const config = {
    modalWidth: 500,
    bubbleText: `Atenção!`,
    ParagraphText: `O processo de candidatura a essa vaga será realizado pela empresa parceira. Sucesso!`,
    buttonConfirmText: 'OK, VAMOS LÁ!',
    buttonCancelText: 'CANCELAR',
    showButtonCancel: true,
    buttonCloseX: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '92%',
    },
  };

  const openExternalModal = (jobId, url) => {
    setOpenModalExternalJob(true);
    setSelectedJobId(jobId);
    setSelectUrl(url);
  };

  const onClickDetails = (jobId) => {
    setSelectedJobId(jobId);
    setOpenDetailsModal(true);
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
    setOpenModalExternalJob(false);
  };

  const handleCloseModalExternalApply = () => {
    setOpenModalExternalJob(false);
  };

  const returnCardJobs = (dataExternalJobOffers) => {
    return dataExternalJobOffers?.map((job) => (
      <div key={job}>
        <GenericCardsJobOffers
          match={job.match}
          code={job.job_id}
          name={job.title}
          companyName={job.company_name}
          location={job.location ? job.location : 'Não informado'}
          onClickDetailsButton={() => onClickDetails(job.job_id)}
          labelTwo={'Detalhes'}
          onClickButton={() => openExternalModal(job.job_id, job.url)}
          label={'Acessar'}
          universityColor={universityColor}
          isExternalJobs={isExternalJobs}
          config={config}
        />
      </div>
    ));
  };

  return (
    <>
      {dataExternalJobOffers?.length === 0 ||
      dataExternalJobOffers === undefined ? (
        <GenericMessage
          title="Nenhuma vaga externa."
          subtitle="No momento você não possui nenhuma vaga externa"
        />
      ) : (
        <>
          <ContainerFiltersPartners>
            <ButtonFiltersPartners universityColor={universityColor}>
              InfoJobs
            </ButtonFiltersPartners>
          </ContainerFiltersPartners>
          <ContainerExternalCards>
            <div>{returnCardJobs(dataExternalJobOffers)}</div>
          </ContainerExternalCards>

          <ContainerButtonsPagination>
            {listExternalJobOffers?.data?.next && (
              <ButtonSeeMore
                universityColor={universityColor}
                onClick={handleMoreItems}
              >
                {isLoading ? (
                  <FilterLoading width={'88px'} height={'16px'} />
                ) : (
                  'Ver mais'
                )}
              </ButtonSeeMore>
            )}
          </ContainerButtonsPagination>

          {openDetailsModal && (
            <DetailsExternalJobs
              jobId={selectedJobId}
              universityColor={universityColor}
              handleCloseModal={handleCloseModal}
              config={config}
            />
          )}

          {openModalExternalJob && (
            <ModalInformation
              universityColor={universityColor}
              modalOpenState={openModalExternalJob}
              config={config}
              buttonCancelFunction={handleCloseModalExternalApply}
              buttonConfirmFunction={() => handleExternalApply(selectUrl)}
              closeModalFunction={handleCloseModalExternalApply}
            />
          )}
        </>
      )}
    </>
  );
};

export default ExternalJobs;
