import { authenticatedAPI } from 'services/api/api';

const getStudentCurriculumData = async (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/students-docs/curriculum/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCurriculumsCompanyForDownload = async (queryString) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/students/curriculums?${queryString}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export { getStudentCurriculumData, getCurriculumsCompanyForDownload };
