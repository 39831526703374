import { useEffect, useState } from 'react';
import { array, number, object } from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withStyles from '@material-ui/core/styles/withStyles';

import CompanyHeaderTabTheme,
{
  Container,
  ContainerFilters,
  ContainerBetween,
  FilterContainer
} from './styled';
import { formatNumber } from 'utils/formatNumber';
import FilterModal from 'components/FilterModal';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import IESCompanyAPI from '../../Service/API';

const CompanyHeaderTab = ({
  classes,
  universityColor,
  activeTab,
  iesTotalCount,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  handleApplyFilters,
}) => {
  const [queryCities, setQueryCities] = useState('');
  const [optionsFilter, setOptionsFilter] = useState({});

  useEffect(() => {
    getFilters();
  }, []);

  const statusOptions = [
    { label: 'conectada', value: true },
    { label: 'desconectada', value: false },
  ];

  const handleClearFilters = () => {
    setFilterState({});
    setAppliedFilters({});
  };

  const getFilters = async (query = '') => {
    const response = await IESCompanyAPI.getFilters(query);
    setOptionsFilter(response);
  };

  const handleSelectState = (value) => {
    if (value.length > 0) {
      const queryString = `?state=${value[0]}${value
        .slice(1)
        .map((state) => `&state=${state}`)
        .join('')}`;
      setQueryCities(queryString.replace('?', ''));
      getFilters(queryString);
      return;
    }
    getFilters(``);
  };

  const handleSelectMacroArea = (value) => {
    if (value.length > 0) {
      const queryString = `?macro_area=${value[0]}${value
        .slice(1)
        .map((state) => `&macro_area=${state}`)
        .join('')}`;
      getFilters(`${queryString}&${queryCities}`);
      return;
    }
    getFilters(`?${queryCities}`);
  };

  return (
    <Container universityColor={universityColor}>
      <AppBar position="static" className={classes.appBarHolder}>
        <Tabs
          value={activeTab}
          scrollButtons="on"
          scrollable
          universityColor={universityColor}
          classes={{
            indicator: 'custom-indicator',
            scrollButtons: classes.scrollButtons,
            flexContainer: classes.borderContainer,
          }}
        >
          <Tab
            universityColor={universityColor}
            style={{ zIndex: 2 }}
            label={`Instituições de ensino (${formatNumber(iesTotalCount || 0)})`}
            className={classes.tabButton}
          />
        </Tabs>

        <FilterContainer>
          <FilterModal
            filterState={filterState}
            setFilterState={setFilterState}
            onClean={handleClearFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={handleApplyFilters}
          >
            <ContainerFilters>
              <ContainerBetween margin={true}>
                <Filter
                  options={optionsFilter.states}
                  label="Estado"
                  name={'state'}
                  type={FilterType.MULTI_SELECT}
                  settings={{
                    onChange: handleSelectState,
                  }}
                />
                <Filter
                  options={optionsFilter.cities}
                  label="Cidade"
                  name={'city'}
                  type={FilterType.MULTI_SELECT}
                  settings={{
                    disabled: optionsFilter.cities?.length === 0,
                  }}
                />
              </ContainerBetween>

              <ContainerBetween>
                <Filter
                  options={optionsFilter.macro_areas}
                  label="Macroáreas"
                  name={'macro_area'}
                  type={FilterType.MULTI_SELECT}
                  settings={{
                    onChange: handleSelectMacroArea,
                  }}
                />
              </ContainerBetween>

              <ContainerBetween>
                <Filter
                  options={statusOptions}
                  label="Status"
                  name={'status'}
                  type={FilterType.SINGLE_SELECT}
                />
                <Filter
                  label="Cursos"
                  name={'courses'}
                  options={optionsFilter.courses}
                  type={FilterType.MULTI_SELECT}
                />
              </ContainerBetween>
            </ContainerFilters>
          </FilterModal>
        </FilterContainer>
      </AppBar>
    </Container>
  );
};

CompanyHeaderTab.propTypes = {
  classes: object.isRequired,
  activeTab: number,
  tabs: array,
};

export default withStyles(CompanyHeaderTabTheme)(CompanyHeaderTab);