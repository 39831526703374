import axios from 'axios';
import urls from 'constants/urls';
import { api } from 'services/api/api';

const verifyExistUniversity = async (university_slug) => {
  try {
    const { data } = await api.get(
      `/api/internal/students/university-exists/${university_slug}`
    );
    return data.exists ?? false;
  } catch (error) {
    return false;
  }
};

export default verifyExistUniversity;
