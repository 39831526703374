import axios from 'axios';
import { isMasterLet } from '../JobController/PublishedJobsTabs/index';
import { authenticatedAPI } from 'services/api/api';

function queryParamsFromObject(
  filterObject,
  options = {
    commaSeparatedFields: [],
  }
) {
  return Object.keys(filterObject).reduce((acc, key) => {
    let newAcc = acc === '' ? '' : `${acc}&`;
    const typeOfFilter = typeof filterObject[key];
    if (Array.isArray(filterObject[key])) {
      if (options.commaSeparatedFields.includes(key)) {
        return `${newAcc}${key}=${filterObject[key].join(',')}`;
      }
      return `${newAcc}${key}=${filterObject[key].join(`&${key}=`)}`;
    } else if (typeOfFilter === 'string' || typeOfFilter === 'boolean') {
      return `${newAcc}${key}=${filterObject[key]}`;
    } else if (typeOfFilter === 'object') {
      return `${newAcc}${key}=${
        filterObject[key] && filterObject[key].toISOString().split('T')[0]
      }`;
    }
    return acc;
  }, '');
}

const getCourses = async (data) => {
  let newData = '';
  if (data) {
    newData = queryParamsFromObject({
      selected_universities: data,
    });
  }

  if (isMasterLet) {
    const response = authenticatedAPI
      .get(`api/internal/university/educational-group/board/courses?${newData}`)
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs/list-courses`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const createJobOffer = async (jobOffer) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .post(
        'api/internal/university/educational-group/board/job-offers/create',
        jobOffer
      )
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .post(`/api/internal/university/jobs/create`, jobOffer)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getBackOfficeProfessions = async () => {
  const response = authenticatedAPI
    .get(`api/internal/companies-universities/professions`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getInformationProfessions = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies-universities/professions/retrieve/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTechnicalSkills = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/companies/jobs/additional-and-new-areas`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getBehaviorAttributes = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/companies/jobs/behavioral-attributes`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCities = (cities) => {
  const response = axios
    .get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${cities}/municipios`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getUfsByNumber = (ids) => {
  const response = axios
    .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ids}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const createProfession = (data) => {
  const response = authenticatedAPI
    .post(`api/internal/companies-universities/professions/create`, data)
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const getJobDetails = (id) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .get(
        `api/internal/university/educational-group/board/job-offer/update/${id}`
      )
      .then((res) => res.data)
      .catch((err) => err);
    return response;
  }
  const response = authenticatedAPI
    .get(`api/internal/university/jobs/${id}`)
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const getJobPartnersDetails = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/university/partner/jobs/${id}`)
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const editJobOffer = (id, data) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .put(
        `api/internal/university/educational-group/board/job-offer/update/${id}`,
        { ...data }
      )
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .put(`api/internal/university/jobs/${id}`, { ...data })
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const updateFeedBack = (id, data) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .patch(
        `api/internal/university/educational-group/board/job-offer/update/${id}`,
        { ...data }
      )
      .then((res) => res.data)
      .catch((err) => err);
    return response;
  }
  const response = authenticatedAPI
    .patch(`api/internal/university/jobs/${id}`, { ...data })
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};
const getJobsCount = async () => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .get(`api/internal/university/educational-group/board/job-offers/count`)
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs/count`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getJobsOpenings = async (params) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .get(
        `api/internal/university/educational-group/board/job-offers?${params}`
      )
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getJobsPartners = async (params) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .get(
        `api/internal/university/educational-group/board/universities/job-offers?${params}`
      )
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .get(`/api/internal/university/partner-jobs?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getHistoryInstitution = async (params) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .get(
        `api/internal/university/educational-group/board/job-offers/history?${params}`
      )
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs-history?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getHistoryPartners = async (params) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .get(
        `api/internal/university/educational-group/board/universities/job-offers/history?${params}`
      )
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  const response = authenticatedAPI
    .get(`/api/internal/university/jobs-partner-history?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getUniversityCandidates = async (id, fromPartner = false, query) => {
  if (isMasterLet) {
    const url = fromPartner
      ? `api/internal/educational-group/universities/jobs/${id}/applications?${query}`
      : `api/internal/educational-group/jobs/${id}/applications?${query}`;
    const response = authenticatedAPI
      .get(url)
      .then((res) => res.data)
      .catch((err) => err);

    return response;
  }
  const url = `api/internal/university/jobs${
    fromPartner ? '/partner' : ''
  }/${id}/applications?${query}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getUnits = async (id) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .get('api/internal/university/educational-group/board/universities')
      .then((res) => res.data)
      .catch((error) => error);

    return response;
  }
  const response = authenticatedAPI
    .get(`api/internal/university/jobs/unities`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const unPublishJob = async (id) => {
  let url = isMasterLet
    ? `api/internal/university/educational-group/board/job-offers/unpublish/${id}`
    : `api/internal/university/jobs/${id}/unpublish`;
  const response = authenticatedAPI
    .patch(url, { is_published: false })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCompanys = async (id) => {
  const response = authenticatedAPI
    .get('api/internal/university/jobs/companies')
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getJobsIesFilters = async () => {
  if (isMasterLet) {
    return authenticatedAPI
      .get('api/internal/university/educational-group/board/job-offers/filter')
      .then((res) => res.data)
      .catch((error) => error);
  }
  return authenticatedAPI
    .get(`api/internal/university/jobs/filters`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getJobsHistoryFilters = async () => {
  if (isMasterLet) {
    return authenticatedAPI
      .get(
        'api/internal/university/educational-group/board/job-offers/history/filter'
      )
      .then((res) => res.data)
      .catch((error) => error);
  }
  return authenticatedAPI
    .get(`api/internal/university/jobs/history/filters`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getJobsPartnersHistoryFilters = async () => {
  if (isMasterLet) {
    return authenticatedAPI
      .get(
        'api/internal/university/educational-group/board/universities/job-offers/history/filter'
      )
      .then((res) => res.data)
      .catch((err) => err);
  }
  return authenticatedAPI
    .get(`api/internal/university/jobs/partner/history/filters`)
    .then((res) => res.data)
    .catch((error) => error);
};

const patchFeedBack = async (id, data) => {
  return authenticatedAPI
    .patch(`api/internal/university/partner/jobs/${id}`, { ...data })
    .then((res) => res.data)
    .catch((error) => error);
};

const getJobsPartnersFilters = async () => {
  if (isMasterLet) {
    return authenticatedAPI
      .get(
        'api/internal/university/educational-group/board/universities/job-offers/filter'
      )
      .then((res) => res.data)
      .catch((err) => err);
  }
  return authenticatedAPI
    .get(`api/internal/university/jobs/partner/filters`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getCurriculumDetail = async (jobOfferId, applicationId) => {
  let url = isMasterLet
    ? `api/internal/educational-group/jobs/${jobOfferId}/applications/${applicationId}`
    : `api/internal/university/jobs/${jobOfferId}/applications/${applicationId}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCurriculumDetailHistoryPartner = async (jobOfferId, applicationId) => {
  let url = `api/internal/companies/partner/jobs/history/${jobOfferId}/applications/${applicationId}`;

  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptionsFilterUniversity = async (jobID, isPartner) => {
  if (isMasterLet) {
    const url = isPartner
      ? `api/internal/educational-group/universities/jobs/${jobID}/applications/filter`
      : `api/internal/educational-group/jobs/${jobID}/applications/filter`;
    const response = authenticatedAPI
      .get(url)
      .then((res) => res.data)
      .catch((err) => err);
    return response;
  }
  const url = isPartner
    ? `api/internal/university/jobs/partner/${jobID}/applications/filter`
    : `api/internal/university/jobs/${jobID}/applications/filter`;

  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptionsFilterHistoryUniversity = async (jobID, isPartner) => {
  if (isMasterLet) {
    const url = isPartner
      ? `api/internal/educational-group/universities/jobs/history/${jobID}/applications/filter`
      : `api/internal/educational-group/jobs/history/${jobID}/applications/filter`;
    const response = authenticatedAPI
      .get(url)
      .then((res) => res.data)
      .catch((error) => error);

    return response;
  }

  const url = isPartner
    ? `api/internal/university/jobs/partner/history/${jobID}/applications/filter`
    : `api/internal/university/jobs/history/${jobID}/applications/filter`;

  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getUniversityCandidatesHistory = async (
  id,
  isPartner,
  query = '',
  pageQuery
) => {
  if (isMasterLet) {
    const url = isPartner
      ? `api/internal/educational-group/universities/jobs/history/${id}/applications?${query}`
      : `api/internal/educational-group/jobs/history/${id}/applications?${query}`;

    const response = authenticatedAPI
      .get(url)
      .then((res) => res.data)
      .catch((err) => err);

    return response;
  }
  const url = isPartner
    ? `api/internal/university/jobs/partner/history/${id}/applications?${query}`
    : `api/internal/university/jobs/history/${id}/applications?${query}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const filterCandidates = async (id, query, isPartner, pageQuery) => {
  if (isMasterLet) {
    const url = isPartner
      ? `api/internal/educational-group/universities/jobs/${id}/applications?${query}&${pageQuery}`
      : `api/internal/educational-group/jobs/${id}/applications?${query}&${pageQuery}`;

    const response = authenticatedAPI
      .get(url)
      .then((res) => res.data)
      .catch((error) => error);

    return response;
  }
  let url = isPartner
    ? `api/internal/university/jobs/partner/${id}/applications?${query}&${pageQuery}`
    : `api/internal/university/jobs/${id}/applications?${query}&${pageQuery}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const filterCandidatesHistory = async (id, query, isPartner, pageQuery) => {
  if (isMasterLet) {
    const url = isPartner
      ? `api/internal/educational-group/universities/jobs/history/${id}/applications?${query}&${pageQuery}`
      : `api/internal/educational-group/jobs/history/${id}/applications?${query}&${pageQuery}`;

    const response = authenticatedAPI
      .get(url)
      .then((res) => res.data)
      .catch((err) => err);

    return response;
  }

  let url = isPartner
    ? `api/internal/university/jobs/partner/history/${id}/applications?${pageQuery}&${query}`
    : `api/internal/university/jobs/history/${id}/applications?${pageQuery}&${query}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getApplicationCoverLetter = async (id) => {
  const response = authenticatedAPI
    .get(`api/internal/university/students-docs/cover-letter/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFinalCandidatesIES = async (jobId) => {
  if (isMasterLet) {
    return authenticatedAPI
      .get(`api/internal/educational-group/jobs/${jobId}/candidates`)
      .then((res) => res.data)
      .catch((error) => error);
  }
  return authenticatedAPI
    .get(`api/internal/university/jobs/${jobId}/candidates`)
    .then((res) => res.data)
    .catch((error) => error);
};

const finishJob = async (jobId, data) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .patch(
        `api/internal/university/educational-group/board/job-offers/finish/${jobId}`,
        data
      )
      .then((res) => res.data)
      .catch((error) => error);

    return response;
  }
  return authenticatedAPI
    .patch(`api/internal/university/jobs/${jobId}/finish`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const finishHistoryJob = async (jobId, data) => {
  if (isMasterLet) {
    const response = authenticatedAPI
      .patch(
        `api/internal/university/educational-group/board/job-offers/history/finish/${jobId}`,
        data
      )
      .then((res) => res.data)
      .catch((error) => error);
    return response;
  }
  return authenticatedAPI
    .patch(`api/internal/university/jobs/history/${jobId}/finish`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const scheduleInterviewUniversity = async (jobId, applicationId, data) => {
  return authenticatedAPI
    .post(
      `api/internal/university/jobs/${jobId}/applications/${applicationId}/interview`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);
};

const getScheduleInterviewUniversity = async (
  jobId,
  applicationId,
  interviewId
) => {
  return authenticatedAPI
    .get(
      `api/internal/university/jobs/${jobId}/applications/${applicationId}/interview/${interviewId}`
    )
    .then((res) => res.data)
    .catch((error) => error);
};

const getCancellationUniversityInterview = async (
  jobId,
  applicationId,
  interviewId
) => {
  try {
    const response = await authenticatedAPI.get(
      `api/internal/university/jobs/${jobId}/applications/${applicationId}/canceled-interview/${interviewId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateScheduleInterviewUniversity = async (
  jobId,
  applicationId,
  interviewId,
  data
) => {
  return authenticatedAPI
    .put(
      `api/internal/university/jobs/${jobId}/applications/${applicationId}/interview/${interviewId}`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);
};

export {
  getCourses,
  createJobOffer,
  getBackOfficeProfessions,
  getInformationProfessions,
  getTechnicalSkills,
  getBehaviorAttributes,
  getCities,
  createProfession,
  getJobDetails,
  getUfsByNumber,
  editJobOffer,
  updateFeedBack,
  getJobsCount,
  getJobsOpenings,
  getJobsPartners,
  getHistoryInstitution,
  getHistoryPartners,
  getUniversityCandidates,
  getUnits,
  getJobPartnersDetails,
  unPublishJob,
  getCompanys,
  getJobsIesFilters,
  patchFeedBack,
  getJobsPartnersFilters,
  getJobsHistoryFilters,
  getJobsPartnersHistoryFilters,
  getCurriculumDetail,
  getOptionsFilterUniversity,
  filterCandidates,
  getApplicationCoverLetter,
  getOptionsFilterHistoryUniversity,
  getUniversityCandidatesHistory,
  filterCandidatesHistory,
  getFinalCandidatesIES,
  finishJob,
  finishHistoryJob,
  scheduleInterviewUniversity,
  getScheduleInterviewUniversity,
  updateScheduleInterviewUniversity,
  getCurriculumDetailHistoryPartner,
  getCancellationUniversityInterview,
};
