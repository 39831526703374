import { create } from 'zustand';

export const TutorialStore = create((set) => ({
  unviewedTutorials: [],

  changeTutorial: (tutorial) =>
    set(() => {
      return { unviewedTutorials: tutorial };
    }),
  updateTutorial: (key, value) =>
    set((state) => {
      let findTutorial = state?.unviewedTutorials?.filter(
        (item) => item.name !== key
      );
      return (state.unviewedTutorials = findTutorial);
    }),
}));

export const actionsTutorialStore = (() => {
  const changeTutorialData = TutorialStore.getState().changeTutorial;
  const updateTutorial = TutorialStore.getState().updateTutorial;

  return { changeTutorialData, updateTutorial };
})();

export const useTutorialStore = () => {
  const unviewedTutorials = TutorialStore((state) => state.unviewedTutorials);
  return { unviewedTutorials };
};
