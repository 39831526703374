import { IconPersonality } from 'assets/img/iconsCurriculum/iconPersonality';
import { IconBehavioral } from 'assets/img/iconsCurriculum/iconBehavioral';
import { IconValues } from 'assets/img/iconsCurriculum/iconValues';
import { IconInterests } from 'assets/img/iconsCurriculum/iconInterests';
import { profileTexts } from 'views/Profile/Utils/profileTexts';

import { ColorInfo } from '../styled';
import { useState, useEffect } from 'react';
import { PersonalInfo } from 'assets/img/iconsCurriculum/personalInfo';

const useStepOneSorting = (stepOneData) => {
  const [iconsData, setIconsData] = useState([]);
  const { getInfoColorOne, getIconColorOne } = profileTexts();

  const getSortedIcons = () => {
    const { interests, personality_attributes, personal_info } = stepOneData;

    const items = [
      {
        key: 'personal_info',
        title: 'Dados Pessoais',
        gamificationRef: 'personalInfoRef',
        icon: (
          <PersonalInfo size={28} color={getIconColorOne('personal_info')} />
        ),
        color: getIconColorOne('personal_info'),
        completed: personal_info === 1,
        legend: 'simple',
      },
      {
        key: 'interests',
        title: 'Interesses',
        gamificationRef: 'interestsRef',
        icon: <IconInterests color={getIconColorOne('interests')} />,
        color: getIconColorOne('interests'),
        completed: interests === 2,
        legend: 'count',
      },
      {
        key: 'personality_attributes',
        gamificationRef: 'personalityRef',
        title: 'Personalidade',
        icon: (
          <IconPersonality color={getIconColorOne('personality_attributes')} />
        ),
        color: getIconColorOne('personality_attributes'),
        completed: personality_attributes === 1,
        legend: 'simple',
      },
    ];

    items.sort((a, b) =>
      a.completed === b.completed ? 0 : a.completed ? -1 : 1
    );

    let totalItems = {
      interests: 2,
      personality_attributes: 1,
      personal_info: 1,
    };

    const returnLegend = (item) => {
      if (item.legend === 'simple') {
        return item.completed ? 'Concluído' : 'Não concluído';
      }

      if (item.legend === 'count') {
        return item.completed
          ? 'Concluído'
          : `${stepOneData[item.key]}/${totalItems[item.key]} prenchido`;
      }
    };

    return items.map((item) => ({
      ...item,
      info: (
        <ColorInfo color={getInfoColorOne(item.key)}>
          <i>{returnLegend(item)}</i>
        </ColorInfo>
      ),
    }));
  };

  useEffect(() => {
    if (stepOneData) {
      setIconsData(getSortedIcons());
    } else {
      setIconsData([]);
    }
  }, [stepOneData]);

  return iconsData;
};

export default useStepOneSorting;
