import React, { useContext, useEffect, useState } from 'react';
import { ContainerCards, ContainerGenericMessage } from '../styled';
import { ContainerButtonsPagination } from '../../styled';

import PaginationSeeMore from '../../PaginationLoadMore';
import GenericCardsJobOffers from '../Cards';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import SkeletonCards from '../../GenericComponents/SkeletonCards';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import GenericMessage from 'components/GenericMessage';
import { DetailsJobOffer } from '../../JobDetails';
import ModalInformation from 'components/informationModal';
import ProfileForm from 'views/MyProfile/components/Profile/ProfileForm';
import { configLocationModal } from '../../utils/wrapperTextsConfig';

const AnotherJobs = (props) => {
  const {
    universityColor,
    listJobOffers,
    dataJobOffers,
    search,
    fetchListJobOffers,
    isGamificationLevelOneIncomplete,
    gamificationLevelOneIncomplete,
    isCoverLetterMissing,
    applyToJobOffer,
  } = props;
  const {
    closeModal,
    handleIsLoadingState,
    openModalWithContent,
    updateAvatarState,
    fetchGamificationData,
    profileData,
    setProfileData,
    updateProfileState,
    handleShowSpecialNeeds,
    zipCodeStudent,
    stateAndCityStudent,
  } = useContext(JobOffersContext);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedJobMatch, setSelectedJobMatch] = useState(null);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [
    jobIdBeingProcessedAsFavorite,
    setJobIdBeingProcessedAsFavorite,
  ] = useState(null);
  const [
    jobIdCandidatureBeingProcessed,
    setJobIdCandidatureBeingProcessed,
  ] = useState(null);
  const { visibleItems, setVisibleItems, filterData } = useContext(
    JobOffersContext
  );
  const [openModalLocalization, setOpenModalLocalization] = useState(false);
  const [selectJobId, setSelectJobId] = useState(null);
  const [location, setLocation] = useState(null);

  const handleSeeMore = () => {
    setVisibleItems((prev) => prev + 20);
  };

  const handleCloseModalLocalization = () => {
    setOpenModalLocalization(false);
  };

  const openLocalizationModal = async (jobId, location) => {
    setSelectJobId(jobId);
    setLocation(location);

    if (
      !gamificationLevelOneIncomplete &&
      location &&
      location?.length !== 0 &&
      Array.isArray(location) &&
      !location?.includes(stateAndCityStudent)
    ) {
      setOpenModalLocalization(true);
    } else {
      await candidateJobOffer(jobId);
    }
  };

  const handleFavoriteToggle = async (jobId, isFavorited) => {
    try {
      setJobIdBeingProcessedAsFavorite(jobId);
      if (isFavorited === false) {
        const data = { job_offer: jobId };
        await JobOffersApi.fetchActionToFavoriteJobs(data);
        await fetchListJobOffers(false);
      }
      if (isFavorited === true) {
        await JobOffersApi.fetchActionToUnfavoriteJobs(jobId);
        await fetchListJobOffers(false);
      }
    } finally {
      setJobIdBeingProcessedAsFavorite(null);
    }
  };

  const returnCardJobs = (dataJobOffers, visibleItems) => {
    const filteredData = filterData(dataJobOffers, search);

    if (isLoading) {
      return <SkeletonCards />;
    }

    if (filteredData.length === 0) {
      return (
        <ContainerGenericMessage>
          <GenericMessage
            title="Nenhuma vaga encontrada."
            subtitle="Não encontramos nenhuma vaga com este nome."
          />
        </ContainerGenericMessage>
      );
    }

    if (dataJobOffers?.length >= 1) {
      return filterData(dataJobOffers, search)
        .slice(0, visibleItems)
        .map((job) => (
          <div key={job}>
            <GenericCardsJobOffers
              match={job.match}
              code={job.id}
              name={job.name}
              companyName={job.company_name}
              finallyDate={job.end_offer_at}
              jobModality={job.modality}
              shift={job.shift}
              status={job.status}
              location={job.location ? job.location : 'Não informado'}
              processingAsFavorite={job.id === jobIdBeingProcessedAsFavorite}
              like={true}
              isFavorite={job.is_favorite}
              onClickLike={() => handleFavoriteToggle(job.id, job.is_favorite)}
              onClickDetailsButton={() =>
                onClickDetails(job.id, job.match, job.location)
              }
              labelTwo={'Detalhes'}
              onClickButton={() => openLocalizationModal(job.id, job.location)}
              label={'Candidatar'}
              processingCandidature={job.id === jobIdCandidatureBeingProcessed}
              universityColor={universityColor}
            />
          </div>
        ));
    }
  };

  useEffect(() => {
    if (listJobOffers && listJobOffers.data && listJobOffers.data.count) {
      setCount(listJobOffers.data.count);
    }
  }, [listJobOffers]);

  const onClickDetails = (jobId, match, location) => {
    setSelectedJobId(jobId);
    setSelectedJobMatch(match);
    setLocation(location);
    setOpenDetailsModal(true);
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
  };

  async function candidateJobOffer(jobId) {
    setOpenModalLocalization && setOpenModalLocalization(false);

    try {
      if (isGamificationLevelOneIncomplete()) {
        return;
      }

      if (isCoverLetterMissing(jobId)) {
        return;
      }

      setJobIdCandidatureBeingProcessed(jobId);
      await applyToJobOffer(jobId);
    } finally {
      setJobIdCandidatureBeingProcessed(null);
    }
  }

  const onClickEdit = (jobId) => {
    openModalWithContent(
      <ProfileForm
        handleShowSpecialNeeds={handleShowSpecialNeeds}
        handleIsLoadingState={handleIsLoadingState}
        universityColor={universityColor}
        updateAvatarState={updateAvatarState}
        updateProfileState={updateProfileState}
        closeModal={closeModal}
        profileData={profileData}
        setProfileData={setProfileData}
        fetchGamificationData={fetchGamificationData}
        isCandidate={true}
        jobId={jobId}
        applyToJobOffer={applyToJobOffer}
        zipCodeStudent={zipCodeStudent}
        {...props}
      />,
      'Edição Dados Pessoais'
    );

    setOpenModalLocalization && setOpenModalLocalization(false);
  };

  return (
    <>
      {openLocalizationModal && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={openModalLocalization}
          config={configLocationModal}
          buttonCancelFunction={handleCloseModalLocalization}
          buttonConfirmFunction={() => candidateJobOffer(selectJobId)}
          closeModalFunction={handleCloseModalLocalization}
          buttonThreeFunction={() => onClickEdit(selectJobId)}
        />
      )}

      <ContainerCards>
        <div>{returnCardJobs(dataJobOffers, visibleItems)}</div>
      </ContainerCards>

      <ContainerButtonsPagination>
        <PaginationSeeMore
          filled
          data={dataJobOffers}
          onClick={() => handleSeeMore()}
          universityColor={universityColor}
          renderedItems={returnCardJobs(dataJobOffers, visibleItems)}
        />
      </ContainerButtonsPagination>

      {openDetailsModal && (
        <DetailsJobOffer
          handleCloseModal={handleCloseModal}
          universityColor={universityColor}
          match={selectedJobMatch}
          jobId={selectedJobId}
          location={location}
          stateAndCityStudent={stateAndCityStudent}
          onClickApplyToJobOffer={() =>
            openLocalizationModal(selectedJobId, location)
          }
          candidatureBeingProcessed={jobIdCandidatureBeingProcessed !== null}
          isExclusive={true}
        />
      )}
    </>
  );
};

export default AnotherJobs;
