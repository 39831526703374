import React, { useEffect, useState } from 'react';
import iconNotMatch from 'assets/img/iconsJobOffers/iconNotMatch.png';
import iconVerifiedMatch from 'assets/img/iconsJobOffers/iconVerifiedMatch.png';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';

import {
  ContainerModal,
  ContainerContent,
  ContainerBackgroundModal,
  ParagraphDetailsMatch,
  TextDetailsMatch,
  ButtonClose,
  HeaderModal,
  ContainerColumn,
  Box,
  ContainerSimpleRow,
  ContainerDiv,
} from '../JobDetails/styled';

export function ModalUnderstendMatch({ closeModal, id, setIsLoadingData }) {
  const [infoModal, setInfoModal] = useState(null);

  const getModalMatch = async () => {
    try {
      setIsLoadingData && setIsLoadingData(true);
      const data = await JobOffersApi.getCompareMatch(id);
      setInfoModal && setInfoModal(data);
    } finally {
      setIsLoadingData && setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getModalMatch();
  }, []);

  const match = infoModal?.data;

  return (
    <div>
      <ContainerBackgroundModal>
        {match !== undefined && (
          <ContainerModal>
            <HeaderModal isDetailsMatch>
              <ContainerColumn>
                <ParagraphDetailsMatch isTitleMatch>
                  Detalhes do match
                </ParagraphDetailsMatch>
                <ParagraphDetailsMatch>
                  Confira abaixo todas as informações que são utilizadas para o
                  cálculo do match
                </ParagraphDetailsMatch>
              </ContainerColumn>
              <ButtonClose onClick={closeModal}>X</ButtonClose>
            </HeaderModal>

            <Box isDetailsMatch>
              {match &&
              match?.academic_requirements &&
              match?.academic_requirements?.courses?.length > 0 ? (
                <ContainerContent>
                  <ContainerColumn>
                    <TextDetailsMatch>Requisitos acadêmicos</TextDetailsMatch>
                    <TextDetailsMatch isParagraph>
                      <ul>
                        {match?.academic_requirements?.courses?.map(
                          (course, index) => (
                            <li key={index}>
                              {course.label}
                              {course.value === true ? (
                                <img src={iconVerifiedMatch} />
                              ) : (
                                <img src={iconNotMatch} />
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </TextDetailsMatch>
                  </ContainerColumn>
                </ContainerContent>
              ) : null}

              {match && match?.location ? (
                <ContainerContent>
                  <ContainerColumn>
                    <TextDetailsMatch>Localização</TextDetailsMatch>
                    <TextDetailsMatch isParagraph>
                      <ul>
                        <li>
                          {match?.location.label}
                          {match?.location.value === true ? (
                            <img src={iconVerifiedMatch} />
                          ) : (
                            <img src={iconNotMatch} />
                          )}
                        </li>
                      </ul>
                    </TextDetailsMatch>
                  </ContainerColumn>
                </ContainerContent>
              ) : null}

              {match && match?.shift && match?.shift?.length > 0 ? (
                <ContainerContent>
                  <ContainerColumn>
                    <TextDetailsMatch>Turno</TextDetailsMatch>
                    <TextDetailsMatch isParagraph>
                      <ul>
                        {match.shift.map((shift, index) => (
                          <li key={index}>
                            {shift.label}
                            {shift.value === true ? (
                              <img src={iconVerifiedMatch} />
                            ) : (
                              <img src={iconNotMatch} />
                            )}
                          </li>
                        ))}
                      </ul>
                    </TextDetailsMatch>
                  </ContainerColumn>
                </ContainerContent>
              ) : null}
            </Box>

            <ContainerDiv>
              <TextDetailsMatch>Legenda:</TextDetailsMatch>

              <ContainerSimpleRow>
                <img src={iconVerifiedMatch} />
                <TextDetailsMatch isParagraph>
                  Você atende a este requisito
                </TextDetailsMatch>
              </ContainerSimpleRow>

              <ContainerSimpleRow>
                <img src={iconNotMatch} />
                <TextDetailsMatch isParagraph>
                  No momento você não atende a este requisito
                </TextDetailsMatch>
              </ContainerSimpleRow>
            </ContainerDiv>
          </ContainerModal>
        )}
      </ContainerBackgroundModal>
    </div>
  );
}
