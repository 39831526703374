import { Box, Container } from './styled';
import {
  BoxImage,
  Button,
  ContainerButtons,
  ContainerDots,
  Content,
  Description,
  Flag,
  Title,
} from './styled';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { renderDots } from './Utils/Resources';
import { useContext, useState } from 'react';
import BaseLayoutContext from 'contexts/base-layout';

/**
 * InternshipTutorialModal Component
 *
 * This component displays a tutorial modal that guides the user through a series of slides
 * containing titles, descriptions, and images. Users can navigate through the slides using
 * "Next" or "Skip" buttons. When the tutorial is completed, the modal is closed, and a callback
 * function is triggered to indicate the tutorial's completion.
 *
 * @param {Object} props - Properties passed to the component.
 * @param {boolean} props.controlModal - Controls the visibility of the modal.
 * @param {function} props.setControlModal - Function to change the visibility state of the modal.
 * @param {Array} props.contentObject - Array of objects containing content (titles, descriptions, images) for each slide.
 * @param {number} props.idTutorial - ID of the current tutorial.
 * @param {function} props.tutorialFinished - Callback function called when the tutorial is finished.
 */

export function TutorialModal({
  contentObjects = [],
  configTutorial = {},
  modalOpenState,
  setModalOpenState,
}) {
  const [activeSlide, setActiveSlide] = useState(0);

  const isLastSlide = activeSlide === contentObjects.length - 1;

  const { universityColor } = useContext(BaseLayoutContext);

  const handleNextSlide = () => {
    setActiveSlide((prev) => prev + 1);
  };

  const handleCloseModal = () => {
    configTutorial.executeOnSkip && configTutorial.executeOnSkip();
    setModalOpenState(false);
  };

  if (!modalOpenState) return null;

  const renderButtons = () => {
    if (isLastSlide) {
      return (
        <ContainerButtons position="center">
          <Button
            universityColor={universityColor}
            onClick={() => configTutorial.tutorialFinished()}
            lastButton
          >
            {configTutorial.buttonTextFinish}
            {configTutorial.buttonTextFinishIcon && <MdKeyboardArrowRight />}
          </Button>
        </ContainerButtons>
      );
    }

    return (
      <ContainerButtons position={configTutorial.footerButtonsJustify}>
        <Button
          universityColor={universityColor}
          outlined
          onClick={handleCloseModal}
        >
          {configTutorial.buttonTextSkip}
        </Button>
        <Button universityColor={universityColor} onClick={handleNextSlide}>
          {configTutorial.buttonTextNext} <MdKeyboardArrowRight />
        </Button>
      </ContainerButtons>
    );
  };

  return (
    <Container isOpen={true}>
      <Box centerModal>
        <Content>
          <ContainerDots>
            {renderDots(
              activeSlide,
              setActiveSlide,
              contentObjects.length,
              universityColor
            )}
          </ContainerDots>
          <Title>{contentObjects[activeSlide].title}</Title>
          <Description>{contentObjects[activeSlide].description}</Description>
          <BoxImage>{contentObjects[activeSlide].image}</BoxImage>
          {renderButtons()}
        </Content>
      </Box>
    </Container>
  );
}
