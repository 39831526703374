import StepOneTutorial from 'assets/student/TutorialModal/StepOneTutorial';
import StepTwoTutorial from 'assets/student/TutorialModal/StepTwoTutorial';

export const contentStudentJourneyStepOne = (universityColor) => {
  return [
    {
      title:
        'Complete a Jornada do Autoconhecimento para ter acesso a vagas, conteúdos de aprendizagem e muito mais!',
      description:
        'Para completar a jornada, basta finalizar os 3 questionários listados na página inicial. ',
      image: <StepOneTutorial color={universityColor} />,
    },
    {
      title: 'Ganhe um selo exclusivo e tenha  destaque em suas candidaturas.',
      description: `Basta completar a Jornada do Protagonismo seguindo os itens listados na página inicial. 
      Além do selo exclusivo, você terá acesso ao questionário comportamental.`,
      image: <StepTwoTutorial color={universityColor} />,
    },
  ];
};

export const contentStudentJourneyStepTwo = (universityColor) => {
  return [
    {
      title: 'Ganhe um selo exclusivo e tenha  destaque em suas candidaturas.',
      description: `Basta completar a Jornada do Protagonismo seguindo os itens listados na página inicial. 
      Além do selo exclusivo, você terá acesso ao questionário comportamental.`,
      image: <StepTwoTutorial color={universityColor} />,
    },
  ];
};
