import { authenticatedAPI } from 'services/api/api';

const getChallenges = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions/company-challenge?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptionsComplementaryActivity = async (event) => {
  const response = authenticatedAPI
    .options(
      `/api/internal/university/attractions/company-challenge/${event.id}/validation`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const validationComplementaryActivity = async (event, data) => {
  const response = authenticatedAPI
    .post(
      `/api/internal/university/attractions/company-challenge/${event.id}/validation`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteValidation = async (challengeId, validationId) => {
  let url = `api/internal/university/attractions/company-challenge/${challengeId}/validation/${validationId}/update-delete`;
  const response = authenticatedAPI
    .delete(url)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const editValidation = async (challengeId, validationId, data) => {
  let url = `api/internal/university/attractions/company-challenge/${challengeId}/validation/${validationId}/update-delete`;
  const response = authenticatedAPI
    .put(url, data)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const unPublishChallenge = async (event) => {
  let url = `api/internal/university/attractions/company-challenge/unpublished/${event.id}`;
  const response = authenticatedAPI
    .patch(url)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const countChallenges = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/attractions/count`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export {
  getChallenges,
  getOptionsComplementaryActivity,
  unPublishChallenge,
  validationComplementaryActivity,
  deleteValidation,
  editValidation,
  countChallenges,
};
