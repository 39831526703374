import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import moment from 'moment';
import {
  Link,
  TitleForm,
  InfoWrapper,
  WrapperInput,
  WrapperContainer,
  MultiStepContainer,
} from 'views/ProfessionalStatus/styles/styles';
import * as Yup from 'yup';
import { maskInputPhone, maskZipCode } from 'utils/inputMasks';
import { zipCodeRequest } from 'services/zipCode';
import Input from '../../../components/Input/input';
import { validationSchemaStepFour } from './schema';
import StepHeader from '../../../components/StepsHeader';
import { useFormContext } from 'views/ProfessionalStatus/context';
import { icons } from 'views/ProfessionalStatus/utils/OptionsIcon';
import FooterForm from '../../../components/StepsFooter/FooterForm';
import Select from 'views/ProfessionalStatus/components/Select/select';
import Checkbox from 'views/ProfessionalStatus/components/Input/checkbox';
import professionalStatusApi from 'views/ProfessionalStatus/services/professionalStatusApi';
import { clearUTMParams } from 'utils/utmManagement';
import { emailBlackList } from 'services/login/recoverPassword';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export function StepFour({
  isSurveysDefinedData,
  professional_status,
  universityColor,
  options,
  professionalStatusData,
}) {
  const { formData, setFormValues, setStep } = useFormContext();
  const [zipCodeError, setZipCodeError] = useState(null);
  const [addressNumber, setAddressNumber] = useState('');
  const [isCepValid, setIsCepValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingCEP, setLoadingCEP] = useState(false);

  const [district, setDistrict] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [cep, setCep] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const formRef = useRef();
  const topDiv = useRef(null);

  const scrollToTopDiv = () => {
    if (topDiv) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToTopDiv();
  }, []);

  const handleInputChange = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, '');
    const formattedValue = sanitizedValue
      .replace(/^(\d{2})(\d)/g, '$1/$2')
      .replace(/^(\d{2})\/(\d{2})(\d)/g, '$1/$2/$3')
      .replace(/^(\d{2})\/(\d{2})\/(\d{4})/g, '$1/$2/$3');

    setBirthDate(formattedValue);
  };

  const handleZipCodeSearch = async (cepValue) => {
    setLoadingCEP(true);

    if (!cepValue) {
      setZipCodeError(null);
      setIsCepValid(false);
      setLoadingCEP(false);
      return;
    }

    if (cepValue.length < 9) {
      setZipCodeError('Informe um CEP válido.');
      setIsCepValid(false);
      setLoadingCEP(false);
      formRef.current.setFieldError('zip_code', 'Informe um CEP válido.');
      return;
    }

    try {
      const data = await zipCodeRequest(cepValue);

      if (data && !data.erro) {
        setZipCodeError(null);
        setAddress(data.logradouro);
        setDistrict(data.bairro);
        setCity(data.localidade);
        setState(data.uf);
        formRef.current.setFieldValue('addressNumber', '');
        setIsCepValid(true);
        formRef.current.setFieldError('zip_code', null);
        formRef.current.setFieldError('address', null);
        formRef.current.setFieldError('address_number', null);
        formRef.current.setFieldError('district', null);
        formRef.current.setFieldError('city', null);
        formRef.current.setFieldError('state', null);
      } else {
        setZipCodeError('CEP não encontrado.');
        setIsCepValid(false);
        formRef.current.setFieldError('zip_code', 'CEP não encontrado.');
      }
    } catch (error) {
      setZipCodeError('CEP não encontrado.');
      setIsCepValid(false);
      formRef.current.setFieldError('zip_code', 'CEP não encontrado.');
    } finally {
      setLoadingCEP(false);
    }
  };

  const handleZipCodeChange = (data) => {
    const cepValue = maskZipCode(data.target.value);
    setCep(cepValue);
    handleZipCodeSearch(cepValue);
  };

  const handlePaste = (event) => {
    const pastedData = (event.clipboardData || window.clipboardData).getData(
      'text'
    );
    const maskedData = maskZipCode(pastedData);
    setCep(maskedData);
    handleZipCodeSearch(maskedData);
  };
  useEffect(() => {
    formRef.current.setData(professionalStatusData);
    if (professionalStatusData.zip_code) {
      const formattedZipCode = maskZipCode(professionalStatusData.zip_code);
      setCep(formattedZipCode);
      handleZipCodeSearch(formattedZipCode);
    }
  }, [professionalStatusData]);

  const handleValidationErrors = async (data) => {
    try {
      await validationSchemaStepFour.validate(data, {
        abortEarly: false,
      });
      return null;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
      return validationErrors;
    }
  };

  const handleSubmit = async (data) => {
    const response = await emailBlackList({ email: data.email });
    const { is_blacklisted } = response;
    if (is_blacklisted) {
      customSnackbar(
        'E-mail inválido. Informe outro endereço de e-mail.',
        'error'
      );
      return;
    }

    try {
      const validationErrors = await handleValidationErrors(data);
      if (validationErrors) {
        formRef.current.setErrors(validationErrors);
      } else {
        setLoading(true);
        data.birth_date = moment(data.birth_date, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        );
        const response = await professionalStatusApi.postFirstAccess({
          ...formData,
          ...professional_status,
          ...data,
        });
        setLoading(false);
      }
      if (!validationErrors) {
        clearUTMParams();
        window.location.reload();
      }
      setFormValues(formData);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePrevsFormStep = () => {
    const data = formRef?.current?.getData();
    setFormValues({
      ...data,
    });
    setStep((prevsStep) => prevsStep - 1);
  };
  const HeaderControll = () => {
    if (isSurveysDefinedData) {
      return (
        <MultiStepContainer>
          <StepHeader
            Steps={['StepOne', 'StepTwo', 'StepThree', 'StepFour']}
            universityColor={universityColor}
          />
        </MultiStepContainer>
      );
    }
    return (
      <MultiStepContainer>
        <StepHeader
          Steps={['StepOne', 'StepTwo', 'StepThree']}
          universityColor={universityColor}
        />
      </MultiStepContainer>
    );
  };
  return (
    <Fragment>
      <div ref={topDiv}>
        <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
          <WrapperContainer>
            {HeaderControll()}
            <InfoWrapper>
              <TitleForm universityColor={universityColor}>
                <p>
                  Muito bem! Para você não perder nenhuma oportunidade de vaga,
                  mantenha aqui os seus dados atualizados.
                </p>
                Lembre de inserir o seu melhor e-mail para receber notificações
              </TitleForm>
              <WrapperInput>
                <Input
                  label="Nome completo*"
                  placeholder="Digite aqui..."
                  type="text"
                  name="name"
                />
                <Input
                  label="Data de nascimento*"
                  placeholder="Digite aqui..."
                  type="text"
                  name="birth_date"
                  maxLength={10}
                  value={birthDate}
                  onChange={handleInputChange}
                />
              </WrapperInput>
              <WrapperInput>
                <Input
                  label="E-mail pessoal*"
                  placeholder="Digite aqui..."
                  type="text"
                  name="email"
                />
                <Input
                  label="Celular*"
                  placeholder="Digite aqui..."
                  type="phone"
                  name="mobile_phone"
                  onChange={(e) =>
                    setPhoneNumber(maskInputPhone(e.target.value))
                  }
                  value={phoneNumber}
                />
              </WrapperInput>

              <Input
                label="CEP*"
                placeholder="00000-000"
                type="text"
                name="zip_code"
                onKeyUp={(e) => handleZipCodeChange(e)}
                onPaste={handlePaste}
                showSearchIcon={true}
                value={cep}
                onChange={(e) => setCep(maskZipCode(e.target.value))}
                loadingCEP={loadingCEP}
              />
              <Link
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
              >
                Não sei meu CEP
              </Link>
              {isCepValid && (
                <>
                  <WrapperInput>
                    <Input
                      label="Rua/Avenida*"
                      placeholder="Digite aqui..."
                      type="text"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <Input
                      label="Número*"
                      placeholder="Digite aqui..."
                      type="number"
                      name="address_number"
                      value={addressNumber}
                      onChange={(e) => setAddressNumber(e.target.value)}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <Input
                      label="Complemento"
                      placeholder="Digite aqui..."
                      type="text"
                      name="address_complement"
                    />
                    <Input
                      label="Bairro*"
                      placeholder="Digite aqui..."
                      type="text"
                      name="district"
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <Input
                      label="Cidade*"
                      placeholder="Digite aqui..."
                      type="text"
                      name="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <Input
                      label="UF*"
                      placeholder="Digite aqui..."
                      type="text"
                      name="state"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </WrapperInput>
                </>
              )}
              <TitleForm universityColor={universityColor}>
                Caso queira participar de vagas inclusivas, preencha os dados de
                diversidade.
              </TitleForm>
              <Select
                icon={icons.iconGender}
                label="Com qual identidade de gênero você se identifica?*"
                name="gender_identity"
                options={options.gender_identity}
                placeholder="Selecione"
                isMulti={false}
                universityColor={universityColor}
                infoTooltip={
                  <div>
                    <strong>Cisgênero:</strong> que se identifica com o gênero
                    (homem/mulher) atribuído no nascimento
                    <br />
                    <strong>Transgênero:</strong> que se identifica com algum
                    gênero diferente do atribuído no nascimento
                  </div>
                }
              />

              <Checkbox
                label="Desejo compartilhar essa informação em processos seletivos inclusivos"
                name="show_gender_identity"
                value="consent"
                universityColor={universityColor}
              />

              <Select
                icon={icons.iconEthnicity}
                label="Qual é a sua “cor ou raça/etnia”?*"
                name="color_or_race"
                options={options.color_or_race}
                placeholder="Selecione"
                isMulti={false}
                universityColor={universityColor}
              />

              <Checkbox
                label="Desejo compartilhar essa informação em processos seletivos inclusivos"
                name="show_color_or_race"
                value="consent1"
                universityColor={universityColor}
              />

              <Select
                icon={icons.iconDeficiency}
                label="Você é uma pessoa com deficiência? Se sim, selecione abaixo:"
                name="special_needs"
                options={options.special_needs}
                placeholder="Selecione"
                isMulti={true}
                universityColor={universityColor}
              />
              <Checkbox
                label="Desejo compartilhar essa informação em processos seletivos inclusivos"
                name="show_special_needs"
                value="consent2"
                universityColor={universityColor}
              />
            </InfoWrapper>
            <FooterForm
              haveFinish={true}
              haveBack={true}
              haveNext={false}
              prevsState={handlePrevsFormStep}
              universityColor={universityColor}
              loading={loading}
            />
          </WrapperContainer>
        </Form>
      </div>
    </Fragment>
  );
}
