import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  min-height: 129px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 4em 5em;

  position: relative;
`;

export const SubContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerSpan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 84%;

  > span {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;

    width: 100%;
    text-align: start;
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  margin-top: 36px;
  border-radius: 8px;
  background-color: #ffffff;
`;

export const Title = styled.p`
  color: #009291;
  font-weight: 700;
  font-size: 24px;

  width: 100%;
  text-align: start;
  margin-bottom: 18px;
`;

export const ButtonAddProject = styled.button`
  width: 210px;
  max-width: 285px;
  height: 34px;

  background-color: #009291;
  border: none;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 14px;
  text-align: center;

  margin-right: 10px;

  cursor: pointer;
`;

export const ContainerFilterAndAddProject = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  right: ${(props) => (props.hasFilters ? '8rem' : '6rem')};
  top: 1rem;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
