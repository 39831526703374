import { authenticatedAPI } from 'services/api/api';

const getLearningTrails = async () => {
  const response = authenticatedAPI
    .get(`api/internal/students/me/learning-trails`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveTrailProgress = async (payload) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/learning-trails`, payload)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTrailAnswer = async (trail) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/learning-trails/${trail}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveTrailAnswer = async (trail, payload) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/learning-trails/${trail}`, payload)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTrailCertificateData = async (trail) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/learning-trails/certificates/${trail}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getLearningTrails,
  saveTrailProgress,
  getTrailAnswer,
  saveTrailAnswer,
  getTrailCertificateData,
};
