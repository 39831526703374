import axios from "axios";
import getCookieValue from "utils/getCookieValue";
import URLS from "constants/urls";
import { authenticatedAPI } from 'services/api/api';

const saveBehavioralTest = async (data) => {
  let answers = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  };
  Object.keys(data).filter((item) => {
    if (data[item] === '1') {
      answers[1].push(Number(item));
    }
    if (data[item] === '2') {
      answers[2].push(Number(item));
    }
    if (data[item] === '3') {
      answers[3].push(Number(item));
    }
    if (data[item] === '4') {
      answers[4].push(Number(item));
    }
    if (data[item] === '5') {
      answers[5].push(Number(item));
    }
  });

  try {
    const response = await authenticatedAPI.post(
      `/api/internal/quizzes/behavioral`,
      answers
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export { saveBehavioralTest };
